import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { clientAxios } from '../../utility/api/admin/axiosCMS';
import { API } from '../../resources/api-constants';
import { formatDate } from 'utility/functions';

const useAgentLogic = () => {

    //get details
    const [id, setId] = useState(useParams());
    const [error, setError] = useState('');

    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [status, setStatus] = useState('');
    const [totalCredits, setTotalCredits] = useState('');
    const [type, setType] = useState('');

    const [createdBy, setCreatedBy] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [updatedAt, setUpdatedAt] = useState('');
    const [lastLogin, setLastLogin] = useState('');

    const [loadingStatus, setLoadingStatus] = useState(true);

    React.useEffect(() => {
        const getPostData = async (id: any) => {
            try {
                const response = await clientAxios.get(
                    API.ADMIN_AGENTS_LINK + '/' + id.id, {
                })

                setError('');

                setEmail(response.data.data.email);
                setFullName(response.data.data.name);
                setStatus(response.data.data.status);
                setType(response.data.data.role.title);
                setTotalCredits(response.data.data.wallet.balance);

                setCreatedBy(response.data.data.CreatedBy.name);

                setCreatedAt(formatDate(response.data.data.createdAt));
                setUpdatedAt(formatDate(response.data.data.updatedAt));
                setLastLogin(response.data.data.lastLogin);
            } catch (error) {
                setError('An error has occured. Please try again.');
            }

            setLoadingStatus(false);

        };
        getPostData(id);
    }, []);

    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const transaction = () => 'add-operator';
    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);
    const handleCloseToast = () => setShowToast(false);

    const [showToastType, setShowToastType] = useState('success');
    const result = true;

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        setShow(false);
        setShowToast(true);
    };

    return {
        showToast, handleCloseToast, showToastType,
        show, handleClose,
        handleSubmit,
        transaction,
        fullName, email, status, type, totalCredits,
        lastLogin, createdBy, createdAt, updatedAt,
        loadingStatus
    };
}

export default useAgentLogic;