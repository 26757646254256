import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Button } from 'react-bootstrap';
import { ROUTES } from '../../resources/routes-constants';

import AgentCustomToast from 'components/agents/CustomToast';

import useAgentDownlineViewLogic from 'components/agents/AgentDownlineRequestViewLogic';
import AgentConfirmationModal from 'components/agents/modals/AgentConfirmationModal';
import { formatNumberToCurrency } from 'utility/functions';
import Loader from 'components/Loader';

const AgentDownlineRequestView: React.FC = () => {
    const {
        showConfirmation, handleConfirmationSubmit, handleCloseConfirmation,
        confirmationType, handleApprove, handleDisapprove,
        showToast, handleCloseToast, showToastType,
        setShowToast, setShowToastType,
        id, fullName, status, amount,
        image, createdAt,
        loadingStatus
    } = useAgentDownlineViewLogic();

    return (
        <>
            <AgentCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
            <AgentConfirmationModal
                show={showConfirmation}
                submit={handleConfirmationSubmit} close={handleCloseConfirmation}
                confirmationType={confirmationType} requestId={id}
                setShowToast={setShowToast} setShowToastType={setShowToastType}
            />

            <Container>
                <Row>
                    <Col
                        lg={{ span: 8, offset: 2 }}
                        sm={{ span: 12 }}
                        className="text-center mb-5">
                        <h4>View Downline <span className='highlightText px-2'>Credit Request</span></h4>
                    </Col>
                </Row>
                {loadingStatus ?
                    <Loader />
                    :
                    <>
                        <Row>
                            <Col
                                lg={{ span: 8, offset: 2 }}
                                sm={{ span: 12 }}
                                className='boxContainer px-5 pt-4 pb-4'>
                                <Row>
                                    <Col
                                        md={{ span: 6 }}
                                        className='text-center'
                                    >
                                        <img
                                            style={{ maxWidth: '200px' }}
                                            src={
                                                image
                                                    ?
                                                    image :
                                                    'images/placeholder.png'
                                            }
                                            className='img-thumbnail mb-2' text-center
                                            alt='gcash screenshot' />
                                    </Col>
                                    <Col
                                        md={{ span: 6 }}
                                    >
                                        <div className='mb-2'><span className='h5 me-2'>Name:</span><span className='h6'>{fullName}</span></div>
                                        <div className='mb-2'><span className='h5 me-2'>Amount:</span><span className='text-warning h6'>{formatNumberToCurrency(amount)}</span></div>
                                        <div className=''><span className='h5 me-2'>Requested Date:</span><span className='h6'>{createdAt}</span></div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                lg={{ span: 8, offset: 2 }}
                                sm={{ span: 12 }}
                                className='boxContainer'
                            >
                                <div className='text-end mt-2 mb-4'>
                                    <Button variant='secondary' className='me-2' onClick={handleDisapprove}>Disapprove</Button>
                                    <Button variant='primary' className='me-2' onClick={handleApprove}>Approve</Button>

                                </div>
                                <div className='text-center mt-2 mb-4'>
                                    <a href={ROUTES.AGENT_DOWNLINE_REQUESTS_ROUTE + '/1'}>&#8592; Back to Request List</a>
                                </div>
                            </Col>
                        </Row>
                    </>
                }
            </Container >
        </>
    );
}

export default AgentDownlineRequestView;