import React, { useState } from 'react';

const usePageLogic = () => {

    const [showToast, setShowToast] = useState(false);

    const handleSubmit = (e: any) => {
        e.preventDefault();

        setShowToast(true);
    }

    const handleCloseToast = () => setShowToast(false);

    return {
        showToast, handleCloseToast, handleSubmit
    };
}

export default usePageLogic;