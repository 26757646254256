import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Contact: React.FC = () => {
    return (
        <div>
            <Container>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>Contact <span className='highlightText px-2'>Us</span></h4>
                    </Col>
                </Row>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        className='boxContainer p-5 text-center'>
                        <h6 className='text-warning d-inline'>Visit our Facebook Page for support and inquiries:</h6>
                        <div className='ms-2 d-inline'><a href="https://www.facebook.com/ggwplayofficial" target="_new">GGWPlay</a></div>

                    </Col>
                </Row>
            </Container >
        </div >
    );
}

export default Contact;