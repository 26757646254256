import axios from "axios";
import { API } from "resources/api-constants";

const CMS = API.CMS_URL;

export const clientAxios = axios.create({
   baseURL: CMS,
   headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access-token') }
});

export const clientAxiosRefresh = axios.create({
   baseURL: CMS,
   headers: {
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + localStorage.getItem('access-token')
   },
   responseType: "json"
});

export const refreshToken = () => {
   let result = false;

   clientAxios.post(
      API.OPERATOR_REFRESH_TOKEN
   ).then((response) => {
      localStorage.setItem('access-token', response.data.access.token);
      localStorage.setItem('login-date', String(Date.now()));
      localStorage.setItem('expires', response.data.access.expires_in);

      result = true;
   }).catch((error) => {
      result = false;
   });

   return result;
}

export const validToken = () => {
   var result = true;
   clientAxios.post(
      API.PLAYER_VALID_TOKEN, {},
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access-token') } }
   ).then((response) => {

      result = response.data.valid;

      localStorage.setItem('validToken', result);
   }).catch((error) => {
      result = false;
      localStorage.setItem('validToken', false);
   });
}