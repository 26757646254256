import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../resources/routes-constants'

const NotFoundPage: React.FC = () => {

    return (
        <>
            <Container>
                <Row className='vh-100 d-flex align-items-center text-center'>
                    <Col className=''>
                        <p className='display-1'>Oops!</p>
                        <p className='text-warning display-6'>404 - Page Not Found</p>
                        <p className='text- display-8'>Try refreshing the page</p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default NotFoundPage
