import React, { useState } from 'react';

import { clientAxios } from '../../utility/api/admin/axiosCMS';
import { API } from '../../resources/api-constants';
import { useParams } from 'react-router-dom';

const useAgentListLogic = () => {
    //get agents
    const [post, setPost] = React.useState({ data: Array(2) });
    const [error, setError] = useState(null);
    const [total, setTotal] = React.useState(0);

    // function to fetch all table data
    const [currentPage, setCurrentPage] = useState(useParams());
    const [pageNumber, setPageNumber] = useState(currentPage.id);

    const [loadingStatus, setLoadingStatus] = useState(true);

    if (!pageNumber) {
        setPageNumber('1');
    }

    function getPostData(pageNumber: any, searchParam = '', type = '') {
        clientAxios.get(
            API.ADMIN_AGENTS_LINK, {
            params: {
                offset: API.NUM_PER_PAGE * (pageNumber - 1),
                limit: API.NUM_PER_PAGE,
                search: searchParam,
                filter: type
            }
        }
        ).then((response) => {
            setPost(response.data);
            setTotal(response.data.count);
            setError(null);
            setLoadingStatus(false);
        }).catch(setError)
    }

    //fetch on initial load
    React.useEffect(() => {
        getPostData(pageNumber);

    }, []);

    // ADD AGENT
    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const transaction = 'add-agent';
    const handleClose = () => setShow(false);
    const handleCloseToast = () => setShowToast(false);

    const [showToastType, setShowToastType] = useState('success');

    const handleSubmit = function (e: any, result: any) {

        e.preventDefault();

        //api call
        if (result) {
            setShowToastType('success');
            getPostData(pageNumber);
        } else {
            setShowToastType('fail');
        }

        setShow(false);
        setShowToast(true);
    }

    const handleShow = (type: any) => setShow(true);
    //END ADD AGENT

    //CONFIRM DEACTIVATE
    const [showConfirmation, setConfirmation] = useState(false);
    const [fullName, setFullName] = useState('');
    const [id, setId] = useState('');
    const handleCloseConfirmation = () => setConfirmation(false);

    const [confirmationType, setConfirmationType] = useState('');

    const handleConfirmation = function (type: any, fullName: any, propId: any) {
        setConfirmation(true);

        setConfirmationType(type);
        setFullName(fullName);
        setId(propId);

    }
    // END CONFIRM DEACTIVATE

    // ACTIVATE OR DEACTIVATE | RESET PASSWORD
    const [deactivateResult, setDeactivateResult] = React.useState({ data: Array(2) });
    const [errorDeactivate, setErrorDeactivate] = useState(null);

    const handleConfirmationSubmit = (action: any) => (e: any) => {

        setConfirmation(false);
        e.preventDefault();

        //api call
        if ('reset' === action) {
            clientAxios.post(
                API.ADMIN_AGENTS_DEACTIVATE_LINK + '/' + id + '/' + action,
                {
                }
            ).then((response) => {
                setDeactivateResult(response);
                setShowToastType('success');
                getPostData(pageNumber);
            }).catch((error) => {
                setShowToastType('fail');
                setErrorDeactivate(error);
            });
        } else {
            clientAxios.patch(
                API.ADMIN_AGENTS_DEACTIVATE_LINK + '/' + id + '/' + action,
                {
                }
            ).then((response) => {
                setDeactivateResult(response);
                setShowToastType('success');
                getPostData(pageNumber);
            }).catch((error) => {
                setShowToastType('fail');
                setErrorDeactivate(error);
            });
        }

        setShowToast(true); // show toast

    }
    // END ACTIVATE OR DEACTIVATE

    //SEARCH
    const [showCloseButton, setShowCloseButton] = useState('invisible');
    const [searchParam, setSearchParam] = useState('');

    // Input Field handler
    const handleSearchInput = (e: any) => {
        setSearchParam(e.target.value);
    };


    const handleSearch = (e: any) => {
        e.preventDefault();

        // setSearchParam(e.target.searchParam.value);
        // searchParam = e.target.searchParam.value;
        setShowCloseButton('visible');
        getPostData(1, searchParam, agentType);
    }

    const resetSearch = (e: any) => {
        setSearchParam('');

        e.preventDefault();

        setShowCloseButton('invisible');

        getPostData(1, '', agentType);
    }
    //END SEARCH

    //FILTER
    const [dropdownText, setDropdownText] = useState('All');
    const [agentType, setAgentType] = useState('');
    const handleDropdown = (e: any) => {

        e.preventDefault();

        setDropdownText(e.target.text);

        let agentType = '';
        let agentText = (e.target.text);
        if ('Master Agent' === agentText) {
            agentType = 'Master Agent';
        } else if ('Sub Agent' === agentText) {
            agentType = 'Sub Agent';
        } else {
            agentType = '';
        }

        setAgentType(agentType);

        getPostData(1, searchParam, agentType);
    }

    //END FILTER

    return {
        showToast, handleCloseToast, showToastType,
        show, handleClose, handleSubmit, transaction,
        showConfirmation, handleConfirmationSubmit, handleCloseConfirmation, confirmationType, fullName, id,
        handleConfirmation, handleSearch, resetSearch, handleSearchInput, searchParam,
        handleShow, showCloseButton,
        post, total, pageNumber,
        handleDropdown, dropdownText,
        loadingStatus
    };
}

export default useAgentListLogic;