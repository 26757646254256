import React from 'react'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card } from 'react-bootstrap';

// const Trends: React.FC = (props: any) => {
const Trends = (props: any) => {

    const runCallback = (cb: any) => {
        return cb();
    };

    const postTrends = props.postTrends;
    const loadingStatusTrends = props.loadingStatusTrends;

    return (
        <Card className="play-card mb-2">
            <Card.Body className=''>
                <div className="mb-3">
                    <div className="play_resultLabel">
                        <div className="meron">
                            <div className="circle meron"><span id="lblResultMeron">{/*meronCount*/}</span></div>
                            MERON
                        </div>
                        <div className="wala">
                            <div className="circle wala"><span id="lblResultWala">{/*walaCount*/}</span></div>
                            WALA
                        </div>
                        <div className="draw">
                            <div className="circle draw"><span id="lblResultDraw">{/*drawCount*/}</span></div>
                            DRAW
                        </div>
                        <div className="meron">
                            <div className="circle cancel"><span id="lblResultCancel">{/*cancelCount*/}</span></div>
                            CANCEL
                        </div>
                    </div>
                </div>
                <div className="gp-panel-bg rounded-bottom landscape-trend">
                    <div className="table-responsive pt-2 pb-2 gp-scroll trend-table-wrapper">
                        <table className="trend-table">
                            <tbody>
                                {/*TODO*/}
                                {
                                    runCallback(() => {
                                        if (!loadingStatusTrends) {
                                            const tableRows: any[] = [];
                                            const mainRow1: any[] = [], mainRow2 = [], mainRow3 = [], mainRow4 = [], mainRow5 = [], mainRow6 = [];
                                            const row1: any[] = [], row2 = [], row3 = [], row4 = [], row5 = [], row6 = [];
                                            const total = postTrends.data.length;

                                            let trCount = 1; // current row
                                            let prevResult = 0; // previous result
                                            let stopRow = 0;
                                            let gameNumber = 0;

                                            if (total > 0) {
                                                for (var i = 0; i < total; i++) {

                                                    let val = postTrends.data[i].winner;
                                                    if (0 === i)
                                                        prevResult = 9999999;
                                                    else
                                                        prevResult = postTrends.data[i - 1].winner;

                                                    //gameNumber = i + 1; //temporary while waiting for fightNumber field
                                                    gameNumber = postTrends.data[i].matchNo;

                                                    if (trCount > 6) {
                                                        trCount = 1;
                                                    } else if ((val !== prevResult)) {
                                                        if ((4 !== val)) {
                                                            stopRow = trCount;
                                                            trCount = 1;
                                                        }
                                                    } else if (val === prevResult) {
                                                        if ((4 === val)) {
                                                            stopRow = trCount;
                                                            trCount = 1;
                                                        }
                                                    }

                                                    if (1 === trCount) {
                                                        if (val === 'blue') {
                                                            row1.push(<td key={Date.now() + (Math.random() * 999)}><div className="trend-item trend-wala ">{gameNumber}</div></td>)
                                                        } else if (val === 'red') {
                                                            row1.push(<td key={Date.now() + (Math.random() * 999)}><div className="trend-item trend-meron ">{gameNumber}</div></td>)
                                                        } else if (val === 'draw') {
                                                            row1.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-draw ">{gameNumber}</div></td>)
                                                        } else {
                                                            row1.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-cancel ">x</div></td>)
                                                        }
                                                    } else if (2 === trCount) {
                                                        if (val === 'blue') {
                                                            row2.push(<td key={Date.now() + (Math.random() * 999)}><div className="trend-item trend-wala ">{gameNumber}</div></td>)
                                                        } else if (val === 'red') {
                                                            row2.push(<td key={Date.now() + (Math.random() * 999)}><div className="trend-item trend-meron ">{gameNumber}</div></td>)
                                                        } else if (val === 'draw') {
                                                            row2.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-draw ">{gameNumber}</div></td>)
                                                        } else {
                                                            row2.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-cancel ">x</div></td>)
                                                        }
                                                    } else if (3 === trCount) {
                                                        if (val === 'blue') {
                                                            row3.push(<td key={Date.now() + (Math.random() * 999)}><div className="trend-item trend-wala ">{gameNumber}</div></td>)
                                                        } else if (val === 'red') {
                                                            row3.push(<td key={Date.now() + (Math.random() * 999)}><div className="trend-item trend-meron ">{gameNumber}</div></td>)
                                                        } else if (val === 'draw') {
                                                            row3.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-draw ">{gameNumber}</div></td>)
                                                        } else {
                                                            row3.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-cancel ">x</div></td>)
                                                        }
                                                    } else if (4 === trCount) {
                                                        if (val === 'blue') {
                                                            row4.push(<td key={Date.now() + (Math.random() * 999)}><div className="trend-item trend-wala ">{gameNumber}</div></td>)
                                                        } else if (val === 'red') {
                                                            row4.push(<td key={Date.now() + (Math.random() * 999)}><div className="trend-item trend-meron ">{gameNumber}</div></td>)
                                                        } else if (val === 'draw') {
                                                            row4.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-draw ">{gameNumber}</div></td>)
                                                        } else {
                                                            row4.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-cancel ">x</div></td>)
                                                        }
                                                    } else if (5 === trCount) {
                                                        if (val === 'blue') {
                                                            row5.push(<td key={Date.now() + (Math.random() * 999)}><div className="trend-item trend-wala ">{gameNumber}</div></td>)
                                                        } else if (val === 'red') {
                                                            row5.push(<td key={Date.now() + (Math.random() * 999)}><div className="trend-item trend-meron ">{gameNumber}</div></td>)
                                                        } else if (val === 'draw') {
                                                            row5.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-draw ">{gameNumber}</div></td>)
                                                        } else {
                                                            row5.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-cancel ">x</div></td>)
                                                        }
                                                    } else {
                                                        if (val === 'blue') {
                                                            row6.push(<td key={Date.now() + (Math.random() * 999)}><div className="trend-item trend-wala ">{gameNumber}</div></td>)
                                                        } else if (val === 'red') {
                                                            row6.push(<td key={Date.now() + (Math.random() * 999)}><div className="trend-item trend-meron ">{gameNumber}</div></td>)
                                                        } else if (val === 'draw') {
                                                            row6.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-draw ">{gameNumber}</div></td>)
                                                        } else {
                                                            row6.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-cancel ">x</div></td>)
                                                        }
                                                    }

                                                    if ((val !== prevResult && i > 0 && (4 !== val))
                                                        || (val === prevResult) && (4 === val) && i > 0) {
                                                        for (var j = stopRow; j <= 6; j++) {
                                                            if (1 === j) {
                                                                row1.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-empty"></div></td>);
                                                            } else if (2 === j) {
                                                                row2.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-empty"></div></td>);
                                                            } else if (3 === j) {
                                                                row3.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-empty"></div></td>);
                                                            } else if (4 === j) {
                                                                row4.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-empty"></div></td>);
                                                            } else if (5 === j) {
                                                                row5.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-empty"></div></td>);
                                                            } else {
                                                                row6.push(<td key={Date.now()+(Math.random()*999)}><div className="trend-item trend-empty"></div></td>);
                                                            }
                                                        }
                                                    }

                                                    trCount++;
                                                }

                                                mainRow1.push(<tr key={1}>{row1}</tr>);
                                                mainRow2.push(<tr key={2}>{row2}</tr>);
                                                mainRow3.push(<tr key={3}>{row3}</tr>);
                                                mainRow4.push(<tr key={4}>{row4}</tr>);
                                                mainRow5.push(<tr key={5}>{row5}</tr>);
                                                mainRow6.push(<tr key={6}>{row6}</tr>);

                                                tableRows.push(mainRow1);
                                                tableRows.push(mainRow2);
                                                tableRows.push(mainRow3);
                                                tableRows.push(mainRow4);
                                                tableRows.push(mainRow5);
                                                tableRows.push(mainRow6);

                                                return tableRows;
                                            }
                                        }
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default Trends
