import React, { useEffect, useState } from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Card, Collapse } from 'react-bootstrap';
import { API } from 'resources/api-constants';
import { clientAxios, refreshToken } from 'utility/api/members/axiosPlayer';
import { formatNumberToCurrency, roundNumberTwoDecimals } from 'utility/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { ROUTES } from 'resources/routes-constants';
import { pusherInit } from '../../../utility/pusher';
import CustomModal from '../../../components/members/modals/CustomModal';

const Bet = (props: any) => {
    // const Bet: React.FC = (props: any) => {
    const matchId = props.matchIdCurrent;
    const fightNumber = props.fightNumber;
    const [betStatus, setBetStatus] = useState(props.betStatus);
    const [settleStatus, setSettleStatus] = useState(props.settleStatus);

    const team1 = props.team1;
    const team2 = props.team2;
    const pusherEvent = props.pusherEvent;
    const handleBetSubmit = props.handleBetSubmit;
    const handleUpdateMatchDetails = props.handleUpdateMatchDetails;

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loadingStatus, setLoadingStatus] = useState(true);

    const [betAmount, setBetAmount] = useState(0);
    const [betChoice, setBetChoice] = useState('');

    const [matchResult, setMatchResult] = useState(props.matchResult);

    const [betTeam1, setBetTeam1] = useState(0);
    const [betTeam2, setBetTeam2] = useState(0);
    const [betPercentTeam1, setBetPercentTeam1] = useState('');
    const [betPercentTeam2, setBetPercentTeam2] = useState('');

    const pusherChannel = props.pusherChannel;

    //MODAL FUNCTIONS
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const [transaction, setTransaction] = useState('bet-balance');
    const [chosenTeam, setChosenTeam] = useState('');

    const handleShow = (e: any) => {
        e.preventDefault();

        const betAmount = e.target.betAmount.value;
        const betBalance = Number(localStorage.getItem('total-credits'));

        setBetAmount(e.target.betAmount.value);

        if (betAmount > betBalance) { // not enough balance
            setTransaction('bet-balance');
            setShow(true);
        } else {
            setTransaction('bet-choice');


            if (betChoice === 'red') {
                setChosenTeam(team2);
            } else if (betChoice === 'blue') {
                setChosenTeam(team1);
            }

            setShow(true);
        }

        setShow(true);
    };
    //END MODAL FUNCTIONS

    //FETCH BETS
    function getPostData() {

        const currentMatch = localStorage.getItem('current-match');

        clientAxios.get(
            API.PLAYER_BET_CURRENT_LINK + '/' + currentMatch + '/bets-placed',
        ).then((response) => {
            if (response.data.count > 0) {
                setBetTeam1(response.data.data.blueTotalBets);
                setBetTeam2(response.data.data.redTotalBets);

                setBetPercentTeam1(response.data.data.bluePercentage);
                setBetPercentTeam2(response.data.data.redPercentage);
                //calculateBets(response.data.data.redTotalBets, response.data.data.blueTotalBets);
            } else {
                setBetTeam1(0);
                setBetTeam2(0);

                setBetPercentTeam1('0');
                setBetPercentTeam2('0');
            }

            setError(false);

        }).catch((error) => {
            setError(true);
        }).finally(() => {
            //reset bets to 0 after match is settled
            if ('1' == settleStatus || 1 == settleStatus) {
                setBetTeam1(0);
                setBetTeam2(0);
            }
        });

        setLoadingStatus(false);
    }

    //GET USER BETS
    const [playerBetTeam1, setPlayerBetTeam1] = useState(0);
    const [playerBetTeam2, setPlayerBetTeam2] = useState(0);
    function getUserBets() {
        const currentMatch = localStorage.getItem('current-match');

        clientAxios.get(
            API.PLAYER_BET_PLACED,
            {
                params: {
                    gid: localStorage.getItem('current-game'), //gameid
                    mid: currentMatch //matchid
                }
            }
        ).then((response) => {
            //console.log(response.data)
            if (response.data.count > 0) {
 
                response.data?.data?.forEach((bet: any, index: any) => {
                    if (bet.team === 'blue') {
                        setPlayerBetTeam1(bet.total);
                    } else {
                        setPlayerBetTeam2(bet.total);
                    }
                });
            } else {

            }

        }).catch((error) => {
        });
    }

    //GET TOTAL CREDITS
    function getTotalCredits() {
        clientAxios.get(
            API.PLAYER_ACCOUNT_LINK,
            {}
        ).then((response) => {
            localStorage.setItem('total-credits', response.data.data.wallet.balance);

            //update balance text
            const creditsObj = document.getElementById('totalCredits');
            Object(creditsObj).innerHTML = formatNumberToCurrency(response.data.data.wallet.balance);

        }).catch((error) => {
        });
    }

    //END GET TOTAL CREDITS

    React.useEffect(() => {
        
        Promise.all([getPostData(), getUserBets()]).then(results => {
            setLoadingStatus(false);
        });

        //const channel1 = pusherChannel.subscribe('ez-app');

        pusherChannel.bind(pusherEvent, function (data: any) {
            if (data.type === 'PLAYER_BET') {
                setBetTeam1(data.data.blueTotalBets);
                setBetTeam2(data.data.redTotalBets);

                setBetPercentTeam1(data.data.bluePercentage);
                setBetPercentTeam2(data.data.redPercentage);
            } else if (data.type === 'UPDATE_WINNER_RESULT') {
                setMatchResult(data.data);
            } else if (data.type === 'UPDATE_BET_STATUS') {
                if (data.data.status) {
                    setBetStatus(data.data.status.status);
                } else {
                    setBetStatus(3);
                }
            } else if (data.type === 'CREATE_NEXT_MATCH') {
                window.location.reload();
                //handleUpdateMatchDetails();
            } else if (data.type === 'MATCH_SETTLED') {
                //getPostData();
                setSettleStatus('Settled');

                getTotalCredits();
            } else if (data.type === 'UPDATE_MATCH_DETAILS') {
                window.location.reload();
                //handleUpdateMatchDetails();
            }

        });

        //return (() => {
        //    pusherChannel.unsubscribe('ez-app');
        //})
    }, []);
    //END FETCH BETS

    const [betLoading, setBetLoading] = useState(false);

    const handleSubmit = function (e: any) {
        //e.preventDefault();

        const betBalance = Number(localStorage.getItem('total-credits'));

        clientAxios.post(
            API.PLAYER_BET_LINK + '/' + localStorage.getItem('current-match') + '/play',
            {
                team: betChoice,
                amount: betAmount
            }
        ).then((response) => {
            setErrorMessage(response.data.message);
            handleBetSubmit(true);

            getTotalCredits();

            // getPostData();
        }).catch((error) => {
        }).finally(() => {
            setBetAmount(0);
            //setTimeout(
            //    () => setBetLoading(false),
            //    500
            //);
            setShow(false);
            getUserBets();
        });
    }

    const chooseBet = (choice: any) => (e: any) => {

        setBetChoice(choice);
    };

    const handleClick = (val: any) => (e: any) => {
        e.preventDefault();

        setBetAmount(val);
    };

    return (
        <>
            <CustomModal show={show} close={handleClose} transaction={transaction} betChoice={chosenTeam} amount={betAmount} submit={handleSubmit} />

        <Card id='bets' className="play-card mb-2">
            <Card.Body className='mt-3 p-0 play'
                style={{ minHeight: '426px' }}
            >
                {/* BET FORM */}
                {/* LIVE BETS */}
                <form onSubmit={handleShow}>
                <table className="currentGame">
                    <tbody>
                        <tr>
                            <td className="col-4">
                                {'Settled' === settleStatus ?
                                    <span className='text-open-bet h5 m-0' key={1}>SETTLED</span> :
                                    (1 === betStatus) ?
                                        <span className='text-open-bet h5 m-0' key={1}>OPEN</span> :
                                        (3 === betStatus) ?
                                            <span className='text-warning-bet h5 m-0 blinkText' key={2}>LAST CALL</span> :
                                            (2 === betStatus) ?
                                                <span className='text-closed-bet h5 m-0' key={0}>CLOSED</span> :
                                                <span className='text-secondary h5 m-0' key={3}>PENDING</span>
                                }
                            </td>
                            <td className="col-4 blink_me">
                                {
                                    (('' === matchResult) || ('null' === matchResult) || (null === matchResult)) ?
                                        <>
                                        </>
                                        :
                                        <>
                                        {("red" === matchResult) ?
                                            <h3 className='bg-danger'><span id="lblWinnerFight" className="text-winner-bet text-danger bg-danger"></span>{team2}</h3>
                                            :
                                            ("blue" === matchResult) ?
                                                            <h3 className='bg-blue'><span id="lblWinnerFight" className="text-winner-bet text-primary bg-blue"></span>{team1}</h3>
                                                :
                                                ("draw" === matchResult) ?
                                                    <><h3 className='bg-orange'><span id="lblWinnerFight" className="text-winner-bet"></span>Draw</h3></>
                                                    :
                                                    <><h3 className='bg-cancel'> <span id="lblWinnerFight" className="text-winner-bet"></span>Cancelled</h3></>
                                        }
                                        </>
                                }
                            </td>
                            {
                                (fightNumber > 0) ?
                                    <td className="col-4"> FIGHT#: <span><span id="lblGameInfoGameNumber" className="text-fightnumber">{fightNumber}</span></span></td>
                                    :
                                    <></>
                            }
                        </tr>
                    </tbody>
                </table>
                
                <div className="bets">
                    
                        {/* Team 2 */}
                        <div className="betMeron">
                        <div className="title bg-red">{team2}</div>
                            <div className="info">
                            <div className="totalBets">
                                <span id="tdTotalBetMeron-1" className="">{formatNumberToCurrency(betTeam2)}</span>
                            </div>
                            <div className="payout">PAYOUT = <span id="tdPayOutMeron">{betPercentTeam2}</span></div>
                            <div className="currentBet">
                                <span id="tdPersonalBetMeron-1">
                                    {playerBetTeam2}
                                </span>
                                        <br />
                                        {(2 === betStatus) ?
                                            <span id="lblMeronWinning" className="bet-amount bet-win">{roundNumberTwoDecimals(Number(playerBetTeam2 * (Number(betPercentTeam2)) / 100))}</span>
                                            :
                                            <span id="lblMeronWinning" className="bet-amount bet-win">0</span>
                                        }
                                        
                            </div>
                            {(betStatus === 1 || betStatus === 3)
                                ?
                                    <button onClick={chooseBet('red')} type="submit" className="btn btn-block btn-lg btn-green" data-id="1" data-name="M" id="btnMeronBet-1" defaultValue="MERONBET" title="MERON 10-10">
                                    <FontAwesomeIcon icon={faPlusCircle} />BET {team2}
                                </button>
                                :
                                <button type="button" className="btn btn-block btn-lg" data-id="1" data-name="M" id="btnMeronBet-1" defaultValue="MERONBET" title="MERON 10-10">
                                    <FontAwesomeIcon icon={faPlusCircle} />BET {team2}
                                </button>
                            }
                        </div>
                    </div>

                    {/* Team 1 */}
                    <div className="betWala">
                        <div className="title bg-blue">{team1}</div>
                        <div className="info">
                            <div className="totalBets">
                                <span id="tdTotalBetWala-1" className="">{formatNumberToCurrency(betTeam1)}</span>
                            </div>
                            <div className="payout">PAYOUT = <span id="tdPayOutWala">{betPercentTeam1}</span></div>
                            <div className="currentBet">
                                <span id="tdPersonalOdd-1" className="hidden">
                                    {playerBetTeam1}
                                </span>
                                    <br />
                                    {(2 === betStatus) ?
                                        <span id="lblWalaWinning" className="bet-amount bet-win">{roundNumberTwoDecimals(Number(playerBetTeam1 * (Number(betPercentTeam1)) / 100))}</span>
                                        :
                                            <span id="lblWalaWinning" className="bet-amount bet-win">0</span>
                                    }
                                       
                            </div>
                            {(betStatus === 1 || betStatus === 3)
                                ?
                                <button onClick={chooseBet('blue')} type="submit" className="btn btn-info btn-block btn-lg btn-green" data-id="1" data-name="W" id="btnWalaBet-1" defaultValue="WALABET" title="WALA 10-10">
                                    <FontAwesomeIcon icon={faPlusCircle} /> BET {team1}
                                </button>
                                :
                                <button type="button" className="btn btn-info btn-block closed btn-lg " data-id="1" data-name="W" id="btnWalaBet-1" defaultValue="WALABET" title="WALA 10-10">
                                    <FontAwesomeIcon icon={faPlusCircle} /> BET {team1}
                                </button>
                            }

                        </div>
                    </div>
                    
                </div>

                <Row>
                    <Col className=' p-3 text-center'>
                        
                            <div className="amount">

                                <input
                                    type="number" min='20' step='.01' inputMode="numeric"
                                    className='input-lg textColorBet text-center form-control mt-0'
                                    name='betAmount'
                                    maxLength={12}
                                    defaultValue={betAmount}
                                    key={betAmount}
                                    placeholder="Enter Amount"
                                    required
                                // pattern="\d*"
                                 />

                                <button className="clearBtn " type="button" defaultValue="" onClick={handleClick('')}>CLEAR</button>
                            </div>
                            <div className="amountsBet">
                                <button className="btn btn-outline btn-xs quickbet mr-1" type="button" onClick={handleClick(roundNumberTwoDecimals(localStorage.getItem('total-credits')))}>All-In</button>
                                <button className="btn btn-outline btn-xs quickbet mr-1" type="button" onClick={handleClick(500)}>500</button>
                                <button className="btn btn-outline btn-xs quickbet mr-1" type="button" onClick={handleClick(100)}>100</button>
                                <button className="btn btn-outline btn-xs quickbet mr-1" type="button" onClick={handleClick(50)}>50</button>
                                <button className="btn btn-outline btn-xs quickbet mr-1" type="button" onClick={handleClick(20)}>20</button>
                            </div>
                            <div>
                                <span className='text-danger'>&nbsp;{errorMessage}</span>
                            </div>
                    </Col>
                </Row>
                    {/* END ---LIVE BETS */}
                {/* END BET FORM */}

                </form>
            </Card.Body>
            </Card >
        </>
    )
}

export default Bet;