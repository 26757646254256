import React, { useState } from 'react'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card } from 'react-bootstrap';
import { clientAxios } from 'utility/api/members/axiosPlayer';
import { API } from 'resources/api-constants';
import { formatDateWithTime, formatNumberToCurrency } from 'utility/functions';

// const ChoiceHistory: React.FC = () => {
const ChoiceHistory = (props: any) => {

    const loadChoices = props.loadChoices;
    const handleBetSubmit = props.handleBetSubmit;
    const settleStatus = props.settleStatus;

    const runCallback = (cb: any) => {
        return cb();
    };

    const [post, setPost] = useState({ data: Array(2) });
    //const [error, setError] = useState(false);

    //const [loadingStatus, setLoadingStatus] = useState(true);

    async function getPostData() {

        await clientAxios.get(
            API.PLAYER_BET_HISTORY_LINK,
            {
                //id: localStorage.getItem('user-id')
            }
        ).then((response) => {
            setPost(response.data);

            handleBetSubmit(false);
        }).catch((error) => {
            //setError(true);
        });

        //setLoadingStatus(false);
    }

    //fetch on initial load
    React.useEffect(() => {
        if (loadChoices)
            getPostData();

        // if (loadChoices || (loadChoices == 'true') || (settleStatus) || (settleStatus == '1')) {
        //     getPostData();
        // }
        // getPostDataInterval();
        if ('1' === settleStatus) {
            getPostData();
        }
    }, [loadChoices, settleStatus]);
    //END GET MATCH DETAILS

    return (
        <Card className="play-card mh-75 pb-2"
        >
            <Card.Header className=''>CHOICE HISTORY (Scroll down)</Card.Header>
            <Card.Body className='pb-4'
                style={{ minHeight: '295px', maxHeight: '295px', overflow: 'auto' }}
            >

                {
                    runCallback(() => {
                        const rows1 = Array(0);
                        let dataCount = 0;

                        post.data.forEach((matchObj, count) => {
                            const cols = Array();
                            const span = Array(), span2 = Array();

                            if (matchObj.length === 0) {
                                return;
                            }

                            if (('Settled' === matchObj.settleStatus)) {

                                if ('cancelled' === matchObj.result) {
                                    span.push(<span key={Date.now() + (Math.random() * 999)} className='text-left h6 style-cancel'>Fight #{matchObj.matchNo}</span>);
                                } else if ('draw' === matchObj.result) {
                                    span.push(<span key={Date.now() + (Math.random() * 999)} className='text-left h6 style-draw'>Fight #{matchObj.matchNo}</span>);
                                } else if ('blue' === matchObj.result) {
                                    span.push(<span key={Date.now() + (Math.random() * 999)} className='text-left h6 style-wala'>Fight #{matchObj.matchNo}</span>);
                                } else {
                                    span.push(<span key={Date.now() + (Math.random() * 999)} className='text-left h6 style-meron'>Fight #{matchObj.matchNo}</span>);
                                }
                                cols.push(
                                    <Col key={Date.now() + (Math.random() * 999)} xs={{ span: 4 }} className='align-middle m-auto'>
                                        <span key={Date.now() + (Math.random() * 999)} className='text-left h6'>{matchObj.gameName}</span><br />
                                        {span}
                                    </Col>);

                                cols.push(
                                    <Col key={Date.now() + (Math.random() * 999)} xs={{ span: 2 }} className='align-middle m-auto'>
                                        <span key={Date.now() + (Math.random() * 999)} className='text-left h6 text-warning'>{formatNumberToCurrency(matchObj.amount)}</span>
                                    </Col>
                                );

                                let winPercent = ((((Number(matchObj.winAmount) - matchObj.amount) / Number(matchObj.amount)) + 1) * 100);
                                let winPercent_p = Number(winPercent).toFixed(2);

                                if ('win' === matchObj.betResult) {
                                    span2.push(<div key={Date.now() + (Math.random() * 999)} className='text-end'><span className='h6'><span className='text-success'>Win </span>
                                        <span key={Date.now() + (Math.random() * 999)} className='text-warning'>{winPercent_p}%</span> {formatNumberToCurrency(matchObj.winAmount)}</span></div>)
                                } else if ('draw' === matchObj.result) {
                                    span2.push(<div key={Date.now() + (Math.random() * 999)} className='text-end'><span className='text-warning h6'>Draw</span></div>)
                                } else if ('cancelled' === matchObj.result || 'cancelled' === matchObj.betResult) {
                                    span2.push(<div key={Date.now() + (Math.random() * 999)} className='text-end'><span className='text-secondary h6'>Cancelled</span></div>)
                                } else {
                                    span2.push(<div key={Date.now() + (Math.random() * 999)} className='text-end'><span className='text-danger h6'>Lose</span></div>)
                                }
                                span2.push(<div key={Date.now() + (Math.random() * 999)} className='text-end'>
                                    <span key={Date.now() + (Math.random() * 999)} className='text-secondary'>{formatDateWithTime(matchObj.createdAt)}</span>
                                </div>);
                                cols.push(<Col key={Date.now() + (Math.random() * 999)} xs={{ span: 6 }} className=''>{span2}</Col>);

                                rows1.push(<Row key={Date.now() + (Math.random() * 999)} className='p-1'>{cols}</Row>);

                            } else {

                                if ('red' === matchObj.team) {
                                    span.push(<span key={Date.now() + (Math.random() * 999)} className='text-left h6 style-meron'>Fight #{matchObj.matchNo}</span>);
                                }
                                else if ('blue' === matchObj.team) {
                                    span.push(<span key={Date.now() + (Math.random() * 999)} className='text-left h6 style-wala'>Fight #{matchObj.matchNo}</span>);
                                }
                                else if ('cancelled' === matchObj.team) {
                                    span.push(<span key={Date.now() + (Math.random() * 999)} className='text-left h6 style-cancel'>Fight #{matchObj.matchNo}</span>);
                                } else {
                                    span.push(<span key={Date.now() + (Math.random() * 999)} className='text-left h6 style-draw'>Fight #{matchObj.matchNo}</span>);
                                }
                                cols.push(
                                    <Col key={Date.now() + (Math.random() * 999)} xs={{ span: 4 }} className='align-middle m-auto'>
                                        <span key={Date.now() + (Math.random() * 999)} className='text-left h6'>{matchObj.gameName}</span><br />
                                        {span}
                                    </Col>);

                                cols.push(
                                    <Col key={Date.now() + (Math.random() * 999)} xs={{ span: 2 }} className='align-middle m-auto'>
                                        <span key={Date.now() + (Math.random() * 999)} className='text-left h6 text-warning'>{formatNumberToCurrency(matchObj.amount)}</span>
                                    </Col>
                                );

                                span2.push(<div key={Date.now() + (Math.random() * 999)} className='text-end'><span className='text-info h6'>Not settled</span></div>)

                                span2.push(<div key={Date.now() + (Math.random() * 999)} className='text-end'>
                                    <span key={Date.now() + (Math.random() * 999)} className='text-secondary'>{formatDateWithTime(matchObj.createdAt)}</span>
                                </div>);
                                cols.push(<Col key={Date.now() + (Math.random() * 999)} xs={{ span: 6 }} className=''>{span2}</Col>);

                                rows1.push(<Row key={Date.now() + (Math.random() * 999)} className='p-1'>{cols}</Row>);
                            }
                            dataCount++;
                        })

                        if (0 === dataCount) {
                            const cols = Array();
                            cols.push(<Col key={Date.now() + (Math.random() * 999)} xs={{ span: 12 }} className='text-center h4 mt-4'>No bets placed today</Col>);
                            rows1.push(<Row key={Date.now() + (Math.random() * 999)} className='p-1'>{cols}</Row>);
                        }
                        return rows1;
                    })
                }

            </Card.Body>
        </Card>
    )
}

export default ChoiceHistory