import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';
import { clientAxios } from '../../utility/api/admin/axiosCMS';
import { API } from '../../resources/api-constants';

function useLogoutLogic() {
    const homeRedirect = useNavigate();

    //clientAxios.post(
    //    API.ADMIN_LOGOUT_LINK
    //).then((response) => {
    //    localStorage.clear();
    //}).catch()

    //redirect
    useEffect(() => {
        localStorage.clear();
        homeRedirect(ROUTES.ADMIN_LOGIN_ROUTE);
    }, []);


};

export default useLogoutLogic;