import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { ROUTES } from '../../resources/routes-constants';
import AvailableGames from '../partials/AvailableGames';

import useAgentForgotPasswordLogic from 'components/agents/ForgotPasswordLogic';

const AgentForgotPassword: React.FC = () => {
    const {
        handleSubmit, handleChange,
        errorMessage
    } = useAgentForgotPasswordLogic();

    return (
        <>
            <Container>
                <Row className='m-auto vh-100'>
                    <Col
                        lg={{ span: 6 }}
                        sm={{ span: 12 }}
                        className='m-auto'
                    >
                        <div id="forgotPassword" className="wrapper mb-2">
                            <div className="login-box">
                                <div className="card card-outline card-primary mx-auto mt-5">
                                    <div className="text-center">
                                        <h2 className="card-header text-center">
                                            Forgot Password
                                        </h2>
                                    </div>
                                    <div className="card-body bg-dark">
                                        <p className="login-box-msg">Enter email address below to retrieve your password.</p>
                                        <form action="#" method="post" onSubmit={handleSubmit}>
                                            <div className="input-group mb-3">
                                                <input type="email" name='email' className="form-control" placeholder="Email" onChange={handleChange} required />
                                            </div>
                                            <div className='mt-2'>
                                                <label className="d-flex justify-content-center form-label"><span className="text-danger">&nbsp;{errorMessage}</span></label>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 text-center">
                                                    <button type="submit" className="btn btn-primary btn-block w-100">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                        <p className="mt-3 mb-1 text-center">
                                            <a href={ROUTES.LOGIN_ROUTE} className='btn-outline-secondary btn w-100'>Back to Login</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col
                        lg={{ span: 6 }}
                        sm={{ span: 12 }}
                        className='m-auto'
                    >
                        <AvailableGames />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AgentForgotPassword