import React, { useState } from 'react';

import { clientAxios, refreshToken } from 'utility/api/agent/axiosAgent';
import { API } from '../../resources/api-constants';
import { useParams } from 'react-router-dom';

const useDownlineListLogic = () => {

    //get game list
    const [post, setPost] = React.useState({ data: Array(2) });
    const [error, setError] = useState(null);
    const [total, setTotal] = React.useState(0);

    const [loadingStatus, setLoadingStatus] = React.useState(true); //check loading status

    // function to fetch all table data
    const [pageParams, setPageParams] = useState(useParams());
    const [pageNumber, setPageNumber] = useState(pageParams.id);

    function getPostData(pageNumber: any) {
        clientAxios.get(
            API.AGENT_DOWNLINES_LINK, {
            params: {
                offset: API.NUM_PER_PAGE * (pageNumber - 1),
                limit: API.NUM_PER_PAGE,
            }
        }
        ).then((response) => {
            setPost(response.data);
            setTotal(response.data.count);
            setLoadingStatus(false);
            setError(null);
        }).catch((setError) => {
            setLoadingStatus(false);
        });
    }

    //fetch on initial load
    React.useEffect(() => {
        getPostData(pageNumber);
    }, []);

    //PROMOTE
    const [showConfirmation, setConfirmation] = useState(false);
    const [confirmationType, setConfirmationType] = useState('promote');

    const [showToast, setShowToast] = useState(false);
    const [showToastType, setShowToastType] = useState('success');

    const handleCloseConfirmation = () => setConfirmation(false);
    const handleCloseToast = () => setShowToast(false);

    const handleConfirmationSubmit = function (e: any) {
        e.preventDefault();
        setConfirmation(false);

        setShowToast(true);

        //reload data
        getPostData(pageNumber);
    }

    const [downlineName, setDownlineName] = useState('');
    const [downlineId, setDownlineId] = useState('');
    const handleApprove = function (downlineId: any, downlineName: any) {
        setConfirmation(true);

        setDownlineName(downlineName);
        setDownlineId(downlineId)

        setConfirmationType('promote-downline');
    }
    //END PROMOTE


    return {
        showConfirmation, handleConfirmationSubmit, handleCloseConfirmation,
        confirmationType,
        handleApprove, downlineName, downlineId,
        showToast, handleCloseToast, showToastType,
        post, total, pageNumber,
        loadingStatus
    };
}

export default useDownlineListLogic;