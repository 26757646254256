import React, { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import { clientAxios } from 'utility/api/agent/axiosAgent';
import { disableButton } from 'utility/functions';
import { API } from '../../../resources/api-constants';

const AgentConfirmationModal = (props: any) => {
    const setError = props.setError;
    const setShowToast = props.setShowToast;
    const setShowToastType = props.setShowToastType;
    const handleClose = props.close;
    const handleSubmit = props.submit;
    const confirmationType = props.confirmationType;
    const fullName = props.fullName;
    const id = props.userId;
    const requestId = props.requestId;

    const downlineId = props.downlineId;
    const downlineName = props.downlineName;

    const listRedirect = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');

    const buttonRef = useRef(null);

    const handleSubmitRequest = function (e: any) {
        e.preventDefault();

        //api call
        if ('approve-credit-request' === confirmationType) {
            setErrorMessage('');

            const remarks = e.target.remarks.value;
            clientAxios.patch(
                API.AGENT_DOWNLINES_CREDIT_APPROVE_ACTION_LINK + '/' + requestId,
                {
                    remarks: remarks
                }
            ).then((response: any) => {

                handleSubmit(e, true);
                setShowToastType('success');
                setShowToast(true);
                listRedirect(ROUTES.AGENT_DOWNLINE_REQUESTS_ROUTE + '/1');

            }).catch((error: any) => {
                //handleSubmit(e, false);
                setErrorMessage(error.response.data.message);
                return;
                //setShowToastType('fail');
                //setShowToast(true);
            }).finally(() => { // successful, redirect to list
            });
        } else if ('disapprove-credit-request' === confirmationType) {
            const remarks = e.target.remarks.value;
            clientAxios.patch(
                API.AGENT_DOWNLINES_CREDIT_DISAPPROVE_ACTION_LINK + '/' + requestId,
                {
                    remarks: remarks,
                }
            ).then((response: any) => {
                handleSubmit(e, true);
                setShowToastType('success');
                setShowToast(true);

                listRedirect(ROUTES.AGENT_DOWNLINE_REQUESTS_ROUTE + '/1');
            }).catch((error: any) => {
                handleSubmit(e, false);
                setShowToastType('fail');
                setShowToast(true);
            });
        } else if ('promote-downline' === confirmationType) {
            // alert('1');
            clientAxios.post(
                API.AGENT_DOWNLINE_PROMOTE_LINK + '/' + downlineId,
                {
                    percentage: 25
                }
            ).then((response: any) => {
                handleSubmit(e, true);
                setShowToastType('success');
                setShowToast(true);

                listRedirect(ROUTES.AGENT_DOWNLINES_ROUTE + '/1');
            }).catch((error: any) => {
                // handleSubmit(e, false);
                // setShowToastType('fail');
                // setShowToast(true);
            });
        } else {
            return;
        }
    }
    //END APPROVE / DISAPPROVED

    if ('approve-credit-request' == confirmationType) {
        return (

            <Modal show={props.show} onHide={handleClose} >
                <Form onSubmit={handleSubmitRequest} >
                {/* <Form> */}
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Action</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">

                        <Form.Group className="my-1 text-center">
                            <Form.Label><span className='h5'>Approve Request?</span></Form.Label>
                        </Form.Group>
                        <Form.Group className="mt-2 text-center">
                            <textarea className='w-75' placeholder='Enter Remarks' rows={10} name='remarks' required>

                            </textarea>
                        </Form.Group>
                        <div className='text-center text-danger'>&nbsp; {errorMessage}</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
                        </Button>
                        <Button variant="primary" type="submit">
                        {/* <Button variant="primary" type='submit' ref={buttonRef} onClick={() => {handleSubmitRequest(buttonRef)}}> */}
                            Yes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    } else if ('disapprove-credit-request' == confirmationType) {
        return (

            <Modal show={props.show} onHide={handleClose}>
                <Form onSubmit={handleSubmitRequest} >
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Action</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">

                        <Form.Group className="my-1 text-center">
                            <Form.Label><span className='h5'>Disapprove Request?</span></Form.Label>
                        </Form.Group>
                        <Form.Group className="mt-2 text-center">
                            <textarea className='w-75' placeholder='Enter Remarks' rows={10} name='remarks' required>

                            </textarea>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
                        </Button>
                        <Button variant="primary" type="submit">
                            Yes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    } else if ('approve-withdrawal-request' == confirmationType) {
        return (

            <Modal show={props.show} onHide={handleClose} >
                <Form onSubmit={handleSubmitRequest} >
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Action</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">

                        <Form.Group className="my-1 text-center">
                            <Form.Label><span className='h5'>Approve Request?</span></Form.Label>
                        </Form.Group>
                        <Form.Group className="mt-2 text-center">
                            <textarea className='w-75' placeholder='Enter Remarks' rows={10} name='remarks' required>

                            </textarea>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
                        </Button>
                        <Button variant="primary" type="submit">
                            Yes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    } else if ('disapprove-withdrawal-request' == confirmationType) {
        return (

            <Modal show={props.show} onHide={handleClose}>
                <Form onSubmit={handleSubmitRequest} >
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Action</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">

                        <Form.Group className="my-1 text-center">
                            <Form.Label><span className='h5'>Disapprove Request?</span></Form.Label>
                        </Form.Group>
                        <Form.Group className="mt-2 text-center">
                            <textarea className='w-75' placeholder='Enter Remarks' rows={10} name='remarks'>

                            </textarea>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
                        </Button>
                        <Button variant="primary" type="submit">
                            Yes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );

    } else if ('promote-downline' == confirmationType) {
        return (

            <Modal show={props.show} onHide={handleClose}>
                <Form onSubmit={handleSubmitRequest} >
                    <Modal.Header closeButton>
                        <Modal.Title>Agent Promotion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">

                        <Form.Group className="my-1 text-center">
                            <Form.Label><span className='h5'>Promote <span className='text-primary'>{downlineName}</span> to Sub Agent?</span></Form.Label>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
                        </Button>
                        <Button variant="primary" type="submit">
                            Yes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );

    } else {
        return (

            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Update</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <h6>Deactivate <span className='text-danger'>{fullName}</span>?</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AgentConfirmationModal;