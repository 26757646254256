import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

import { ROUTES } from '../../resources/routes-constants';

import useCategoriesLogic from 'components/operator/CategoriesLogic';

const GameCategories: React.FC = () => {
    const {
        post, total, pageNumber
    } = useCategoriesLogic();

    return (
        <div>
            <Container fluid>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>SELECT CATEGORY</h4>
                    </Col>
                </Row>
                <Row className='mb-5'>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        sm={{ span: 8, offset: 2 }}
                        xs={{ span: 6, offset: 3 }}
                    >
                        <Row className='text-center'>
                            {post && post.data.map((category: any, count: any) => (
                                <Col
                                    md={{ span: 4 }}
                                    xs={{ span: 12 }}
                                    className="m-auto mb-2 d-flex justify-content-center"
                                    key={count}>
                                    <a href={ROUTES.OPERATOR_GAMES_ROUTE + '/' + category.id + '/1'} className='text-decoration-none'>
                                        <div className='p-1 bg-dark border border-secondary rounded categoryIcons'>
                                            <div className='m-auto text-center'>
                                                <Image rounded src={
                                                    category.image ?
                                                        category.image :
                                                        '/images/placeholder.png'
                                                }
                                                    className='m-auto img-responsive'
                                                    style={{ height: '100px' }}
                                                />
                                                <p className='imageCardLabel mt-2 mb-2 bg-dark text-warning w-100'>{category.name}</p>
                                            </div>
                                        </div>
                                    </a>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default GameCategories;