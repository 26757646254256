import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { Button } from 'react-bootstrap';
import { ROUTES } from '../../resources/routes-constants';

import OperatorCustomModal from '../../components/operator/modals/OperatorCustomModal';
import OperatorCustomToast from '../../components/operator/OperatorCustomToast';

import CustomPagination from '../../components/CustomPagination';

import useOperatorMatchListLogic from '../../components/operator/OperatorMatchListLogic';
import { formatDate } from 'utility/functions';

const OperatorMatchList: React.FC = () => {
    const {
        showToast, handleCloseToast, showToastType,
        show, handleClose, handleSubmit,
        handleShow,
        categoryId, gameId, gameName,
        post, total, pageNumber,
        loadingStatus,
        getPostData
    } = useOperatorMatchListLogic();

    return (
        <>
            <OperatorCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
            <OperatorCustomModal show={show} close={handleClose} submit={handleSubmit} transaction={'add-match'} categoryId={categoryId} gameId={gameId} getPostData={getPostData} total={total} />
            {/* <OperatorConfirmationModal show={showConfirmation} submit={handleConfirmationSubmit} close={handleCloseConfirmation} confirmationType={confirmationType} fullName={fullName} /> */}

            <Container>
                <Row>
                    <Col
                        md={{ span: 10, offset: 1 }}
                        sm={{ span: 12 }}
                        className="text-center mb-5">
                        {/*<h4>Matches under {gameName}</h4>*/}
                        <h4>Matches</h4>
                    </Col>
                </Row>

                <Row className='mb-3'>
                    <Col
                        md={{ span: 10, offset: 1 }}
                        sm={{ span: 12 }}
                    >
                        <Row>
                            <Col
                                md={{ span: 4 }}
                                sm={{ span: 12 }}
                                className="text-start">
                                <div><Button type="button" className='w-100' onClick={() => handleShow('add-match')}>Add Match</Button></div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col md={{ span: 10, offset: 1 }} className="text-center">
                        <Table striped bordered hover variant="dark">
                            <thead className='bg-dark'>
                                <tr>
                                    {/* <th>ID</th> */}
                                    <th>DATE</th>
                                    <th>FIGHT #</th>
                                    <th>NAME</th>
                                    <th>STATUS</th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {post && post.data.map((matchObj: any, count: any) => (
                                    <tr className='bg-dark p-5 text-white' key={matchObj.id}>
                                        {/* <td>{matchObj.matchID}</td> */}
                                        <td>{formatDate(matchObj.date)}</td>
                                        <td>Fight #{matchObj.matchNo}</td>
                                        <td>{matchObj.name}</td>
                                        <td>
                                            {(1 === matchObj.betStatus) ?
                                                <span className='text-success animate-show-text' key={1}>Open</span> :
                                                (3 === matchObj.betStatus) ?
                                                    <span className='text-warning' key={2}>Last Call</span> :
                                                    (2 === matchObj.betStatus) ?
                                                        <span className='text-danger animate-show-text' key={0}>Closed</span> :
                                                        <span className='text-danger animate-show-text' key={3}>Pending</span>
                                            }
                                        </td>
                                        <td>
                                            <a href={ROUTES.OPERATOR_MATCH_VIEW_ROUTE + '/' + categoryId + '/' + gameId + '/' + matchObj.id + '/' + matchObj.matchNo + '?date=' + formatDate(matchObj.date)} className="me-1">View</a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col
                        md={{ span: 10, offset: 1 }}
                        sm={{ span: 12 }}
                        className="d-flex justify-content-end mb-2">
                        <CustomPagination post={post} total={total} current={pageNumber} />
                    </Col>
                </Row>
                <Row>
                    <Col
                        md={{ span: 10, offset: 1 }}
                        sm={{ span: 12 }}
                        className="d-flex justify-content-end mb-5">
                        <div className='text-center mt-2 mb-4'>
                            <a href={ROUTES.OPERATOR_GAMES_ROUTE + '/' + categoryId + '/' + pageNumber}>&lt;&lt; Back to Games</a>
                        </div>
                    </Col>
                </Row>
            </Container >
        </>
    );
}

export default OperatorMatchList;