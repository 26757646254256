import { faEye, faEyeLowVision } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { ROUTES } from '../resources/routes-constants'

import useNewPasswordLogic from 'components/NewPasswordLogic';

const NewPassword: React.FC = () => {
    const {
        handleSubmit, handleChange,
        errorMessage,
        password, password2, eyeicon, eyeicon2,
        Eye
    } = useNewPasswordLogic();

    return (
        <div id="register" className="register-box">
            <div className="card card-outline card-primary mx-auto">
                <div className="text-center">
                    <h2 className="card-header text-center">
                        New Password
                    </h2>
                </div>
                <div className="card-body bg-dark">
                    <form action="#" method="post" onSubmit={handleSubmit}>
                        <div className="input-group mb-3">
                            <label className="form-label" ><h6>New Password</h6></label>
                            <input type={password} name='password' minLength={6} className="form-control w-100" placeholder="Password" required onChange={handleChange} />
                            <FontAwesomeIcon icon={eyeicon ? faEye : faEyeLowVision} className='password-eye' onClick={() => Eye(1)} />
                            <div className="input-group-append">
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <label className="form-label" ><h6>Confirm Password</h6></label>
                            <input type={password2} name='verifyPassword' minLength={6} className="form-control w-100" placeholder="Confirm password" required onChange={handleChange} />
                            <FontAwesomeIcon icon={eyeicon2 ? faEye : faEyeLowVision} className='password-eye' onClick={() => Eye(2)} />
                            <div className="input-group-append">
                            </div>
                        </div>
                        <div className='mt-2'>
                            <label className="d-flex justify-content-center form-label"><span className="text-danger">&nbsp;{errorMessage}</span></label>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button type="submit" className="btn btn-primary btn-block register-button w-100 mt-3">Submit</button>
                            </div>
                            <div className='text-center'>
                                <a href={ROUTES.LOGIN_ROUTE} className='btn-outline-secondary btn w-100 my-3'>Back to Login</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default NewPassword