import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../resources/routes-constants';

import { clientAxiosLoginPlayer } from 'utility/api/members/axiosPlayer';

import { API } from '../resources/api-constants';
import { useParams } from 'react-router-dom';

const useNewPasswordLogic = () => {

    const [sessionId, setSessionID] = useState(useParams());

    const successRedirect = useNavigate();
    // if((null == sessionId) || (undefined == sessionId)) {
    //     redirect404(ROUTES.NotFoundPage);
    // }

    const initialFormData = Object.freeze({
        password: "",
        verifyPassword: ""
    });

    const [formData, updateFormData] = React.useState(initialFormData);
    const [errorMessage, updateErrorMessage] = React.useState('');

    const handleChange = (e: any) => {
        updateFormData({
            ...formData,

            // Trimming any whitespace
            [e.target.name]: e.target.value.trim()
        });
    };


    const handleSubmit = async (e: any) => {
        e.preventDefault()
        
        if (formData.verifyPassword !== formData.password) {
            updateErrorMessage("Passwords don't match.");
            return;
        }

        // updateErrorMessage("Please wait...");

        // call api
        try {
            // updateErrorMessage("Logging In...");
            const response = await clientAxiosLoginPlayer.post(
                API.PLAYER_FORGOT_RESET_PASSWORD_LINK + '?t=' + sessionId.id,
                {
                    password: formData.password,
                    confirmPassword: formData.verifyPassword,
                    //token: sessionId.id
                }
            );

            //redirect
            successRedirect(ROUTES.NEWPASSWORD_SUCCESS_ROUTE);

        } catch (error: any) {

            if (!error.response) {
                updateErrorMessage("No Server Response");
            } else if (error.response.status == 400) {
                updateErrorMessage("Missing Username or Password");
            } else if (error.response.status == 401) {
                updateErrorMessage("User not found.");
            } else {
                updateErrorMessage("Submit Failed. Try again.");
            }

        }
    };

    const [eyeicon, seteye] = useState(true);
    const [password, setpassword] = useState("password");

    const [eyeicon2, seteye2] = useState(true);
    const [password2, setpassword2] = useState("password");

    function Eye(passType: any) {

        if (1 == passType) {
            if (password == "password") {
                setpassword("text");
                seteye(false);
            }
            else {
                setpassword("password");
                seteye(true);
            }
        } else {

            if (password2 == "password") {
                setpassword2("text");
                seteye2(false);
            }
            else {
                setpassword2("password");
                seteye2(true);
            }
        }
    }
    return {
        handleSubmit, handleChange,
        errorMessage,
        password, password2, eyeicon, eyeicon2,
        Eye
    };
}

export default useNewPasswordLogic;