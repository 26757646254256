import React, { useState, useCallback } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { Button } from 'react-bootstrap';
import { ROUTES } from '../../resources/routes-constants';

import AdminCustomToast from '../../components/admin/AdminCustomToast';

import ImageViewer from 'react-simple-image-viewer';

import useCategoryLogic from 'components/admin/CategoryLogic';
import { formatDate, formatDateWithTime } from 'utility/functions';
import Loader from 'components/Loader';

const CategoryView: React.FC = () => {
    const {
        showToast, handleCloseToast, showToastType,
        handleSubmit, error,
        categoryName, image, status,
        createdBy, createdAt, updatedAt,
        loadingStatus
    } = useCategoryLogic();

    //IMAGE VIEWER
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const images = [
        image
    ];

    const openImageViewer = useCallback((index: any) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    //END IMAGE VIEWER
    return (
        <>
            <AdminCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />

            <Container>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>View Category</h4>
                    </Col>
                </Row>
                {loadingStatus ?
                    <Loader />
                    :
                    <>
                        <Row>
                            <Col
                                lg={{ span: 6, offset: 3 }}
                                md={{ span: 8, offset: 2 }}
                                className='boxContainer px-5 pt-4 pb-4'>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3">
                                        <Form.Label><h6>Name</h6></Form.Label>
                                        <Form.Control type="text" placeholder="" name='name' defaultValue={categoryName} required />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label><h6>Image</h6></Form.Label><br />
                                        <Form.Label className='d-flex justify-content-center mb-2'>
                                            <img
                                                src={image ?
                                                    image
                                                    :
                                                    '/images/placeholder.png'
                                                }
                                                alt={categoryName}
                                                className='text-center w-50'
                                                onClick={() => openImageViewer(0)}
                                            />
                                        </Form.Label>
                                        <Form.Control type="file" placeholder="Choose File" name='image' />
                                        <Form.Text className="text-muted">Maximum size (4Mb)</Form.Text>
                                    </Form.Group>
                                    <Form.Group className="mb-3 mt-4">
                                        <Form.Label>
                                            <h6 className='mb-0'>Status</h6>
                                        </Form.Label>
                                        <div className="m-0 form-check">
                                            {(1 === status) ?
                                                <input name="status" type="checkbox" id="custom-switch" className="form-check-input" defaultChecked /> :
                                                <input name="status" type="checkbox" id="custom-switch" className="form-check-input" />
                                            }
                                            <label title="" htmlFor="custom-switch" className="form-check-label">Activate</label>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="">
                                        <Form.Label>Created By : </Form.Label>
                                        <Form.Text className="ms-2">{createdBy}</Form.Text>
                                    </Form.Group>
                                    <Form.Group className="">
                                        <Form.Label>Created : </Form.Label>
                                        <Form.Text className="ms-2">{formatDate(createdAt)}</Form.Text>
                                    </Form.Group>
                                    <Form.Group className="">
                                        <Form.Label>Updated : </Form.Label>
                                        <Form.Text className="ms-2">{formatDate(updatedAt)}</Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className='d-flex justify-content-center'><span className='text-danger'>&nbsp;{error}</span></Form.Label><br />
                                    </Form.Group>
                                    <div className='text-end'>
                                        <Button href={ROUTES.ADMIN_CATEGORIES_ROUTE + '/1'} variant="secondary" className='me-2'>Back</Button>
                                        <Button variant="primary" type='submit'>Update</Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </>
                }
            </Container >

            {isViewerOpen && (
                <ImageViewer
                    src={images}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
        </>
    );
}

export default CategoryView;