import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'

import Form from 'react-bootstrap/Form';
import AgentCustomModal from 'components/agents/modals/AgentCustomModal';

import useAgentWithdrawLogic from '../../components/agents/AgentWithdrawLogic';
import { ROUTES } from 'resources/routes-constants';

import { updateTextView } from 'utility/functions';

const Withdraw: React.FC = () => {
    const {
        show, handleClose, handleSubmit, transaction,
        handleShow,
        handleVerifyEmail, errorMessage, sendEmailStatus,
        amount, gcash, gcashName, withdrawSubmitted,
        error
    } = useAgentWithdrawLogic();

    const emailVerified = localStorage.getItem('user-verified');

    return (
        <>
            <AgentCustomModal
                show={show}
                close={handleClose}
                submit={handleSubmit}
                transaction={transaction}
                amount={amount}
                gcashName={gcashName}
                gcash={gcash}
            />

            <Container>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center">
                        <h4>Claim <span className='highlightText px-2'>Credits</span></h4>
                    </Col>
                </Row>
                {(emailVerified == '0') || ('0' == localStorage.getItem('withdraw-credit')) ?
                    <>
                        <Row>
                            <Col
                                lg={{ span: 6, offset: 3 }}
                                md={{ span: 8, offset: 2 }}
                                className='p-5 text-center'>
                                <h6>Verify your email first to withraw.</h6>
                                {sendEmailStatus ?
                                    <>
                                        <a href="#" className='btn btn-outline-warning mt-4 disabled'>Verify Email</a><br />
                                    </>
                                    :
                                    <>
                                        <a href="#" onClick={handleVerifyEmail} className='btn btn-outline-warning mt-4'>Verify Email</a><br />
                                    </>
                                }
                                <p className='text-danger h6 mt-4'>&nbsp;{errorMessage}</p>
                            </Col>
                        </Row>
                    </>
                    : // else verified
                    <>
                        {withdrawSubmitted ?
                            <>
                                <Row>
                                    <Col
                                        lg={{ span: 6, offset: 3 }}
                                        md={{ span: 8, offset: 2 }}
                                        className='boxContainer p-5 my-4 '>
                                        <div className='text-center h6'>Submitted Withdrawal Request</div>
                                        <div className='text-center mt-4'>
                                            <a href={ROUTES.WITHDRAWALS_ROUTE + '/1'}>Click here to see your requests</a>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <Row>
                                    <Col
                                        lg={{ span: 6, offset: 3 }}
                                        md={{ span: 8, offset: 2 }}
                                        className='boxContainer p-5 my-4 '>
                                        <Form onSubmit={handleShow}>
                                            <Form.Group className="mb-2" >
                                                <Form.Label><h6>Credits: <b>{localStorage.getItem('total-credits')}</b></h6></Form.Label><br />
                                                <Form.Label className='mt-3'><h6>Gcash Name</h6></Form.Label>
                                                <Form.Control type="text" placeholder="" name='gcashName' required />
                                                <Form.Label className='mt-3'><h6>Gcash Number</h6></Form.Label>
                                                <Form.Control type="number" placeholder="" name='gcash' required />
                                                <Form.Label className='mt-3'><h6>Amount <small className='m-2 display-block'>Min: 500 - Max: 50,000</small></h6></Form.Label>
                                                <Form.Control type="text" placeholder="0" name='amount' min={500} max={50000} maxLength={6} onChange={updateTextView} required />
                                                {/* (localStorage.getItem('total-credits') */}
                                                {errorMessage ?
                                                    <>
                                                        <p className='text-success h6 mt-4 text-center'>{errorMessage}</p>
                                                        <p className='h6 text-center'><a href={ROUTES.WITHDRAWALS_ROUTE + '/1'}>Click here to see your requests</a></p>
                                                    </>
                                                    :
                                                    error ?
                                                        <>
                                                            <p className='text-danger h6 mt-4 text-center'>{error}</p>
                                                        </>
                                                        :
                                                        <>
                                                            <p className='text-danger h6 mt-4 text-center'>&nbsp;</p>
                                                            <p className='text-danger h6 text-center'>&nbsp;</p>
                                                        </>
                                                }
                                                <div className='text-center'><Button className="mt-4 w-50" type="submit" >Next</Button></div>
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </>
                        }
                    </>
                }
            </Container >
        </ >
    );
}

export default Withdraw;