import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';

import { clientAxios } from '../../utility/api/admin/axiosCMS';

import { API } from '../../resources/api-constants';

const useLoginLogic = () => {
    const initialFormData = Object.freeze({
        email: "",
        password: "",
        remember: "",
    });

    const [formData, updateFormData] = React.useState(initialFormData);
    const [loadingStatus, setLoadingStatus] = useState(true);

    const handleChange = (e: any) => {
        updateFormData({
            ...formData,

            // Trimming any whitespace
            [e.target.name]: e.target.value.trim()
        });
    };

    const homeRedirect = useNavigate();

    const [errorMessage, updateErrorMessage] = React.useState('');
    const [showError, updateShowError] = React.useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        updateShowError(false);

        updateErrorMessage("Logging In...");
        const rememberMe = e.target.remember.checked;

        // call api
        try {
            const response = await clientAxios.post(
                API.ADMIN_LOGIN_LINK,
                {
                    email: formData.email,
                    password: formData.password,
                    remember: formData.remember,
                },
                {
                    headers: { "Content-Type": "application/json" },
                }
            );

            //successful, add to local storage
            if (response.data.data.role.title === 'Admin') {

                if (rememberMe) {
                    localStorage.setItem('admin-remember', '1');
                } else {
                    localStorage.setItem('admin-remember', '0');
                }

                localStorage.setItem('access-token', response.data.data.accessToken);
                localStorage.setItem('login-date', String(Date.now()));
                //localStorage.setItem('expires', response.data.access.expires_in);
                localStorage.setItem('user-id', response.data.data.id);
                localStorage.setItem('email-address', response.data.data.email);
                localStorage.setItem('name', response.data.data.name);
                if (response.data.data.email_verified_at == null) { //TODO
                    localStorage.setItem('user-verified', '0');
                } else {
                    localStorage.setItem('user-verified', '1');
                }

                localStorage.setItem('user-session', API.ADMIN_CODE);
                //redirect
                // homeRedirect(ROUTES.ADMIN_HOME_ROUTE);
                updateErrorMessage("Login Successful");
                window.location.replace(ROUTES.ADMIN_HOME_ROUTE);
            } else {
                updateErrorMessage("Login Failed");

                updateShowError(true);
            }

        } catch (error: any) {

            // if (!error.response) {
            //     updateErrorMessage("Login Failed.");
            // } else if (error.response.status == 400) {
            //     updateErrorMessage("Missing Username or Password");
            // } else if (error.response.status == 401) {
            //     updateErrorMessage("User not found.");
            // } else {
            //     updateErrorMessage("Login Failed");
            // }
            updateErrorMessage("Login Failed");

            updateShowError(true);

            localStorage.clear();
        }

    };

    const [eyeicon, seteye] = useState(true);
    const [password, setpassword] = useState("password");

    function Eye() {
        if (password == "password") {
            setpassword("text");
            seteye(false);
        }
        else {
            setpassword("password");
            seteye(true);
        }
    }

    //fetch on initial load
    React.useEffect(() => {
        if (localStorage.getItem('admin-remember') === '1' && localStorage.getItem('access-token')) {
            window.location.replace(ROUTES.ADMIN_HOME_ROUTE);
        } else {
            setLoadingStatus(false);
        }
    }, []);

    return {
        handleSubmit, handleChange,
        password, eyeicon,
        Eye,
        errorMessage,
        loadingStatus
    };
}

export default useLoginLogic;