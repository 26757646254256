import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import { clientAxios } from 'utility/api/agent/axiosAgent';
import { API } from '../../../resources/api-constants';

const ConfirmationModal = (props: any) => {
    const handleClose = props.close;
    const confirmationType = props.confirmationType;

    if ('terms' == confirmationType) {
        return (

            <Modal
                className=''
                show={props.show}
                onHide={handleClose}
            >
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>Terms and Conditions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">
                        <Form.Group className="mt-2 text-center"
                        style={{maxHeight:'400px', overflow:'auto'}}
                        >
<p>The following terms and conditions (the "Terms & Conditions") explain the rules under which you can access and use our online service.</p>

<p>This mobile application, through which our Service is offered, is duly licensed under existing laws and applicable regulations.</p>

<p>You may only use Tokens or Gems for the purpose of making gambling or betting transactions on our mobile app. You must abide by these Terms & Conditions at all times when accessing the application (app) and using our Services.</p>

<p>These Terms & Conditions govern your access to and use of the app and our Services. By accessing and using the app and any of our Services (including by opening an Account with us), you agree to be bound by these Terms & Conditions. You also agree to the use of electronic communications in order to enter into contracts, and you waive any rights or requirements under applicable laws or regulations in any jurisdiction, which require a signature by hand, or to the extent permitted by law.</p>

<p>Please read these Terms & Conditions carefully, as they form a legally binding contract between you and us.</p>

<p>If you do not agree to be bound by these Terms & Conditions, please do not use the Website or our Services.</p>

<p>We recommend that you print out these Terms & Conditions and store them safely along with all the confirmation e-mails, transaction data and payment methods relevant to your use of the Website and our Services.</p>

<p>Contacting Us You can contact our Customer Services team if you have any questions. Please see our website, or check our app for contact details.</p>

<p>Game Rules These Terms & Conditions include and incorporate the following additional rules and terms:</p>

<p>The Game Rules (" Game Rules"), which govern our games and products and provide product-specific information about each game. When you play any game, you agree to be bound by the Game Rules of that game;</p>

<p>Terms which apply with respect to promotions, bonuses and special offers related to the Services from time to time ("Promotional Terms"). Please note, not all players will be entitled to receive all promotions, bonuses and special offers available via the Website, our the app; and</p>

<p>Our Privacy Policy, which sets out the terms on which we process any personal data we collect from you or that you provide to us.</p>

<p></p>

<p>If there is a conflict between these Terms & Conditions and any of the Game Rules, Promotional Terms and/or the Privacy Policy, the provisions of, as applicable, the Game Rules, Promotional Terms and/or the Privacy Policy shall to that extent prevail over these Terms & Conditions.</p>

<p>By using our Services, you will be deemed to accept these Terms & Conditions, including any amendments made to them. If you do not agree to accept and be bound by these Terms & Conditions, you should stop using our Services and Website.</p>

<p>Definitions The following words and terms, when used in these Terms & Conditions, shall have the following meanings:</p>

<p>"Account" means an account opened by you with us to enable you to deposit and wager tokens or gems on the Website, app, or otherwise use the Services.</p>

<p>"Applicable Laws" means any laws, rules and regulations which apply to you in the country in which you reside, or, if different, from which you are conducting activities with us, including (but not limited to) those relating to online gambling, your access to or use of the Services and/or the Website, and your making deposits and receipt of any withdrawal.</p>

<p>“Token or Gem” means the electronic medium or representation created for the express purpose of gambling on the website or app, and is the sole funding method allowed for the depositing and withdrawal to Accounts.</p>

<p>"Gambling or Betting Transaction" means a bet, wager, stake or similar transaction placed on the website, app or by otherwise using our Services.</p>

<p>"Group" means, in relation to us, a company which is from time to time a subsidiary or the ultimate holding company ours, or another direct or indirect subsidiary of such ultimate holding company.</p>

<p>“Permitted Jurisdictions” means any country that allows the use of the website or app by the user.</p>

<p>"Prohibited Jurisdictions" means any jurisdiction that isn’t a Permitted Jurisdiction.</p>

<p>"Services" means the services offered by us from time to time through the website or the app.</p>

<p>"Software" means the software that is required to be downloaded, accessed or otherwise utilised by you from the website or app, for the purpose of enabling you to participate in the Services, including any related program or data file and any related documentation, and including any enhancements, modifications, additions, translations or updates to such software.</p>

<p>"Winnings" means any winnings payable to you following a successful gambling or betting transaction.</p>

<p></p>

<p>Opening an Account We require you to make certain statements before opening an Account. When you register an Account with us you are agreeing and promising that:</p>

<p>you have understood and agree to be bound by these Terms & Conditions (including any additional Game Rules and/or other terms that may apply to you, as described in the "GAME RULES AND ADDITIONAL TERMS" section above;</p>

<p>you are at least 18 years of age or the age at which gambling is legal under any law that applies to you, whichever is the greater. We retain the right to ask for proof of age documentation from any applicant or customer at any time, and we will void any gambling or betting transactions made with minors;</p>

<p>you will provide accurate registration information when opening your account, which will include without limitation your correct date of birth, along with valid identification evidencing this, your country of residence, your current home address, email address and personal telephone number. You must inform us of any changes to these details;</p>

<p>you are legally capable of entering into binding contracts, including these Terms & Conditions and any gambling or betting transactions you place;</p>

<p>you are opening your account solely for your personal use, and are acting on your own behalf and not as an agent on behalf of a third party;</p>

<p>you will not try to sell or in any way transfer the benefit of your account to any third party and nor will you acquire or attempt to acquire an account which has been opened in the name of a third party;</p>

<p>you are not depositing funds originating from criminal or unlawful activities, and you are not otherwise conducting criminal or unlawful activities and/or intending to use the Account in connection with such activities;</p>

<p>you are not colluding, or attempting to collude, or intending to participate, directly or indirectly, in any collusion scheme with any other player in the course of any game you play, or will play, on the Website;</p>

<p>any crypto address supplied by you in the registration process, or which may be required of you is yours as the registered account holder;</p>

<p>you are entirely responsible for complying (and you comply) with your own local, national, federal, state or other laws concerning betting and gaming prior to opening an Account, placing a gambling or betting transaction or using our services. If you are located in a country where use of a particular Service is prohibited, you must not: (i) register with us for the applicable Service; or (ii) attempt to use that Service;</p>

<p>you are not prohibited for any reason from placing a gambling or betting transaction with us or from using the Services;</p>

<p>you are not an officer, director, employee, consultant or agent or of one of our Group companies, or our suppliers or vendors, and you are not a relative of anyone who falls into these categories (for the purpose of this paragraph, the term "relative" means spouse, partner, parent, child or sibling);</p>

<p>you understand that there is a risk of losing money by wagering on our website, or app, and/or for using our Services, and you accept full responsibility for such losses; and</p>

<p>you are not currently subject to a period of self-exclusion, and neither we nor any other operator has excluded you, from gambling.</p>

<p>You will commit a criminal offence if you are under the legal age to gamble in your territory. You must not access our services to gamble if you are underage.</p>

<p>The availability of our Services does not constitute an offer, solicitation or invitation by us for the use of our Services in any jurisdictions in which such use is prohibited by law. If you use our Services in breach of any local, national, federal, state or other laws that apply to you in your country, we will not be liable to you for such use.</p>

<p>You must keep your account details up-to-date. If you fail to do so, it may result in your ability to access the Services being revoked.</p>

<p>If you breach any of the terms in this clause:</p>

<p>we may declare any gambling or betting transaction you have placed void;</p>

<p>we will reserve the right to claim back any Winnings from any gambling or betting transaction declared void; and</p>

<p>we may refer the matter to the police, guardians or family members, or any other appropriate regulatory authority.</p>

<p>Verification Checks  You understand and accept that, in order to comply with anti-money laundering laws and related regulations, we may need to carry out additional checks to verify your identity and (where necessary) the source of any funds used by you ("Checks").</p>

<p>We will typically get the information we need from your account, but you agree to promptly provide us with any information we request in relation to such Checks, and we reserve the right to request the proof of age documentation and the evidence you have used to verify your identity for your account, or the source of your funds including certified documentation, from you at any time.</p>

<p>Until such time as the relevant Checks are completed to our reasonable satisfaction, and any requested information has been provided, we are entitled to restrict your Account in any manner that we may reasonably deem appropriate, including by preventing you from placing any bets or wagers until the verification checks are completed satisfactorily.</p>

<p>We reserve the right, acting reasonably, to suspend or terminate your Account where we are unable to complete these Checks to our satisfaction.</p>

<p>We may supply the information that you give us, including details from your account to authorised credit reference agencies and/or fraud prevention agencies to confirm your identity and card details. You agree that we may process, use, record and disclose personal information which you provide in connection with your registration and that such personal data may be recorded by us or such third party agencies. For further information please see our Privacy Policy.</p>

<p>Use of Your Account You must not allow any other person to use your Account. You accept that any actions taken using your Account will be considered to have been taken by you and any gambling or betting transactions placed using your Account will be considered valid. We will not be liable if you disclose account details to someone else.</p>

<p>If you lose access to your account, or have reason to believe that someone else is aware of your details, please contact our Customer Services team immediately (our contact details can be found on our website).</p>

<p>The security of any crypto address you supply, if any, is your responsibility. Both your online wallet and any cold storage devices should be password protected with a password known only to you.</p>

<p>You can monitor all gambling and betting transactions made using your Account by clicking in the “Account” section when logged in. You are responsible for all transactions made using your Account.</p>

<p>Deposits and Withdrawals If you wish to participate in the Services, you must only use only allowed tokens or gems and such tokens or gems must lawfully belong to you.</p>

<p>If relevant in your jurisdiction, it is your responsibility to account to the relevant tax authorities for any winnings you receive via your gambling activity. We are not liable to account to any tax authority for any of your personal taxes.</p>

<p>Closing your Account If you wish to close your Account, please contact customer services. We shall be entitled to close your Account in the following circumstances:</p>

<p>immediately if we discover that you have become bankrupt;</p>

<p>you are using tokens or gems which have been obtained unlawfully or in a clearly and seriously debase manner;</p>

<p>in accordance with the "Cheating, Collusion, Fraud and Criminal Activity" section below if an investigation concludes that you have used the website, app, and/or the Services in a fraudulent manner or for illegal, unlawful and/or improper purposes;</p>

<p>immediately if we are requested to do so by law enforcement or regulatory authorities or by a court order;</p>

<p>by giving you reasonable notice if you don't supply any information reasonably requested by us;</p>

<p>by giving you reasonable notice if we discover or have reason to believe that you have breached, or are breaching, these Terms & Conditions.</p>

<p>Gambling or Betting Transactions We reserve the right to decline a any gambling or betting transaction if your Account is restricted in our reasonable discretion. All gambling or betting transactions are entered into at your own discretion. Gambling or betting transactions will not be confirmed if there are insufficient funds in your Account.</p>

<p>We will only accept gambling or betting transactions made online (including via mobile phone or mobile applications). Once a transaction has been accepted by us, you cannot cancel the same. It is your responsibility to ensure that all details of the transaction are correct.</p>

<p>You may only bet up to the lesser of (i) the amount held in your account or (ii) any limits set in the game rules. We will determine in our discretion what the minimum and maximum gambling or betting selection is for each transaction.</p>

<p>Errors or Omissions The software and/or app features an innovative system to automatically protect the integrity and fairness of games or broadcast.</p>

<p>A number of circumstances may arise where errors are made in respect of transactions placed or payments made by us ("Error"). An Error could be the result of a mistake by an individual or a defect or fault with our systems and/or the Software. Save where as set out in these Terms & Conditions, we will not be liable for any Error (however caused), including (but not limited to) where:</p>

<p>we incorrectly calculate or pay out the amount of Winnings that are paid to you;</p>

<p>We reserve the right to correct any Error which has been identified take steps as soon as practicable to remedy the problem and ensure that you are treated fairly according to the circumstances.</p>

<p>Where it is not practicable for us to correct and re-settle a transaction which has been subject to an Error, we reserve the right to declare the particular transaction void. Where we have paid Winnings to you,which we can reasonably demonstrate were as a result of an Error, we reserve the right to claim back such Winnings from you.</p>

<p>You should inform us of any Error as soon as possible and should not seek to exploit an Error to generate Winnings. Where we can reasonably demonstrate that you have exploited an Error to generate Winnings we shall have no liability to you in respect of such Winnings. Where we have paid Winnings to you, which we can reasonably demonstrate were as a result of you exploiting an Error, we reserve the right to claim back such Winnings from you.</p>

<p>Neither we, nor our employees, partners or suppliers, will be liable for, or be required to compensate you for, any loss that results from any Error by us.</p>

<p>Cheating, Collusion, Fraud and Criminal Activity If we discover, or have reasonable grounds to believe, that you are engaging, or have engaged, in one or more of the following activities, we will commence and/or be involved in and/or assist an investigation into such circumstances:</p>

<p>cheating (including by exploiting a game or game fault);</p>

<p>abusing bonuses or promotions that we offer;</p>

<p>colluding with others (including by working together to exploit a game or game fault, using "bots" or similar software, or manipulating the Software); and/or</p>

<p>fraudulent behaviour or criminal activity (including money laundering, chargeback risk or stolen tokens).</p>

<p>You agree to assist and cooperate with any such investigation, and also agree that we may provide your personal information to a third party investigating your activity or Account (as set out in our Privacy Policy). While an investigation is ongoing, your Account will be frozen and you will not be able to place any bet on any transaction. If the investigation concludes that you have not engaged in any of the foregoing activities, your Account will be re-activated and we will process any outstanding withdrawal request. If the investigation concludes that you have engaged in any of the foregoing activities, we will close your Account.</p>

<p>You should only deposit allowed tokens or gems into a game session for the purposes of entering into a gambling or betting transaction. In circumstances where we reasonably believe that you are depositing tokens or gems without any intention to enter into a gambling or betting transaction, we may investigate your Account in accordance with the preceding paragraph.</p>

<p>Prohibited Transactions You may not use the Website and/or the Services if you are located in a Prohibited Jurisdiction. The fact that the Website is accessible in a Prohibited Jurisdiction, or that the Website is provided in the official language of a Prohibited Jurisdiction, shall not be construed as condoning the use of the Website in such Prohibited Jurisdiction. Any attempt to circumvent this restriction is a breach of these Terms & Conditions and may constitute a criminal offence under Applicable Laws. If we discover, or we have reasonable grounds to suspect, that you accessing the Website or the Services from a location in any of the Prohibited Jurisdictions, this will result in us closing your Account, without any obligation to provide you with advance notice, and providing the applicable details to any relevant authorities, and you shall compensate us for any damage or loss resulting therefrom.</p>

<p>The Prohibited Jurisdictions may be changed by us from time to time, with or without prior notice to you.</p>

<p></p>

<p>Protection of Customer Funds You acknowledge and agree that the website is not a bank account and is therefore not insured, guaranteed, sponsored or otherwise protected by any deposit or banking or other insurance system of any jurisdiction.</p>

<p>Use of the Website or App No warranty or representation, express or implied, is made as to the accuracy, timeliness, completeness or suitability of the information and materials contained on our website or app, or the results obtained through its use. The information provided on the website or app is not intended to amount to advice or recommendations and is provided for information purposes only. No reliance shall be placed on such information when entering into a gambling or betting transaction.</p>

<p>You are solely responsible for the supply and maintenance of all computer equipment, telecommunications networks and internet connection services that you require in order to access and use the website or app. We shall not be liable in for any loss or damage caused to your computer equipment, cellular phone, telecommunications network or internet connection service as a result of accessing or using the website or app, save that if any defective digital content that we have supplied to you damages a device or digital content belonging to you.</p>

<p>You may only use the website, app and/or the Services for the purpose of entering into gambling or betting transactions with us and for your own private use. Any commercial exploitation of information from our website or app is strictly prohibited.</p>

<p>You must not use the website, app and/or the services for any purpose which is illegal, fraudulent, abusive, obscene, discriminatory, dishonest or inappropriate. You will fully compensate us for any and all losses, costs, damages and claims arising from any such activity.</p>

<p>Availability of the Website or the App From time to time the website or app may be unavailable for use by you due to maintenance of the website, app and/or alteration of our products/services. We may alter or amend the products/Services and/or undertake maintenance at any time or for any reason.</p>

<p>Intellectual Property We are the owner or the licensee of all intellectual property rights in the website, or app. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</p>

<p>You must not use any part of the materials on the website or app for any commercial purposes without obtaining a license to do so from us or our licensors.</p>

<p>You may print off one copy, and may download extracts, of any page(s) from the Website for your personal reference only.</p>

<p>You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>

<p>Our status (and that of any identified contributors) as the authors of material on the Website must always be acknowledged.</p>

<p>Software We do not accept any liability in respect of the Software and you agree that you are only permitted to use the Software for the purpose of using the Services and, save to the extent permitted by Applicable Law, for no other purpose.</p>

<p>We hereby grant you a personal, non-exclusive, non-transferable right to use the Software for the sole purpose of using the Services and the Website or app.</p>

<p>You do not own the Software. Your use of the Software does not give you ownership of any intellectual property rights in the Software or any other rights in or with respect to the Software, except for the right to use the Software as expressly provided in these Terms & Conditions.</p>

<p>The Software is provided "as is" without any promises, warranties, conditions, undertakings or representations, express or implied, statutory or otherwise. We and the Software Provider, and all of our and its affiliates and related parties, hereby exclude all implied terms, conditions and warranties (including any warranty of merchantability, satisfactory quality and fitness for any particular purpose) and do not warrant that the Software will meet your requirements, that the Software will not infringe any third party's intellectual property rights, that the operation of the Software will be error free or uninterrupted, that any defects in the Software will be corrected, or that the Software is virus-free.</p>

<p>Neither we nor any member of our Group will be liable to you for any costs, expenses, losses or claims arising or resulting from communications or system errors occurring in connection with the settlement of Accounts or other features or components of the Software, or for any damage caused to your devices (PCs, mobile devices) from use of the Software, save that if the Software damages a device or digital content belonging to you and this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you compensation. In the event of such errors, we will further have the right, but not the obligation, to remove any relevant games from the Software, app and the Website and take any other action to correct such errors.</p>

<p>Our Liability Nothing in these Terms & Conditions affects our liability for death or personal injury arising from our negligence, nor our liability for fraudulent misrepresentation or misrepresentation as to a fundamental matter, nor any other liability which cannot be excluded or limited under applicable law. Your statutory rights remain unaffected and we shall use reasonable care when providing you services pursuant to these Terms & Conditions.</p>

<p>The website, the app and the Services (including all material and information displayed on or via the Website, the app and the Services) are provided without any guarantees, conditions or warranties as to their accuracy.</p>

<p>Subject to the preceding paragraph, and other than in relation to the payment of Winnings lawfully won, our liability to you pursuant to these Terms & Conditions shall not exceed:</p>

<p>in the case of any liability which relates to a specific Gambling Transaction, the amount of the stake wagered.</p>

<p>We supply the Services for domestic and private use. If you use the Services for any commercial, business or re-sale purpose, we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</p>

<p>Where we provide links to other websites, we do so for information purposes only and you use any such links at your own risk. We accept no responsibility for the content or use of any other websites or for the information contained on them.</p>

<p>Your Liability You agree to compensate us (and our subsidiaries, employees, agents and/or partners) from any claims, liabilities, costs and expenses that may arise as a result of your breach of these Terms & Conditions.</p>

<p>Changes to the Terms and Conditions</p>

<p>These Terms & Conditions are subject to change and we reserve the right to change them at any time.</p>

<p>Following your initial acceptance of these Terms & Conditions, we will notify you of any subsequent significant changes to the Terms & Conditions before they come into effect, as further described in these Terms & Conditions. However, you should check the Terms & Conditions regularly for any changes as your continued use of the website, the app, and the services will be regarded as acceptance of the latest version of the Terms & Conditions (including the Privacy Policy).</p>

<p>If you do not wish to accept any changes that have been made to these Terms & Conditions then you should stop using the website, the app and the Services. Any bets or wagers received but not settled prior to the introduction of the new Terms & Conditions will be subject to the version of the Terms & Conditions applicable at the time the bet or wager was received.</p>

<p>Complaints and Dispute Resolution</p>

<p>If you still have a complaint at the end of a Dispute or have any other complaint with regards to the website, the app or any of our Services, please first contact our Customer Services team (using the contact details) in order for this to be internally reviewed by the management.</p>

<p>Events beyond our Control</p>

<p>We shall not be in breach of these Terms & Conditions nor be liable for delay in performing, or failure to perform, any of our obligations under these Terms & Conditions if such delay or failure results from events, circumstances or causes beyond our reasonable control.</p>

<p>Waiver</p>

<p>Our failure or delay in enforcing or partially enforcing any term of these Terms & Conditions shall not be interpreted as a waiver of any of our rights or remedies. No single or partial exercise of such right or remedy shall preclude or restrict the further exercise of that or any other right or remedy.</p>

<p>Severance</p>

<p>If any provision of these Terms & Conditions is held by any competent authority to be invalid, void, voidable, unenforceable or unreasonable in whole or in part, the validity of the other provisions of these Terms & Conditions and the remainder of the provision in question shall continue in full force and effect.</p>

<p>Entire Agreement</p>

<p>These Terms & Conditions, including any document expressly referred to in them, represent the entire agreement between you and us and replace any prior agreement, understanding or arrangement between you and us. We each acknowledge that neither of us has relied on any representation, undertaking or promise made by the other except as expressly stated in these Terms & Conditions.</p>

<p>Transfer of Agreement</p>

<p>We may at any time assign or transfer any or all of our rights and obligations under these Terms & Conditions. In particular, we may assign or transfer our rights and obligations to any purchaser of all or part of our business. We may also subcontract or delegate in any manner any or all of our obligations under these Terms & Conditions to any third party or agent.</p>

<p>These Terms & Conditions are personal to you and you may not assign, sub-license or otherwise transfer in any manner whatsoever any of your rights or obligations under these Terms & Conditions.</p>

<p>Notices</p>

<p>You agree to receive communications from us in an electronic form. Electronic communications may be posted on the pages within the website, the app and/or the messages/help files of your client application, and/or delivered to your email address, as decided by us from time to time. All communications in electronic format will be considered to be "in writing" and to have been received no later than five business days after posting or dissemination, whether or not you have actually received or retrieved the communication.</p>

<p>Governing Law and Jurisdiction</p>

<p>These Terms & Conditions are governed by the laws and regulations of the national country of the provider of the website or app. The parties therefore agree to submit to the exclusive jurisdiction of such courts to the exclusion of a any other venue.</p>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    } else {
        return (
            <></>
        );
    }
}

export default ConfirmationModal;