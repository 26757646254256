import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ROUTES } from '../../resources/routes-constants';

import Loader from 'components/Loader';
import useApiCategoryListLogic from '../../components/api/ApiCategoryListLogic';

const ApiCategories: React.FC = () => {
    const {
        post, errorMessage, error,
        loadingStatus
    } = useApiCategoryListLogic();

    return (
        <div>
            <Container>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 4, offset: 4 }}
                        className="text-center mb-5">
                        <h4>Select <span className='highlightText px-2'>Category</span></h4>
                    </Col>
                </Row>
                {!loadingStatus ?
                    (error === false) ?
                        <Row>
                            <Col
                                lg={{ span: 6, offset: 3 }}
                                sm={{ span: 8, offset: 2 }}
                                xs={{ span: 6, offset: 3 }}
                            >
                                <Row className='text-center'>
                                    {post && post.data.map((category: any, count: any) => (
                                        <Col
                                            md={{ span: 4 }}
                                            xs={{ span: 12 }}
                                            className="m-auto mb-2 d-flex justify-content-center"
                                            key={category.id}>
                                            <a href={ROUTES.API_RESPONSE_GAMES + '/' + category.id} className='text-decoration-none'>
                                                <div className='p-1 bg-dark border border-secondary rounded categoryIcons'>
                                                    <div className='m-auto text-center'>
                                                        <img
                                                            src={category.image ?
                                                                category.image
                                                                :
                                                                '/images/placeholder.png'
                                                            }
                                                            className='m-auto img-responsive rounded'
                                                            style={{ maxHeight: '100px', maxWidth: '100px' }}
                                                        />
                                                        <p className='imageCardLabel mt-2 mb-2 bg-dark text-warning w-100'>{category.name}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col
                                lg={{ span: 6, offset: 3 }}
                                sm={{ span: 8, offset: 2 }}
                                xs={{ span: 6, offset: 3 }}
                            >
                                <Row className='text-center'>
                                    <p className='mt-2 mb-2 bg-dark text-danger w-100'>{errorMessage}</p>
                                </Row>
                            </Col>
                        </Row>
                    :
                    <Loader/>
                }
            </Container>
        </div>
    );
}

export default ApiCategories;