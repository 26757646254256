import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';
import { clientAxios } from 'utility/api/agent/axiosAgent';
import { API } from '../../resources/api-constants';

function useAgentLogoutLogic() {
    const homeRedirect = useNavigate();

    //clientAxios.post(
    //    API.AGENT_LOGOUT_ROUTE,
    //    {
    //        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access-token') }
    //    }
    //).then((response) => {
    //    localStorage.clear();
    //}).catch((error) => {
    //    localStorage.clear();
    //})

    //redirect
    useEffect(() => {
        localStorage.clear();
        homeRedirect(ROUTES.AGENT_LOGIN_ROUTE);
    }, []);

};

export default useAgentLogoutLogic;