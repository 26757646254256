import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { ROUTES } from '../../resources/routes-constants';
import CustomPagination from '../../components/CustomPagination';

import { formatDate } from 'utility/functions';

import useGameListLogic from 'components/admin/GameListLogic';
import Loader from 'components/Loader';

const GameList: React.FC = () => {
    const {
        gameCategory,
        post, total, pageNumber,
        loadingStatus
    } = useGameListLogic();

    if (0 === total) {
        return (
            <>
                {loadingStatus ?
                    <Loader />
                    :
                    <Container>
                        <Row>
                            <Col
                                md={{ span: 8, offset: 2 }}
                                sm={{ span: 12 }}
                                className="text-center mb-5">
                                <h4><span className='text-warning'>No Games Available</span></h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={{ span: 12 }} className="d-flex justify-content-center mb-3">
                                <div className=''><a href={ROUTES.ADMIN_CATEGORIES_ROUTE + '/1'}><span className='btn btn-secondary'>Back to Categories</span></a></div>
                            </Col>
                        </Row>
                    </Container>
                }
            </>
        );
    } else {
        return (
            <>
                <Container>
                    <Row>
                        <Col
                            md={{ span: 8, offset: 2 }}
                            sm={{ span: 12 }}
                            className="text-center mb-5">
                            <h4>Games for <span className='text-warning'>{gameCategory}</span></h4>
                        </Col>
                    </Row>
                    {loadingStatus ?
                        <Loader />
                        :
                        <>
                            <Row>
                                <Col sm={{ span: 8, offset: 2 }} className="d-flex justify-content-end mb-3">
                                    <div className=''><a href={ROUTES.ADMIN_CATEGORIES_ROUTE + '/1'}><span className='btn btn-secondary'>Back to Categories</span></a></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    md={{ span: 8, offset: 2 }}
                                    sm={{ span: 12 }}
                                    className="text-center">
                                    <Table striped bordered hover variant="dark">
                                        <thead className='bg-dark'>
                                            <tr>
                                                {/* <th>ID</th> */}
                                                <th>PREVIEW</th>
                                                <th>NAME</th>
                                                <th>CREATED BY</th>
                                                <th>CREATED AT</th>
                                                <th>LAST UPDATED</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {post && post.data.map((gameObj: any, count: any) => (
                                                <tr className='bg-dark p-5 text-white' key={gameObj.id}>
                                                    <td className='w-25'>
                                                        <img
                                                            src={gameObj.image ?
                                                                gameObj.image
                                                                :
                                                                '/images/placeholder.png'
                                                            }
                                                            alt={gameObj.name}
                                                            className='img-thumbnail' style={{ maxWidth: '120px' }}
                                                        />
                                                    </td>
                                                    <td>{gameObj.name}</td>
                                                    <td>{gameObj.createdBy}</td>
                                                    <td>{formatDate(gameObj.createdAt)}</td>
                                                    <td>{formatDate(gameObj.updatedAt)}</td>
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ span: 8, offset: 2 }} className="d-flex justify-content-end mb-5">
                                    <CustomPagination post={post} total={total} current={pageNumber} />
                                </Col>
                            </Row>
                        </>
                    }
                </Container >
            </>
        );
    }
}

export default GameList;

function updateFormData(arg0: any) {
    throw new Error('Function not implemented.');
}