import React from 'react';

const Loader = () => {
    return (
        <>
            <div className="divLoader d-flex justify-content-center mt-5"
                style={{ background: '', height: '100vh', verticalAlign: 'middle' }}
            >
                <div className='mt-5'>
                    <img src={window.location.origin + '/images/loading.gif'} />
                </div>
            </div>
        </>
    );

}
export default Loader;