import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';

import CustomToast from '../../components/members/CustomToast';
import useAccountLogic from '../../components/admin/AccountLogic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeLowVision } from '@fortawesome/free-solid-svg-icons';

const AdminAccount: React.FC = () => {
    const {
        Eye,
        showToast,
        handleCloseToast,
        show,
        handleClose,
        handleSubmit,
        transaction,
        handleChangePassword,
        password, eyeicon,
        password2, eyeicon2,
        password3, eyeicon3,
        errorMessage
    } = useAccountLogic();

    return (
        <>
            <CustomToast show={showToast} close={handleCloseToast} />

            <Container>
                <Row>
                    <Col md={{ span: 4, offset: 4 }} className="text-center mb-5">
                        <h4>Admin Account </h4>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 6, offset: 3 }} className='boxContainer p-5 pt-0'>
                        <Row>
                            <Col className="p-2">
                                <p className='text-center mb-5'><span className='h5'>Update Password</span></p>
                                <Form onSubmit={handleChangePassword}>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label><h6>Current Password</h6></Form.Label>
                                        <Form.Control type={password} name='password' placeholder="******" required />
                                        <FontAwesomeIcon icon={eyeicon ? faEye : faEyeLowVision} className='account-password-eye' onClick={() => Eye(1)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label><h6>New Password</h6></Form.Label>
                                        <Form.Control type={password2} name='newpassword' placeholder="******" required />
                                        <FontAwesomeIcon icon={eyeicon2 ? faEye : faEyeLowVision} className='account-password-eye' onClick={() => Eye(2)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label><h6>Verify Password</h6></Form.Label>
                                        <Form.Control type={password3} name='verifypassword' placeholder="******" required />
                                        <FontAwesomeIcon icon={eyeicon3 ? faEye : faEyeLowVision} className='account-password-eye' onClick={() => Eye(3)} />
                                    </Form.Group>
                                    <p className='text-danger text-center'>&nbsp;{errorMessage}</p>
                                    <p className='text-center'><Button className="mt-3 w-50" type="submit">Change Password</Button></p>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container >
        </ >
    );
}

export default AdminAccount;