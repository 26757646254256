import React from 'react';

function AvailableGames() {
    return (
        <>
            <div className="container text-center">
                <div className="row text-center">
                    <div className="col-xs-12 col-sm-12 align-self-center mb-5">
                        <span className='h4'>Play the following</span>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-sm-4 align-self-center col-4">
                        <img src={window.location.origin + '/images/esports.png'} className='img-thumbnail'
                            style={{ border: 'none', background: 'none' }}
                        />
                    </div>
                    <div className="col-sm-4 align-self-center col-4">
                        <img src={window.location.origin + '/images/sabong.png'} className='img-thumbnail'
                            style={{ border: 'none', background: 'none' }}
                        />
                    </div>
                    <div className="col-sm-4 align-self-center col-4">
                        <img src={window.location.origin + '/images/sports.png'} className='img-thumbnail'
                            style={{ border: 'none', background: 'none' }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AvailableGames;