import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import CustomPagination from '../../components/CustomPagination';
import { API } from 'resources/api-constants';
import { clientAxios } from 'utility/api/agent/axiosAgent';
import { useParams } from 'react-router-dom';
import { formatDateWithTime } from 'utility/functions';
import Loader from 'components/Loader';

const AgentCommissionTransactionList: React.FC = () => {

    //GET STATS
    const [post, setPost] = useState({ data: Array(2) });
    const [error, setError] = useState(null);
    const [total, setTotal] = useState(0);

    const [loadingStatus, setLoadingStatus] = useState(true);

    // function to fetch all table data
    const [currentPage, setCurrentPage] = useState(useParams());
    const [pageNumber, setPageNumber] = useState(currentPage.id);

    function getPostData(pageNumber: any) {
        clientAxios.get(
            API.AGENT_COMMISSION_TRANSACTION_LINK, {
            params: {
                offset: API.NUM_PER_PAGE * (pageNumber - 1),
                limit: API.NUM_PER_PAGE,
            }
        }
        ).then((response: any) => {
            setPost(response.data);
            setTotal(response.data.count);
            setError(null);

            setLoadingStatus(false);
        }).catch(setError)
    }

    //fetch on initial load
    React.useEffect(() => {
        getPostData(pageNumber);
    }, []);

    //END GET STATS

    if (!(post) || (0 == total)) {
        return (
            <>
                {loadingStatus ?
                    <Loader />
                    :
                    <Container >
                        <Row>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                                className="text-center mb-5">
                                <h4>No Items Found</h4>
                            </Col>
                        </Row>
                    </Container>
                }
            </>
        );
    } else {
        return (
            <>
                <Container>
                    <Row>
                        <Col
                            md={{ span: 10, offset: 1 }}
                            sm={{ span: 12 }}
                            className="text-center mb-5">
                            <h4>Commission <span className='highlightText px-2'>Transactions</span></h4>
                        </Col>
                    </Row>

                    {/* <Row className='mb-3 text-end'>
                    <Col
                        md={{ span: 3, offset: 7 }}
                        xs={{ span: 12 }}
                    >
                        <Form className='w-100 d-inline-flex justify-content-end'>
                            <Form.Group className="d-inline-flex justify-content-end ms-0 w-100">
                                <Form.Control type='text' placeholder="Search Name" className='w-100 ms-0' />
                                <FontAwesomeIcon icon={faSearch} className='text-dark my-auto d-inline-flex justify-content-end search-icon' />
                            </Form.Group>
                        </Form>

                    </Col>
                </Row> */}

                    {loadingStatus ?
                        <Loader />
                        :
                        <>
                            <Row>
                                <Col
                                    md={{ span: 10, offset: 1 }}
                                    sm={{ span: 12 }}
                                    className="text-center table-responsive">
                                    <Table striped bordered hover variant="dark">
                                        <thead className='bg-dark'>
                                            <tr>
                                                <th>DATE</th>
                                                <th>AMOUNT</th>
                                                <th>NOTES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {post && post.data.map((commission: any, count: any) => (
                                                <tr className='bg-dark p-5 text-white' key={commission.id}>
                                                    <td>{formatDateWithTime(commission.createdAt)}</td>
                                                    <td><span className='text-warning'>{commission.amount}</span></td>
                                                    <td>
                                                        {commission.note}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    md={{ span: 10, offset: 1 }}
                                    sm={{ span: 12 }}
                                    className="d-flex justify-content-end mb-5">
                                    <CustomPagination post={post} total={total} current={pageNumber} />
                                </Col>
                            </Row>
                        </>
                    }
                </Container >
            </>
        );
    }
}

export default AgentCommissionTransactionList;