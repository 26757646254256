import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from 'resources/api-constants';
import { ROUTES } from 'resources/routes-constants';
import { clientAxios } from 'utility/api/agent/axiosAgent';
import { convertImageToBase64, fileCheck, getNumber, optimizeImage } from 'utility/functions';

const useAgentDepositLogic = () => {
    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showToastType, setShowToastType] = useState('fail');
    const [image, setImage] = useState('');
    const [imageUpload, setImageUpload] = useState('');
    const [amount, setAmount] = useState(0);
    const [gcashId, setGcashId] = useState('');
    const [gcashNumber, setGcashNumber] = useState('');
    const [gcashName, setGcashName] = useState('');

    const [transaction, setTransaction] = useState('buy');
    const [error, setError] = useState('');

    const [loadingStatus, setLoadingStatus] = useState(true);

    //GET GCASH
    async function getGcashLink() {
        try {
            const response = await clientAxios.get(
                API.AGENT_GCASH_LINK
            )

            setGcashId(response.data.data.id);
            setGcashNumber(response.data.data.number);
            setGcashName(response.data.data.name);

            setError('');

        } catch (error) {
            setError('An error has occured. Please try again.');
        }

        setLoadingStatus(false);

    };

    React.useEffect(() => {
        getGcashLink();
    }, []);

    //END GET GCASH

    const handleClose = () => setShow(false);
    const verificationRedirect = useNavigate();
    const handleSubmit = async function () {

        try {
            const response = await clientAxios.post(
                API.AGENT_CREDITS_REQUEST_LINK, {
                    gcashAccountId: gcashId,
                    amount: amount,
                    image: imageUpload
                //image: imageUpload
            },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }

                })

            setError('');
            setShowToastType('success');
            verificationRedirect(ROUTES.AGENT_HOME_ROUTE);
        } catch (error) {
            setError('An error has occured. Please try again.');
            setShowToastType('fail');
        }

        setShow(false);
        setShowToast(true);

        setLoadingStatus(false);

    }

    const handleShow = async function (e: any) {
        e.preventDefault();

        const gcashAmount = getNumber(e.target.amount.value);
        setError('')

        if (gcashAmount < 100) {
            setError('Minimum amount is 100')
            return null;
        } else if (gcashAmount > 300000) {
            setError('Maximum amount is 300,000')
            return null;
        }

        let newImage = e.target.screenshot.files[0];

        let imageValid = '';
        let result = true;

        imageValid = fileCheck(newImage);

        setError(imageValid);

        if (imageValid === '') {
            //const optimizedImage = optimizeImage(newImage, 300, 300); 
            setImageUpload(newImage);

            const optimizedDataUrl = await optimizeImage(newImage, 600, 800);

            setImage(URL.createObjectURL(newImage));

            setAmount(gcashAmount);

            setShow(true);
        }


    }
    const handleCloseToast = () => setShowToast(false);

    return {
        showToast, handleCloseToast, showToastType,
        show, handleClose, handleSubmit, transaction,
        handleShow, image, amount, gcashName, gcashNumber,
        error, loadingStatus
    };
}

export default useAgentDepositLogic;