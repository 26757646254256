import axios from "axios";
import { API } from "resources/api-constants";

const AGENT = API.AGENT_URL;

export const clientAxiosLogin = axios.create({
   baseURL: AGENT,
   headers: {
      "Content-Type": "application/json"
   }
   // timeout: 1000,
});

export const clientAxios = axios.create({
   baseURL: AGENT,
   // timeout: 1000,
   headers: {
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + localStorage.getItem('access-token')
   },
   statusCodes: [401, 403, 429, 500], // default: [ 401 ]
});

export const clientAxiosRefresh = axios.create({
   baseURL: AGENT,
   headers: {
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + localStorage.getItem('access-token')
   },
   responseType: "json"
});

// Instantiate the interceptor
//createAuthRefreshInterceptor(clientAxios, refreshAuthLogic);

export const refreshToken = () => {
   let result = false;

   clientAxios.post(
      API.AGENT_REFRESH_TOKEN, {},
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access-token') } }
   ).then((response) => {
      localStorage.setItem('access-token', response.data.access.token);
      localStorage.setItem('login-date', String(Date.now()));
      localStorage.setItem('expires', response.data.access.expires_in);
      localStorage.setItem('user-id', response.data.data.user_id);
      localStorage.setItem('email-address', response.data.data.email);
      localStorage.setItem('name', response.data.data.name);
      localStorage.setItem('total-credits', response.data.data.total_credits);
      localStorage.setItem('referral-code', response.data.data.ref_code);
      result = true;
   }).catch((error) => {
      result = false;
   });

   return result;
}

export const validToken = () => {
   var result = true;
   clientAxios.post(
      API.PLAYER_VALID_TOKEN, {},
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access-token') } }
   ).then((response) => {

      result = response.data.valid;

      localStorage.setItem('validToken', result);
   }).catch((error) => {
      result = false;
      localStorage.setItem('validToken', false);
   });
}