import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';

import { clientAxios } from 'utility/api/operator/axiosOperator';

import { API } from '../../resources/api-constants';

const useLoginLogic = () => {
    const initialFormData = Object.freeze({
        email: "",
        password: "",
        remember: "",
    });

    const [formData, updateFormData] = React.useState(initialFormData);
    const [loadingStatus, setLoadingStatus] = useState(true);

    const handleChange = (e: any) => {
        updateFormData({
            ...formData,

            // Trimming any whitespace
            [e.target.name]: e.target.value.trim()
        });
    };

    const homeRedirect = useNavigate();

    const [errorMessage, updateErrorMessage] = React.useState('');
    // const [showError, updateShowError] = React.useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        // updateShowError(false);
        // call api
        // try {
        updateErrorMessage('Logging In');

        const rememberMe = e.target.remember.checked;

        const response = await clientAxios.post(
            API.OPERATOR_LOGIN_LINK,
            {
                email: formData.email,
                password: formData.password
            },
            {
                headers: { "Content-Type": "application/json" },
            }
        ).then((response) => {
            //successful, add to local storage

            localStorage.setItem('access-token', response.data.data.accessToken);
            localStorage.setItem('login-date', String(Date.now()));
            localStorage.setItem('user-id', response.data.data.id);
            localStorage.setItem('email-address', response.data.data.email);
            localStorage.setItem('name', response.data.data.name);

            localStorage.setItem('user-session', API.OPERATOR_CODE);

            if (rememberMe) {
                localStorage.setItem('operator-remember', '1');
            } else {
                localStorage.setItem('operator-remember', '0');
            }

            updateErrorMessage('Logged In');

            //redirect
            // homeRedirect(ROUTES.OPERATOR_CATEGORIES_ROUTE);
            window.location.replace(ROUTES.OPERATOR_CATEGORIES_ROUTE);

        }).catch((error) => {
            updateErrorMessage("Login failed");
            // updateShowError(true);
            localStorage.clear();
        });
    };

    const [eyeicon, seteye] = useState(true);
    const [password, setpassword] = useState("password");

    function Eye() {
        if (password == "password") {
            setpassword("text");
            seteye(false);
        }
        else {
            setpassword("password");
            seteye(true);
        }
    }

    //fetch on initial load
    React.useEffect(() => {
        if (localStorage.getItem('operator-remember') === '1' && localStorage.getItem('access-token')) {
            window.location.replace(ROUTES.OPERATOR_CATEGORIES_ROUTE);
        } else {
            setLoadingStatus(false);
        }
    }, []);

    return {
        handleSubmit, handleChange,
        password, eyeicon,
        Eye,
        errorMessage,
        loadingStatus
    };
}

export default useLoginLogic;