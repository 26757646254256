import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { Button } from 'react-bootstrap';
import { ROUTES } from '../../resources/routes-constants';

import AdminCustomModal from '../../components/admin/modals/AdminCustomModal';
import AdminCustomToast from '../../components/admin/AdminCustomToast';

import useAgentLogic from '../../components/admin/AgentLogic';

const UsersAgentEdit: React.FC = () => {
    const {
        showToast, handleCloseToast, showToastType,
        show, handleClose,
        handleSubmit,
        transaction

    } = useAgentLogic();

    return (
        <>
            <AdminCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
            <AdminCustomModal show={show} close={handleClose} submit={handleSubmit} transaction={transaction} />

            <Container>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>Agent Profile</h4>
                    </Col>
                </Row>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 8, offset: 2 }}
                        className='boxContainer px-5 pt-4 pb-4'>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="my-1 text-center">
                                <Form.Label className='text-danger'><h5 className=''>MASTER AGENT</h5></Form.Label>
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="exampl e Form.ControlInput1">
                                <Form.Label><h6>Email : email@email.com</h6></Form.Label>
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="exampl e Form.ControlInput1">
                                <Form.Label><h6>Name : Full name</h6></Form.Label>
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="exampl e Form.ControlInput1">
                                <Form.Label><h6>Credits : <span className='text-warning'>10,231</span></h6></Form.Label>
                            </Form.Group>

                            <Form.Group className="mb-3 mt" controlId="example Form.ControlInput1">
                                <Form.Label>
                                    <h6 className='mb-0'>Status</h6>
                                </Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="Activate"
                                    className='m-0'
                                    name='status' />
                            </Form.Group>
                            <div className='text-end mt-4'>
                                {/* <Button variant="danger" className='me-2'>Deactivate</Button> */}
                                <Button href={ROUTES.ADMIN_AGENTS_ROUTE} variant="secondary" className='me-2'>Back</Button>
                                <Button variant="primary" type='submit'>Update</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container >
        </>
    );
}

export default UsersAgentEdit;