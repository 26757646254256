import React, { useState } from 'react';

import { clientAxios } from 'utility/api/members/axiosPlayer';
import { API } from '../../resources/api-constants';
import { ROUTES } from '../../resources/routes-constants';
import { useParams } from 'react-router-dom';

const useApiCategoryListLogic = () => {

    const [currentPage, setCurrentPage] = useState(useParams());
    const [userId, setUserId] = useState(currentPage.id);

    const [loadingStatus, setLoadingStatus] = useState(true);
    const [errorMessage, updateErrorMessage] = useState('');

    //GET CATEGORIES
    const [post, setPost] = useState({ data: Array(2) });
    const [error, setError] = useState(false);

    async function loginUser(e: any) {
        // try {
        updateErrorMessage("Connecting...");

        const email = 'user-' + userId + '@ggwplay.xyz';
        localStorage.setItem('connect-user-id', String(userId));

        await clientAxios.post(
            API.API_CONNECT,
            {
                userId: userId,
                email: email
            }
        ).then((response) => {
            if (response.data.data.role.title === 'Player') {

                //updateErrorMessage('Connect Successful');
                //successful

                localStorage.setItem('validToken', 'true');

                localStorage.setItem('access-token', response.data.data.accessToken);

                localStorage.setItem('login-date', String(Date.now()));
                localStorage.setItem('user-id', response.data.data.id);
                localStorage.setItem('email-address', response.data.data.email);
                localStorage.setItem('name', response.data.data.name);


                localStorage.setItem('total-credits', response.data.data.wallet.balance);

                localStorage.setItem('withdraw-credit', '0');
                localStorage.setItem('user-session', API.CONNECT_CODE);

                getPostData();
            } else {
                updateErrorMessage("An error has occurred. Refresh page or contact the site admin.");
                localStorage.clear();
            }

            setError(false);
        }).catch((error) => {
            updateErrorMessage("An error has occurred. Refresh page or contact the site admin.");
            setError(true);
            setLoadingStatus(false);

            localStorage.clear();
        }).finally(() => {
            //homeRedirect(ROUTES.API_RESPONSE_CATEGORIES);
        });

    }

    async function getPostData() {
        await clientAxios.get(
            API.PLAYER_CATEGORIES_LINK
        ).then((response) => {
            setPost(response.data);
            setError(false);

        }).catch((error) => {
            setError(true);
            // if ('ERR_BAD_RESPONSE' == error.code) {
            //     window.location.reload();
            // }
        });
        setLoadingStatus(false);
    }

    //fetch on initial load
    React.useEffect(() => {
        localStorage.setItem('user-session', API.CONNECT_CODE);

        loginUser(userId);
    }, []);

    //END GET CATEGORIES

    return {
        post, errorMessage, error,
        loadingStatus
    };
}

export default useApiCategoryListLogic;