import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';

import { clientAxios } from 'utility/api/operator/axiosOperator';
import { formatNumberToCurrency } from 'utility/functions';
import { API } from '../../../resources/api-constants';
import { pusherInit } from '../../../utility/pusher';

const OperatorBetModal = (props: any) => {
    const handleCloseBet = props.closeBet;
    const showBet = props.showBet;
    const team1 = props.team1;
    const team2 = props.team2;
    const betStatus = props.betStatus;

    const categoryId = props.categoryId;
    //const gameId = props.gameId;
    const matchId = props.matchId;

    const [error, setError] = useState(false);
    //const [errorMessage, setErrorMessage] = useState('');

    //GET BETS
    const pusherEventKey = props.pusherEventKey;

    const [betTeam1, setBetTeam1] = useState(0);
    const [betTeam2, setBetTeam2] = useState(0);
    const [betPercentTeam1, setBetPercentTeam1] = useState('');
    const [betPercentTeam2, setBetPercentTeam2] = useState('');

    //FETCH BETS
    function getPostData() {
        let queryType = "sabong";

        if (categoryId === '1') {
            queryType = 'esports';
        } else if (categoryId === '3') {
            queryType = 'sports';
        }

        clientAxios.get(
            API.OPERATOR_GAMES_MATCH_VIEW_BETS_LINK + '/' + queryType + '/' + categoryId + '/matches/' + matchId + '/view-total-bets',
            {
                //id: matchId
            }
        ).then((response) => {
            setBetTeam1(response.data.data.blueTotalBets);
            setBetTeam2(response.data.data.redTotalBets);
            setBetPercentTeam1(response.data.data.bluePercentage);
            setBetPercentTeam2(response.data.data.redPercentage);


            //calculateBets(response.data.data.team_1, response.data.data.team_2);

            setError(false);

        }).catch((error) => {
            setError(true);
        }).finally(() => {
        });

    }

    React.useEffect(() => {
        if (showBet)
            getPostData();


        const pusher = pusherInit();

        const channel1 = pusher.subscribe('ez-app');
        //setPusherChannel(channel1);

        channel1.bind(pusherEventKey, function (data: any) {
            if (data.type === 'PLAYER_BET') {
                setBetTeam1(data.data.blueTotalBets);
                setBetTeam2(data.data.redTotalBets);

                setBetPercentTeam1(data.data.bluePercentage);
                setBetPercentTeam2(data.data.redPercentage);

            }

        });

        return (() => {
            pusher.unsubscribe('ez-app');
        })
    }, [showBet]);

    return (

        <Modal show={showBet} onHide={handleCloseBet}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {
                        (1 === betStatus || 3 === betStatus)
                            ? 'Ongoing Bets'
                            : 'Bets Closed'
                    }

                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
                <Row>
                    <Col xs={{ span: 6 }}>
                        <h4 className='text-center text-wala'>{team1}</h4>
                        <p className='text-center text-warning h5 mt-4'>{formatNumberToCurrency(betTeam1)}</p>
                        <p className='text-center h6 mt-2'>{betPercentTeam1}%</p>
                    </Col>
                    <Col xs={{ span: 6 }} className='mb-3'>
                        <h4 className='text-center text-danger'>{team2}</h4>
                        <p className='text-center text-warning h5 mt-4'>{formatNumberToCurrency(betTeam2)}</p>
                        <p className='text-center h6 mt-2'>{betPercentTeam2}%</p>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>

                <Button variant="secondary" onClick={handleCloseBet}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

    );

}

export default OperatorBetModal;