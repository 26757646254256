import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { ROUTES } from '../resources/routes-constants';
import AvailableGames from './partials/AvailableGames';

const ForgotPasswordSuccess: React.FC = () => {

    return (
        <>
            <Container>
                <Row className='m-auto vh-100'>
                    <Col
                        lg={{ span: 6 }}
                        sm={{ span: 12 }}
                        className='m-auto'
                    >
                        <div id="forgotPassword" className="wrapper mb-2">
                            <div className="login-box">
                                <div className="card card-outline card-primary mx-auto mt-5">
                                    <div className="text-center">
                                        <h2 className="card-header text-center">
                                            Forgot Password    
                                        </h2>
                                    </div>
                                    <div className="card-body bg-dark">
                                        <p className="login-box-msg mt-4 text-center"><span className='h4'>Submitted your request</span></p>
                                        <p className="login-box-msg mt-3 text-center"><span className='h6'>Check your email to reset your password</span></p>
                                        <p className="mt-5 mb-1 text-center">
                                            <a href={ROUTES.LOGIN_ROUTE} className='btn-outline-secondary btn w-100'>Back to Login</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col
                        lg={{ span: 6 }}
                        sm={{ span: 12 }}
                        className='m-auto'
                    >
                        <AvailableGames />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ForgotPasswordSuccess;
