import ConnectContent from './ConnectContent'

const ConnectLayout = () => {
  return (
    <div>
        <div className="body flex-grow-1 px-3 mt-4">
              <ConnectContent />
        </div>
    </div>
  )
}

export default ConnectLayout