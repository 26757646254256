import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ROUTES } from '../../resources/routes-constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faEnvelopeOpenText, faMoneyBillTransfer, faRotate } from '@fortawesome/free-solid-svg-icons';

import AgentCustomModal from 'components/agents/modals/AgentCustomModal';

import useAgentHomeLogic from 'components/agents/AgentHomeLogic';
import { formatDateWithTime, formatNumberToCurrency } from 'utility/functions';
import Loader from 'components/Loader';

const AgentHome: React.FC = () => {
    const {
        show, handleClose, handleShowQR, transaction,
        agentLink,
        totalCommission,
        commissionMTD, mainCoor, topDownlines,
        loadingStatus
    } = useAgentHomeLogic();

    return (
        <>
            <AgentCustomModal
                show={show}
                close={handleClose}
                agentLink={agentLink}
                transaction={transaction}
            />

            <Container>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 12 }}
                        className="text-center mb-4">
                        <h4>Agent <span className='highlightText px-2'>Dashboard</span></h4>
                    </Col>
                </Row>
                {loadingStatus ?
                    <Loader />
                    :
                    <>
                        <Row>
                            <Col
                                lg={{ span: 6, offset: 3 }}
                                md={{ span: 12 }}
                                className='boxContainer p-5'>
                                <Row>
                                    <Col
                                        md={{ span: 6 }}
                                        className="mb-3">
                                        <div className='text-left h5 mb-0 pb-0 text-warning'>
                                            {
                                                (localStorage.getItem('total-commissions') === 'null' || localStorage.getItem('total-commissions') === '0')
                                                    ?
                                                    0
                                                    :
                                                    formatNumberToCurrency(localStorage.getItem('total-commissions'))}
                                        </div>
                                        <div className='text-left' >Commission Balance</div>
                                    </Col>
                                    <Col className="mb-3">
                                        <div className='text-right h5 mb-0 pb-0 text-warning'>{formatNumberToCurrency(commissionMTD)}</div>
                                        <div className='text-right' >Commission (MTD)</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mb-1">
                                        <div className='text-left' >Commission computed : {formatDateWithTime(Date())}</div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col className="bg-secondary p-2 text-center">
                                        <div className='h6'>
                                            Main Coor:
                                            {(typeof mainCoor === 'string') ? mainCoor : mainCoor.name}
                                        </div>
                                        {/* <div className='h6'>Commission: 18.18% of Revenue (GGR)</div> */}
                                    </Col>
                                </Row>
                                <Row className='mt-4 mb-2 agentHomelinks'>
                                    <Col xs={{ span: 3 }} className='text-center bg-'>
                                        <a href={ROUTES.AGENT_CREDITS_BUY_ROUTE} title='Buy'>
                                            <FontAwesomeIcon icon={faCartShopping} className='fa-2xl' /><br />
                                            Buy
                                        </a>
                                    </Col>
                                    <Col xs={{ span: 3 }} className='text-center'>
                                        <a href={ROUTES.AGENT_WITHRAWALS_ROUTE + '/1'} title='Withdrawals'>
                                            <FontAwesomeIcon icon={faMoneyBillTransfer} className='fa-2xl' /><br />
                                            Withdrawals
                                        </a>
                                    </Col>
                                    <Col xs={{ span: 3 }} className='text-center'>
                                        {'' === localStorage.getItem('gcash') ?
                                            <>
                                                <a href={ROUTES.AGENT_ACCOUNT_ROUTE} className='text-warning' title='Gcash'>
                                                    <FontAwesomeIcon icon={faEnvelopeOpenText} className='fa-2xl' /><br />
                                                    Setup Gcash
                                                </a>
                                            </>
                                            :
                                            <>
                                                <a href="#" onClick={handleShowQR} title='Inv Link'>
                                                    <FontAwesomeIcon icon={faEnvelopeOpenText} className='fa-2xl' /><br />
                                                    Inv Link
                                                </a>
                                            </>
                                        }
                                    </Col>
                                    <Col xs={{ span: 3 }} className='text-center'>
                                        <a href={ROUTES.AGENT_COMMISSION_CONVERT_ROUTE} title='Convert'>
                                            <FontAwesomeIcon icon={faRotate} className='fa-2xl' /><br />
                                            Convert
                                        </a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="p-2 text-center">
                                        <div className='text-center h3 mt-4'>Top Downlines</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        xs={{ span: 6 }}
                                        className="mb-2">
                                        <div className='text-left' >Downline</div>
                                    </Col>
                                    <Col className="">
                                        <div className='text-center' >Commission (MTD)</div>
                                    </Col>
                                    <hr />
                                </Row>

                                {topDownlines && topDownlines.map((downline: any, count: any) => (
                                    <Row key={Date.now() + Math.random()}>
                                        <Col
                                            xs={{ span: 6 }}
                                            className="mb-3"
                                        >
                                            <div className='text-left h6 pb-0 mb-0'>{downline.userName}</div>
                                            {/* <small className='text-left text-warning'>email</small> */}
                                        </Col>
                                        <Col className="mb-3">
                                            <div className='text-center h5 mb-0 pb-0'>
                                                <span className='text-warning'>{formatNumberToCurrency(downline.commission)}</span>
                                                {/* <a href="#">
                                            <FontAwesomeIcon icon={faPaperPlane} className=' ms-2' /><br />
                                        </a> */}
                                            </div>
                                        </Col>
                                        <hr />
                                    </Row>
                                ))}
                                <Row>
                                    <Col className='text-center mt-4 mb-4'>
                                        <a href={ROUTES.AGENT_DOWNLINES_ROUTE + '/1'} className='btn btn-warning w-50'> Active Downlines</a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                }
            </Container >
        </ >
    );
}

export default AgentHome;