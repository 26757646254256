import React, { useEffect, useState } from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Card, Collapse } from 'react-bootstrap';
import { API } from 'resources/api-constants';
import { clientAxios, refreshToken } from 'utility/api/members/axiosPlayer';
import { formatNumberToCurrency } from 'utility/functions';
import { ROUTES } from 'resources/routes-constants';
import { pusherInit } from '../../../utility/pusher';

const Bet2 = (props: any) => {
    // const Bet: React.FC = (props: any) => {
    const matchId = props.matchIdCurrent;
    const fightNumber = props.fightNumber;
    const [betStatus, setBetStatus] = useState(props.betStatus);
    const [settleStatus, setSettleStatus] = useState(props.settleStatus);

    const team1 = props.team1;
    const team2 = props.team2;
    const pusherEvent = props.pusherEvent;
    const handleBetSubmit = props.handleBetSubmit;
    const handleUpdateMatchDetails = props.handleUpdateMatchDetails;

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loadingStatus, setLoadingStatus] = useState(true);

    const [message, setMessage] = useState('');

    const [betAmount, setBetAmount] = useState(0);
    const [betChoice, setBetChoice] = useState('');

    const [matchResult, setMatchResult] = useState(props.matchResult);

    const [betTeam1, setBetTeam1] = useState(0);
    const [betTeam2, setBetTeam2] = useState(0);
    const [betPercentTeam1, setBetPercentTeam1] = useState('');
    const [betPercentTeam2, setBetPercentTeam2] = useState('');

    const pusherChannel = props.pusherChannel;

    //FETCH BETS
    function getPostData() {

        const currentMatch = localStorage.getItem('current-match');

        clientAxios.get(
            API.PLAYER_BET_CURRENT_LINK + '/' + currentMatch + '/bets-placed',
        ).then((response) => {
            if (response.data.count > 0) {
                setBetTeam1(response.data.data.blueTotalBets);
                setBetTeam2(response.data.data.redTotalBets);

                setBetPercentTeam1(response.data.data.bluePercentage);
                setBetPercentTeam2(response.data.data.redPercentage);
                //calculateBets(response.data.data.redTotalBets, response.data.data.blueTotalBets);
            } else {
                setBetTeam1(0);
                setBetTeam2(0);

                setBetPercentTeam1('0');
                setBetPercentTeam2('0');
                //calculateBets(0, 0);
            }

            setError(false);

        }).catch((error) => {
            setError(true);
        }).finally(() => {
            //reset bets to 0 after match is settled
            if ('1' == settleStatus || 1 == settleStatus) {
                setBetTeam1(0);
                setBetTeam2(0);
            }
        });

        setLoadingStatus(false);
    }

    React.useEffect(() => {
        getPostData();

        //const channel1 = pusherChannel.subscribe('ez-app');

        pusherChannel.bind(pusherEvent, function (data: any) {
            if (data.type === 'PLAYER_BET') {
                setBetTeam1(data.data.blueTotalBets);
                setBetTeam2(data.data.redTotalBets);

                setBetPercentTeam1(data.data.bluePercentage);
                setBetPercentTeam2(data.data.redPercentage);
            } else if (data.type === 'UPDATE_WINNER_RESULT') {
                setMatchResult(data.data);
            } else if (data.type === 'UPDATE_BET_STATUS') {
                if (data.data.status) {
                    setBetStatus(data.data.status.status);
                } else {
                    setBetStatus(3);
                }
            } else if (data.type === 'CREATE_NEXT_MATCH') {
                window.location.reload();
                //handleUpdateMatchDetails();
            } else if (data.type === 'MATCH_SETTLED') {
                //getPostData();
                setSettleStatus('Settled');
            } else if (data.type === 'UPDATE_MATCH_DETAILS') {
                window.location.reload();
                //handleUpdateMatchDetails();
            }

        });

        //return (() => {
        //    pusherChannel.unsubscribe('ez-app');
        //})
    }, []);
    //END FETCH BETS

    const [betLoading, setBetLoading] = useState(false);
    const handleSubmit = async function (e: any) {
        e.preventDefault();

        const betAmount = e.target.betAmount.value;
        const betBalance = Number(localStorage.getItem('total-credits'));

        if (betAmount > betBalance) { // not enough balance
            setErrorMessage('Not enough balance');
        } else {
            setErrorMessage('Placing Bet...');
            setBetLoading(true);

            await clientAxios.post(
                API.PLAYER_BET_LINK + '/' + localStorage.getItem('current-match') + '/play',
                {
                    team: betChoice,
                    amount: betAmount
                }
            ).then((response) => {
                setErrorMessage(response.data.message);
                handleBetSubmit(true);

                clientAxios.get(
                    API.PLAYER_ACCOUNT_LINK,
                    {}
                ).then((response) => {
                    localStorage.setItem('total-credits', response.data.data.wallet.balance);

                    //update balance text
                    const creditsObj = document.getElementById('totalCredits');
                    Object(creditsObj).innerHTML = formatNumberToCurrency(response.data.data.wallet.balance);

                }).catch((error) => {
                });

                // getPostData();
            }).catch((error) => {
            }).finally(() => {
                //setBetLoading(false);
                e.target.betAmount.value = '';
                setTimeout(
                    () => setBetLoading(false),
                    500
                );
            });
        }
    }

    const chooseBet = (choice: any) => (e: any) => {

        setBetChoice(choice);
    };

    const handleClick = (val: any) => (e: any) => {
        e.preventDefault();

        setBetAmount(val);
    };

    return (
        <Card id='bets' className="play-card mb-2" bg='dark'>
            <Card.Header className=''>
                <Row>
                    <Col
                        xs={{ span: 4 }}
                        className='d-flex justify-content-start'>PLACE BET</Col>
                    <Col
                        xs={{ span: 4 }}
                        className='d-flex justify-content-center'>
                        {'Settled' === settleStatus ?
                            <span className='text-success h5 m-0' key={1}>Settled</span> :
                            (1 === betStatus) ?
                                <span className='text-success h5 m-0' key={1}>Open</span> :
                                (3 === betStatus) ?
                                    <span className='text-warning h5 m-0 blinkText' key={2}>Last Call</span> :
                                    (2 === betStatus) ?
                                        <span className='text-danger h5 m-0' key={0}>Closed</span> :
                                        <span className='text-secondary h5 m-0' key={3}>Pending</span>
                        }
                    </Col>
                    <Col
                        xs={{ span: 4 }}
                        className='d-flex justify-content-end'><span className='text-align-right h6 m-0'>Fight #{fightNumber}</span></Col>
                </Row>
            </Card.Header>
            <Card.Body className='mt-3'
                style={{ minHeight: '426px' }}
            >
                {/* BET FORM */}
                <Row>
                    <Col className='p-3 text-center'>
                        <form onSubmit={handleSubmit}>
                            <div>
                                {(1 === betStatus || 3 === betStatus) ?
                                    <>
                                        <a href='#' onClick={handleClick(localStorage.getItem('total-credits'))} className='btn btn-secondary btn-block btn-bet-circle w-25 m-1'>All-In</a>
                                        <a href='#' onClick={handleClick(500)} className='btn btn-secondary btn-block btn-bet-circle w-25 m-1'>500</a>
                                        <a href='#' onClick={handleClick(100)} className='btn btn-secondary btn-block btn-bet-circle w-25 m-1'>100</a>
                                        <a href='#' onClick={handleClick(50)} className='btn btn-secondary btn-block btn-bet-circle w-25 m-1'>50</a>
                                        <a href='#' onClick={handleClick(20)} className='btn btn-secondary btn-block btn-bet-circle w-25 m-1'>20</a>
                                    </>
                                    :
                                    <>
                                        <a href='#' className='btn btn-secondary btn-block btn-bet-circle w-25 m-1 disabled'>All-In</a>
                                        <a href='#' className='btn btn-secondary btn-block btn-bet-circle w-25 m-1 disabled'>500</a>
                                        <a href='#' className='btn btn-secondary btn-block btn-bet-circle w-25 m-1 disabled'>100</a>
                                        <a href='#' className='btn btn-secondary btn-block btn-bet-circle w-25 m-1 disabled'>50</a>
                                        <a href='#' className='btn btn-secondary btn-block btn-bet-circle w-25 m-1 disabled'>20</a>
                                    </>
                                }
                            </div>
                            <div>
                                <span className='text-danger'>&nbsp;{errorMessage}</span>
                            </div>
                            <div>
                                {(1 === betStatus || 3 === betStatus) ?
                                    <>
                                        <input
                                            type="number" min='20' step='.01' inputMode="numeric"
                                            className='w-25 my-3 text-center'
                                            name='betAmount'
                                            defaultValue={betAmount}
                                            key={betAmount}
                                            required
                                        // pattern="\d*"
                                        />
                                    </>
                                    :
                                    <></>
                                }
                            </div>
                            <div>
                                <div className='text-center mt-4'>
                                    {('null' === matchId || null === matchId) ?
                                        <span className='h4 text-danger'>Bets not Open</span>
                                        :
                                        (1 === betStatus || 3 === betStatus) ?
                                            <>
                                                {(betLoading) ?
                                                    <>
                                                        <Button type="submit" className="mw-50 me-3 button-wala" disabled>Bet {team1}</Button>
                                                        <Button type="submit" className="mw-50 btn-danger me-3" disabled>Bet {team2}</Button>
                                                    </>
                                                    :
                                                    <>
                                                        <Button type="submit" className="mw-50 button-wala me-3"
                                                            style={{ width: '110px' }}
                                                            onClick={chooseBet('blue')}>Bet {team1}</Button>
                                                        <Button type="submit" className="mw-50 btn-danger"
                                                            style={{ width: '110px' }}
                                                            onClick={chooseBet('red')}>Bet {team2}</Button>
                                                    </>
                                                }
                                            </>
                                            :
                                            <span className='h4'>Bets are closed</span>
                                    }

                                </div>
                            </div>
                        </form>
                    </Col>
                </Row>
                {/* END BET FORM */}

                {
                    (('' === matchResult) || ('null' === matchResult) || (null === matchResult)) ?
                        <>
                            <Row>
                                <Col xs={{ span: 6 }}>
                                    <h4 className='text-center text-wala'>{team1}</h4>
                                    <p className='text-center text-warning h5 mt-4'>{formatNumberToCurrency(betTeam1)}</p>
                                    <p className='text-center h6 mt-2'>{betPercentTeam1}%</p>
                                </Col>
                                <Col xs={{ span: 6 }} className='mb-3'>
                                    <h4 className='text-center text-danger'>{team2}</h4>
                                    <p className='text-center text-warning h5 mt-4'>{formatNumberToCurrency(betTeam2)}</p>
                                    <p className='text-center h6 mt-2'>{betPercentTeam2}%</p>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            < Row >
                                <Col xs={{ span: 12 }} className='mb-3 align-middle'
                                    style={{ minHeight: '110px' }}
                                >
                                    <p className='text-center mt-5 align-middle h2'>
                                        {("red" === matchResult) ?
                                            <><span className='text-danger'>{team2}</span> wins!</>
                                            :
                                            ("blue" === matchResult) ?
                                                <><span className='text-primary'>{team1}</span> wins!</>
                                                :
                                                ("draw" === matchResult) ?
                                                    <><span className="text-warning">Draw</span></>
                                                    :
                                                    <><span className="text-capitalize">Cancelled</span></>
                                        }
                                    </p>
                                </Col>
                            </Row>
                        </>
                }
            </Card.Body>
        </Card >
    )
}

export default Bet2;