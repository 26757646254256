import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';
import { clientAxios } from 'utility/api/members/axiosPlayer';
import { API } from '../../resources/api-constants';

function useLogoutLogic() {
    const homeRedirect = useNavigate();

    useEffect(() => {
        localStorage.clear();
        homeRedirect(ROUTES.LOGIN_ROUTE);
    }, []);

};

export default useLogoutLogic;