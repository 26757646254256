import React from 'react';

import useLogoutLogic from '../../components/admin/LogoutLogic';

const Logout: React.FC = () => {
    useLogoutLogic();
    return (
        <>
        </>
    )
}

export default Logout