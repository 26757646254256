import React, { useState, useCallback } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { ROUTES } from '../../resources/routes-constants';
import { Button } from 'react-bootstrap';

import AgentCustomToast from 'components/agents/CustomToast';
import useAgentCommissionConvertLogic from 'components/agents/AgentCommissionLogic';
import { formatNumberToCurrency, updateTextView } from 'utility/functions';
import Loader from 'components/Loader';

const AgentCommissionConvert: React.FC = () => {
    const {
        showToast, showToastType, handleCloseToast,
        totalCommission,
        error, errorFlag,
        handleSubmit,
        loadingStatus
    } = useAgentCommissionConvertLogic();

    return (
        <>
            {/* <OperatorCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} /> */}
            <AgentCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />

            <Container>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>Convert <span className='highlightText px-2'>Commission</span></h4>
                    </Col>
                </Row>
                {loadingStatus ?
                    <Loader />
                    :
                    <>
                        <Row className='mb-3'>
                            <Col
                                lg={{ span: 6, offset: 3 }}
                                md={{ span: 8, offset: 2 }}
                                className='boxContainer px-5 pt-4 pb-4'>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label><h6>Commission Balance: <span className='text-warning ms-4'>{formatNumberToCurrency(totalCommission !== 'null' ? totalCommission : 0)}</span></h6></Form.Label>
                                    </Form.Group>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label><h6>Amount to Convert</h6></Form.Label>
                                        <Form.Control type='text' maxLength={7} name='amount' placeholder="100" defaultValue={0} max={Number(totalCommission !== 'null' ? totalCommission : 0)} min={100}  onChange={updateTextView} required />
                                    </Form.Group>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label><h6>Notes</h6></Form.Label><br />
                                        <textarea rows={5} name='notes' className='w-100' required>
                                        </textarea>

                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label className='d-flex justify-content-center'>
                                            {errorFlag ?
                                                <span className='text-danger'>&nbsp;{error}</span>
                                                :
                                                <span className='text-success'>&nbsp;{error}</span>
                                            }
                                        </Form.Label><br />
                                    </Form.Group>
                                    <div className='text-end'>
                                        <Button name="btnSubmit" className='w-25' variant="primary" type='submit'>Submit</Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </>
                }
            </Container >
        </>
    );
}

export default AgentCommissionConvert;