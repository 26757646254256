import React, { useState } from 'react';
import { ToastContainer } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';

const CustomToast = (props: any) => {
    const showToastType = props.showToastType;

    if ('success' == showToastType) {
        return (
            <Row>
                <Col xs={6}>
                    <ToastContainer className="p-3 position-fixed" position="top-end">
                        <Toast onClose={props.close} show={props.show} delay={5000} autohide className='bg-success'>
                            <Toast.Header closeButton={false}>
                                <img
                                    src="holder.js/20x20?text=%20"
                                    className="rounded me-2"
                                    alt=""
                                />
                                <strong className="me-auto">SUCCESS</strong>
                                {/* <small>1 min ago</small> */}
                            </Toast.Header>
                            <Toast.Body>Submitted Successfully!</Toast.Body>
                        </Toast>
                    </ToastContainer>
                </Col>
            </Row>
        );
    } else {
        return (
            <Row>
                <Col xs={6}>
                    <ToastContainer className="p-3 position-fixed" position="top-end">
                        <Toast onClose={props.close} show={props.show} delay={5000} autohide className='bg-danger'>
                            <Toast.Header closeButton={false}>
                                <img
                                    src="holder.js/20x20?text=%20"
                                    className="rounded me-2"
                                    alt=""
                                />
                                <strong className="me-auto">ERROR</strong>
                                {/* <small>1 min ago</small> */}
                            </Toast.Header>
                            <Toast.Body>An error has occured. Please try again.</Toast.Body>
                        </Toast>
                    </ToastContainer>
                </Col>
            </Row>
        );
    }
}

export default CustomToast;