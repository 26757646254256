import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'

import Form from 'react-bootstrap/Form';

import AgentCustomModal from 'components/agents/modals/AgentCustomModal';
import AgentCustomToast from 'components/agents/CustomToast';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeLowVision } from '@fortawesome/free-solid-svg-icons';

import useAgentAccountLogic from 'components/agents/AgentAccountLogic';

const AgentAccount: React.FC = () => {
    const {
        Eye,
        showToast,
        handleCloseToast,
        show,
        handleClose,
        handleSubmit,
        transaction,
        handleUpdateName,
        handleUpdateGcash, gcashMessage,
        handleChangePassword,
        password, eyeicon,
        password2, eyeicon2,
        password3, eyeicon3,
        errorMessage, errorMessageName
    } = useAgentAccountLogic();

    return (
        <>
            <AgentCustomModal show={showToast} close={handleCloseToast} />
            <AgentCustomToast show={show} close={handleClose} submit={handleSubmit} transaction={transaction} />

            <Container>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>Agent <span className='highlightText px-2'>Account</span> </h4>
                    </Col>
                </Row>
                <Row className='agentAccountForm'>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        className='boxContainer p-5 pt-0'>
                        <Row>
                            <Col className="p-2">
                                <Form onSubmit={handleUpdateName}>
                                {/* <Form> */}
                                    <Form.Group className="mb-3">
                                        <Form.Label><h6>Agent Name</h6></Form.Label>
                                        <Form.Control type="text" placeholder="Name" name='fullname' defaultValue={String(localStorage.getItem('name'))} key={localStorage.getItem('name')} required />
                                        <p className='text-danger text-center mt-2'>&nbsp;{errorMessageName}</p>
                                        <p className='text-center'><Button className="mt-1 w-50" type="submit">Update Name</Button></p>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col className="p-2">
                            <h5 className='text-center mb-5'>Update Gcash</h5>
                                <Form onSubmit={handleUpdateGcash}>
                                    <Form.Group className="mb-3">
                                    <Form.Label><h6>Gcash Name</h6></Form.Label>
                                        <Form.Control type="text" placeholder="" name='gcashName' defaultValue={String(localStorage.getItem('gcashName') === null ? '' : localStorage.getItem('gcashName'))} key={Date.now()+Math.random()} />
                                        <Form.Label className='mt-4'><h6>Gcash Number</h6></Form.Label>
                                        <Form.Control type="text" placeholder="09xxxxxx" name='gcashNumber' defaultValue={String(localStorage.getItem('gcash') === null ? '' : localStorage.getItem('gcash'))} key={Date.now()+Math.random()} required />
                                        <p className='text-danger text-center mt-2'>&nbsp;{gcashMessage}</p>
                                        <p className='text-center'><Button className="mt-1 w-50" type="submit">Update Gcash</Button></p>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col className="p-2">
                                <h5 className='text-center mb-5'>Update Password</h5>
                                <Form onSubmit={handleChangePassword}>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label><h6>Current Password</h6></Form.Label>
                                        <Form.Control type={password} name='password' placeholder="******" required />
                                        <FontAwesomeIcon icon={eyeicon ? faEye : faEyeLowVision} className='account-password-eye' onClick={() => Eye(1)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label><h6>New Password</h6></Form.Label>
                                        <Form.Control type={password2} name='newpassword' placeholder="******" required />
                                        <FontAwesomeIcon icon={eyeicon2 ? faEye : faEyeLowVision} className='account-password-eye' onClick={() => Eye(2)} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 position-relative">
                                        <Form.Label><h6>Verify Password</h6></Form.Label>
                                        <Form.Control type={password3} name='verifypassword' placeholder="******" required />
                                        <FontAwesomeIcon icon={eyeicon3 ? faEye : faEyeLowVision} className='account-password-eye' onClick={() => Eye(3)} />
                                    </Form.Group>
                                    <p className='text-danger text-center'>&nbsp;{errorMessage}</p>
                                    <p className='text-center'><Button className="mt-3 w-50" type="submit">Change Password</Button></p>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container >
        </ >
    );
}

export default AgentAccount;