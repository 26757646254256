import React, { useState } from 'react';

import { formatDate } from 'utility/functions';
import { useParams } from 'react-router-dom';
import { clientAxios } from 'utility/api/agent/axiosAgent';
import { API } from '../../resources/api-constants';

const useAgentDownlineTransactionViewLogic = () => {
    //get details
    const [idTemp, setIdTemp] = useState(useParams());
    const [id, setId] = useState(idTemp.id);
    const [post, setPost] = React.useState({ data: Array(2) });
    const [error, setError] = useState('');
    const [loadingStatus, setLoadingStatus] = useState(true);

    const [fullName, setFullName] = useState('');
    const [status, setStatus] = useState('');
    const [amount, setAmount] = useState('');
    const [image, setImage] = useState('');
    const [remarks, setRemarks] = useState('');

    const [createdAt, setCreatedAt] = useState('');

    const getPostData = async (id: any) => {
        try {
            const response = await clientAxios.get(
                API.AGENT_DOWNLINES_CREDIT_HISTORY_VIEW_LINK + '/' + id, {
            })

            setError('');

            setFullName(response.data.data.user.name);
            setStatus(response.data.data.status);
            setAmount(response.data.data.amount);
            setImage(response.data.data.image);
            setRemarks(response.data.data.remarks);

            setCreatedAt(response.data.data.createdAt);

            // setApprovedBy(response.data.data[0].approvedBy);
            // setUpdatedAt(formatDate(response.data.data[0].updatedAt));

        } catch (error) {
            setError('An error has occured. Please try again.');
        }

        setLoadingStatus(false);

    };

    React.useEffect(() => {
        getPostData(id);
    }, []);
    //end get details

    return {
        id, fullName, status, amount,
        image, createdAt, remarks,
        loadingStatus
    };
}

export default useAgentDownlineTransactionViewLogic;