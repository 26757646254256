import React, { useState } from 'react';

import { clientAxios } from '../../utility/api/admin/axiosCMS';
import { API } from '../../resources/api-constants';
import { useParams } from 'react-router-dom';

const useCategoryListLogic = () => {
    //get categories
    const [post, setPost] = React.useState({data: Array(2) });
    const [error, setError] = useState(null);
    const [total, setTotal] = React.useState(0);
    const [loadingStatus, setLoadingStatus] = useState(true);

    // function to fetch all table data
    const [currentPage, setCurrentPage] = useState(useParams());
    const [pageNumber, setPageNumber] = useState(currentPage.id);

    if (!pageNumber) {
        setPageNumber('1');
    }

    function getPostData(pageNumber: any) {
        clientAxios.get(
            API.ADMIN_CATEGORIES_LINK, {
            //page: pageNumber,
            //perPage: API.NUM_PER_PAGE,
        }
        ).then((response) => {
            setPost(response.data);
            setTotal(response.data.count);
            setError(null);

            setLoadingStatus(false);

        }).catch(setError)
    }

    //fetch on initial load
    React.useEffect(() => {
        getPostData(pageNumber);
    }, []);

    // ADD CATEGORY
    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const transaction = 'add-category';
    const handleClose = () => setShow(false);
    const handleCloseToast = () => setShowToast(false);

    const [showToastType, setShowToastType] = useState('success');

    const handleSubmit = function (e: any, result: any) {

        e.preventDefault();

        //api call
        if (result) {
            setShowToastType('success');
            getPostData(pageNumber);
        } else {
            setShowToastType('fail');
        }

        setShow(false);
        setShowToast(true);
    }

    const handleShow = (type: any) => setShow(true);
    //END ADD CATEGORY

    //CONFIRM DEACTIVATE
    const [showConfirmation, setConfirmation] = useState(false);
    const [fullName, setFullName] = useState('');
    const [id, setId] = useState('');
    const handleCloseConfirmation = () => setConfirmation(false);

    const [confirmationType, setConfirmationType] = useState('');

    const handleConfirmation = function (type: any, fullName: any, propId: any) {
        setConfirmation(true);

        setConfirmationType(type);
        setFullName(fullName);
        setId(propId);

    }
    // END CONFIRM DEACTIVATE

    // ACTIVATE OR DEACTIVATE
    const [deactivateResult, setDeactivateResult] = React.useState({ data: Array(2) });
    const [errorDeactivate, setErrorDeactivate] = useState(null);

    const handleConfirmationSubmit = (link: any) => (e: any) => {

        setConfirmation(false);
        e.preventDefault();

        //api call
        clientAxios.post(
            link,
            {
                id: id
            }
        ).then((response) => {
            setDeactivateResult(response);
            setShowToastType('success');
            getPostData(pageNumber);
        }).catch((error) => {
            setShowToastType('fail');
            setErrorDeactivate(error);
        });

        setShowToast(true); // show toast

    }
    // END ACTIVATE OR DEACTIVATE

    return {
        showToast, handleCloseToast, showToastType,
        show, handleClose, handleSubmit, transaction,
        showConfirmation, handleConfirmationSubmit, handleCloseConfirmation, confirmationType, fullName, id,
        handleShow,
        handleConfirmation,
        post, total, pageNumber,
        loadingStatus
    };
}

export default useCategoryListLogic;