import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

import { ROUTES } from '../../resources/routes-constants';

import useGameLogic from 'components/members/GameLogic';
import Loader from 'components/Loader';

const Games: React.FC = () => {
    const {
        post, handlePlayRedirect,
        loadingStatus
    } = useGameLogic();

    if (!(post) || 0 == post.data.length) {
        return (
            <>
                {!loadingStatus ?
                    <Container >
                        <Row>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                                className="text-center mb-5">
                                <h4>No Games Found</h4>
                            </Col>
                        </Row>
                    </Container>
                    :
                    <></>
                }
            </>
        );

    } else {

        return (
            <div>

                <Container>
                    <Row>
                        <Col
                            lg={{ span: 6, offset: 3 }}
                            md={{ span: 8, offset: 2 }}
                            className="text-center mb-5">
                            <h4>Select <span className='highlightText px-2'>Game</span></h4>
                        </Col>
                    </Row>
                    {!loadingStatus ?
                        <Row>
                            <Col
                                lg={{ span: 6, offset: 3 }}
                                sm={{ span: 8, offset: 2 }}
                                xs={{ span: 6, offset: 3 }}
                            >
                                <Row className='text-center'>
                                    {post && post.data.map((game: any, count: any) => (
                                        game.status === true ?
                                            <Col md={{ span: 4 }} className="m-auto mb-2" key={game.id}>
                                                <a href='#' onClick={handlePlayRedirect(game.id)} className='text-decoration-none'>
                                                    <div className='p-1 bg-dark border border-secondary rounded categoryIcons m-auto'>
                                                        <div className='m-auto text-center'>
                                                            <img
                                                                src={game.image ?
                                                                    game.image
                                                                    :
                                                                    '/images/placeholder.png'
                                                                }
                                                                alt={game.name}
                                                                className='m-auto rounded' style={{ maxHeight: '100px', maxWidth: '100px' }}
                                                            />
                                                            <p className='imageCardLabel mt-2 mb-2 bg-dark text-warning w-100'>{game.name}</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </Col>
                                            :
                                            <></>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                        :
                        <Loader />
                    }
                </Container >
            </div >
        );
    }
}

export default Games;