import { faEye, faEyeLowVision } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import useConnectLogic from '../../components/api/ConnectLogic';

const Connect: React.FC = () => {
    const {
        handleSubmit, handleChange,
        errorMessage,
        loadingStatus
    } = useConnectLogic();

    return (
        <>
            {
                (loadingStatus) ? <></>
                    :
                    <Container className='ps-0'>
                        <Row className='m-auto vh-100'>
                            <Col
                                lg={{ span: 12 }}
                                sm={{ span: 12 }}
                                className='m-auto ps-0'
                            >
                                <div id="login" className="wrapper mb-2">
                                    <section className="content">
                                        <div className="container-fluid">
                                            <div className="login-box">
                                                <div className="card card-outline card-primary mx-auto">
                                                    <h2 className="card-header text-center">
                                                        Connect
                                                    </h2>
                                                    <div className="card-body bg-dark">
                                                        <form action="/" method="get" onSubmit={handleSubmit}>
                                                            <div className="input-group mb-3">
                                                                <label className="form-label" ><h6>UserId</h6></label>
                                                                <input name='userid' type="text" className="form-control w-100" placeholder="UserId" onChange={handleChange} required />
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <label className="form-label" ><h6>Email</h6></label>
                                                                <input name='email' type="email" className="form-control w-100" placeholder="Email" onChange={handleChange} required />
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <button type="submit" className="btn btn-primary btn-block">Connect</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="content">
                                        <div className="container-fluid text-center mt-3">
                                            <span className='text-danger h6'>&nbsp;{errorMessage}</span>
                                        </div>
                                    </section>
                                </div>
                            </Col>
                        </Row>
                    </Container>
            }
        </>
    )
}

export default Connect