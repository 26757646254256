import React from 'react'
import { ROUTES } from '../../../resources/routes-constants'


// import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
// import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faHouse, faPlay, faCartShopping, faUpload, faMoneyBill, faCoins, faQuestion,
    faCircleExclamation, faPenToSquare, faList, faRightFromBracket, faEnvelope
} from '@fortawesome/free-solid-svg-icons'

function BottomNavBar() {
    return (
        <div className='d-md-none d-lg-none d-xl-none d-xxl-none'>
            {[false].map((expand) => (
                <Navbar key={null} expand={expand} className="" fixed="bottom">
                    <Container fluid className="bottomNav-container">
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="start"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className='header-title p-0'>
                                    {/* <img src='../logo.png' className='w-25 align-center me-2'
                                        style={{ background: '#FFF' }}
                                    /> GGWPlay */}
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Container className='text-center'>
                                    <div className='d-flex flex-row'>
                                        <div className='text-center'>
                                            <img src='../logo.png' className='w-50' alt="logo"/>
                                        </div>
                                        <div className='text-start'
                                            style={{ width: '350px', overflow: 'scroll' }}
                                        >
                                            {(localStorage.getItem('name') === 'null' || null == localStorage.getItem('name')) ?
                                                '' :
                                                localStorage.getItem('name')
                                            }
                                            <br />
                                            <small className='text-warning mt-0 pt-0'>
                                                {localStorage.getItem('email-address')}
                                            </small>
                                        </div>
                                    </div>
                                    {/* <Row>
                                        <img src='../logo.png' className='w-25 align-center me-2'
                                            style={{ background: '#FFF' }}
                                        /> GGWPlay
                                        <Col xs={4} className='text-center w-100'>
                                            {(localStorage.getItem('name') == 'null' || null == localStorage.getItem('name')) ?
                                                '' :
                                                localStorage.getItem('name')
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4} className="mt-0 pt-0 text-center w-100">
                                            <small className='text-warning mt-0 pt-0'>
                                                {localStorage.getItem('email-address')}
                                            </small>
                                        </Col>
                                    </Row> */}
                                </Container>
                                <Nav className="justify-content-end flex-grow-1 pe-3">

                                    <Navbar.Text className='mt-3'>WALLET</Navbar.Text>
                                    <Nav.Link href="#action2" className='p-1'></Nav.Link>
                                    <Nav.Link href={ROUTES.WITHDRAW_ROUTE} className='p-1'><FontAwesomeIcon icon={faUpload} className='me-3' />Withdraw</Nav.Link>

                                    <Navbar.Text className='mt-3'>REQUESTS</Navbar.Text>
                                    <Nav.Link href={ROUTES.DEPOSITS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faMoneyBill} className='me-3' />Cash Ins</Nav.Link>
                                    <Nav.Link href={ROUTES.WITHDRAWALS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faCoins} className='me-3' />Withrawals</Nav.Link>

                                    <Navbar.Text className='mt-3'>HISTORY</Navbar.Text>
                                    <Nav.Link href={ROUTES.DEPOSIT_HISTORY_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faList} className='me-3' />Cash Ins</Nav.Link>
                                    <Nav.Link href={ROUTES.WITHDRAWAL_HISTORY_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faList} className='me-3' />Withdrawals</Nav.Link>

                                    <Navbar.Text className='mt-3'>HELP</Navbar.Text>
                                    <Nav.Link href={ROUTES.TUTORIAL_ROUTE} className='p-1'><FontAwesomeIcon icon={faQuestion} className='me-3' />Tutorial</Nav.Link>
                                    <Nav.Link href={ROUTES.RULES_ROUTE} className='p-1'><FontAwesomeIcon icon={faCircleExclamation} className='me-3' />Rules</Nav.Link>
                                    <Nav.Link href={ROUTES.CONTACT_ROUTE} className='p-1'><FontAwesomeIcon icon={faEnvelope} className='me-3' />Contact</Nav.Link>

                                    <Navbar.Text className='mt-3'>ACCOUNT SETTINGS</Navbar.Text>

                                    <Nav.Link href={ROUTES.ACCOUNT_ROUTE} className='p-1'><FontAwesomeIcon icon={faPenToSquare} className='me-3' />Edit Account</Nav.Link>
                                    <Nav.Link href={ROUTES.PLAYER_LOGOUT_ROUTE} className='p-1'><FontAwesomeIcon icon={faRightFromBracket} className='me-3' />Sign Out</Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                        <Container >
                            <Row>
                                <Col xs={3} className=''>
                                    <a href={ROUTES.HOME_ROUTE} className='text-decoration-none text-light'>
                                        <div className='text-center'><FontAwesomeIcon icon={faHouse} className='' /></div>
                                        <div className='text-center'>Home</div>
                                    </a>
                                </Col>
                                <Col xs={3} className=''>
                                    <a href={ROUTES.PLAY_ROUTE} className='text-decoration-none text-light'>
                                        <div className='text-center'><FontAwesomeIcon icon={faPlay} className='' /></div>
                                        <div className='text-center'> Play</div>
                                    </a>
                                </Col>
                                <Col xs={3} className=''>
                                    <a href={ROUTES.DEPOSIT_ROUTE} className='text-decoration-none text-light'>
                                        <div className='text-center'><FontAwesomeIcon icon={faCartShopping} className='' /></div>
                                        <div className='text-center'>Credits</div>
                                    </a>

                                </Col>
                                <Col xs={3} className=''>
                                    <div className='text-center'><Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} /></div>
                                </Col>
                            </Row>
                        </Container>

                    </Container>
                </Navbar>
            ))}
        </div>
    );
}

export default BottomNavBar;