import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from 'resources/api-constants';
import { ROUTES } from 'resources/routes-constants';
import { clientAxios } from 'utility/api/members/axiosPlayer';
import { convertImageToBase64, fileCheck, getNumber, optimizeImage, toBase64 } from 'utility/functions';

const useDepositLogic = () => {
    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showToastType, setShowToastType] = useState('fail');
    const [image, setImage] = useState('');
    const [imageUpload, setImageUpload] = useState('');
    const [amount, setAmount] = useState(0);
    const [gcashId, setGcashId] = useState('');
    const [gcashNumber, setGcashNumber] = useState('');
    const [gcashName, setGcashName] = useState('');
    const [gcashStatus, setGcashStatus] = useState('');

    const [transaction, setTransaction] = useState('buy');
    const [error, setError] = useState('');

    const [loadingStatus, setLoadingStatus] = useState(true);

    //GET GCASH
    async function getGcashLink() {
        try {
            const response = await clientAxios.get(
                API.PLAYER_GCASH_LINK
            )
            setGcashId(response.data.data.id);
            setGcashNumber(response.data.data.number);
            setGcashName(response.data.data.name);
            //setGcashStatus(response.data.data.status);

            setError('');

        } catch (error) {
            setError('An error has occured. Please try again.');
        }

        setLoadingStatus(false);

    };

    React.useEffect(() => {
        getGcashLink();
    }, []);

    //END GET GCASH

    //CREDIT REQUEST
    const homeRedirect = useNavigate();
    const handleClose = () => setShow(false);
    const handleSubmit = async function () {

        try {
            const response = await clientAxios.post(
                API.PLAYER_CREDITS_REQUEST_LINK, {
                amount: amount,
                gcashAccountId: gcashId,
                image: imageUpload
            },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }

                })

            setError('');

            setShowToastType('success');
            homeRedirect(ROUTES.PLAY_ROUTE);

        } catch (error) {
            setError('An error has occured. Please try again.');
            setShowToastType('fail');
        } finally {
        }

        setShow(false);
        setShowToast(true);

        setLoadingStatus(false);

        //redirect to home page


    }
    //END CREDIT REQUEST

    const handleShow = async function (e: any) {
        e.preventDefault();

        const gcashAmount = getNumber(e.target.amount.value);
        setError('')

        if (gcashAmount < 100) {
            setError('Minimum amount is 100')
            return null;
        } else if (gcashAmount > 10000) {
            setError('Maximum amount is 10,000')
            return null;
        }

        let newImage = e.target.screenshot.files[0];

        let imageValid = '';
        let result = true;

        imageValid = fileCheck(newImage);
        setError(imageValid);


        if (imageValid === '') {
            //const optimizedImage = optimizeImage(newImage, 300, 300); 
            setImageUpload(newImage);

            const optimizedDataUrl = await optimizeImage(newImage, 600, 800);
            //// const base64String = toBase64(optimizedDataUrl);
            // return;
            //newImage = await convertImageToBase64(newImage);

            //setImage(optimizedDataUrl as string);
            setImage(URL.createObjectURL(newImage));

            setAmount(gcashAmount);

            setShow(true);
        }


    }
    const handleCloseToast = () => setShowToast(false);

    return {
        showToast, handleCloseToast, showToastType,
        show, handleClose, handleSubmit, transaction,
        handleShow, image, amount,
        gcashId, gcashName, gcashNumber, gcashStatus,
        error, loadingStatus
    };
}

export default useDepositLogic;