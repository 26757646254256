import React, { useState } from 'react';

import { clientAxios } from '../../utility/api/admin/axiosCMS';
import { API } from '../../resources/api-constants';
import { useParams } from 'react-router-dom';

const useWithdrawalListAgentLogic = () => {
    //get withdrawal history
    const [post, setPost] = React.useState({ data: Array(2) });
    const [error, setError] = useState(null);
    const [total, setTotal] = React.useState(0);

    // function to fetch all table data
    const [currentPage, setCurrentPage] = useState(useParams());
    const [pageNumber, setPageNumber] = useState(currentPage.id);

    const [loadingStatus, setLoadingStatus] = useState(true);

    if (!pageNumber) {
        setPageNumber('1');
    }

    function getPostData(pageNumber: any, searchParam = '', status = '') {
        clientAxios.get(
            API.ADMIN_AGENT_WITHDRAWAL_HISTORY_LINK, {
            params: {
                offset: API.NUM_PER_PAGE * (pageNumber - 1),
                limit: API.NUM_PER_PAGE,
                search: searchParam,
                filter: status
            }
        }
        ).then((response) => {
            setPost(response.data);
            setTotal(response.data.count);
            setError(null);
            setLoadingStatus(false);
        }).catch(setError)
    }

    //fetch on initial load
    React.useEffect(() => {
        getPostData(pageNumber);
    }, []);

    //SEARCH
    const [showCloseButton, setShowCloseButton] = useState('invisible');
    const [searchParam, setSearchParam] = useState('');

    // Input Field handler
    const handleSearchInput = (e: any) => {
        setSearchParam(e.target.value);
    };


    const handleSearch = (e: any) => {
        e.preventDefault();

        setShowCloseButton('visible');
        getPostData(1, searchParam, creditStatus);
    }

    const resetSearch = (e: any) => {
        setSearchParam('');

        e.preventDefault();

        setShowCloseButton('invisible');

        getPostData(1, '', creditStatus);
    }
    //END SEARCH

    //FILTER
    const [dropdownText, setDropdownText] = useState('All');
    const [creditStatus, setCreditStatus] = useState('');
    const handleDropdown = (e: any) => {

        e.preventDefault();

        setDropdownText(e.target.text);

        setCreditStatus(e.target.text);

        getPostData(1, searchParam, e.target.text);
    }

    //END FILTER

    return {
        post, total, pageNumber,
        handleSearch, searchParam, handleSearchInput, showCloseButton, resetSearch,
        handleDropdown, dropdownText,
        loadingStatus
    };
}

export default useWithdrawalListAgentLogic;