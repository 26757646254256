import React, { useState } from 'react';

import useAgentLogoutLogic from 'components/agents/AgentLogoutLogic';

const Logout: React.FC = () => {
    useAgentLogoutLogic();
    return (
        <>
        </>
    )
}

export default Logout