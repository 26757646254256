import React, { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../resources/routes-constants';

import { clientAxiosLoginPlayer } from 'utility/api/members/axiosPlayer';

import { API } from '../resources/api-constants';

const useRegisterLogic = () => {
    const [show, setShow] = useState(false);
    const refCodeTemp = useParams();
    const [refCode, setRefCode] = useState(refCodeTemp.id);

    const [confirmationType, setConfirmationType] = useState('terms');
    const handleClose = () => setShow(false);

    const handleShow = async function (e: any) {
        e.preventDefault();

        setShow(true);
    }

    const initialFormData = Object.freeze({
        email: "",
        username: "",
        name: "",
        password: "",
        verifyPassword: ""
    });

    const [formData, updateFormData] = React.useState(initialFormData);
    const [errorMessage, updateErrorMessage] = React.useState('');

    const handleChange = (e: any) => {
        updateFormData({
            ...formData,

            // Trimming any whitespace
            [e.target.name]: e.target.value.trim()
        });
    };
    //api call
    const [registerLoading, setRegisterLoading] = useState(false);
    const handleSubmit = async (e: any) => {
        e.preventDefault();

        //disable register button
        setRegisterLoading(true);

        if (formData.verifyPassword !== formData.password) {
            updateErrorMessage("Passwords don't match.");
            setRegisterLoading(false);
            return;
        }

        // call api
        try {
            let queryUrl = API.PLAYER_REGISTER_LINK + '?t=' + refCode;

            //if there is a ref code
            if ('' === refCode?.trim() || undefined === refCode?.trim()) {
                queryUrl = API.PLAYER_REGISTER_LINK;
            }

            const response = await clientAxiosLoginPlayer.post(
                queryUrl,
                {
                    email: formData.email,
                    username: formData.username,
                    name: formData.name,
                    password: formData.password,
                    confirmPassword: formData.verifyPassword
                },
                {
                    headers: { "Content-Type": "application/json" },
                }
            );

            //successful
            localStorage.setItem('access-token', response.data.data.accessToken);
            localStorage.setItem('login-date', String(Date.now()));
            localStorage.setItem('user-id', response.data.data.id);
            localStorage.setItem('email-address', response.data.data.email);
            localStorage.setItem('name', response.data.data.name);

            localStorage.setItem('total-credits', '0');

            localStorage.setItem('user-verified', '0');

            localStorage.setItem('withdraw-credit', '0');
            localStorage.setItem('user-session', API.PLAYER_CODE);

            //redirect to authorize page
            window.location.replace(ROUTES.AUTHORIZE_ROUTE);

            // if (null == response.data.data.last_login || 'null' == response.data.data.last_login) {
            //     window.location.replace(ROUTES.TUTORIAL_ROUTE);
            // } else {
            //     window.location.replace(ROUTES.HOME_ROUTE);
            // }

            //if (response.data.result === 'OK') {
            //    //redirect
            //    // homeRedirect(ROUTES.LOGIN_ROUTE);

            //    //successful
            //    localStorage.setItem('access-token', response.data.access.token);
            //    localStorage.setItem('login-date', String(Date.now()));
            //    localStorage.setItem('expires', response.data.access.expires_in);
            //    localStorage.setItem('user-id', response.data.data.user_id);
            //    localStorage.setItem('email-address', response.data.data.email);
            //    localStorage.setItem('name', response.data.data.name);

            //    if (response.data.data.total_credits == null) {
            //        localStorage.setItem('total-credits', '0');
            //    } else {
            //        localStorage.setItem('total-credits', response.data.data.total_credits);
            //    }

            //    if (response.data.data.email_verified_at == null) {
            //        localStorage.setItem('user-verified', '0');
            //    } else {
            //        localStorage.setItem('user-verified', '1');
            //    }

            //    localStorage.setItem('withdraw-credit', '0');
            //    localStorage.setItem('user-session', API.PLAYER_CODE);

            //    //redirect to authorize page
            //    // homeRedirect(ROUTES.AUTHORIZE_ROUTE);
            //    window.location.replace(ROUTES.AUTHORIZE_ROUTE);

            //    // refreshAuthLogic();
            //    // homeRedirect(ROUTES.TUTORIAL_ROUTE, {replace: true});

            //    // if (null == response.data.data.last_login || 'null' == response.data.data.last_login) {
            //    //     window.location.replace(ROUTES.TUTORIAL_ROUTE);
            //    // } else {
            //    //     window.location.replace(ROUTES.HOME_ROUTE);
            //    // }
            //} else {
            //    updateErrorMessage(response.data.message.email[0]);
            //}


        } catch (error: any) {
            if (error.response.status === 422) {
                updateErrorMessage(error.response.data.resource[0].msg);
            } else {
                updateErrorMessage("Registration Failed. Try again.");
            }
            setRegisterLoading(false);
        }
    };

    const [eyeicon, seteye] = useState(true);
    const [password, setpassword] = useState("password");

    const [eyeicon2, seteye2] = useState(true);
    const [password2, setpassword2] = useState("password");

    function Eye(passType: any) {

        if (1 == passType) {
            if (password == "password") {
                setpassword("text");
                seteye(false);
                //settype(true);
            }
            else {
                setpassword("password");
                seteye(true);
                //settype(false);

            }
        } else {

            if (password2 == "password") {
                setpassword2("text");
                seteye2(false);
                //settype2(true);
            }
            else {
                setpassword2("password");
                seteye2(true);
                //settype2(false);

            }
        }
    }

    return {
        Eye, handleSubmit, handleChange,
        password, password2, eyeicon,
        eyeicon2, errorMessage,
        show, handleShow, handleClose, confirmationType,
        registerLoading
    };
}

export default useRegisterLogic;