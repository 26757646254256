import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Table from 'react-bootstrap/Table';
import { Button, Form } from 'react-bootstrap';
import AdminCustomModal from '../../components/admin/modals/AdminCustomModal';
import AdminCustomToast from '../../components/admin/AdminCustomToast';
import { ROUTES } from '../../resources/routes-constants';
import CustomPagination from '../../components/CustomPagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import AdminConfirmationModal from '../../components/admin/modals/AdminConfirmationModal';

import useOperatorListLogic from '../../components/admin/OperatorListLogic';
import { formatDate, formatDateWithTime } from 'utility/functions';
import Loader from 'components/Loader';

const UsersOperators: React.FC = () => {
    const {
        showToast, handleCloseToast, showToastType,
        show, handleClose, handleSubmit, transaction,
        showConfirmation, handleConfirmationSubmit, handleCloseConfirmation, confirmationType, fullName, id,
        handleShow,
        handleConfirmation, handleSearch, resetSearch, handleSearchInput, searchParam,
        post, total, pageNumber, showCloseButton,
        loadingStatus
    } = useOperatorListLogic();

    return (
        <>
            <AdminCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
            <AdminCustomModal show={show} close={handleClose} submit={handleSubmit} transaction={transaction} />
            <AdminConfirmationModal show={showConfirmation} submit={handleConfirmationSubmit} close={handleCloseConfirmation} confirmationType={confirmationType} fullName={fullName} userId={id} />

            <Container>
                <Row>
                    <Col
                        md={{ span: 8, offset: 2 }}
                        sm={{ span: 12 }}
                        className="text-center mb-5">
                        <h4>List of Operators</h4>
                    </Col>
                </Row>
                {loadingStatus ?
                    <Loader />
                    :
                    <>
                        <Row className='mb-3'>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                            >
                                <Row>
                                    <Col
                                        md={{ span: 4 }}
                                        sm={{ span: 12 }}
                                        className="text-start">
                                        <div><Button type="button" className='w-100' onClick={() => handleShow('add')}>Add Operator</Button></div>
                                    </Col>

                                    <Col
                                        md={{ span: 4, offset: 4 }}
                                        sm={{ span: 12 }}
                                        className="text-end">
                                        <Form id='searchForm' className='w-100' onSubmit={handleSearch}>
                                            <Form.Group className="d-inline-flex justify-content-end ms-0 w-100">
                                                <Form.Control id="searchField" type='text' name='searchParam' placeholder="Search Name" className='w-100 ms-0 pe-4' value={searchParam} onChange={handleSearchInput} />
                                                <FontAwesomeIcon icon={faClose} className={'text-dark my-auto d-flex justify-content-end search-icon search-close-button ' + showCloseButton} onClick={resetSearch} />
                                                <FontAwesomeIcon icon={faSearch} className='text-dark my-auto d-flex justify-content-end ps-2' />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                                className="text-center table-responsive">
                                <Table striped bordered hover variant="dark">
                                    <thead className='bg-dark'>
                                        <tr>
                                            <th>USERNAME</th>
                                            <th>NAME</th>
                                            <th>EMAIL</th>
                                            <th>STATUS</th>
                                            <th>LAST LOGIN</th>
                                            <th>ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {post && post.data.map((operator: any, count: any) => (
                                            <tr className='bg-dark p-5 text-white' key={operator.id}>
                                                <td>{operator.username}</td>
                                                <td>{operator.name}</td>
                                                <td>{operator.email}</td>
                                                <td>
                                                    {operator.status === 'active' ? <span className='text-success'>Active</span> : <span className='text-danger'>Inactive</span>}
                                                </td>
                                                {/* <td>{formatDate(operator.lastLogin)}</td> */}
                                                <td>{formatDateWithTime(operator.lastLogin)}</td>
                                                <td>
                                                    <a href={ROUTES.ADMIN_OPERATOR_VIEW_ROUTE + '/' + operator.id} className="me-1">View</a> <br />
                                                    {/* <a href={ROUTES.ADMIN_OPERATOR_EDIT_ROUTE + '/' + operator.adminID} className='mx-1'>Edit</a> <br /> */}
                                                    {operator.status === 'active' ?
                                                        <a href='#' className='ms-1' onClick={() => handleConfirmation('deactivate-operator', operator.name, operator.id)}>Deactivate</a> :
                                                        <a href='#' className='ms-1' onClick={() => handleConfirmation('activate-operator', operator.name, operator.id)}>Activate</a>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                                className="d-flex justify-content-end mb-5">
                                <CustomPagination post={post} total={total} current={pageNumber} />
                            </Col>
                        </Row>
                    </>
                }
            </Container >
        </>
    );
}

export default UsersOperators;

function updateFormData(arg0: any) {
    throw new Error('Function not implemented.');
}