import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ROUTES } from '../../resources/routes-constants';

import useAgentDownlineTransactionViewLogic from 'components/agents/AgentDownlineTransactionViewLogic';
import { formatDateWithTime, formatNumberToCurrency } from 'utility/functions';
import Loader from 'components/Loader';

const AgentDownlineTransactionView: React.FC = () => {
    const {
        id, fullName, status, amount,
        image, createdAt, remarks,
        loadingStatus
    } = useAgentDownlineTransactionViewLogic();

    return (
        <>
            <Container>
                <Row>
                    <Col
                        lg={{ span: 8, offset: 2 }}
                        sm={{ span: 12 }}
                        className="text-center mb-5">
                        <h4>View Downline <span className='highlightText px-2'>Credit Request</span></h4>
                    </Col>
                </Row>
                {loadingStatus ?
                    <Loader />
                    :
                    <>
                        <Row>
                            <Col
                                lg={{ span: 8, offset: 2 }}
                                sm={{ span: 12 }}
                                className='boxContainer px-5 pt-4 pb-4'>
                                <Row>
                                    <Col
                                        md={{ span: 6 }}
                                        className='text-center'
                                    >
                                        <img
                                            style={{ maxWidth: '200px' }}
                                            src={
                                                image ? image :
                                                    '/images/placehoder.png'
                                            }
                                            className='img-thumbnail mb-2 text-center'
                                            alt='gcash screenshot' />
                                    </Col>
                                    <Col
                                        md={{ span: 6 }}
                                    >
                                        {('Approved' === status) ?
                                            <div className='mb-2'><span className=' h5 me-2'>Status:</span><span className='text-success h6'>{status}</span></div>
                                            :
                                            ('Disapproved' === status) ?
                                                <div className='mb-2'><span className='h5 me-2'> Status:</span><span className='text-danger h6'>{status}</span></div>
                                                :
                                                <div className='mb-2'><span className='h5 me-2'> Status:</span><span className='text-warning h6'>{status}</span></div>

                                        }
                                        <div className='mb-2'><span className='h5 me-2'>Name:</span><span className='h6'>{fullName}</span></div>
                                        <div className='mb-2'><span className='h5 me-2'>Amount:</span><span className='text-warning h6'>{formatNumberToCurrency(amount)}</span></div>
                                        <div className=''><span className='h5 me-2'>Requested Date:</span><span className='h6'>{formatDateWithTime(createdAt)}</span></div>
                                        <div className=''><br /><span className='h5 me-2'>Remarks:</span><span className='h6'>{remarks}</span></div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                lg={{ span: 8, offset: 2 }}
                                sm={{ span: 12 }}
                                className='boxContainer'
                            >
                                <div className='text-end mt-2 mb-4'>
                                    <a href={ROUTES.AGENT_DOWNLINE_TRANSACTIONS_ROUTE + '/1'} className='btn btn-secondary'>&#8592; Back to Transactions</a>
                                </div>
                            </Col>
                        </Row>
                    </>
                }
            </Container >
        </>
    );
}

export default AgentDownlineTransactionView;