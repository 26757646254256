import React, { useState } from 'react';

import { formatDate } from 'utility/functions';
import { useParams } from 'react-router-dom';
import { clientAxios } from 'utility/api/agent/axiosAgent';
import { API } from '../../resources/api-constants';

const useAgentDownlineCreditViewLogic = () => {
    //get details
    const [idTemp, setIdTemp] = useState(useParams());
    const [id, setId] = useState(idTemp.id);
    const [post, setPost] = React.useState({ data: Array(2) });
    const [error, setError] = useState('');
    const [loadingStatus, setLoadingStatus] = useState(true);

    const [fullName, setFullName] = useState('');
    const [status, setStatus] = useState('');
    const [amount, setAmount] = useState('');
    const [image, setImage] = useState('');

    const [createdAt, setCreatedAt] = useState('');

    React.useEffect(() => {
        const getPostData = async (id: any) => {
            try {
                const response = await clientAxios.get(
                    API.AGENT_DOWNLINES_CREDIT_REQUEST_VIEW_LINK + '/' + id, {
                })

                setError('');

                setFullName(response.data.data.user.name);
                //setStatus(response.data.data[0].status);
                setAmount(response.data.data.amount);
                setImage(response.data.data.image);

                setCreatedAt(formatDate(response.data.data.createdAt));

                // setApprovedBy(response.data.data[0].approvedBy);
                // setUpdatedAt(formatDate(response.data.data[0].updatedAt));

            } catch (error) {
                setError('An error has occured. Please try again.');
            }

            setLoadingStatus(false);

        };
        getPostData(id);

    }, []);
    //end get details

    //confirmation
    const [showConfirmation, setConfirmation] = useState(false);
    const [confirmationType, setConfirmationType] = useState('approveRemarks');

    const [showToast, setShowToast] = useState(false);
    const [showToastType, setShowToastType] = useState('success');

    const handleCloseConfirmation = () => setConfirmation(false);
    const handleCloseToast = () => setShowToast(false);

    const handleApprove = function () {
        setConfirmation(true);

        setConfirmationType('approve-credit-request');
    }

    const handleDisapprove = function () {
        setConfirmation(true);

        setConfirmationType('disapprove-credit-request');
    }

    const handleConfirmationSubmit = function (e: any) {
        setConfirmation(false);
        e.preventDefault();

        setShowToast(true);
    }

    return {
        showConfirmation, handleConfirmationSubmit, handleCloseConfirmation,
        confirmationType, handleApprove, handleDisapprove,
        showToast, handleCloseToast, showToastType,
        setShowToast, setShowToastType,
        id, fullName, status, amount,
        image, createdAt,
        loadingStatus
    };
}

export default useAgentDownlineCreditViewLogic;