import axios from "axios";

import { API } from "resources/api-constants";

const BETTING = API.PLAYER_URL;

export const clientAxiosLoginPlayer = axios.create({
   baseURL: BETTING,
   headers: {
      "Content-Type": "application/json"
   }
   // timeout: 1000,
});

const getAccessToken = () => {
   return localStorage.getItem('access-token');
}

export const clientAxios = axios.create({
   baseURL: BETTING,
   // timeout: 1000,
   headers: {
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + getAccessToken()
   },
   statusCodes: [401, 403, 429, 500], // default: [ 401 ]
});

export const clientAxiosRefresh = axios.create({
   baseURL: BETTING,
   headers: {
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + getAccessToken()
   },
   statusCodes: [401, 403, 429, 500], // default: [ 401 ]
   responseType: "json"
});


// Function that will be called to refresh authorization
export const refreshAuthLogic = (failedRequest) =>
   clientAxios.post(API.PLAYER_REFRESH_TOKEN).then((response) => {

      localStorage.setItem('access-token', response.data.access.token);
      localStorage.setItem('login-date', String(Date.now()));
      localStorage.setItem('expires', response.data.access.expires_in);
      localStorage.setItem('user-id', response.data.data.user_id);
      localStorage.setItem('email-address', response.data.data.email);
      localStorage.setItem('name', response.data.data.name);
      localStorage.setItem('total-credits', response.data.data.total_credits);

      failedRequest.response.config.headers['Authorization'] = 'Bearer ' + response.data.access.token;

      return Promise.resolve();
   });

export const refreshToken = async (reload = false) => {
   let result = false;

   if ('0' === localStorage.getItem('remember')) { // refresh

      await clientAxios.post(
         API.PLAYER_REFRESH_TOKEN, {},
         { headers: { 'Authorization': 'Bearer ' + getAccessToken() } }
      ).then((response) => {
         localStorage.setItem('access-token', response.data.access.token);
         localStorage.setItem('login-date', String(Date.now()));
         localStorage.setItem('expires', '7200');
         localStorage.setItem('user-id', response.data.data.user_id);
         localStorage.setItem('email-address', response.data.data.email);
         localStorage.setItem('name', response.data.data.name);
         localStorage.setItem('total-credits', response.data.data.total_credits);

         result = true;

      }).catch((error) => {
         result = false;

      }).finally(() => {

         if (reload) {
            window.location.reload();
         }
      });

   } else { //no need to refresh

   }

   return result;
}

export const validToken = () => {
   var result = true;
   clientAxios.post(
      API.PLAYER_VALID_TOKEN, {},
      { headers: { 'Authorization': 'Bearer ' + getAccessToken() } }
   ).then((response) => {

      result = response.data.valid;

      localStorage.setItem('validToken', result);
   }).catch((error) => {
      result = false;
   });
}