import React, { useState } from 'react';

import { clientAxios, refreshToken } from 'utility/api/agent/axiosAgent';
import { API } from '../../resources/api-constants';
import { useParams } from 'react-router-dom';

const useDownlineCreditRequestListLogic = () => {

    //GET TRANSACTIONS
    const [post, setPost] = useState({ data: Array(2) });
    const [error, setError] = useState(null);
    const [total, setTotal] = useState(0);

    const [loadingStatus, setLoadingStatus] = useState(true);

    // function to fetch all table data
    const [currentPage, setCurrentPage] = useState(useParams());
    const [pageNumber, setPageNumber] = useState(currentPage.id);

    function getPostData(pageNumber: any) {
        clientAxios.get(
            API.AGENT_DOWNLINES_CREDIT_REQUEST_LINK, {
            params: {
                offset: API.NUM_PER_PAGE * (pageNumber - 1),
                limit: API.NUM_PER_PAGE
            }
        }
        ).then((response) => {
            setPost(response.data);
            setTotal(response.data.count);
            setError(null);

            setLoadingStatus(false);
        }).catch(setError)
    }

    //fetch on initial load
    React.useEffect(() => {
        getPostData(pageNumber);
    }, []);

    //END GET TRANSACTIONS

    return {
        post, total, pageNumber,
        loadingStatus
    };
}

export default useDownlineCreditRequestListLogic;