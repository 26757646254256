import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ROUTES } from '../../resources/routes-constants';

import useWithdrawalLogic from 'components/admin/WithdrawalLogic';
import { formatDateWithTime, formatNumberToCurrency } from 'utility/functions';
import Loader from 'components/Loader';

const WithdrawalView: React.FC = () => {
    const {
        fullName, status, amount,
        remarks, gcashNumber, gcashName,
        approvedBy, createdAt, updatedAt,
        loadingStatus
    } = useWithdrawalLogic();

    return (
        <>
            <Container>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 10, offset: 1 }}
                        className="text-center mb-5">
                        <h4>View Withdrawal</h4>
                    </Col>
                </Row>
                {loadingStatus ?
                    <Loader />
                    :
                    <>
                        <Row>
                            <Col
                                lg={{ span: 6, offset: 3 }}
                                md={{ span: 10, offset: 1 }}
                                className='boxContainer px-5 pt-4 pb-4'>
                                <Row>
                                    <Col
                                        md={{ span: 6, offset: 3 }}
                                    >
                                        <div className='mb-2'><span className='h5 me-2'>Name:</span><span className='h6'>{fullName}</span></div>
                                        <div className='mb-2'><span className='h5 me-2'>Amount:</span><span className='text-warning h6'>{formatNumberToCurrency(amount)}</span></div>
                                        <div className='mb-4'>
                                            <span className='h5 me-2'>Status:</span>
                                            {status === 'Approved' ? <span className='text-success'>{status}</span> : <span className='text-danger'>{status}</span>}
                                        </div>
                                        <div className='mb-2'><span className='h5 me-2'>Approver:</span><span className='h6'>{approvedBy}</span></div>
                                        <div className='mb-2'><span className='h5 me-2'>Gcash Name:</span><span className='h6'>{gcashName}</span></div>
                                        <div className='mb-2'><span className='h5 me-2'>Gcash Number:</span><span className='h6'>{gcashNumber}</span></div>
                                        <div className=''>
                                            <span className='h5 me-2'>Remarks:</span><br />
                                            <span className='h7 fst-italic'>
                                                {remarks}
                                            </span>
                                        </div>
                                        <div className='mt-3'><span className='me-2'>Requested Date:</span><span className='small'>{formatDateWithTime(createdAt)}</span></div>
                                        <div className='mb-3'><span className='me-2'>Updated Date:</span><span className='small'>{formatDateWithTime(updatedAt)}</span></div>
                                        <div className='text-end'><a href={ROUTES.ADMIN_WITHDRAWALS_ROUTE + '/1'}><span className='btn btn-secondary w-50'>Back</span></a></div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                }
            </Container >
        </>
    );
}

export default WithdrawalView;