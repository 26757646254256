import React, { useState, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Table from 'react-bootstrap/Table';
import CustomPagination from '../../components/CustomPagination';

import useDepositHistoryLogic from 'components/members/DepositHistoryLogic';

import ImageViewer from 'react-simple-image-viewer';
import { ROUTES } from 'resources/routes-constants';
import { formatDate, formatDateWithTime, formatNumberToCurrency } from 'utility/functions';
import Loader from 'components/Loader';

const DepositHistory: React.FC = () => {
    const {
        post, total, pageNumber,
        loadingStatus
    } = useDepositHistoryLogic();


    //IMAGE VIEWER
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const images: string[] = [];

    post.data.forEach((credit: any, index: any) => {
        images.push(
            credit.image
        );
    });

    const openImageViewer = useCallback((index: any) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    //END IMAGE VIEWER

    if (!(post) || (0 == total)) {
        return (
            <>
                {loadingStatus ?
                    <Loader />
                    :

                    <Container >
                        <Row>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                                className="text-center mb-5">
                                <h4>No Items Found</h4>
                            </Col>
                        </Row>
                    </Container>
                }
            </>
        );

    } else {
        return (
            <div>
                <Container>
                    <Row>
                        <Col
                            md={{ span: 10, offset: 1 }}
                            sm={{ span: 12 }}
                            className="text-center mb-5">
                            <h4>Deposit <span className='highlightText px-2'>History</span></h4>
                        </Col>
                    </Row>
                    {loadingStatus ?
                        <Loader />
                        :
                        <>
                            <Row>
                                <Col
                                    md={{ span: 10, offset: 1 }}
                                    sm={{ span: 12 }}
                                    className="text-center table-responsive">
                                    <Table striped bordered hover variant="dark">
                                        <thead className='bg-dark'>
                                            <tr>
                                                <th>DATE</th>
                                                <th>IMAGE</th>
                                                <th>AMOUNT</th>
                                                <th>STATUS</th>
                                                <th>REMARKS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {post && post.data.map((credit: any, count: any) => (
                                                <tr className='bg-dark p-5 text-white' key={credit.id}>
                                                    <td>{formatDateWithTime(credit.createdAt)}</td>
                                                    <td>
                                                        <img
                                                            src={credit.image ?
                                                                credit.image
                                                                :
                                                                '/images/placeholder.png'
                                                            }
                                                            className='img-thumbnail'
                                                            style={{ maxWidth: '120px' }}
                                                            onClick={() => openImageViewer(count)}
                                                        />
                                                    </td>
                                                    <td><span className='text-warning'>{formatNumberToCurrency(credit.amount)}</span></td>
                                                    <td>
                                                        {credit.status === 'Pending' ?
                                                            <span className='text-warning'>Pending</span> :
                                                            credit.status === 'Approved' ?
                                                                <span className='text-success'>Approved</span> :
                                                                <span className='text-danger'>Disapproved</span>
                                                        }
                                                    </td>
                                                    <td>{credit.remarks}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    md={{ span: 10, offset: 1 }}
                                    sm={{ span: 12 }}
                                    className="d-flex justify-content-end mb-5">
                                    <CustomPagination post={post} total={total} current={pageNumber} />
                                </Col>
                            </Row>
                        </>
                    }
                </Container >

                {isViewerOpen && (
                    <ImageViewer
                        src={images}
                        currentIndex={currentImage}
                        disableScroll={false}
                        closeOnClickOutside={true}
                        onClose={closeImageViewer}
                    />
                )}
            </div >
        );
    }
}

export default DepositHistory;