import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ROUTES } from 'resources/routes-constants';

import useAuthorizeLogic from 'components/members/AuthorizeLogic';

const Authorize: React.FC = () => {
    const {
        handleAuthorizeUser,
        errorMessage,
    } = useAuthorizeLogic();

    return (
        <>
            <Container>
                <Row className='mt-5'>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        className="text-center mb-5">
                        <h1 className='mb-4'>New <span className='highlightText px-2'>Device</span></h1>
                        <p className='h5'>To help you secure your account, we only allow authorized devices to access your account.</p>
                        <p className='h5'>Would you like to authorized this device?</p>
                    </Col>
                </Row>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        style={{ minHeight: '' }}
                        className='boxContainer p-5 text-center'>

                        <div className='mb-4'>
                            <a href='#' className='btn btn-primary'
                                style={{ width: '200px' }}
                                onClick={handleAuthorizeUser}
                            >Authorize Device</a>
                        </div>
                        <div>
                            <a href={ROUTES.PLAYER_LOGOUT_ROUTE} className='btn btn-outline-secondary'
                                style={{ width: '200px' }}
                            >Log me Out</a>
                        </div>

                    </Col>
                </Row>
            </Container >
        </>
    );
}

export default Authorize;