import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'

import Form from 'react-bootstrap/Form';
import CustomModal from '../../components/members/modals/CustomModal';
import CustomToast from '../../components/members/CustomToast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeLowVision } from '@fortawesome/free-solid-svg-icons';

import useAccountLogic from '../../components/members/AccountLogic';

const Account: React.FC = () => {
    const {
        Eye,
        showToast,
        handleCloseToast,
        show,
        handleClose,
        handleSubmit,
        transaction,
        handleUpdateName,
        handleChangePassword,
        password, eyeicon,
        password2, eyeicon2,
        password3, eyeicon3,
        errorMessage, errorMessageName,
        handleVerifyEmail
    } = useAccountLogic();

    const emailVerified = Number(localStorage.getItem('user-verified'));

    return (
        <>
            <CustomToast show={showToast} close={handleCloseToast} />
            <CustomModal show={show} close={handleClose} submit={handleSubmit} transaction={transaction} />

            <Container className='mb-4'>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>User <span className='highlightText px-2'>Account</span> </h4>
                    </Col>
                </Row>
                {emailVerified == 0 ?
                    <Row>
                        <Col
                            lg={{ span: 6, offset: 3 }}
                            md={{ span: 8, offset: 2 }}
                            className='boxContainer p-5 text-center'>
                            <p>To access all the features of this app please verify your email</p>
                            <a href="#" onClick={handleVerifyEmail} className="btn btn-outline-warning">Verify Now</a>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col
                            lg={{ span: 6, offset: 3 }}
                            md={{ span: 8, offset: 2 }}
                            className='boxContainer px-5 pt-0'>
                            <Row>
                                <Col className="p-2">
                                    <Form onSubmit={handleUpdateName}>
                                        <Form.Group className="mb-3 mt-2">
                                            <Form.Label><h6>Name</h6></Form.Label>
                                            <Form.Control type="text" placeholder="Name" name='fullname' required />
                                            {/* <Form.Text className="text-muted">You can only update your name twice</Form.Text> */}
                                            <p className='text-danger text-center mt-2'>&nbsp;{errorMessageName}</p>
                                            <p className='text-center'><Button className="mt-1 w-50" type="submit">Update Name</Button></p>
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col className="p-2">
                                    <h5 className='text-center mb-5'>Update Password</h5>
                                    <Form onSubmit={handleChangePassword}>
                                        <Form.Group className="mb-3 position-relative">
                                            <Form.Label><h6>Current Password</h6></Form.Label>
                                            <Form.Control type={password} name='password' placeholder="******" required />
                                            <FontAwesomeIcon icon={eyeicon ? faEye : faEyeLowVision} className='account-password-eye' onClick={() => Eye(1)} />
                                        </Form.Group>
                                        <Form.Group className="mb-3 position-relative">
                                            <Form.Label><h6>New Password</h6></Form.Label>
                                            <Form.Control type={password2} name='newpassword' placeholder="******" required />
                                            <FontAwesomeIcon icon={eyeicon2 ? faEye : faEyeLowVision} className='account-password-eye' onClick={() => Eye(2)} />
                                        </Form.Group>
                                        <Form.Group className="mb-3 position-relative">
                                            <Form.Label><h6>Verify Password</h6></Form.Label>
                                            <Form.Control type={password3} name='verifypassword' placeholder="******" required />
                                            <FontAwesomeIcon icon={eyeicon3 ? faEye : faEyeLowVision} className='account-password-eye' onClick={() => Eye(3)} />
                                        </Form.Group>
                                        <p className='text-danger text-center'>&nbsp;{errorMessage}</p>
                                        <p className='text-center'><Button className="mt-3 w-50" type="submit">Change Password</Button></p>
                                    </Form>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
            </Container >
        </ >
    );
}

export default Account;