import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import { ROUTES } from '../../resources/routes-constants';

import CustomPagination from '../../components/CustomPagination';

import useWithdrawalRequestListLogic from 'components/admin/WithdrawalRequestListLogic';
import { formatDateWithTime, formatNumberToCurrency } from 'utility/functions';
import Loader from 'components/Loader';

const WithdrawalRequestList: React.FC = () => {
    const {
        post, total, pageNumber,
        handleSearch, searchParam, handleSearchInput, showCloseButton, resetSearch,
        loadingStatus
    } = useWithdrawalRequestListLogic();

    if (!(post) || (0 == total)) {
        return (
            <>
                {loadingStatus ?
                    <Loader />
                    :
                    <Container >
                        <Row>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                                className="text-center mb-5">
                                <h4>No Items Found</h4>
                            </Col>
                        </Row>
                    </Container>
                }
            </>
        );

    } else {

        return (
            <>
                <Container>
                    <Row>
                        <Col
                            md={{ span: 8, offset: 2 }}
                            sm={{ span: 12 }}
                            className="text-center mb-5">
                            <h4>Player Withdrawal Requests</h4>
                        </Col>
                    </Row>

                    {loadingStatus ?
                        <Loader />
                        :
                        <>
                            <Row className='mb-3 text-end'>
                                <Col
                                    md={{ span: 3, offset: 7 }}
                                    xs={{ span: 12 }}
                                >
                                    <Form id='searchForm' className='w-100' onSubmit={handleSearch}>
                                        <Form.Group className="d-inline-flex justify-content-end ms-0 w-100">
                                            <Form.Control id="searchField" type='text' name='searchParam' placeholder="Search Name" className='w-100 ms-0 pe-4' value={searchParam} onChange={handleSearchInput} />
                                            <FontAwesomeIcon icon={faClose} className={'text-dark my-auto d-flex justify-content-end search-icon search-close-button ' + showCloseButton} onClick={resetSearch} />
                                            <FontAwesomeIcon icon={faSearch} className='text-dark my-auto d-flex justify-content-end ps-2' />
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={{ span: 8, offset: 2 }} className="text-center table-responsive">
                                    <Table striped bordered hover variant="dark">
                                        <thead className='bg-dark'>
                                            <tr>
                                                <th>DATE</th>
                                                <th>PLAYER NAME</th>
                                                <th>GCASH NAME</th>
                                                <th>GCASH NUMBER</th>
                                                <th>AMOUNT</th>
                                                <th>ACTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {post && post.data.map((withdrawal: any, count: any) => (
                                                <tr className='bg-dark p-5 text-white' key={withdrawal.id}>
                                                    {/* <td>{formatDateWithTime(withdrawal.createdAt)}</td> */}
                                                    <td>{formatDateWithTime(withdrawal.createdAt)}</td>
                                                    <td>{withdrawal.user.name}</td>
                                                    <td>{withdrawal.gcashName}</td>
                                                    <td>{withdrawal.gcashNumber}</td>
                                                    <td><span className='text-warning'>{formatNumberToCurrency(withdrawal.amount)}</span></td>
                                                    <td>
                                                        <a href={ROUTES.ADMIN_WITHDRAWAL_REQUEST_VIEW_ROUTE + '/' + withdrawal.id} className="me-1">View</a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ span: 8, offset: 2 }} className="d-flex justify-content-end mb-5">
                                    <CustomPagination post={post} total={total} current={pageNumber} />
                                </Col>
                            </Row>
                        </>
                    }
                </Container >
            </>
        );
    }
}

export default WithdrawalRequestList;