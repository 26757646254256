import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { clientAxios } from '../../utility/api/admin/axiosCMS';
import { API } from '../../resources/api-constants';
import { convertImageToBase64, fileCheck, formatDate, optimizeImage } from 'utility/functions';

const useCategoryLogic = () => {

    //get details
    const [id, setId] = useState(useParams());
    const [post, setPost] = React.useState({ data: Array(2) });
    const [error, setError] = useState('');

    const [categoryName, setCategoryName] = useState('');
    const [image, setImage] = useState('');
    const [status, setStatus] = useState(0);

    const [createdBy, setCreatedBy] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [updatedAt, setUpdatedAt] = useState('');

    const [loadingStatus, setLoadingStatus] = useState(false);

    const getPostData = async (id: any) => {
        try {
            const response = await clientAxios.get(
                API.ADMIN_CATEGORIES_LINK + '/' + id.id, {
            })

            setError('');
            setCategoryName(response.data.data.name);
            setImage(response.data.data.image);
            setStatus(response.data.data.status);

            setCreatedBy(response.data.data.createdBy);
            setCreatedAt(formatDate(response.data.data.createdAt));
            setUpdatedAt(formatDate(response.data.data.updatedAt));

        } catch (error) {
            setError('An error has occured. Please try again.');
        } finally {
            setLoadingStatus(false);
        }

    };

    React.useEffect(() => {
        getPostData(id);
    }, []);

    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);

    // const transaction = () => 'add-category';
    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);
    const handleCloseToast = () => setShowToast(false);

    const [showToastType, setShowToastType] = useState('success');
    const result = true;

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const categoryName = e.target.name.value;

        const cStatus = e.target.status.checked;
        let cStatusF = 0;

        if (cStatus)
            cStatusF = 1;

        let newImage = e.target.image.files[0];

        let imageValid = '';
        let result = true;
        //let newImage2 = '';

        if (!newImage) {
            newImage = image;
        } else {
            imageValid = fileCheck(newImage);

            //if (imageValid === '') {
            //    const newImage2 = await optimizeImage(newImage, 600, 800);
            //}
        }

        if ('' === imageValid) {
            // const encodedImage = await convertImageToBase64(newImage);

            //api call
            try {
                const response = await clientAxios.patch(
                    API.ADMIN_CATEGORIES_UPDATE_LINK + '/' + id.id + '/edit', {
                    name: categoryName,
                    image: newImage,
                    status: cStatusF
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }

                })

                setError('');
                getPostData(id);
            } catch (error) {
                result = false;
                setError('An error has occured. Please try again.');
            }
        } else {
            setError(imageValid);
            result = false;
        }

        if (result) {
            setShowToastType('success');
        } else {
            setShowToastType('fail');
        }

        setShow(false);
        setShowToast(true);
    };

    return {
        showToast, handleCloseToast, showToastType,
        show, handleClose,
        handleSubmit, error,
        categoryName, image, status,
        createdBy, createdAt, updatedAt,
        loadingStatus
    };
}

export default useCategoryLogic;