import React, { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ROUTES } from 'resources/routes-constants';
import { disableButton, formatNumberToCurrency } from 'utility/functions';

// function CustomModal() {
const CustomModal = (props: any) => {
    const handleClose = props.close;
    const handleSubmit = props.submit;
    const transaction = props.transaction;
    const image = props.image;
    const amount = props.amount;
    const gcash = props.gcash;
    const gcashName = props.gcashName;
    const betChoice = props.betChoice;

    const buttonRef = useRef(null);

    if ('buy' === transaction) {
        return (

            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h4 className='mb-4'>Are these correct?</h4>
                    <p className='h5 text-start mb-4'>Amount : <span className='text-warning'>{formatNumberToCurrency(amount)}</span></p>
                    <p className='h5 text-start mb-4'>Sent to (Gcash) : <span className='text-warning'>{gcash}</span></p>
                    <p><img src={image} className='w-75' /></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" ref={buttonRef} onClick={() => { handleSubmit(); disableButton(buttonRef) }}>
                    {/*<Button variant="primary" ref={buttonRef} onClick={handleSubmit()}>*/}
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

        );
    } else if ('withdraw' === transaction) {

        return (

            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='ms-5'>
                    <p>GCASH NAME: {gcashName}</p>
                    <p>GCASH NUMBER: {gcash}</p>
                    <p>AMOUNT: {formatNumberToCurrency(amount)}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" ref={buttonRef} onClick={handleSubmit(gcash, amount, buttonRef)}>
                    {/* <Button variant="primary" ref={buttonRef} onClick={() => {handleSubmit(gcash, amount); disableButton(buttonRef)}}> */}
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else if ('session' == transaction) {

        return (

            <Modal show={props.show} className='mt-5 modal-dialog-centered'>
                <Modal.Header className=''>
                    <Modal.Title>Session Expired</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-5 mb-5 pt-5 text-center'>
                    <p>Your session has expired. </p>
                    <p><a href={ROUTES.LOGIN_ROUTE}>Click here to log in.</a></p>
                    {/* <small>NOTE: Click "Remember Me" if you want a longer sesison.</small> */}
                </Modal.Body>
            </Modal>
        );
    } else if ('bet-balance' === transaction) {

        return (

            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Insufficient Credits</Modal.Title>
                </Modal.Header>
                <Modal.Body className='ms-5'>
                    <p>Not enough balance</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else if ('bet-choice' === transaction) {

        return (

            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Bet Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body className='ms-5'>
                    <p>Are you sure you want to BET {amount} - <strong>{betChoice}</strong>?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" ref={buttonRef} onClick={() => { handleSubmit(); disableButton(buttonRef) }}>
                    Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else {
        return (

            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>CONFIRM UPDATE</Modal.Title>
                </Modal.Header>
                <Modal.Body className='ms-5'>
                    Are you sure?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

        );
    }
}

export default CustomModal;