import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'

import { ROUTES } from '../../resources/routes-constants';
import TopNavBarOperator from '../partials/operator/TopNavbarOperator';

const Home: React.FC = () => {
    return (
        <div>
            <TopNavBarOperator />

            <Container fluid>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>COMING SOON</h4>
                    </Col>
                </Row>
                
            </Container>
        </div>
    );
}

export default Home;