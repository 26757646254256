import React from 'react'
import { ROUTES } from '../../../resources/routes-constants'

// import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
// import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faHouse, faPlay, faCartShopping, faUpload, faMoneyBill, faCoins, faQuestion,
    faCircleExclamation, faPenToSquare, faList, faRightFromBracket, faPeopleCarryBox, faHandHoldingHeart
} from '@fortawesome/free-solid-svg-icons'

function BottomNavbarAgent() {
    return (
        <div className='d-md-none d-lg-none d-xl-none d-xxl-none mt-5 pt-4'>
            {[false].map((expand) => (
                <Navbar key={null} expand={expand} className="" fixed="bottom">
                    <Container fluid className="bottomNav-container">
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="start"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className='header-title p-0'>
                                    {/* <img src={window.location.origin + '/logo.png'} className='w-25 align-center me-2'
                                        style={{ background: '#FFF' }}
                                    /> GGWPlay Agent */}
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Container className='text-center'>
                                    <div className='d-flex flex-row'>
                                        <div className='text-center'>
                                            <img src='../logo.png' className='w-50' />
                                        </div>
                                        <div className='text-start'
                                            style={{ width: '350px', overflow: 'scroll' }}
                                        >
                                            {(localStorage.getItem('name') == 'null' || null == localStorage.getItem('name')) ?
                                                '' :
                                                localStorage.getItem('name')
                                            }
                                            <br />
                                            <small className='text-warning mt-0 pt-0'>
                                                {localStorage.getItem('email-address')}
                                            </small>
                                        </div>
                                    </div>
                                    {/* <Row>
                                        <Col xs={4} className='text-center w-100'>
                                            {(localStorage.getItem('name') == 'null' || null == localStorage.getItem('name')) ?
                                                '' :
                                                localStorage.getItem('name')
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4} className="mt-0 pt-0 text-center w-100">
                                            <small className='text-warning mt-0 pt-0'>
                                                {localStorage.getItem('email-address')}
                                            </small>
                                        </Col>
                                    </Row> */}
                                </Container>
                                <Nav className="justify-content-end flex-grow-1 pe-3 mt-5">
                                    <Nav.Link href={ROUTES.AGENT_TRANSACTIONS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faList} className='me-3' />Transactions</Nav.Link>

                                    <Navbar.Text className='mt-3'>COMMISSION</Navbar.Text>
                                    <Nav.Link href={ROUTES.AGENT_COMMISSION_CONVERT_ROUTE} className='p-1'><FontAwesomeIcon icon={faMoneyBill} className='me-3' />Convert</Nav.Link>
                                    <Nav.Link href={ROUTES.AGENT_COMMISSION_TRANSACTIONS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faList} className='me-3' />Transactions</Nav.Link>
                                    {/*<Nav.Link href={ROUTES.AGENT_STATS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faList} className='me-3' />Stats</Nav.Link>*/}

                                    <Navbar.Text className='mt-3'>DOWNLINES</Navbar.Text>
                                    <Nav.Link href={ROUTES.AGENT_DOWNLINES_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faPeopleCarryBox} className='me-3' />List</Nav.Link>

                                    {localStorage.getItem('user-role') === 'Master Agent' ?
                                        <>
                                            <Nav.Link href={ROUTES.AGENT_DOWNLINE_REQUESTS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faHandHoldingHeart} className='me-3' />Requests</Nav.Link>
                                            <Nav.Link href={ROUTES.AGENT_DOWNLINE_TRANSACTIONS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faList} className='me-3' />History</Nav.Link>
                                        </> : <></>
                                    }
                                    <Navbar.Text className='mt-3'>ACCOUNT SETTINGS</Navbar.Text>
                                    <Nav.Link href={ROUTES.AGENT_ACCOUNT_ROUTE} className='p-1'><FontAwesomeIcon icon={faPenToSquare} className='me-3' />Edit Account</Nav.Link>
                                    <Nav.Link href={ROUTES.AGENT_LOGOUT_ROUTE} className='p-1'><FontAwesomeIcon icon={faRightFromBracket} className='me-3' />Sign Out</Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                        <Container >
                            <Row>
                                <Col xs={3} className=''>
                                    <a href={ROUTES.AGENT_HOME_ROUTE} className='text-decoration-none text-light'>
                                        <div className='text-center'><FontAwesomeIcon icon={faHouse} className='' /></div>
                                        <div className='text-center'>Dashboard</div>
                                    </a>
                                </Col>
                                <Col xs={3} className=''>
                                    <a href={ROUTES.AGENT_CREDITS_BUY_ROUTE} className='text-decoration-none text-light'>
                                        <div className='text-center'><FontAwesomeIcon icon={faCartShopping} className='' /></div>
                                        <div className='text-center'>Buy</div>
                                    </a>
                                </Col>
                                <Col xs={3} className=''>
                                    <a href={ROUTES.AGENT_WITHDRAW_ROUTE} className='text-decoration-none text-light'>
                                        <div className='text-center'><FontAwesomeIcon icon={faUpload} className='' /></div>
                                        <div className='text-center'>Withdraw</div>
                                    </a>

                                </Col>
                                <Col xs={3} className=''>
                                    <div className='text-center'><Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} /></div>
                                </Col>
                            </Row>
                        </Container>

                    </Container>
                </Navbar>
            ))}
        </div>
    );
}

export default BottomNavbarAgent;