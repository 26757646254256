import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'resources/routes-constants';
import { API } from 'resources/api-constants';

import { clientAxiosLogin } from 'utility/api/agent/axiosAgent';

const useAgentForgotPasswordLogic = () => {
    const initialFormData = Object.freeze({
        email: ""
    });

    const [formData, updateFormData] = React.useState(initialFormData);
    const [errorMessage, updateErrorMessage] = React.useState('');

    const handleChange = (e: any) => {
        updateFormData({
            ...formData,

            // Trimming any whitespace
            [e.target.name]: e.target.value.trim()
        });
    };

    const homeRedirect = useNavigate();
    const handleSubmit = async (e: any) => {
        e.preventDefault()

        updateErrorMessage("Sending email...");

        const siteURL = window.location.hostname;

        try {
            const response = await clientAxiosLogin.post(
                API.AGENT_FORGOT_PASSWORD_LINK,
                {
                    email: formData.email,
                    url: siteURL,
                    appEmail: API.APP_EMAIL_ADDRESS
                }
            );

            //redirect
            homeRedirect(ROUTES.AGENT_FORGOTPASSWORD_SUCCESS_ROUTE);

        } catch (error: any) {

            if (!error.response) {
                updateErrorMessage("No Server Response");
            } else if (error.response.status == 400) {
                updateErrorMessage("Missing Username or Password");
            } else if (error.response.status == 401) {
                updateErrorMessage("User not found.");
            } else {
                updateErrorMessage("Submit Failed. Try again.");
            }

        }
    };

    return {
        handleSubmit, handleChange,
        errorMessage
    };
}

export default useAgentForgotPasswordLogic;