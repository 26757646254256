import React, { useState } from 'react';

import { clientAxios } from '../../utility/api/admin/axiosCMS';
import { API } from '../../resources/api-constants';
import { useParams } from 'react-router-dom';

const usePlayerListLogic = () => {
    //get players
    const [post, setPost] = React.useState({ data: Array(2) });
    const [error, setError] = useState(null);
    const [total, setTotal] = React.useState(0);

    // function to fetch all table data
    const [currentPage, setCurrentPage] = useState(useParams());
    const [pageNumber, setPageNumber] = useState(currentPage.id);

    const [loadingStatus, setLoadingStatus] = useState(true);

    if (!pageNumber) {
        setPageNumber('1');
    }

    function getPostData(pageNumber: any, searchParam = '', status = '') {
        clientAxios.get(
            API.ADMIN_PLAYERS_LINK, {
            params: {
                offset: API.NUM_PER_PAGE * (pageNumber - 1),
                limit: API.NUM_PER_PAGE,
                search: searchParam,
                filter: status
            }
        }
        ).then((response) => {

            setPost(response.data);
            setTotal(response.data.count);
            setError(null);
            setLoadingStatus(false);
        }).catch(setError)
    }

    //fetch on initial load
    React.useEffect(() => {
        getPostData(pageNumber);
    }, []);

    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showToastType, setShowToastType] = useState('success');

    const handleClose = () => setShow(false);
    const handleCloseToast = () => setShowToast(false);
    const handleShow = (type: any) => setShow(true);

    //CONFIRM DEACTIVATE
    const [showConfirmation, setConfirmation] = useState(false);
    const [fullName, setFullName] = useState('');
    const [id, setId] = useState('');
    const [confirmationType, setConfirmationType] = useState('');

    const handleCloseConfirmation = () => setConfirmation(false);

    const handleConfirmation = function (type: any, fullName: any, propId: any) {
        setConfirmation(true);

        setConfirmationType(type);
        setFullName(fullName);
        setId(propId);

    }
    // END CONFIRM DEACTIVATE

    // ACTIVATE OR DEACTIVATE / RESET/UNBLOCK
    const [deactivateResult, setDeactivateResult] = React.useState({ data: Array(2) });
    const [errorDeactivate, setErrorDeactivate] = useState(null);

    const handleConfirmationSubmit = (action: any) => (e: any) => {

        setConfirmation(false);
        e.preventDefault();

        //api call
        if ('reset' === action) {
            clientAxios.post(
                API.ADMIN_PLAYERS_DEACTIVATE_LINK + '/' + id + '/' + action,
                {
                }
            ).then((response) => {
                setDeactivateResult(response);
                setShowToastType('success');
                getPostData(pageNumber);
            }).catch((error) => {
                setShowToastType('fail');
                setErrorDeactivate(error);
            });
        } else {
            clientAxios.patch(
                API.ADMIN_PLAYERS_DEACTIVATE_LINK + '/' + id + '/' + action,
                {
                }
            ).then((response) => {
                setDeactivateResult(response);
                setShowToastType('success');
                getPostData(pageNumber);
            }).catch((error) => {
                setShowToastType('fail');
                setErrorDeactivate(error);
            });
        }

        setShowToast(true); // show toast

    }
    // END ACTIVATE/DEACTIVATE OR RESET/UNBLOCK

    //SEARCH
    const [showCloseButton, setShowCloseButton] = useState('invisible');
    const [searchParam, setSearchParam] = useState('');

    // Input Field handler
    const handleSearchInput = (e: any) => {
        setSearchParam(e.target.value);
    };


    const handleSearch = (e: any) => {
        e.preventDefault();

        setShowCloseButton('visible');
        getPostData(pageNumber, searchParam, playerStatus);
    }

    const resetSearch = (e: any) => {
        setSearchParam('');

        e.preventDefault();

        setShowCloseButton('invisible');

        getPostData(1, '', playerStatus);
    }
    //END SEARCH

    //FILTER
    const [dropdownText, setDropdownText] = useState('All');
    const [playerStatus, setPlayerStatus] = useState('');
    const handleDropdown = (e: any) => {

        e.preventDefault();

        setDropdownText(e.target.text);

        let playerStatus = '';
        let playerText = (e.target.text);
        if ('Active' === playerText) {
            playerStatus = 'activated';
        } else if ('Inactive' === playerText) {
            playerStatus = 'deactivated';
        } else {
            playerStatus = '';
        }

        setPlayerStatus(playerStatus);

        getPostData(1, searchParam, playerStatus);
    }

    //END FILTER

    return {
        showToast, handleCloseToast, showToastType,
        show, handleClose,
        showConfirmation, handleConfirmationSubmit, handleCloseConfirmation, confirmationType, fullName, id,
        handleShow, handleSearch, resetSearch, handleSearchInput, searchParam,
        handleConfirmation,
        post, total, pageNumber, showCloseButton,
        handleDropdown, dropdownText,
        loadingStatus
    };
}

export default usePlayerListLogic;