import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { ROUTES } from '../../resources/routes-constants';
import { Button } from 'react-bootstrap';

import AdminCustomModal from '../../components/admin/modals/AdminCustomModal';
import AdminCustomToast from '../../components/admin/AdminCustomToast';

import useAccountGcashViewLogic from '../../components/admin/AccountGcashLogic';
import { formatDate, formatDateWithTime } from 'utility/functions';
import Loader from 'components/Loader';

const AccountGcashView: React.FC = () => {
    const {
        showToast, handleCloseToast, showToastType,
        show, handleClose,
        handleSubmit,
        transaction,
        number, status, gcashName,
        createdBy, createdAt, updatedAt,
        loadingStatus
    } = useAccountGcashViewLogic();


    return (
        <>
            <AdminCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
            <AdminCustomModal show={show} close={handleClose} submit={handleSubmit} transaction={transaction} />

            <Container>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>View GCash</h4>
                    </Col>
                </Row>
                {loadingStatus ?
                    <Loader />
                    :
                    <>
                        <Row>
                            <Col
                                lg={{ span: 6, offset: 3 }}
                                md={{ span: 8, offset: 2 }}
                                className='boxContainer px-5 pt-4 pb-4'>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3">
                                        <Form.Label><h6>Name</h6></Form.Label>
                                        <Form.Control type="text" placeholder="" name='name' defaultValue={gcashName} required />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label><h6>Number</h6></Form.Label>
                                        <Form.Control type="number" placeholder="" name='number' defaultValue={number} required />
                                    </Form.Group>
                                    <Form.Group className="mb-3 mt-4">
                                        <Form.Label>
                                            <h6 className='mb-0'>Status</h6>
                                        </Form.Label>
                                        <div className="m-0 form-check">
                                            {status === 'Active' ?
                                                <input name="status" type="checkbox" id="custom-switch" className="form-check-input" defaultChecked />
                                                :
                                                <input name="status" type="checkbox" id="custom-switch" className="form-check-input" />
                                            }
                                            <label title="" htmlFor="custom-switch" className="form-check-label">Activate</label>
                                        </div>
                                    </Form.Group>
                                    {/*<Form.Group className="">*/}
                                    {/*    <Form.Label>Created By : </Form.Label>*/}
                                    {/*    <Form.Text className="ms-2">{createdBy}</Form.Text>*/}
                                    {/*</Form.Group>*/}
                                    <Form.Group className="">
                                        <Form.Label>Created : </Form.Label>
                                        <Form.Text className="ms-2">{formatDate(createdAt)}</Form.Text>
                                    </Form.Group>
                                    <Form.Group className="">
                                        <Form.Label>Updated : </Form.Label>
                                        <Form.Text className="ms-2">{formatDate(updatedAt)}</Form.Text>
                                    </Form.Group>
                                    <div className='text-end mt-4'>
                                        <Button href={ROUTES.ADMIN_ACCOUNT_GCASH_ROUTE + '/1'} variant="secondary" className='me-2'>Back</Button>
                                        <Button variant="primary" type='submit'>Update</Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </>
                }
            </Container >
        </>
    );
}

export default AccountGcashView;