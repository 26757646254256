import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { ROUTES } from '../../resources/routes-constants';
import { Button } from 'react-bootstrap';

import AdminCustomModal from '../../components/admin/modals/AdminCustomModal';
import AdminCustomToast from '../../components/admin/AdminCustomToast';

import useAccountGcashLogic from '../../components/admin/AccountGcashLogic';

const AccountGcashEdit: React.FC = () => {
    const {
        showToast, handleCloseToast, showToastType,
        show, handleClose,
        handleSubmit, 
        transaction

    } = useAccountGcashLogic();

    return (
        <>
            <AdminCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
            <AdminCustomModal show={show} close={handleClose} submit={handleSubmit} transaction={transaction} />

            <Container>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>Edit GCash</h4>
                    </Col>
                </Row>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 8, offset: 2 }}
                        className='boxContainer px-5 pt-4 pb-4'>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Label><h6>Name</h6></Form.Label>
                                <Form.Control type="text" placeholder="" name='name' required />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label><h6>Number</h6></Form.Label>
                                <Form.Control type="number" placeholder="" name='gcash' required />
                            </Form.Group>
                            <Form.Group className="mb-3 mt-4">
                                <Form.Label>
                                    <h6 className='mb-0'>Status</h6>
                                </Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="Activate"
                                    className='m-0'
                                    name='status' />
                            </Form.Group>
                            <div className='text-end mt-4'>
                                <Button href={ROUTES.ADMIN_ACCOUNT_GCASH_ROUTE} variant="secondary" className='me-2'>Back</Button>
                                <Button variant="primary" type='submit'>Update</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container >
        </>
    );
}

export default AccountGcashEdit;