import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

// routes config
import { ROUTES } from 'resources/routes-constants'

// main public pages
import NotFoundPage from 'pages/NotFoundPage'
// end main public pages

// operators pages
import OperatorLogout from 'pages/operators/Logout'

import OperatorHome from 'pages/operators/Home'
import OperatorAccount from 'pages/operators/Account'

import OperatorCategories from 'pages/operators/GameCategories'
import OperatorGames from 'pages/operators/GameList'
import OperatorGameView from 'pages/operators/GameView'
import OperatorGameEdit from 'pages/operators/GameEdit'

import OperatorMatches from 'pages/operators/MatchList'
import OperatorMatchView from 'pages/operators/MatchView'
// end operators pages

import { refreshToken as operatorRefreshToken, validToken as operatorValidToken } from 'utility/api/operator/axiosOperator';

const OperatorContent = () => {
  return (
    <Routes>
      {/* {operatorValidToken()} */}
      <Route path={ROUTES.OPERATOR_LOGOUT_ROUTE} element={<OperatorLogout />} />
      <Route path={ROUTES.OPERATOR_HOME_ROUTE} element={<OperatorHome />} />
      <Route path={ROUTES.OPERATOR_ACCOUNT_ROUTE} element={<OperatorAccount />} />
      <Route path={ROUTES.OPERATOR_CATEGORIES_ROUTE} element={<OperatorCategories />} />

      <Route path={ROUTES.OPERATOR_GAMES_ROUTE + '/:id/:page'} element={<OperatorGames />} />
      <Route path={ROUTES.OPERATOR_GAME_VIEW_ROUTE + '/:id'} element={<OperatorGameView />} />
      <Route path={ROUTES.OPERATOR_GAME_EDIT_ROUTE + '/:id'} element={<OperatorGameEdit />} />

          <Route path={ROUTES.OPERATOR_MATCHES_ROUTE + '/:categoryid/:gameid/:page'} element={<OperatorMatches />} />
          <Route path={ROUTES.OPERATOR_MATCH_VIEW_ROUTE + '/:categoryid/:gameid/:matchid/:fightNumber'} element={<OperatorMatchView />} />

      <Route path="*" element={<NotFoundPage />} />

    </Routes>
  )
}

export default React.memo(OperatorContent)
