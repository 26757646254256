import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

// import useWithdrawalRequestListLogic from 'components/admin/WithdrawalRequestListLogic';

const WithdrawalRequestList: React.FC = () => {
    // const {
    //     post, total, pageNumber
    // } = useWithdrawalRequestListLogic();

    return (
        <>
            <Container>
                <Row>
                    <Col
                        md={{ span: 10, offset: 1 }}
                        sm={{ span: 12 }}
                        className="text-center mb-5">
                        <h4>Withdrawal <span className='highlightText px-2'>Requests</span></h4>
                    </Col>
                </Row>

                {/* <Row className='mb-3 text-end'>
                    <Col
                        md={{ span: 3, offset: 7 }}
                        xs={{ span: 12 }}
                    >
                        <Form className='w-100 d-inline-flex justify-content-end'>
                            <Form.Group className="d-inline-flex justify-content-end ms-0 w-100">
                                <Form.Control type='text' placeholder="Search Name" className='w-100 ms-0' />
                                <FontAwesomeIcon icon={faSearch} className='text-dark my-auto d-inline-flex justify-content-end search-icon' />
                            </Form.Group>
                        </Form>

                    </Col>
                </Row> */}

                <Row>
                    <Col
                        md={{ span: 10, offset: 1 }}
                        sm={{ span: 12 }}
                        className="text-center table-responsive">
                        <Table striped bordered hover variant="dark">
                            <thead className='bg-dark'>
                                <tr>
                                    <th>ID</th>
                                    <th>DATE</th>
                                    <th>AMOUNT</th>
                                    <th>STATUS</th>
                                    <th>REMARKS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {post && post.data.map((withdrawal: any, count: any) => ( */}
                                <tr className='bg-dark p-5 text-white'>
                                    <td>111</td>
                                    <td>2022-11-11</td>
                                    <td><span className='text-warning'>300</span></td>
                                    <td>
                                        Pending
                                    </td>
                                    <td>Hello</td>
                                </tr>
                                {/* ))} */}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col
                        md={{ span: 10, offset: 1 }}
                        sm={{ span: 12 }}
                        className="d-flex justify-content-end mb-5">
                        {/* <CustomPagination post={post} total={total} current={pageNumber} /> */}
                    </Col>
                </Row>
            </Container >
        </>
    );
}

export default WithdrawalRequestList;