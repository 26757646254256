import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ROUTES } from '../../resources/routes-constants';

import useCreditAgentLogic from 'components/admin/CreditAgentLogic';
import { formatDateWithTime, formatNumberToCurrency } from 'utility/functions';
import Loader from 'components/Loader';

const CreditsViewAgent: React.FC = () => {
    const {
        fullName, status, amount,
        image, remarks,
        approvedBy, createdAt, updatedAt,
        loadingStatus
    } = useCreditAgentLogic();

    return (
        <>
            <Container>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 10, offset: 1 }}
                        className="text-center mb-5">
                        <h4>View Agent Credit</h4>
                    </Col>
                </Row>
                {loadingStatus ?
                    <Loader />
                    :
                    <>
                        <Row>
                            <Col
                                lg={{ span: 6, offset: 3 }}
                                md={{ span: 10, offset: 1 }}
                                className='boxContainer px-5 pt-4 pb-4'>
                                <Row>
                                    <Col
                                        md={{ span: 6 }}
                                    >
                                        <img src={
                                            image ?
                                                image
                                                :
                                                'images/placeholder.png'
                                        }
                                            className='img-thumbnail mb-2' alt='gcash screenshot' />
                                    </Col>
                                    <Col
                                        md={{ span: 6 }}
                                    >
                                        <div className='mb-2'><span className='h5 me-2'>Name:</span><span className='h6'>{fullName}</span></div>
                                        <div className='mb-2'><span className='h5 me-2'>Amount:</span><span className='text-warning h6'>{formatNumberToCurrency(amount)}</span></div>
                                        <div className='mb-4'>
                                            <span className='h5 me-2'>Status:</span>
                                            {status === 'Approved' ? <span className='text-success'>{status}</span> : <span className='text-danger'>{status}</span>}
                                        </div>
                                        <div className='mb-2'><span className='h5 me-2'>Approver:</span><span className='h6'>{approvedBy}</span></div>
                                        <div className=''>
                                            <span className='h5 me-2'>Remarks:</span><br />
                                            <span className='h7 fst-italic'>
                                                {remarks}
                                            </span>
                                        </div>
                                        {/* <div className='mt-3'><span className='me-2'>Requested Date:</span><span className='small'>{formatDate(createdAt)}</span></div>
                                        <div className='mb-3'><span className='me-2'>Updated Date:</span><span className='small'>{formatDate(updatedAt)}</span></div> */}
                                        <div className='mt-3'><span className='me-2'>Requested Date:</span><span className='small'>{formatDateWithTime(createdAt)}</span></div>
                                        <div className='mb-3'><span className='me-2'>Updated Date:</span><span className='small'>{formatDateWithTime(updatedAt)}</span></div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        md={{ span: 12 }}
                                        className="text-right"
                                    >
                                        <div className='text-end'><a href={ROUTES.ADMIN_CREDITS_AGENT_ROUTE + '/1'}><span className='btn btn-secondary px-5'>Back</span></a></div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                }
            </Container >
        </>
    );
}

export default CreditsViewAgent;