import React from 'react'

import TopNavBarOperator from 'pages/partials/operator/TopNavbarOperator'
import OperatorContent from './OperatorContent'

const OperatorLayout = () => {
  return (
    <div>
      <TopNavBarOperator />
        <div className="body flex-grow-1 px-3">
          <OperatorContent />
        </div>
    </div>
  )
}

export default OperatorLayout