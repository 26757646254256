import React from 'react'

// import AgentCon from './AppContent'
import AgentContent from './AgentContent'
import TopNavbarAgent from 'pages/partials/agent/TopNavbarAgent'

const AgentLayout = () => {
  return (
    <div>
      <TopNavbarAgent />
        <div className="body flex-grow-1 px-3">
          <AgentContent />
        </div>
    </div>
  )
}

export default AgentLayout
