import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { clientAxios } from '../../utility/api/admin/axiosCMS';
import { API } from '../../resources/api-constants';
import { formatDate } from 'utility/functions';

const useWithdrawalAgentLogic = () => {

    //get details
    const [id, setId] = useState(useParams());
    const [post, setPost] = React.useState({ data: Array(2) });
    const [error, setError] = useState('');
    const [loadingStatus, setLoadingStatus] = useState(true);
    
    const [fullName, setFullName] = useState('');
    const [status, setStatus] = useState('');
    const [amount, setAmount] = useState('');
    const [image, setImage] = useState('');
    const [gcashName, setGcashName] = useState('');
    const [gcashNumber, setGcashNumber] = useState('');
    const [remarks, setRemarks] = useState('');
    
    const [approvedBy, setApprovedBy] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [updatedAt, setUpdatedAt] = useState('');

    const getPostData = async (id: any) => {
        try {
            const response = await clientAxios.get(
                API.ADMIN_AGENT_WITHDRAWAL_HISTORY_VIEW_LINK + '/' + id.id, {
            })

            setError('');

            setFullName(response.data.data.user.name);
            setStatus(response.data.data.status);
            setAmount(response.data.data.amount);
            //setImage(response.data.data[0].image);
            setGcashName(response.data.data.gcashName);
            setGcashNumber(response.data.data.gcashNumber);
            setRemarks(response.data.data.remarks);

            setApprovedBy(response.data.data.ApprovedBy.name);
            setCreatedAt(formatDate(response.data.data.createdAt));
            setUpdatedAt(formatDate(response.data.data.updatedAt));

            setLoadingStatus(false);

        } catch (error) {
            setError('An error has occured. Please try again.');
        }

    };

    React.useEffect(() => {
        getPostData(id);
    }, []);

    return {
        fullName, status, amount,
        image, remarks, gcashNumber, gcashName,
        approvedBy, createdAt, updatedAt,
        loadingStatus
    };
}

export default useWithdrawalAgentLogic;