import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import { clientAxios } from 'utility/api/admin/axiosCMS';
import { API } from '../../../resources/api-constants';

// function CustomModal() {
const AdminConfirmationModal = (props: any) => {
    const setError = props.setError;
    const setShowToast = props.setShowToast;
    const setShowToastType = props.setShowToastType;
    const handleClose = props.close;
    const handleSubmit = props.submit;
    const confirmationType = props.confirmationType;
    const fullName = props.fullName;
    const userId = props.userId;
    const requestId = props.requestId;
    const userType = props.type;

    const listRedirect = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmitRequest = function (e: any) {
        e.preventDefault();

        if ('change-player-email' === confirmationType) {
            const newEmail = e.target.email.value;

            setErrorMessage('');

            clientAxios.patch(
                API.ADMIN_CHANGE_USER_EMAIL_LINK + '/' + userId + '/update-email',
                {
                    email: newEmail
                }
            ).then((response: any) => {
                window.location.reload();
                handleSubmit(e, true);
                setShowToastType('success');
                setShowToast(true);

            }).catch((error: any) => {
                if (error.response.status === 422) {
                    setErrorMessage('Email Already Exists');
                } else {
                    handleSubmit(e, false);
                    setShowToastType('fail');
                    setShowToast(true);
                }
            }).finally(() => {
            });
        } else {

            const remarks = e.target.remarks.value;

            //api call
            if ('approve-credit-request' === confirmationType ||
                'approve-credit-request-agent' === confirmationType) {
                clientAxios.patch(
                    API.ADMIN_CREDITS_ACTION_LINK + '/' + requestId,
                    {
                        remarks: remarks
                    }
                ).then((response: any) => {
                    handleSubmit(e, true);
                    setShowToastType('success');
                    setShowToast(true);

                }).catch((error: any) => {
                    handleSubmit(e, false);
                    setShowToastType('fail');
                    setShowToast(true);
                }).finally(() => { // successful, redirect to list
                    if ('approve-credit-request' == confirmationType) {
                        listRedirect(ROUTES.ADMIN_CREDIT_REQUESTS_ROUTE + '/1');
                    } else {
                        listRedirect(ROUTES.ADMIN_CREDIT_REQUESTS_AGENT_ROUTE + '/1');
                    }
                });
            } else if ('disapprove-credit-request' === confirmationType ||
                'disapprove-credit-request-agent' === confirmationType) {
                clientAxios.patch(
                    API.ADMIN_CREDITS_ACTION_DISAPPROVE_LINK + '/' + requestId,
                    {
                        remarks: remarks
                    }
                ).then((response: any) => {
                    handleSubmit(e, true);
                    setShowToastType('success');
                    setShowToast(true);

                }).catch((error: any) => {
                    handleSubmit(e, false);
                    setShowToastType('fail');
                    setShowToast(true);
                }).finally(() => { // successful, redirect to list
                    if ('disapprove-credit-request' === confirmationType) {
                        listRedirect(ROUTES.ADMIN_CREDIT_REQUESTS_ROUTE + '/1');
                    } else {
                        listRedirect(ROUTES.ADMIN_CREDIT_REQUESTS_AGENT_ROUTE + '/1');
                    }
                });
            } else if ('approve-withdrawal-request' == confirmationType ||
                'approve-withdrawal-request-agent' == confirmationType) {
                clientAxios.patch(
                    API.ADMIN_WITHDRAWAL_ACTION_LINK + '/' + requestId,
                    {
                        remarks: remarks
                    }
                ).then((response: any) => { // successful, redirect to list
                    handleSubmit(e, true);
                    setShowToastType('success');
                    setShowToast(true);

                }).catch((error: any) => {
                    handleSubmit(e, false);
                    setShowToastType('fail');
                    setShowToast(true);
                }).finally(() => { // successful, redirect to list
                    if ('approve-withdrawal-request' === confirmationType) {
                        listRedirect(ROUTES.ADMIN_WITHDRAWAL_REQUESTS_ROUTE + '/1');
                    } else {
                        listRedirect(ROUTES.ADMIN_WITHDRAWAL_REQUESTS_AGENT_ROUTE + '/1');
                    }
                });
            } else if ('disapprove-withdrawal-request' == confirmationType ||
                'disapprove-withdrawal-request-agent' == confirmationType) {
                clientAxios.patch(
                    API.ADMIN_WITHDRAWAL_ACTION_DISAPPROVE_LINK + '/' + requestId,
                    {
                        remarks: remarks
                    }
                ).then((response: any) => {
                    handleSubmit(e, true);
                    setShowToastType('success');
                    setShowToast(true);

                }).catch((error: any) => {
                    handleSubmit(e, false);
                    setShowToastType('fail');
                    setShowToast(true);
                }).finally(() => { // successful, redirect to list
                    if ('disapprove-withdrawal-request' === confirmationType) {
                        listRedirect(ROUTES.ADMIN_WITHDRAWAL_REQUESTS_ROUTE + '/1');
                    } else {
                        listRedirect(ROUTES.ADMIN_WITHDRAWAL_REQUESTS_AGENT_ROUTE + '/1');
                    }
                });
            } else {
                return;
            }
        }
    }
    //END APPROVE / DISAPPROVED

    if ('activate-gcash' == confirmationType) {
        return (
            <Form >
                <Modal show={props.show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Action</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">

                        <Form.Group className="my-1">
                            <Form.Label><span className='h6'>Activate <span className='text-danger'>{fullName}</span>?</span></Form.Label>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
                        </Button>
                        <Button variant="primary" onClick={handleSubmit('true')}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Form >
        );
    } else if ('deactivate-gcash' == confirmationType) {
        return (

            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Action</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <Form>
                        <Form.Group className="my-1">
                            <Form.Label><span className='h6'>Deactivate <span className='text-danger'>{fullName}</span>?</span></Form.Label>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit('false')}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else if ('approve-credit-request' == confirmationType ||
        'approve-credit-request-agent' == confirmationType) {
        return (

            <Modal show={props.show} onHide={handleClose} >
                <Form onSubmit={handleSubmitRequest} >
                    <Modal.Header className='bg-confirm-yes' closeButton>
                        <Modal.Title>Confirm Action</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">

                        <Form.Group className="my-1 text-center">
                            <Form.Label><span className='h5'>Approve Request?</span></Form.Label>
                        </Form.Group>
                        <Form.Group className="mt-2 text-center">
                            <textarea className='w-75' placeholder='Enter Remarks' rows={10} name='remarks'>

                            </textarea>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
                        </Button>
                        <Button variant="primary" type="submit">
                            Yes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    } else if ('disapprove-credit-request' == confirmationType ||
        'disapprove-credit-request-agent' == confirmationType) {
        return (

            <Modal show={props.show} onHide={handleClose}>
                <Form onSubmit={handleSubmitRequest} >
                    <Modal.Header className='bg-confirm-no' closeButton>
                        <Modal.Title>Confirm Action</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">

                        <Form.Group className="my-1 text-center">
                            <Form.Label><span className='h5'>Disapprove Request?</span></Form.Label>
                        </Form.Group>
                        <Form.Group className="mt-2 text-center">
                            <textarea className='w-75' placeholder='Enter Remarks' rows={10} name='remarks' required>

                            </textarea>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
                        </Button>
                        <Button variant="primary" type="submit">
                            Yes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    } else if ('approve-withdrawal-request' == confirmationType ||
        'approve-withdrawal-request-agent' == confirmationType) {
        return (

            <Modal show={props.show} onHide={handleClose} >
                <Form onSubmit={handleSubmitRequest} >
                    <Modal.Header className='bg-confirm-yes' closeButton>
                        <Modal.Title>Confirm Action</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">

                        <Form.Group className="my-1 text-center">
                            <Form.Label><span className='h5'>Approve Request?</span></Form.Label>
                        </Form.Group>
                        <Form.Group className="mt-2 text-center">
                            <textarea className='w-75' placeholder='Enter Remarks' rows={10} name='remarks'>

                            </textarea>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
                        </Button>
                        <Button variant="primary" type="submit">
                            Yes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    } else if ('disapprove-withdrawal-request' == confirmationType ||
        'disapprove-withdrawal-request-agent' == confirmationType) {
        return (

            <Modal show={props.show} onHide={handleClose}>
                <Form onSubmit={handleSubmitRequest} >
                    <Modal.Header className='bg-confirm-no' closeButton>
                        <Modal.Title>Confirm Action</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">

                        <Form.Group className="my-1 text-center">
                            <Form.Label><span className='h5'>Disapprove Request?</span></Form.Label>
                        </Form.Group>
                        <Form.Group className="mt-2 text-center">
                            <textarea className='w-75' placeholder='Enter Remarks' rows={10} name='remarks' required>

                            </textarea>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
                        </Button>
                        <Button variant="primary" type="submit">
                            Yes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    } else if ('deactivate-operator' === confirmationType) {
        return (
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Update</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <h6>Deactivate <span className='text-danger'>{fullName}</span>?</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit('deactivate')}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );

    } else if ('activate-operator' === confirmationType) {
        return (
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Update</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <h6>Activate <span className='text-danger'>{fullName}</span>?</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit('activate')}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else if ('deactivate-agent' === confirmationType) {
        return (
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Update</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <h6>Deactivate <span className='text-danger'>{fullName}</span>?</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit('deactivate')}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );

    } else if ('activate-agent' === confirmationType) {
        return (
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Update</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <h6>Activate <span className='text-danger'>{fullName}</span>?</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit('activate')}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else if ('reset-player-password' == confirmationType) {
        return (
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <h6>Reset <span className='text-danger'>{fullName}'s</span> password?</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit('reset')}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else if ('reset-agent-password' === confirmationType) {
        return (
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <h6>Reset <span className='text-danger'>{fullName}'s</span> password?</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit('reset')}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else if ('change-player-email' == confirmationType) {
        return (

            <Modal show={props.show} onHide={handleClose}>
                <Form onSubmit={handleSubmitRequest} >
                    <Modal.Header className='bg-confirm-no' closeButton>
                        <Modal.Title>Change Email</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">

                        <Form.Group className="my-1 text-center">
                            <Form.Label>
                                <h5>Change <span className='text-danger'>{fullName}'s</span> email?</h5>
                            </Form.Label>
                        </Form.Group>
                        <Form.Group className="mt-2 ms-4">
                            <Form.Label className='ms-4'>New Email: </Form.Label>
                            <input type='email' name='email' className='ms-2' required />
                        </Form.Group>
                        <Form.Group className="mt-3 text-center">
                            <span className='text-warning h6'>NOTE: Only if absolutely necessary</span>
                        </Form.Group>
                        <p className='text-danger text-center mt-2 mb-0'>&nbsp;{errorMessage}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            No
                        </Button>
                        <Button variant="primary" type="submit">
                            Yes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    } else {
        return (

            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Update</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <h6>Deactivate <span className='text-danger'>{fullName}</span>?</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AdminConfirmationModal;