import React, { useMemo, useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Card } from 'react-bootstrap';


import Trends from '../partials/members/Trends';
import Bet2 from '../partials/members/Bet2';
import ChoiceHistory from '../partials/members/ChoiceHistory';

import usePlayLogic from 'components/members/PlayLogic';
import Loader from 'components/Loader';
import { formatNumberToCurrencyRoundUp } from '../../utility/functions';
import { API } from '../../resources/api-constants';
import { clientAxios } from '../../utility/api/members/axiosPlayer';
import { ROUTES } from '../../resources/routes-constants';

const ApiPlay: React.FC = () => {
    const {
        pusherChannel,
        matchId, fightNumber, betStatus, matchResult,
        team1, team2, stream, settleStatus, pusherEvent,
        ongoingBet,
        postTrends,
        handleBetSubmit, loadChoices,
        handleUpdateMatchDetails,
        loadingStatus, loadingStatusTrends
    } = usePlayLogic();

    const currentCredits = localStorage.getItem('total-credits');
/*    console.log(ongoingBet)*/

    const renderStream = useMemo(() => {
        if (Number(currentCredits) < 100 && ongoingBet === '0') {
            return <div className='m-auto w-100 text-center h5 align-items-center'>A balance of 100 credits is required to view stream</div>;
        }
        if (stream) {
            return (
                <div className="iframeWrapper">
                    {stream.includes('iframe') ? (
                        <span dangerouslySetInnerHTML={{ __html: stream }} />
                    ) : (
                        <iframe width="560" height="315" src={stream} />
                    )}
                </div>
            );
        }
        return <div className='m-auto w-100 text-center h5 align-items-center'>No Stream Available. Please check again later.</div>;
    }, [currentCredits, stream, ongoingBet]);

    const handleBuy = async function (e: any) {
        e.preventDefault();
        const creditAmount = e.target.creditAmount.value;

        await clientAxios.post(
            API.API_BUY,
            {
                amount: creditAmount
            }
        ).then((response: any) => {
            getProfile();

        }).catch((error: any) => {
            //localStorage.clear();
            //window.location.href = ROU.LOGIN_ROUTE;
        });
    };

    const handleWithdraw = async function (e: any) {
        e.preventDefault();
        const creditAmount = e.target.creditAmount.value;

        await clientAxios.post(
            API.API_WITHDRAW,
            {
                amount: creditAmount
            }
        ).then((response: any) => {
            getProfile();

        }).catch((error: any) => {
            //localStorage.clear();
            //window.location.href = ROU.LOGIN_ROUTE;
        });
    };


    //GET TOTAL CREDITS
    const [totalCredits, setTotalCredits] = useState('');
    function getProfile() {
    
    clientAxios.get(
        API.PLAYER_ACCOUNT_LINK,
        {}
    ).then((response: any) => {

        localStorage.setItem('total-credits', response.data.data.wallet.balance);
        localStorage.setItem('email-address', response.data.data.email);
        localStorage.setItem('name', response.data.data.name);
        localStorage.setItem('total-credits', response.data.data.wallet.balance);

        setTotalCredits(response.data.data.wallet.balance);

    }).catch((error: any) => {
        //localStorage.clear();
        //window.location.href = ROU.LOGIN_ROUTE;
    });
    }
    //END GET TOTAL CREDITS

    React.useEffect(() => {
        getProfile();
    }, []);

    return (
        <>
            {loadingStatus ? (
                <Loader />
            ) : (
                    <Container>
                        <Row className='mb-4 mt-4'>
                            <Col md={12} className=''>
                                <span className='text- h5 m-0' key={3}>Credits: <span id='totalCredits'>{formatNumberToCurrencyRoundUp(totalCredits)}</span></span>
                            </Col>
                        </Row>
                        <Row className='mb-4 mt-4'>
                            <Col md={12} className=''>
                                <form onSubmit={handleBuy }>
                            <input
                                type="number" inputMode="numeric"
                                className=' my-3 text-center'
                                    name='creditAmount'
                                defaultValue={0}
                                key={0}
                                required
                                    />
                                    <Button type="submit" className="mw-50 ms-3 button-wala" >Add Credits</Button>
                                </form>
                                <form onSubmit={handleWithdraw}>
                                    <input
                                        type="number" inputMode="numeric"
                                        className='my-3 text-center'
                                        name='creditAmount'
                                        defaultValue={0}
                                        key={0}
                                        required
                                    />
                                    <Button type="submit" className="mw-50 btn-danger ms-3">Withdraw Credits</Button>
                                </form>
                                
                            </Col>
                        </Row>
                        <Row className='mb-4 mt-4'>
                            <Col md={12} className='text-end'>
                                <span className='text-end h8 m-0' key={3}>  &#11164; <a href={ROUTES.API_RESPONSE_CATEGORIES + '/' + localStorage.getItem('connect-user-id')} >Back to Home</a></span>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={12}>
                                <Row className='mb-4'>
                                    <Col>
                                        <Card className="play-card mb-4" bg='dark'>
                                            <Card.Header className='pb-2'>LIVE STREAM</Card.Header>
                                            <Card.Body className='align-items-center mt-4' style={{ minHeight: '418px' }}>
                                                {renderStream}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={6} md={12} style={{ minHeight: '400px' }}>
                                <Bet2
                                    fightNumber={fightNumber}
                                    matchResult={matchResult}
                                    betStatus={betStatus}
                                    settleStatus={settleStatus}
                                    team1={team1}
                                    team2={team2}
                                    pusherChannel={pusherChannel}
                                    matchIdCurrent={matchId}
                                    pusherEvent={pusherEvent}
                                    handleBetSubmit={handleBetSubmit}
                                    handleUpdateMatchDetails={handleUpdateMatchDetails}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} md={12}>
                                <Trends
                                    loadingStatusTrends={loadingStatusTrends}
                                    postTrends={postTrends}
                                />
                            </Col>
                            <Col lg={6} md={12} className='mb-4'>
                                <ChoiceHistory
                                    handleBetSubmit={handleBetSubmit}
                                    loadChoices={loadChoices}
                                    settleStatus={settleStatus}
                                />
                            </Col>
                        </Row>
                        <Row className='mb-4 mt-4'>
                            <Col md={12} className='text-end'>
                                <span className='text-end h8 m-0' key={3}>  &#11164; <a href={ROUTES.API_RESPONSE_CATEGORIES + '/' + localStorage.getItem('connect-user-id')} >Back to Home</a></span>
                            </Col>
                        </Row>
                    </Container>
            )}
        </>
    );
};

export default ApiPlay;
