import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { clientAxios } from '../../utility/api/admin/axiosCMS';
import { API } from '../../resources/api-constants';
import { formatDateWithTime } from 'utility/functions';

const useOperatorLogic = () => {

    //get details
    const [id, setId] = useState(useParams());
    const [post, setPost] = React.useState({ data: Array(2) });
    const [error, setError] = useState('');
    const [loadingStatus, setLoadingStatus] = useState(true);

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');

    const [emailVerifiedAt, setEmailVerifiedAt] = useState('');
    const [lastLogin, setLastLogin] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [updatedAt, setUpdatedAt] = useState('');

    // TODO: Wait for id query
    const getPostData = async (id: any) => {
        try {
            const response = await clientAxios.get(
                API.ADMIN_OPERATORS_LINK + '/' + id.id, {
            })

            setError('');

            setFullName(response.data.data.name);
            setEmail(response.data.data.email);
            setStatus(response.data.data.status);

            setCreatedBy(response.data.data.CreatedBy.name);

            setCreatedAt(formatDateWithTime(response.data.data.createdAt));
            setUpdatedAt(formatDateWithTime(response.data.data.updatedAt));

            if (response.data.data.lastLogin === null || response.data.data.lastLogin === 'null') {
                setLastLogin('--');
            } else {
                setLastLogin(formatDateWithTime(response.data.data.lastLogin));
            }

        } catch (error) {
            setError('An error has occured. Please try again.');
        } finally {
            setLoadingStatus(false);
        }

    };

    React.useEffect(() => {
        getPostData(id);
    }, []);

    //end get details

    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const transaction = () => 'add-operator';
    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);
    const handleCloseToast = () => setShowToast(false);

    const [showToastType, setShowToastType] = useState('success');

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const opName = e.target.name.value;
        const opStatus = e.target.status.checked;
        let opStatusF = false;

        if (opStatus)
            opStatusF = true;

        let result = true;

        //api call
        try {
            const response = await clientAxios.put(
                API.ADMIN_OPERATORS_UPDATE_LINK + '/' + id.id, {
                name: opName,
                status: opStatusF
            })

            setError('');
        } catch (error) {
            result = false;
            setError('An error has occured. Please try again.');
        }

        if (result) {
            setShowToastType('success');
        } else {
            setShowToastType('fail');
        }

        setShow(false);
        setShowToast(true);
    };
    return {
        showToast, handleCloseToast, showToastType,
        show, handleClose, handleSubmit, transaction,
        fullName, email, status,
        emailVerifiedAt, lastLogin, createdBy, createdAt, updatedAt,
        loadingStatus
    };
}

export default useOperatorLogic;