import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { ROUTES } from '../../resources/routes-constants';

import AdminCustomModal from '../../components/admin/modals/AdminCustomModal';
import AdminCustomToast from '../../components/admin/AdminCustomToast';
import CustomPagination from '../../components/CustomPagination';
import AdminConfirmationModal from '../../components/admin/modals/AdminConfirmationModal';

import useCategoryListLogic from 'components/admin/CategoryListLogic';
import { API } from 'resources/api-constants';
import Loader from 'components/Loader';

const CategoryList: React.FC = () => {

    const {
        showToast, handleCloseToast, showToastType,
        show, handleClose, handleSubmit, transaction,
        showConfirmation, handleConfirmationSubmit, handleCloseConfirmation, confirmationType, fullName, id,
        post, total, pageNumber,
        loadingStatus

    } = useCategoryListLogic();

    return (
        <>
            <AdminCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
            <AdminCustomModal show={show} close={handleClose} submit={handleSubmit} transaction={transaction} />
            <AdminConfirmationModal show={showConfirmation} submit={handleConfirmationSubmit} close={handleCloseConfirmation} confirmationType={confirmationType} fullName={fullName} />

            <Container>
                <Row>
                    <Col
                        md={{ span: 8, offset: 2 }}
                        sm={{ span: 12 }}
                        className="text-center mb-5">
                        <h4>Game Categories</h4>
                    </Col>
                </Row>
                {loadingStatus ?
                    <Loader />
                    :
                    <>
                        <Row>
                            <Col
                                md={{ span: 8, offset: 2 }}
                                sm={{ span: 12 }}
                                className="text-center">
                                <Table striped bordered hover variant="dark">
                                    <thead className='bg-dark'>
                                        <tr>
                                            {/* <th>ID</th> */}
                                            <th>PREVIEW</th>
                                            <th>NAME</th>
                                            <th>ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {post && post.data.map((category: any, count: any) => (
                                            <tr className='bg-dark p-5 text-white' key={category.id}>
                                                {/* <td>{category.gameCategoryID}</td> */}
                                                <td className='w-25'>
                                                    <img
                                                        src={category.image ?
                                                            category.image
                                                            :
                                                            '/images/placeholder.png'
                                                        }
                                                        alt={category.name}
                                                        className='img-thumbnail' style={{ maxWidth: '120px' }} 
                                                    />
                                                </td>
                                                <td>{category.name}</td>
                                                <td>
                                                    <a href={ROUTES.ADMIN_CATEGORY_VIEW_ROUTE + '/' + category.id} className="me-1">View</a><br />
                                                    {/* <a href={ROUTES.ADMIN_CATEGORY_EDIT_ROUTE + '/1'} className='mx-1'>Edit</a><br /> */}
                                                    {/*<a href={ROUTES.ADMIN_GAMES_ROUTE + '/' + category.id + '/1'} className='mx-1'>View Games</a>*/}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        {/* <Row>
                    <Col sm={{ span: 8, offset: 2 }} className="d-flex justify-content-end mb-5">
                        <CustomPagination post={post} total={total} current={pageNumber} />
                    </Col>
                </Row> */}
                    </>
                }
            </Container >
        </>
    );
}

export default CategoryList;

function updateFormData(arg0: any) {
    throw new Error('Function not implemented.');
}