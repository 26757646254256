import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Table from 'react-bootstrap/Table';
import CustomPagination from '../../components/CustomPagination';

import { formatDate, formatDateWithTime, formatNumberToCurrency } from 'utility/functions';
import { ROUTES } from 'resources/routes-constants';

import useWithdrawalListLogic from 'components/members/WithdrawalListLogic';
import Loader from 'components/Loader';

const Withdrawals: React.FC = () => {
    const {
        post, total, pageNumber,
        loadingStatus
    } = useWithdrawalListLogic();

    if (!(post) || (0 == total)) {
        return (
            <>
                {loadingStatus ?
                    <Loader />
                    :
                    <Container >
                        <Row>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                                className="text-center mb-5">
                                <h4>No Items Found</h4>
                            </Col>
                        </Row>
                    </Container>
                }
            </>
        );

    } else {

        return (
            <div>
                <Container>
                    <Row>
                        <Col
                            md={{ span: 10, offset: 1 }}
                            sm={{ span: 12 }}
                            className="text-center mb-5">
                            <h4>Withdrawal <span className='highlightText px-2'>Requests</span></h4>
                        </Col>
                    </Row>
                    {loadingStatus ?
                        <Loader />
                        :
                        <>
                            <Row>
                                <Col
                                    md={{ span: 10, offset: 1 }}
                                    sm={{ span: 12 }}
                                    className="text-center table-responsive">
                                    <Table striped bordered hover variant="dark">
                                        <thead className='bg-dark'>
                                            <tr>
                                                <th>DATE</th>
                                                <th>GCASH</th>
                                                <th>AMOUNT</th>
                                                <th>STATUS</th>
                                                <th>REMARKS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {post && post.data.map((withdrawal: any, count: any) => (
                                                <tr className='bg-dark p-5 text-white' key={withdrawal.id}>
                                                    <td>{formatDateWithTime(withdrawal.createdAt)}</td>
                                                    <td>{withdrawal.gcashNumber}</td>
                                                    <td><span className='text-warning'>{formatNumberToCurrency(withdrawal.amount)}</span></td>
                                                    <td>
                                                        {withdrawal.status === 'Pending' ?
                                                            <span className='text-warning'>Pending</span> :
                                                            withdrawal.status === 'Approved' ?
                                                                <span className='text-success'>Approved</span> :
                                                                <span className='text-danger'>Disapproved</span>
                                                        }
                                                    </td>
                                                    <td>{withdrawal.remarks}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    md={{ span: 10, offset: 1 }}
                                    sm={{ span: 12 }}
                                    className="d-flex justify-content-end mb-5">
                                    <CustomPagination post={post} total={total} current={pageNumber} />
                                </Col>
                            </Row>
                        </>
                    }
                </Container >
            </div >
        );
    }
}

export default Withdrawals;