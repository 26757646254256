import React, { useState, useEffect } from 'react';
import { clientAxios } from 'utility/api/operator/axiosOperator';
import { API } from '../../resources/api-constants';
import { useParams } from 'react-router-dom';

// Define the expected shape of the post data
interface PostData {
    data: any[]; // Replace 'any' with the actual type if possible
    count: number;
}

const useOperatorMatchListLogic = () => {
    // State for post data, error handling, and loading status
    const [post, setPost] = useState<PostData>({ data: [], count: 0 });
    const [error, setError] = useState<string | null>(null);
    const [loadingStatus, setLoadingStatus] = useState(true);

    // State for pagination and filters
    const { categoryid: categoryId, gameid: gameId, page: pageNumber } = useParams();
    const [gameName, setGameName] = useState('');
    const [currentPage, setCurrentPage] = useState<number>(Number(pageNumber) || 1);

    // States for modal and toast notifications
    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showToastType, setShowToastType] = useState<'success' | 'fail'>('success');

    // Function to fetch data
    const getPostData = async (gameId: string, pageNumber: number) => {
        setLoadingStatus(true);
        try {
            const response = await clientAxios.get(`${API.OPERATOR_GAMES_MATCHES_LINK}/${gameId}/matches`, {
                params: {
                    offset: API.NUM_PER_PAGE * (pageNumber - 1),
                    limit: API.NUM_PER_PAGE,
                }
            });
            setPost({ data: response.data.data, count: response.data.count });
            setLoadingStatus(false);
            setError(null);
        } catch (error) {
            setError('Failed to fetch data');
            setLoadingStatus(false);
        }
    };

    // Fetch data on initial load and when gameId or pageNumber changes
    useEffect(() => {
        if (gameId && currentPage) {
            getPostData(gameId, currentPage);
        }
    }, [gameId, currentPage]);

    // Handlers for modal and toast notifications
    const handleClose = () => setShow(false);
    const handleCloseToast = () => setShowToast(false);
    const handleSubmit = (e: React.FormEvent, result: boolean) => {
        e.preventDefault();
        setShowToastType(result ? 'success' : 'fail');
        setShow(false);
        setShowToast(true);
    };
    const handleShow = (type: any) => setShow(true);

    return {
        showToast, handleCloseToast, showToastType,
        show, handleClose, handleSubmit,
        handleShow,
        categoryId, gameId, gameName,
        post, total: post.count, pageNumber: currentPage,
        loadingStatus,
        getPostData
    };
};

export default useOperatorMatchListLogic;