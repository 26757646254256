import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'

import Form from 'react-bootstrap/Form';
import CustomModal from '../../components/members/modals/CustomModal';
import CustomToast from '../../components/members/CustomToast';
import useDepositLogic from '../../components/members/DepositLogic';
import { copyContent, updateTextView } from 'utility/functions';
import Loader from 'components/Loader';

const Deposit: React.FC = () => {

    const {
        showToast, handleCloseToast, showToastType,
        show, handleClose, handleSubmit, transaction,
        handleShow, image, amount,
        gcashId, gcashName, gcashNumber, gcashStatus,
        error, loadingStatus
    } = useDepositLogic();

    return (
        <>
            <CustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
            <CustomModal show={show} close={handleClose} submit={handleSubmit} transaction={transaction} image={image} amount={amount} gcash={gcashNumber} />
            <Container>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>Add <span className='highlightText px-2'>Credits</span></h4>
                        {/* <Image fluid src='/images/gcash.png' className='mt-3' width={100} /> */}
                    </Col>
                </Row>
                {loadingStatus ?
                    <Loader />
                    :
                    <>
                        {
                            null === gcashNumber || 'null' === gcashNumber || '' === gcashNumber
                                ?
                                <>
                                    <Row>
                                        <Col
                                            lg={{ span: 6, offset: 3 }}
                                            md={{ span: 8, offset: 2 }}
                                            className='p-5 text-center boxContainer'>
                                            <h6 className='text-center' >No Gcash assigned yet. Please contact Administrator.</h6>
                                        </Col>
                                    </Row>
                                </>
                                :
                                <>
                                    <Row>
                                        <Col
                                            lg={{ span: 6, offset: 3 }}
                                            md={{ span: 8, offset: 2 }}
                                            className='boxContainer p-5'>
                                            <Row>
                                                <Col className="mb-3">
                                                    <h5 className='text-center' >Send to Gcash</h5>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="bg-secondary p-1 text-center">
                                                    <h6>{gcashNumber} <a href="#" onClick={() => copyContent(gcashNumber)} className='ms-2'>Copy</a></h6>
                                                    <span>{gcashName}</span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="p-2 text-center my-2">
                                                    <span className='text-center m-auto p-0' >amount between</span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="bg-secondary p-2 m-2 text-center">
                                                    <h6 className=' p-0 m-0 '>Php 100.00 - 10,000.00</h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p className='text-center p-2 m-0'>Download or take a screenshot
                                                        of the receipt and attach below</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className='mt-5'>
                                        <Col
                                            lg={{ span: 6, offset: 3 }}
                                            md={{ span: 8, offset: 2 }}
                                            className="mb-5">
                                            <Form onSubmit={handleShow}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label><h6>Amount</h6></Form.Label>
                                                    <Form.Control className='' type="text" maxLength={6} defaultValue={100} name='amount' min={100} max={10000} onChange={updateTextView} required />
                                                </Form.Group>
                                                <Form.Group className="">
                                                    <Form.Label><h6>Screenshot of the GCash receipt</h6></Form.Label>
                                                    <Form.Control className='' type="file" placeholder="Choose File" name='screenshot' required />
                                                    <Form.Text className="text-muted">Maximum size (4Mb)</Form.Text>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label className='d-flex justify-content-center'><span className='text-danger'>&nbsp;{error}</span></Form.Label><br />
                                                    <div className='text-center'><Button className="w-50" type='submit'>Proceed</Button></div>
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                    </Row>
                                </>
                        }
                    </>
                }
            </Container >
        </ >
    );
}

export default Deposit;