import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { ROUTES } from '../../resources/routes-constants';
import { Button } from 'react-bootstrap';
import {useParams} from 'react-router-dom';

import AdminCustomModal from '../../components/admin/modals/AdminCustomModal';
import AdminCustomToast from '../../components/admin/AdminCustomToast';

const CategoryEdit: React.FC = () => {
    //get id
    const {id} = useParams();

    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const transaction = () => 'add-agent';
    const handleClose = () => setShow(false);

    const handleCloseToast = () => setShowToast(false);

    const initialFormData = Object.freeze({
        status: ""
    });

    const [formData, updateFormData] = React.useState(initialFormData);

    const handleChange = (e: any) => {
        updateFormData({
            ...formData,

            // Trimming any whitespace
            [e.target.name]: e.target.value.trim()
        });
    };

    const [showToastType, setShowToastType] = useState('success');
    const result = true;

    const handleSubmit = (e: any) => {
        e.preventDefault();

        //api call
        if (result) {
            setShowToastType('success');
        } else {
            setShowToastType('fail');
        }

        setShow(false);
        setShowToast(true);
    };

    return (
        <>
            <AdminCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
            <AdminCustomModal show={show} close={handleClose} submit={handleSubmit} transaction={transaction} />

            <Container>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>Edit Category</h4>
                    </Col>
                </Row>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 8, offset: 2 }}
                        className='boxContainer px-5 pt-4 pb-4'>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Label><h6>Name</h6></Form.Label>
                                <Form.Control type="text" placeholder="" name='name' onChange={handleChange} required />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label><h6>Image</h6></Form.Label>
                                <Form.Control type="file" placeholder="Choose File" name='image' />
                            </Form.Group>
                            <div className='text-end mt-4'>
                                <Button href={ROUTES.ADMIN_CATEGORIES_ROUTE} variant="secondary" className='me-2'>Back</Button>
                                <Button variant="primary" type='submit'>Update</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container >
        </>
    );
}

export default CategoryEdit;