import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { ROUTES } from '../../resources/routes-constants';

import CustomPagination from '../../components/CustomPagination';

import useDownlineCreditRequestListLogic from 'components/agents/AgentDownlineRequestListLogic';
import { formatDate, formatNumberToCurrency } from 'utility/functions';
import Loader from 'components/Loader';

const AgentDownlineRequestList: React.FC = () => {
    const {
        post, total, pageNumber,
        loadingStatus
    } = useDownlineCreditRequestListLogic();

    if (!(post) || (0 === total)) {
        return (
            <>
                {loadingStatus ?
                    <Loader />
                    :
                    <Container >
                        <Row>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                                className="text-center mb-5">
                                <h4>No Items Found</h4>
                            </Col>
                        </Row>
                    </Container>
                }
            </>
        );

    } else {

        return (
            <>
                <Container>
                    <Row>
                        <Col
                            md={{ span: 10, offset: 1 }}
                            sm={{ span: 12 }}
                            className="text-center mb-5">
                            <h4>Downline <span className='highlightText px-2'>Requests</span></h4>
                        </Col>
                    </Row>

                    {/* <Row className='mb-3 text-end'>
                    <Col
                        md={{ span: 3, offset: 7 }}
                        xs={{ span: 12 }}
                    >
                        <Form className='w-100 d-inline-flex justify-content-end'>
                            <Form.Group className="d-inline-flex justify-content-end ms-0 w-100">
                                <Form.Control type='text' placeholder="Search Name" className='w-100 ms-0' />
                                <FontAwesomeIcon icon={faSearch} className='text-dark my-auto d-inline-flex justify-content-end search-icon' />
                            </Form.Group>
                        </Form>

                    </Col>
                </Row> */}
                    {loadingStatus ?
                        <Loader />
                        :
                        <>
                            <Row>
                                <Col
                                    md={{ span: 10, offset: 1 }}
                                    sm={{ span: 12 }}
                                    className="text-center table-responsive">
                                    <Table striped bordered hover variant="dark">
                                        <thead className='bg-dark'>
                                            <tr>
                                                <th>DATE</th>
                                                <th>USERNAME</th>
                                                <th>NAME</th>
                                                <th>AMOUNT</th>
                                                <th>ACTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {post && post.data.map((request: any, count: any) => (
                                                <tr className='bg-dark p-5 text-white' key={request.id}>
                                                    <td>{formatDate(request.createdAt)}</td>
                                                    <td>{request.user.username}</td>
                                                    <td>{request.user.name}</td>
                                                    <td><span className='text-warning'>{formatNumberToCurrency(request.amount)}</span></td>
                                                    <td>
                                                        <a href={ROUTES.AGENT_DOWNLINE_REQUEST_VIEW_ROUTE + '/' + request.id} className="me-1">View</a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    md={{ span: 10, offset: 1 }}
                                    sm={{ span: 12 }}
                                    className="d-flex justify-content-end mb-5">
                                    <CustomPagination post={post} total={total} current={pageNumber} />
                                </Col>
                            </Row>
                        </>
                    }
                </Container >
            </>
        );
    }
}

export default AgentDownlineRequestList;