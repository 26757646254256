import React from 'react'
import { Route, Routes } from 'react-router-dom'

// routes config
import { ROUTES } from 'resources/routes-constants'

// main public pages
import NotFoundPage from 'pages/NotFoundPage'
// end main public pages

// admin pages
import AdminLogout from 'pages/admin/Logout'
import AdminHome from 'pages/admin/Home'

import AdminOperatorList from 'pages/admin/OperatorList'
import AdminOperatorView from 'pages/admin/OperatorView'
import AdminOperatorEdit from 'pages/admin/OperatorEdit'

import AdminAgentList from 'pages/admin/AgentList'
import AdminAgentView from 'pages/admin/AgentView'
import AdminAgentEdit from 'pages/admin/AgentEdit'

import AdminPlayers from 'pages/admin/PlayerList'

import AdminCategories from 'pages/admin/CategoryList'
import AdminCategoryView from 'pages/admin/CategoryView'
import AdminCategoryEdit from 'pages/admin/CategoryEdit'

import AdminGames from 'pages/admin/GameList'

import AdminCredits from 'pages/admin/CreditsList'
import AdminCreditsView from 'pages/admin/CreditsView'
import AdminCreditsAgent from 'pages/admin/CreditsListAgent'
import AdminCreditsAgentView from 'pages/admin/CreditsViewAgent'


import AdminCreditRequests from 'pages/admin/CreditRequestList'
import AdminCreditRequestView from 'pages/admin/CreditRequestView'

import AdminCreditRequestsAgent from 'pages/admin/CreditRequestListAgent'
import AdminCreditRequestViewAgent from 'pages/admin/CreditRequestViewAgent'

import AdminWithdrawals from 'pages/admin/WithdrawalsList'
import AdminWithdrawalView from 'pages/admin/WithdrawalView'
import AdminWithdrawalRequests from 'pages/admin/WithdrawalRequestList'
import AdminWithdrawalRequestView from 'pages/admin/WithdrawalRequestView'

import AdminWithdrawalsAgent from 'pages/admin/WithdrawalsAgentList'
import AdminWithdrawalAgentView from 'pages/admin/WithdrawalAgentView'
import AdminWithdrawalRequestsAgent from 'pages/admin/WithdrawalRequestAgentList'
import AdminWithdrawalRequestAgentView from 'pages/admin/WithdrawalRequestAgentView'

import AdminPageTerms from 'pages/admin/PageTerms'
import AdminPageRules from 'pages/admin/PageRules'

import AdminAccount from 'pages/admin/Account'

import AdminAccountGcash from 'pages/admin/AccountGcashList'
import AdminAccountGcashView from 'pages/admin/AccountGcashView'
import AdminAccountGcashEdit from 'pages/admin/AccountGcashEdit'
//end admin pages

import { refreshToken as operatorRefreshToken, validToken as operatorValidToken } from 'utility/api/operator/axiosOperator';

const AdminContent = () => {
  return (
    <Routes>
      {/* {adminValidToken()} */}
      <Route path={ROUTES.ADMIN_LOGOUT_ROUTE} element={<AdminLogout />} />
      <Route path={ROUTES.ADMIN_HOME_ROUTE} element={<AdminHome />} />

      <Route path={ROUTES.ADMIN_OPERATORS_ROUTE + '/:id'} element={<AdminOperatorList />} />
      <Route path={ROUTES.ADMIN_OPERATOR_VIEW_ROUTE + '/:id'} element={<AdminOperatorView />} />
      <Route path={ROUTES.ADMIN_OPERATOR_EDIT_ROUTE + '/:id'} element={<AdminOperatorEdit />} />

      <Route path={ROUTES.ADMIN_AGENTS_ROUTE + '/:id'} element={<AdminAgentList />} />
      <Route path={ROUTES.ADMIN_AGENT_VIEW_ROUTE + '/:id'} element={<AdminAgentView />} />
      <Route path={ROUTES.ADMIN_AGENT_EDIT_ROUTE + '/:id'} element={<AdminAgentEdit />} />

      <Route path={ROUTES.ADMIN_PLAYERS_ROUTE + '/:id'} element={<AdminPlayers />} />

      <Route path={ROUTES.ADMIN_CATEGORIES_ROUTE + '/:id'} element={<AdminCategories />} />

      <Route path={ROUTES.ADMIN_CATEGORY_VIEW_ROUTE + '/:id'} element={<AdminCategoryView />} />

      <Route path={ROUTES.ADMIN_CATEGORY_EDIT_ROUTE}>
        <Route index element={<AdminCategories />} />
        <Route path=":id" element={<AdminCategoryEdit />} />
      </Route>

      <Route path={ROUTES.ADMIN_GAMES_ROUTE + '/:id/:page'} element={<AdminGames />} />

      <Route path={ROUTES.ADMIN_CREDITS_ROUTE + '/:id'} element={<AdminCredits />} />
      <Route path={ROUTES.ADMIN_CREDIT_VIEW_ROUTE + '/:id'} element={<AdminCreditsView />} />

      <Route path={ROUTES.ADMIN_CREDITS_AGENT_ROUTE + '/:id'} element={<AdminCreditsAgent />} />
      <Route path={ROUTES.ADMIN_CREDIT_AGENT_VIEW_ROUTE + '/:id'} element={<AdminCreditsAgentView />} />

      <Route path={ROUTES.ADMIN_CREDIT_REQUESTS_ROUTE + '/:id'} element={<AdminCreditRequests />} />
      <Route path={ROUTES.ADMIN_CREDIT_REQUEST_VIEW_ROUTE + '/:id'} element={<AdminCreditRequestView />} />

      <Route path={ROUTES.ADMIN_CREDIT_REQUESTS_AGENT_ROUTE + '/:id'} element={<AdminCreditRequestsAgent />} />
      <Route path={ROUTES.ADMIN_CREDIT_REQUESTS_AGENT_VIEW_ROUTE + '/:id'} element={<AdminCreditRequestViewAgent />} />

      <Route path={ROUTES.ADMIN_WITHDRAWALS_ROUTE + '/:id'} element={<AdminWithdrawals />} />
      <Route path={ROUTES.ADMIN_WITHDRAWAL_VIEW_ROUTE + '/:id'} element={<AdminWithdrawalView />} />
      <Route path={ROUTES.ADMIN_WITHDRAWAL_REQUESTS_ROUTE + '/:id'} element={<AdminWithdrawalRequests />} />
      <Route path={ROUTES.ADMIN_WITHDRAWAL_REQUEST_VIEW_ROUTE + '/:id'} element={<AdminWithdrawalRequestView />} />

      <Route path={ROUTES.ADMIN_WITHDRAWALS_AGENT_ROUTE + '/:id'} element={<AdminWithdrawalsAgent />} />
      <Route path={ROUTES.ADMIN_WITHDRAWAL_VIEW_AGENT_ROUTE + '/:id'} element={<AdminWithdrawalAgentView />} />
      <Route path={ROUTES.ADMIN_WITHDRAWAL_REQUESTS_AGENT_ROUTE + '/:id'} element={<AdminWithdrawalRequestsAgent />} />
      <Route path={ROUTES.ADMIN_WITHDRAWAL_REQUEST_VIEW_AGENT_ROUTE + '/:id'} element={<AdminWithdrawalRequestAgentView />} />

      <Route path={ROUTES.ADMIN_PAGE_TERMS_ROUTE} element={<AdminPageTerms />} />
      <Route path={ROUTES.ADMIN_PAGE_RULES_ROUTE} element={<AdminPageRules />} />

      <Route path={ROUTES.ADMIN_ACCOUNT_ROUTE} element={<AdminAccount />} />

      <Route path={ROUTES.ADMIN_ACCOUNT_GCASH_ROUTE + '/:id'} element={<AdminAccountGcash />} />
      <Route path={ROUTES.ADMIN_ACCOUNT_GCASH_VIEW_ROUTE + '/:id'} element={<AdminAccountGcashView />} />
      <Route path={ROUTES.ADMIN_ACCOUNT_GCASH_EDIT_ROUTE + '/:id'} element={<AdminAccountGcashEdit />} />

      <Route path="*" element={<NotFoundPage />} />

    </Routes>
  )
}

export default React.memo(AdminContent)
