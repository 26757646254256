import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Button } from 'react-bootstrap';
import AdminCustomToast from '../../components/admin/AdminCustomToast';
import Form from 'react-bootstrap/Form';
import usePageLogic from '../../components/admin/PageLogic';

const PageTerms: React.FC = () => {
    const {
        showToast, handleCloseToast, handleSubmit

    } = usePageLogic();

    return (
        <>
            <AdminCustomToast show={showToast} close={handleCloseToast} />

            <Container>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        sm={{ span: 12 }}
                        className="text-center mb-5">
                        <h4>Terms & Conditions</h4>
                    </Col>
                </Row>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        sm={{ span: 12 }}
                        className='boxContainer px-5 pt-4 pb-4'>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" >
                                <Form.Label><h6>Title</h6></Form.Label>
                                <Form.Control type="text" placeholder="Terms & Conditions" required />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label><h6>Content</h6></Form.Label>
                                <Form.Control
                                    required
                                    as="textarea"
                                    placeholder="Terms & Condtions goes here"
                                    style={{ height: '25vh' }}
                                />
                            </Form.Group>
                            <div className='text-end'>
                                <Button variant="primary" type='submit'>Update</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container >
        </>
    );
}

export default PageTerms;