import React, { useState } from 'react';

import { clientAxios, refreshToken } from 'utility/api/operator/axiosOperator';
import { API } from '../../resources/api-constants';
import { useParams } from 'react-router-dom';

const useCategoriesLogic = () => {
    //get categories
    const [post, setPost] = React.useState({ data: Array(2) });
    const [error, setError] = useState(null);
    const [total, setTotal] = React.useState(0);

    // function to fetch all table data
    const [currentPage, setCurrentPage] = useState(useParams());
    const [pageNumber, setPageNumber] = useState(currentPage.id);

    if (!pageNumber) {
        setPageNumber('1');
    }

    function getPostData(pageNumber: any) {
        clientAxios.get(
            API.OPERATOR_CATEGORIES_LINK, { 
        }
        ).then((response) => {
            setPost(response.data);
            setTotal(response.data.count);
            setError(null);

        }).catch((error) => {
            // if ('ERR_BAD_RESPONSE' == error.code) {
            //     window.location.reload(); //@TODO
            // }
        });
    }

    //fetch on initial load
    React.useEffect(() => {
        getPostData(pageNumber);
    }, []);


    return {
        post, total, pageNumber
    };
}

export default useCategoriesLogic;