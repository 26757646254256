import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';

import { clientAxios } from 'utility/api/operator/axiosOperator';
import { convertImageToBase64, fileCheck, formatDate } from 'utility/functions';
import { API } from '../../../resources/api-constants';

const OperatorCustomModal = (props: any) => {
    const handleClose = props.close;
    const handleSubmit = props.submit;
    const transaction = props.transaction;
    const show = props.show;
    const gameCategory = props.gameCategory;
    const gameCategoryId = props.categoryId;
    const gameId = props.gameId;
    const stream = props.stream;

    const total = props.total;
    const getPostData = props.getPostData;

    const [pageParams, setPageParams] = useState(useParams());
    const [categoryId, setCategoryId] = useState(pageParams.id);
    const [pageNumber, setPageNumber] = useState(pageParams.page)
    const [error, setError] = useState('');

    const [loading, setLoading] = useState(false);

    const handleSubmitAPI = async function (e: any) {
        e.preventDefault();

        //api call
        if ('add-game' === transaction) {
            setLoading(true);

            let newImage = e.target.image.files[0];
            let gameName = e.target.name.value;

            let imageValid = '';
            let result = true;

            imageValid = fileCheck(newImage);

            // if (imageValid == '') {
            //     newImage = await convertImageToBase64(newImage);
            // }

            if (imageValid === '') {
                let queryType = "sabong";
                if (gameCategoryId == 1) {
                    queryType = 'esports';
                } else if (gameCategoryId == 3) {
                    queryType = 'sports';
                }

                await clientAxios.post(
                    API.OPERATOR_GAMES_CREATE_LINK + '/' + queryType + '/' + categoryId + '/create',
                    {
                        name: gameName,
                        image: newImage
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then((response: any) => {
                    handleSubmit(e, true);
                    window.location.reload();
                }).catch((error: any) => {
                    handleSubmit(e, false);
                    setLoading(false);
                });
            } else {
                setError(imageValid);
                result = false;
                setLoading(false);
            }

        } else if ('add-match' === transaction) {
            let matchName = e.target.name.value;
            let matchDate = e.target.matchDate.value + ' 00:00:00';

            let fightNumber = 0;
            //const arrPostData = JSON.parse(String(localStorage.getItem('arrPostData')));

            // if (total == 0) {
            //     fightNumber = 0;
            // } else {
            //     arrPostData.forEach((v: any) => (v === matchDate && fightNumber++)); //count number of fights during said date
            // }

            // if ('null' == arrPostData || null == arrPostData) {
            //     fightNumber = 0;
            // } else {
            //     arrPostData.forEach((v: any) => (v === matchDate && fightNumber++)); //count number of fights during said date
            // }

            // if (fightNumber == 0) {

            clientAxios.post(
                API.OPERATOR_GAMES_MATCH_CREATE_LINK + '/' + gameId + '/create-match',
                //API.OPERATOR_GAMES_MATCH_CREATE_LINK + '/' + gameCategoryId + '/' + gameId + '/matches/',
                {
                    name: matchName,
                    date: matchDate
                }
            ).then((response: any) => {
                handleSubmit(e, true);
                getPostData(gameId, pageNumber);
                setError('Match Added');
                // window.location.reload();
            }).catch((error: any) => {
                handleSubmit(e, false);
                setError('An error has occured. Try again.');
            });
            // } else {
            //     setError('Match already exists for that date');
            // }
            // window.location.reload();
        }
    }
    //END ADD

    if ('add-game' === transaction) {
        return (

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New Game</Modal.Title>
                </Modal.Header>
                <Form className='addGame' onSubmit={handleSubmitAPI}>
                    <Modal.Body className="">
                        {/* <Form.Group className="mb-3">
                            <Form.Label><span className='h6'>Category</span>: {gameCategory}</Form.Label>
                        </Form.Group> */}
                        <Form.Group className="mb-3">
                            <Form.Label><h6>Game Name</h6></Form.Label>
                            <Form.Control type="text" placeholder="game name" name='name' required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label><h6>Image</h6></Form.Label>
                            <Form.Control type="file" placeholder="Choose File" name='image' required />
                        </Form.Group>
                        <p className='text-center text-danger'>&nbsp; {error}</p>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {(loading)
                            ?
                            <Button variant="primary" type='button' disabled>
                                Submit
                            </Button>
                            :
                            <Button variant="primary" type='submit'>
                                Submit
                            </Button>
                            }
                        
                    </Modal.Footer>
                </Form>
            </Modal>

        );
    } else if ('video-player' === transaction) {

        if (('' === stream) || (null === stream)) {
            return (
                <Modal show={show} onHide={handleClose} className='modal-lg'>
                    <Modal.Header closeButton>
                        <Modal.Title>Video Stream</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="w-100 text-center">
                        No video steam found. Update settings first.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        } else {
            return (
                <Modal show={show} onHide={handleClose} className='modal-lg'>
                    <Modal.Header closeButton>
                        <Modal.Title>Video Stream</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="w-100 text-center">
                        <span dangerouslySetInnerHTML={{ __html: stream }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

            );
        }
    } else if ('add-match' === transaction) {
        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New Match</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmitAPI}>
                    <Modal.Body className="">
                        <Form.Group className="mb-3">
                            <Form.Label><h6>Match Name</h6></Form.Label>
                            <Form.Control type="text" placeholder="match name" name='name' required />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className='d-inline-flex me-2'><h6>Date</h6></Form.Label>
                            <Form.Control type='date' name='matchDate' min="2022-11-01" className='w-75' placeholder="" pattern="\d{4}-\d{2}-\d{2}" required />
                        </Form.Group>
                        <div className='text-danger mt-4 text-center'>{error}</div><br />
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type='submit'>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    } else if ('session' === transaction) {

        return (

            <Modal show={show} className='mt-5 modal-dialog-centered'>
                <Modal.Header className=''>
                    <Modal.Title>Session Expired</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-5 mb-5 pt-5 text-center'>
                    <p>Your session has expired. </p>
                    <p><a href={ROUTES.OPERATOR_LOGIN_ROUTE}>Click here to log in.</a></p>
                </Modal.Body>
            </Modal>
        );
    } else {
        return (
            <></>
        );
    }
}

export default OperatorCustomModal;