import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { Button, Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faClose } from '@fortawesome/free-solid-svg-icons';
import { ROUTES } from '../../resources/routes-constants';

import AdminCustomModal from '../../components/admin/modals/AdminCustomModal';
import AdminCustomToast from '../../components/admin/AdminCustomToast';
import CustomPagination from '../../components/CustomPagination';
import AdminConfirmationModal from '../../components/admin/modals/AdminConfirmationModal';

import useAgentListLogic from '../../components/admin/AgentListLogic';
import { formatDateWithTime, formatNumberToCurrency } from 'utility/functions';
import Loader from 'components/Loader';

const UsersAgentList: React.FC = () => {
    const {
        showToast, handleCloseToast, showToastType,
        show, handleClose, handleSubmit, transaction,
        showConfirmation, handleConfirmationSubmit, handleCloseConfirmation, confirmationType, fullName, id,
        handleConfirmation, handleSearch, resetSearch, handleSearchInput, searchParam,
        handleShow, showCloseButton,
        post, total, pageNumber,
        handleDropdown, dropdownText,
        loadingStatus

    } = useAgentListLogic();

    return (
        <>
            <AdminCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
            <AdminCustomModal show={show} close={handleClose} submit={handleSubmit} transaction={transaction} />
            <AdminConfirmationModal show={showConfirmation} submit={handleConfirmationSubmit} close={handleCloseConfirmation} confirmationType={confirmationType} fullName={fullName} userId={id} />

            <Container>
                <Row>
                    <Col
                        md={{ span: 8, offset: 2 }}
                        sm={{ span: 12 }}
                        className="text-center mb-5">
                        <h4>List of Agents</h4>
                    </Col>
                </Row>
                {loadingStatus ?
                    <Loader />
                    :
                    <>
                        <Row className='mb-3'>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                            >
                                <Row>
                                    <Col
                                        md={{ span: 4 }}
                                        sm={{ span: 12 }}
                                        className="text-start">
                                        <div><Button type="button" className='w-100' onClick={() => handleShow('add')}>Add Agent</Button></div>
                                    </Col>

                                    <Col
                                        md={{ span: 3, offset: 1 }}
                                        sm={{ span: 12 }}
                                        className="text-end me-0 pe-0">
                                        <Dropdown className="justify-content-end me-0 pe-0 w-100">
                                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='w-100'>
                                                {dropdownText}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className='w-100'>
                                                {/* <Dropdown.Item href="#/action-1">All</Dropdown.Item> */}
                                                <Dropdown.Item href="#" onClick={handleDropdown}>All</Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={handleDropdown}>Master Agent</Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={handleDropdown}>Sub Agent</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                    <Col
                                        md={{ span: 4 }}
                                        sm={{ span: 12 }}
                                        className="text-end ms-0">
                                        <Form id='searchForm' className='w-100' onSubmit={handleSearch}>
                                            <Form.Group className="d-inline-flex justify-content-end ms-0 w-100">
                                                <Form.Control id="searchField" type='text' name='searchParam' placeholder="Search Name" className='w-100 ms-0 pe-5' value={searchParam} onChange={handleSearchInput} />
                                                <FontAwesomeIcon icon={faClose} className={'text-dark my-auto d-flex justify-content-end search-icon search-close-button ' + showCloseButton} onClick={resetSearch} />
                                                <FontAwesomeIcon icon={faSearch} className='text-dark my-auto d-flex justify-content-end ps-2' />
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                                className="text-center table-responsive">
                                <Table striped bordered hover variant="dark">
                                    <thead className='bg-dark'>
                                        <tr>
                                            <th>USERNAME</th>
                                            <th>NAME</th>
                                            <th>EMAIL</th>
                                            <th>STATUS</th>
                                            <th>CREDITS</th>
                                            <th>TYPE</th>
                                            <th>LAST LOGIN</th>
                                            <th>ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {post && post.data.map((agent: any, count: any) => (
                                            <tr className='bg-dark p-5 text-white' key={agent.id}>
                                                <td>{agent.username}</td>
                                                <td>{agent.name}</td>
                                                <td>{agent.email}</td>
                                                <td>
                                                    {agent.status === 'active' ? <span className='text-success'>Active</span> : <span className='text-danger'>Inactive</span>}
                                                </td>
                                                <td><span className="text-warning">{formatNumberToCurrency(agent.wallet?.balance)}</span></td>
                                                <td>
                                                    {agent.role.title === 'Master Agent' ? 'MA' : 'SA'}
                                                </td>
                                                {/* <td>{formatDateWithTime(agent.last_login)}</td> */}
                                                <td>{formatDateWithTime(agent.lastLogin)}</td>
                                                <td>
                                                    <a href={ROUTES.ADMIN_AGENT_VIEW_ROUTE + '/' + agent.id} className="me-1">View</a> <br />
                                                    {/* <a href={ROUTES.ADMIN_AGENT_EDIT_ROUTE + '/' + agent.userId} className='mx-1'>Edit</a><br /> */}
                                                    {agent.status === 'active' ?
                                                        <a href='#' className='ms-1' onClick={() => handleConfirmation('deactivate-agent', agent.name, agent.id)}>Deactivate</a> :
                                                        <a href='#' className='ms-1' onClick={() => handleConfirmation('activate-agent', agent.name, agent.id)}>Activate</a>
                                                    }
                                                    <br/>
                                                    <a href='#' className='ms-1' onClick={() => handleConfirmation('reset-agent-password', agent.name, agent.id)}>Reset/Unblock</a>

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                                className="d-flex justify-content-end mb-5">
                                <CustomPagination post={post} total={total} current={pageNumber} />
                            </Col>
                        </Row>
                    </>
                }
            </Container >
        </>
    );
}

export default UsersAgentList;

function updateFormData(arg0: any) {
    throw new Error('Function not implemented.');
}


