import React from 'react'

import TopNavBarAdmin from 'pages/partials/admin/TopNavbarAdmin'
import AdminContent from './AdminContent'

const AdminLayout = () => {
  return (
    <div>
      <TopNavBarAdmin />
        <div className="body flex-grow-1 px-3">
          <AdminContent />
        </div>
    </div>
  )
}

export default AdminLayout