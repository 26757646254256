import React, { useState } from 'react';

import { formatDate } from 'utility/functions';
import { useParams } from 'react-router-dom';
import { clientAxios } from '../../utility/api/admin/axiosCMS';
import { API } from '../../resources/api-constants';


const useWithdrawalRequestViewAgentLogic = () => {
    //get details
    const [id, setId] = useState(useParams());
    const [post, setPost] = React.useState({ data: Array(2) });
    const [error, setError] = useState('');
    const [loadingStatus, setLoadingStatus] = useState(true);

    const [fullName, setFullName] = useState('');
    const [gcashName, setGcashName] = useState('');
    const [gcashNumber, setGcashNumber] = useState('');
    const [status, setStatus] = useState('');
    const [amount, setAmount] = useState('');
    const [image, setImage] = useState('');
    const [remarks, setRemarks] = useState('');

    const [approvedBy, setApprovedBy] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [updatedAt, setUpdatedAt] = useState('');

    React.useEffect(() => {
        const getPostData = async (id: any) => {
            try {
                const response = await clientAxios.get(
                    API.ADMIN_AGENT_WITHDRAWAL_REQUEST_VIEW_LINK + '/' + id.id, {
                })

                setError('');

                setFullName(response.data.data.user.name);
                setGcashName(response.data.data.gcashName);
                setGcashNumber(response.data.data.gcashNumber);
                setStatus(response.data.data.status);
                setAmount(response.data.data.amount);
                //setImage(response.data.data.image);
                setRemarks(response.data.data.remarks);

                //setApprovedBy(response.data.data.approvedBy);
                setCreatedAt(response.data.data.createdAt);
                //setUpdatedAt(response.data.data.updatedAt);

                setLoadingStatus(false);

            } catch (error) {
                setError('An error has occured. Please try again.');
            }
        };
        getPostData(id);

    }, []);
    //end get details

    //confirmation
    const [showConfirmation, setConfirmation] = useState(false);
    const [confirmationType, setConfirmationType] = useState('approveRemarks');

    const [showToast, setShowToast] = useState(false);
    const [showToastType, setShowToastType] = useState('success');

    const handleCloseConfirmation = () => setConfirmation(false);
    const handleCloseToast = () => setShowToast(false);

    const handleApprove = function () {
        setConfirmation(true);

        setConfirmationType('approve-withdrawal-request-agent');
    }

    const handleDisapprove = function () {
        setConfirmation(true);

        setConfirmationType('disapprove-withdrawal-request-agent');
    }

    const handleConfirmationSubmit = function (e: any) {
        setConfirmation(false);
        e.preventDefault();

        setShowToast(true);
    }

    return {
        showConfirmation, handleConfirmationSubmit, handleCloseConfirmation,
        confirmationType, handleApprove, handleDisapprove,
        showToast, handleCloseToast, showToastType,
        setShowToast, setShowToastType,
        id, fullName, status, amount, gcashNumber, gcashName,
        image, remarks,
        approvedBy, createdAt, updatedAt,
        loadingStatus
    };
}

export default useWithdrawalRequestViewAgentLogic;