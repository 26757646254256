import React, { useRef } from 'react';

import { Button, Modal } from 'react-bootstrap';
import { QRCodeCanvas } from "qrcode.react";
import { copyContent, disableButton, formatNumberToCurrency } from 'utility/functions';
import { ROUTES } from 'resources/routes-constants';

const AgentCustomModal = (props: any) => {
    const handleClose = props.close;
    const handleSubmit = props.submit;
    const transaction = props.transaction;

    const agentLink = props.agentLink;

    const image = props.image;
    const amount = props.amount;
    const gcash = props.gcash;
    const gcashName = props.gcashName;

    //QR CODE
    var url = '';

    // const downloadQRCode = (e) => {
    //     e.preventDefault();
    //     url = '';
    // };

    // const qrCodeEncoder = (e) => {
    //     setUrl(e.target.value);
    // };

    const qrcode = (
        <QRCodeCanvas
            id="qrCode"
            value={'http://' + window.location.host + agentLink}
            size={300}
            bgColor={"#FFFFFF"}
            level={"H"}
        />
    );

    const buttonRef = useRef(null);

    //END QR CODE

    if ('buy' === transaction) {
        return (

            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h4 className='mb-4'>Are these correct?</h4>
                    <p className='h5 text-start mb-4'>Amount : <span className='text-warning'>{formatNumberToCurrency(amount)}</span></p>
                    <p className='h5 text-start mb-4'>Sent to (Gcash) : <span className='text-warning'>{gcash}</span></p>
                    <p><img src={image} className='w-75' /></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {/*<Button variant="primary" onClick={handleSubmit}>*/}
                     <Button variant="primary" ref={buttonRef} onClick={() => {handleSubmit(); disableButton(buttonRef)}}> 
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

        );
    } else if ('withdraw' == transaction) {

        return (

            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className='ms-5'>
                    <p>GCASH NAME: {gcashName}</p>
                    <p>GCASH NUMBER: {gcash}</p>
                    <p>AMOUNT: {formatNumberToCurrency(amount)}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" ref={buttonRef} onClick={handleSubmit(gcash, amount, buttonRef)}>
                    {/* <Button variant="primary" onClick={handleSubmit(gcash, amount)}> */}
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else if ('showQR' == transaction) {
        return (
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>INVITE LINK</Modal.Title>
                </Modal.Header>
                <Modal.Body className=''>
                    <p className='h5'>Share the following details:</p>
                    <p className='mt-3 text-center'>Scan QR Code</p>
                    <div className='text-center'>
                        {qrcode}
                    </div>
                    <p className='text-center mt-3'>OR</p>
                    <p className='mt-2 h6'>
                        Share Referral Link <br />
                        <a href={agentLink} className='text-secondary text-break'>{'http://' + window.location.host + agentLink}</a>
                        <a href="#" onClick={() => copyContent('http://' + window.location.host + agentLink)} className='ms-2 text-secondary'>(Copy)</a>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {/* <Button variant="primary" onClick={handleSubmit}>
                        Yes
                    </Button> */}
                </Modal.Footer>
            </Modal>

        );
    } else if ('session' == transaction) {

        return (

            <Modal show={props.show} className='mt-5'>
                <Modal.Header className=''>
                    <Modal.Title>Session Expired</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-5 mb-5 pt-5 text-center'>
                    <p>Your session has expired. </p>
                    <p><a href={ROUTES.AGENT_LOGIN_ROUTE}>Click here to log in.</a></p>
                    {/* <small>NOTE: Click "Remember Me" if you want a longer sesison.</small> */}
                </Modal.Body>
            </Modal>
        );
    } else {
        return (
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>CONFIRM UPDATE</Modal.Title>
                </Modal.Header>
                <Modal.Body className='ms-5'>
                    Are you sure?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

        );
    }
}

export default AgentCustomModal;