import React, { useState } from 'react'
import { useEffect } from 'react'

import { ROUTES } from '../../../resources/routes-constants'
import { API } from '../../../resources/api-constants';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

//font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faHouse, faQuestion,
    faCircleExclamation, faPenToSquare, faList, faRightFromBracket, faHandHoldingHeart, faUserAstronaut, faUserNinja, faUser, faGamepad
} from '@fortawesome/free-solid-svg-icons'

import { useNavigate } from 'react-router-dom';
import { clientAxios, refreshAuthLogic, refreshToken, validToken } from '../../../utility/api/admin/axiosCMS';


function TopNavBarAdmin() {
    const homeRedirect = useNavigate();

    useEffect(() => {
        //check if correct user
        const userSession = localStorage.getItem('user-session');
        
        if (userSession !== API.ADMIN_CODE) {
            window.location.href = ROUTES.ADMIN_LOGIN_ROUTE;
        }

        const loggedInUser = localStorage.getItem('access-token');
        const loginDate = localStorage.getItem('login-date');
        //const expires = (Number(localStorage.getItem('expires')) * 1000);
        //const expireDate = expires + Number(loginDate);
        //const timeToRefreshToken = (Number(expireDate) - Date.now() - API.REFRESH_TOKEN_BUFFER);

        //check if token is valid
        //validToken();
        //end check if token is valid

        if (!loggedInUser) { // not logged in, redirect
            localStorage.clear();
            window.location.href = ROUTES.ADMIN_LOGIN_ROUTE;
        }
    }, []);

    return (
        <div>
            {[false].map((expand) => (
                <Navbar key={null} expand={expand} className="mb-5 bg-danger">
                    <Container fluid className="topNav-container">

                        <Container fluid>
                            <Row>
                                <Col md={1}>
                                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className='' />
                                </Col>
                                <Col md={8} className='d-none d-md-block mt-2'>
                                    <Navbar.Brand href={ROUTES.ADMIN_ACCOUNT_ROUTE} className='m-0 p-0'>
                                        <Row>
                                            <Col sm={4} className='h5'>Welcome, Admin.</Col>
                                        </Row>
                                    </Navbar.Brand>
                                </Col>
                            </Row>
                        </Container>
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="start"
                            style={{ maxWidth: '300px' }}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    Admin Portal
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <Nav.Link href={ROUTES.ADMIN_HOME_ROUTE} className='p-1'>
                                        <FontAwesomeIcon icon={faHouse} className='me-3' />Dashboard
                                    </Nav.Link>

                                    <Navbar.Text className='mt-3'>USERS</Navbar.Text>
                                    <Nav.Link href={ROUTES.ADMIN_OPERATORS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faUserAstronaut} className='me-3' />Operators</Nav.Link>
                                    <Nav.Link href={ROUTES.ADMIN_AGENTS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faUserNinja} className='me-3' />Agents</Nav.Link>
                                    <Nav.Link href={ROUTES.ADMIN_PLAYERS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faUser} className='me-3' />Players</Nav.Link>

                                    <Navbar.Text className='mt-3'>GAMES</Navbar.Text>
                                    <Nav.Link href={ROUTES.ADMIN_CATEGORIES_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faGamepad} className='me-3' />Categories</Nav.Link>

                                    <Navbar.Text className='mt-3'>CREDITS</Navbar.Text>
                                    {/* <Nav.Link href='#' className='p-1'><FontAwesomeIcon icon={faUpload} className='me-3' />Send Credits</Nav.Link> */}
                                    <Nav.Link href={ROUTES.ADMIN_CREDIT_REQUESTS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faHandHoldingHeart} className='me-3' />Player Requests</Nav.Link>
                                    <Nav.Link href={ROUTES.ADMIN_CREDIT_REQUESTS_AGENT_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faHandHoldingHeart} className='me-3' />Agent Requests</Nav.Link>
                                    <Nav.Link href={ROUTES.ADMIN_CREDITS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faList} className='me-3' />Player History</Nav.Link>
                                    <Nav.Link href={ROUTES.ADMIN_CREDITS_AGENT_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faList} className='me-3' />Agent History</Nav.Link>
                                    
                                    <Navbar.Text className='mt-3'>WITHDRAWALS</Navbar.Text>
                                    <Nav.Link href={ROUTES.ADMIN_WITHDRAWAL_REQUESTS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faHandHoldingHeart} className='me-3' />Player Requests</Nav.Link>
                                    <Nav.Link href={ROUTES.ADMIN_WITHDRAWAL_REQUESTS_AGENT_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faHandHoldingHeart} className='me-3' />Agent Requests</Nav.Link>
                                    <Nav.Link href={ROUTES.ADMIN_WITHDRAWALS_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faList} className='me-3' />Player History</Nav.Link>
                                    <Nav.Link href={ROUTES.ADMIN_WITHDRAWALS_AGENT_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faList} className='me-3' />Agent History</Nav.Link>


                                    {/* <Navbar.Text className='mt-3'>PAGES</Navbar.Text>
                                    <Nav.Link href={ROUTES.ADMIN_PAGE_TERMS_ROUTE} className='p-1'><FontAwesomeIcon icon={faQuestion} className='me-3' />Terms & Conditions</Nav.Link>
                                    <Nav.Link href={ROUTES.ADMIN_PAGE_RULES_ROUTE} className='p-1'><FontAwesomeIcon icon={faCircleExclamation} className='me-3' />Rules</Nav.Link> */}

                                    <Navbar.Text className='mt-3'>ACCOUNT SETTINGS</Navbar.Text>

                                    <Nav.Link href={ROUTES.ADMIN_ACCOUNT_ROUTE} className='p-1'><FontAwesomeIcon icon={faPenToSquare} className='me-3' />Edit Account</Nav.Link>
                                    <Nav.Link href={ROUTES.ADMIN_ACCOUNT_GCASH_ROUTE + '/1'} className='p-1'><FontAwesomeIcon icon={faPenToSquare} className='me-3' />Gcash Accounts</Nav.Link>
                                    <Nav.Link href={ROUTES.ADMIN_LOGOUT_ROUTE} className='p-1'><FontAwesomeIcon icon={faRightFromBracket} className='me-3' />Sign Out</Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </div>
    );
}

export default TopNavBarAdmin;