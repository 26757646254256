export const ROUTES = {
    HOMEPAGE_ROUTE: './',
    LOGIN_ROUTE: '/',
    REGISTER_ROUTE: '/register',
    REGISTER_SUCCESS_ROUTE: '/register-success',
    FORGOTPASSWORD_ROUTE: '/forgot-password',
    FORGOTPASSWORD_SUCCESS_ROUTE: '/forgot-password-success',
    NEWPASSWORD_ROUTE: '/new-password',
    NEWPASSWORD_SUCCESS_ROUTE: '/new-password-success',

    // third party integration
    API_RESPONSE_CONNECT: '/connect/connect',
    API_RESPONSE_CATEGORIES: '/connect/categories',
    API_RESPONSE_GAMES: '/connect/games',
    API_RESPONSE_PLAY: '/connect/play',

    // members
    AUTHORIZE_ROUTE: '/authorize',
    HOME_ROUTE: '/home',
    GAMES_ROUTE: '/games',
    PLAY_ROUTE: '/play',
    DEPOSIT_ROUTE: '/deposit',
    WITHDRAW_ROUTE: '/withdraw',
    DEPOSITS_ROUTE: '/deposits',
    WITHDRAWALS_ROUTE: '/withdrawals',
    TUTORIAL_ROUTE: '/tutorial',
    RULES_ROUTE: '/rules',
    CONTACT_ROUTE: '/contact',
    ACCOUNT_ROUTE: '/account',
    DEPOSIT_HISTORY_ROUTE: '/deposit-history',
    WITHDRAWAL_HISTORY_ROUTE: '/withdrawal-history',
    VERIFY_ROUTE: '/verify',

    PLAYER_LOGOUT_ROUTE: '/logout',

    IMAGE_PATH: process.env.REACT_APP_S3_URL + '/players',

    // admin
    ADMIN_LOGIN_ROUTE: '/admin/',
    ADMIN_LOGOUT_ROUTE: '/admin/logout',
    ADMIN_FORGOTPASSWORD_ROUTE: '/admin/forgot-password',

    ADMIN_HOME_ROUTE: '/admin/home',

    ADMIN_OPERATORS_ROUTE: '/admin/operator/list',
    ADMIN_OPERATOR_VIEW_ROUTE: '/admin/operator/view',
    ADMIN_OPERATOR_EDIT_ROUTE: '/admin/operator/edit',

    ADMIN_AGENTS_ROUTE: '/admin/agent/list',
    ADMIN_AGENT_VIEW_ROUTE: '/admin/agent/view',
    ADMIN_AGENT_EDIT_ROUTE: '/admin/agent/edit',

    ADMIN_PLAYERS_ROUTE: '/admin/player/list',

    ADMIN_CATEGORIES_ROUTE: '/admin/category/list',
    ADMIN_CATEGORY_VIEW_ROUTE: '/admin/category/view',
    ADMIN_CATEGORY_EDIT_ROUTE: '/admin/category/edit',

    ADMIN_GAMES_ROUTE: '/admin/games/list',

    ADMIN_CREDITS_ROUTE: '/admin/credit/list',
    ADMIN_CREDIT_VIEW_ROUTE: '/admin/credit/view',
    ADMIN_CREDITS_AGENT_ROUTE: '/admin/credit/agent/list',
    ADMIN_CREDIT_AGENT_VIEW_ROUTE: '/admin/credit/agent/view',
    
    ADMIN_CREDIT_REQUESTS_AGENT_ROUTE: '/admin/credit/agent/request/list',
    ADMIN_CREDIT_REQUESTS_AGENT_VIEW_ROUTE: '/admin/credit/agent/request/view',
    ADMIN_CREDIT_REQUESTS_ROUTE: '/admin/credit/request/list',
    ADMIN_CREDIT_REQUEST_VIEW_ROUTE: '/admin/credit/request/view',

    ADMIN_WITHDRAWALS_ROUTE: '/admin/withdrawal/list',
    ADMIN_WITHDRAWAL_VIEW_ROUTE: '/admin/withdrawal/view',
    ADMIN_WITHDRAWAL_REQUESTS_ROUTE: '/admin/withdrawal/request/list',
    ADMIN_WITHDRAWAL_REQUEST_VIEW_ROUTE: '/admin/withdrawal/request/view',

    ADMIN_WITHDRAWALS_AGENT_ROUTE: '/admin/withdrawal/agent/list',
    ADMIN_WITHDRAWAL_VIEW_AGENT_ROUTE: '/admin/withdrawal/agent/view',
    ADMIN_WITHDRAWAL_REQUESTS_AGENT_ROUTE: '/admin/withdrawal/agent/request/list',
    ADMIN_WITHDRAWAL_REQUEST_VIEW_AGENT_ROUTE: '/admin/withdrawal/agent/request/view',

    ADMIN_PAGE_TERMS_ROUTE: '/admin/terms',
    ADMIN_PAGE_RULES_ROUTE: '/admin/rules',

    ADMIN_ACCOUNT_ROUTE: '/admin/account',
    
    ADMIN_ACCOUNT_GCASH_ROUTE: '/admin/account/gcash/list',
    ADMIN_ACCOUNT_GCASH_VIEW_ROUTE: '/admin/account/gcash/view',
    ADMIN_ACCOUNT_GCASH_EDIT_ROUTE: '/admin/account/gcash/edit',

    ADMIN_IMAGE_PATH: process.env.REACT_APP_S3_URL + '/admin',
    //end admin

    //operators
    OPERATOR_LOGIN_ROUTE: '/operator/',
    OPERATOR_LOGOUT_ROUTE: '/operator/logout',

    OPERATOR_ACCOUNT_ROUTE: '/operator/account',

    OPERATOR_HOME_ROUTE: '/operator/home',
    OPERATOR_CATEGORIES_ROUTE: '/operator/categories',

    OPERATOR_GAMES_ROUTE: '/operator/game/list',
    OPERATOR_GAME_VIEW_ROUTE: '/operator/game/view',
    OPERATOR_GAME_EDIT_ROUTE: '/operator/game/edit',
    
    OPERATOR_MATCHES_ROUTE: '/operator/game/matches',
    OPERATOR_MATCH_VIEW_ROUTE: '/operator/game/match',
    //end operators
    
    //agents
    AGENT_LOGIN_ROUTE: '/agent/',
    AGENT_FORGOTPASSWORD_ROUTE: '/agent/forgot-password',
    AGENT_FORGOTPASSWORD_SUCCESS_ROUTE: '/agent/forgot-password-success',
    AGENT_LOGOUT_ROUTE: '/agent/logout',

    AGENT_HOME_ROUTE: '/agent/dashboard',
    // AGENT_CREDITS_SEND_ROUTE: '/agent/send',
    AGENT_CREDITS_BUY_ROUTE: '/agent/buy',
    AGENT_WITHDRAW_ROUTE: '/agent/withdraw',
    AGENT_VERIFY_ROUTE: '/agent/verify',
    AGENT_WITHRAWALS_ROUTE: '/agent/withdrawals',
    AGENT_TRANSACTIONS_ROUTE: '/agent/transactions',
    AGENT_STATS_ROUTE: '/agent/stats',

    AGENT_COMMISSION_CONVERT_ROUTE: '/agent/commission/convert',
    AGENT_COMMISSION_TRANSACTIONS_ROUTE: '/agent/commission/transactions',

    AGENT_DOWNLINES_ROUTE: '/agent/downline/list',
    AGENT_DOWNLINE_REQUESTS_ROUTE: '/agent/downline/requests',
    AGENT_DOWNLINE_TRANSACTIONS_ROUTE: '/agent/downline/transactions',

    AGENT_DOWNLINE_REQUEST_VIEW_ROUTE: '/agent/downline/requests/view',
    AGENT_DOWNLINE_TRANSACTION_VIEW_ROUTE: '/agent/downline/transactions/view',


    AGENT_ACCOUNT_ROUTE: '/agent/account',

    AGENT_IMAGE_PATH: process.env.REACT_APP_S3_URL + '/players',
    //end agents
    
}