import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import CustomPagination from '../../components/CustomPagination';

import AgentCustomToast from 'components/agents/CustomToast';
import AgentConfirmationModal from 'components/agents/modals/AgentConfirmationModal';
import useDownlineListLogic from 'components/agents/AgentDownlineListLogic';
import { formatNumberToCurrency } from 'utility/functions';
import Loader from 'components/Loader';

const AgentDownlineList: React.FC = () => {
    const {
        showConfirmation, handleConfirmationSubmit, handleCloseConfirmation,
        confirmationType,
        handleApprove, downlineName, downlineId,
        showToast, handleCloseToast, showToastType,
        post, total, pageNumber,
        loadingStatus
    } = useDownlineListLogic();

    if (!(post) || (0 === total)) {
        return (
            <>
                {loadingStatus ?
                    <Loader />
                    :
                    <Container >
                        <Row>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                                className="text-center mb-5">
                                <h4>No Items Found</h4>
                            </Col>
                        </Row>
                    </Container>
                }
            </>
        );

    } else {

        return (
            <>
                <AgentCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
                <AgentConfirmationModal
                    show={showConfirmation}
                    submit={handleConfirmationSubmit}
                    close={handleCloseConfirmation}
                    downlineId={downlineId}
                    downlineName={downlineName}
                    confirmationType={confirmationType}
                />

                <Container>
                    <Row>
                        <Col
                            md={{ span: 10, offset: 1 }}
                            sm={{ span: 12 }}
                            className="text-center mb-5">
                            <h4>Downline <span className='highlightText px-2'>List</span></h4>
                        </Col>
                    </Row>

                    {/* <Row className='mb-3 text-end'>
                    <Col
                        md={{ span: 3, offset: 7 }}
                        xs={{ span: 12 }}
                    >
                        <Form className='w-100 d-inline-flex justify-content-end'>
                            <Form.Group className="d-inline-flex justify-content-end ms-0 w-100">
                                <Form.Control type='text' placeholder="Search Name" className='w-100 ms-0' />
                                <FontAwesomeIcon icon={faSearch} className='text-dark my-auto d-inline-flex justify-content-end search-icon' />
                            </Form.Group>
                        </Form>

                    </Col>
                </Row> */}
                    {loadingStatus ?
                        <Loader />
                        :
                        <>
                            <Row>
                                <Col
                                    md={{ span: 10, offset: 1 }}
                                    sm={{ span: 12 }}
                                    className="text-center table-responsive">
                                    <Table striped bordered hover variant="dark">
                                        <thead className='bg-dark'>
                                            <tr>
                                                <th>USERNAME</th>
                                                <th>NAME</th>
                                                <th>TYPE</th>
                                                <th>EMAIL</th>
                                                <th>CREDITS</th>
                                                {localStorage.getItem('user-role') === 'Master Agent'
                                                    ?
                                                    < th > ACTIONS</th>
                                                    :
                                                    <></>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {post && post.data.map((downline: any, count: any) => (
                                                <tr className='bg-dark p-5 text-white' key={downline.id}>
                                                    <td>{downline.username}</td>
                                                    <td>{downline.name}</td>
                                                    <td>
                                                        {'Player' === downline.role.title ?
                                                            <span>Player</span>
                                                            :
                                                            <span>Sub Agent</span>
                                                        }
                                                    </td>
                                                    <td>{downline.email}</td>
                                                    <td>
                                                        <span className='text-warning'>
                                                            {formatNumberToCurrency(downline.wallet.balance)}
                                                        </span>
                                                    </td>
                                                    {localStorage.getItem('user-role') === 'Master Agent'
                                                        ?
                                                        <td>
                                                            {'Player' === downline.role.title ?
                                                                <a href='#' onClick={() => handleApprove(downline.id, downline.name)}>Promote</a>
                                                                :
                                                                <span className='text-success'>Promoted</span>
                                                            }
                                                        </td>
                                                        :
                                                        <></>
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    md={{ span: 10, offset: 1 }}
                                    sm={{ span: 12 }}
                                    className="d-flex justify-content-end mb-5">
                                    <CustomPagination post={post} total={total} current={pageNumber} />
                                </Col>
                            </Row>
                        </>
                    }
                </Container >
            </>
        );
    }
}

export default AgentDownlineList;