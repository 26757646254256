import React from 'react';

import { ROUTES } from '../../resources/routes-constants';

import useOperatorLogoutLogic from 'components/operator/OperatorLogoutLogic';

const Logout: React.FC = () => {
    useOperatorLogoutLogic();
    return (
        <>
        </>
    )
}

export default Logout