import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ROUTES } from '../../resources/routes-constants';
import { Button, FormGroup, Table } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';

import useOperatorMatchLogic from 'components/operator/OperatorMatchLogic';
import OperatorCustomModal from 'components/operator/modals/OperatorCustomModal';
import OperatorConfirmationModal from 'components/operator/modals/OperatorConfirmationModal';
import OperatorCustomToast from 'components/operator/OperatorCustomToast';
import OperatorBetModal from 'components/operator/modals/OperatorBetModal';

const MatchView: React.FC = () => {
    const {
        showToast, handleCloseToast, showToastType,
        show, handleClose, handleShow,
        showBet, handleCloseBet, handleShowBet,
        handleSubmit, handleNextMatch,
        error,
        id, categoryId, gameID, matchName, fightNumber, betStatus, matchResult, settleStatus,
        status, stream, team1, team2, matchDate,
        createdBy, createdAt, updatedAt,
        pusherEventKey,
        handleBetSubmit, betStatusLabel, betStatusUpdate,
        previousMatch, nextMatch,
        handleOpenConfirmation, showResultConfirmation, handleResultCloseConfirmation, confirmationType,
        loadingStatus
    } = useOperatorMatchLogic();

    if (loadingStatus)
        return null;

    return (
        <>
            <OperatorCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
            <OperatorCustomModal show={show} close={handleClose} transaction='video-player' stream={stream} />
            <OperatorBetModal
                showBet={showBet} closeBet={handleCloseBet} categoryId={categoryId} gameId={gameID} matchId={id} team1={team1} team2={team2}
                pusherEventKey={pusherEventKey} betStatus={betStatus}
            />

            <OperatorConfirmationModal
                show={showResultConfirmation}
                submit={handleBetSubmit}
                close={handleResultCloseConfirmation}
                confirmationType={confirmationType}
                fullName={betStatusLabel}
                betStatusUpdate={betStatusUpdate}
                matchId={id}
                gameID={gameID}
                team1={team1}
                team2={team2}
            />

            <Container>
                <Row className='mb-2'>
                    <Col
                        md={{ span: 6 }}
                    >
                        <span className='h4'> FIGHT #{fightNumber}</span>
                    </Col>
                    <Col
                        md={{ span: 6 }}
                        className='text-end'
                    >
                        {(previousMatch !== null) && (previousMatch !== undefined) ?
                            <a href='#' onClick={handleNextMatch('prev', previousMatch)}>
                                <span className='btn btn-secondary'>
                                    <FontAwesomeIcon icon={faArrowLeft} className='me-2' />
                                    Previous
                                </span>
                            </a>
                            :
                            <>
                                <span className='btn btn-secondary ms-5 disabled'>
                                    <FontAwesomeIcon icon={faArrowLeft} className='me-2' />
                                    Previous
                                </span>
                            </>
                        }

                        {('Settled' === settleStatus) ?
                            <>
                                <a href='#' onClick={handleNextMatch('next', nextMatch)}>
                                    <span className='btn btn-secondary ms-5'>
                                        Next
                                        <FontAwesomeIcon icon={faArrowRight} className='ms-2' />
                                    </span>
                                </a>
                            </>
                            :
                            <>
                                <span className='btn btn-secondary ms-5 disabled'>
                                    Next
                                    <FontAwesomeIcon icon={faArrowRight} className='ms-2' />
                                </span>
                            </>
                        }
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col
                        md={{ span: 12 }}
                        className='overflow-auto'
                    >
                        <Table striped bordered hover variant="dark">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>DATE</th>
                                    <th>GAME</th>
                                    <th>NAME</th>
                                    <th>BET STATUS</th>
                                    <th>WINNER</th>
                                    <th>SETTLE STATUS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='bg-dark p-5 text-white '>
                                    <td>{id}</td>
                                    <td>{matchDate}</td>
                                    <td>{gameID}</td>
                                    <td>{matchName}</td>
                                    <td>
                                        {(1 === betStatus) ?
                                            <span className='text-success animate-show-text' key={1}>Open</span> :
                                            (3 === betStatus) ?
                                                <span className='text-warning blinkText' key={2}>Last Call</span> :
                                                (2 === betStatus) ?
                                                    <span className='text-danger animate-show-text' key={0}>Closed</span> :
                                                    <span className='text-danger animate-show-text' key={3}>Pending</span>
                                        }
                                    </td>
                                    <td>
                                        {("red" === matchResult) ?
                                            <span className='text-danger'>{team2}</span> :
                                            ("blue" === matchResult) ?
                                                <span className='text-primary'>{team1}</span> :
                                                ("draw" === matchResult) ?
                                                    <span className='text-warning'>Draw</span> :
                                                    ("cancelled" === matchResult) ?
                                                        <span className='text-secondary'>Cancelled</span> :
                                                        <span className=''>Pending</span>
                                        }
                                    </td>
                                    <td>
                                        {'Settled' === settleStatus ?
                                            <span className='text-success'>Settled</span> :
                                            <span className='text-danger'>Not Settled</span>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col
                        sm={{ span: 12 }}
                        className='mb-2 d-flex justify-content-center'>

                        {/* {false == stream.includes('iframe') ?
                            <iframe width="560" height="315"
                                src={stream}>
                            </iframe> :
                            <span dangerouslySetInnerHTML={{ __html: stream }} />
                        } */}
                        <span dangerouslySetInnerHTML={{ __html: stream }} />

                    </Col>
                </Row>
                <Row className='mb-5'>
                    <Col md={{ span: 6 }}>
                        <Row>
                            <Col
                                md={{ span: 12 }}
                                className="text-center pb-2 bg-dark">
                                <Row className='mb-4 mt-4'>
                                    <span className='h5'>INFORMATION</span>
                                </Row>
                                <Row>
                                    <Col md={{ span: 12 }} className="pb-2">
                                        <a href='#' onClick={handleShowBet}><span className='btn btn-secondary w-100'>View Bets</span></a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='d-none d-md-block operatorMatchViewForm'>
                            <Col
                                md={{ span: 12 }}
                                className="text-center pb-2 bg-dark">
                                <Form onSubmit={handleSubmit}>
                                    <Row className='mb-2'>
                                        <Col className='pt-2'><h5 className='text-center w-100'>SETTINGS</h5></Col>
                                    </Row>
                                    <Row className='pb-2'>
                                        <Col md={{ span: 6 }} className='text-start'>
                                            <FormGroup>
                                                <Form.Label className='d-inline-flex me-2'><h6>Name</h6></Form.Label>
                                                <Form.Control type='text' name='matchName' className='w-75 d-inline-flex' placeholder="Match Name" key={matchName} defaultValue={matchName} required />
                                            </FormGroup>
                                        </Col>
                                        <Col md={{ span: 6 }} className='text-start'>
                                            <FormGroup>
                                                <Form.Label className='d-inline-flex me-2'><h6>Date</h6></Form.Label>
                                                <Form.Control type='text' name='matchDate' className='w-75 d-inline-flex bg-secondary' placeholder="Match Name" key={matchDate} defaultValue={matchDate} disabled />
                                                {/* <Form.Control type='date' name='matchDate' className='w-75 d-inline-flex' placeholder="" value={matchDate} required /> */}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={{ span: 12 }} className="pb-2">
                                            <h6 className='text-start'>Stream iFrame</h6>
                                            <textarea rows={4} className='w-100' name='stream' key={stream} defaultValue={stream} required>
                                            </textarea>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col md={{ span: 6 }} className='text-start'>
                                            <Form.Label><h6 className='text-primary'>Team 1 Name</h6></Form.Label>
                                            <Form.Control type='text' name='teamName1' placeholder="Team 1"
                                                defaultValue={team1 ? team1 : 'Wala'}
                                                key={team1} required />
                                        </Col>
                                        <Col md={{ span: 6 }} className='text-start mb-2'>
                                            <Form.Label><h6 className='text-danger'>Team 2 Name</h6></Form.Label>
                                            <Form.Control type='text' name='teamName2' placeholder="Team 2"
                                                defaultValue={team2 ? team2 : 'Meron'}
                                                key={team2}
                                                required />
                                        </Col>

                                    </Row>
                                    <Row className='mt-3'>
                                        <Col md={{ span: 6 }} className='text-start'>
                                            <Form.Label><h6 className='text-primary'>Team 1 Logo</h6></Form.Label>
                                            <Form.Control type="file" placeholder="Choose File" />
                                        </Col>
                                        <Col md={{ span: 6 }} className='text-start mb-2'>
                                            <Form.Label><h6 className='text-danger'>Team 2 Logo</h6></Form.Label>
                                            <Form.Control type="file" placeholder="Choose File" />
                                        </Col>
                                        <Form.Group>
                                            <Form.Label className='d-flex justify-content-center mt-4'><span className='text-danger'>&nbsp;{error}</span></Form.Label>
                                        </Form.Group>
                                        {('Not Settled' === settleStatus) ?
                                            <p className='text-middle mt-2'><Button className="w-50" type="submit">Update</Button></p>
                                            :
                                            <p className='text-middle mt-2'><Button className="w-50" type="button" disabled>Update</Button></p>
                                        }
                                    </Row>
                                </Form>
                            </Col>

                        </Row>
                    </Col>

                    <Col
                        md={{ span: 6 }}
                        className="text-center pb-2">
                        <Row>
                            <Col
                                md={{ span: 12 }}>
                                <Row className='mb-4'>
                                    <Col className='pb-2 pt-2'><h5>BET STATUS</h5></Col>
                                </Row>
                                <Row>
                                    <Col md={{ span: 12 }} className="pb-2 mb-3">
                                        {('' === stream || null === stream || 'Settled' === settleStatus) ?
                                            <span className='btn btn-success w-100 disabled'>Open</span>
                                            :
                                            (0 === betStatus || null === betStatus) ?
                                                //true ?
                                                <a href='#' onClick={() => handleOpenConfirmation('bet-status', id, 1)}><span className='btn btn-success w-100'>Open</span></a>
                                                :
                                                <span className='btn btn-success w-100 disabled'>Open</span>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={{ span: 12 }} className="pb-2 mb-3">
                                        {('' === stream || null === stream || '1' === settleStatus) ?
                                            <span className='btn btn-warning w-100 disabled'>Last Call</span>
                                            :
                                            (1 === betStatus) ?
                                                //true ?
                                                <a href='#' onClick={() => handleOpenConfirmation('bet-status', id, 3)}><span className='btn btn-warning w-100'>Last Call</span></a>
                                                :
                                                <span className='btn btn-warning w-100 disabled'>Last Call</span>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={{ span: 12 }} className="pb-2">
                                        {('' === stream || null == stream) ?
                                            <span className='btn btn-danger w-100 disabled'>Close</span>
                                            :
                                            (3 === betStatus) ?
                                                //true ?
                                                <a href='#' onClick={() => handleOpenConfirmation('bet-status', id, 2)}><span className='btn btn-danger w-100'>Close</span></a>
                                                :
                                                <span className='btn btn-danger w-100 disabled'>Close</span>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                md={{ span: 12 }}>
                                <Row className='mt-4'>
                                    <Col className='pb-2'><h5>RESULT</h5></Col>
                                </Row>
                                <Row className='mb-3'>
                                    <Col md={{ span: 6 }} className="pb-2">
                                        {((null === matchResult) && (2 === betStatus) || ("" === matchResult) && (2 === betStatus)) ?
                                            <><a href='#' onClick={() => handleOpenConfirmation('match-result', id, 'blue')}><span className='btn btn-outline-primary btn-outline-primary-ggwp w-100'>Team Blue</span></a></>
                                            :
                                            <span className='btn btn-outline-primary btn-outline-primary-ggwp w-100 disabled'>Team Blue</span>
                                        }
                                    </Col>
                                    <Col md={{ span: 6 }} className="pb-2">
                                        {((null === matchResult) && (2 === betStatus) || ("" === matchResult) && (2 === betStatus)) ?
                                            <><a href='#' onClick={() => handleOpenConfirmation('match-result', id, 'red')}><span className='btn btn-outline-danger w-100'>Team Red</span></a></>
                                            :
                                            <span className='btn btn-outline-danger w-100 disabled'>Team Red</span>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={{ span: 6 }} className="pb-2">
                                        {((null === matchResult) && (2 === betStatus) || ("" === matchResult) && (2 === betStatus)) ?
                                            <><a href='#' onClick={() => handleOpenConfirmation('match-result', id, 'draw')}><span className='btn btn-outline-warning w-100'>Draw</span></a></>
                                            :
                                            <span className='btn btn-outline-warning w-100 disabled'>Draw</span>
                                        }

                                    </Col>
                                    <Col md={{ span: 6 }} className="pb-4">
                                        {((null === matchResult) && (2 === betStatus) || ("" === matchResult) && (2 === betStatus)) ?
                                            <><a href='#' onClick={() => handleOpenConfirmation('match-result', id, 'cancelled')}><span className='btn btn-outline-secondary w-100'>Cancelled</span></a></>
                                            :
                                            <span className='btn btn-outline-secondary w-100 disabled'>Cancelled</span>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={{ span: 12 }} className="pb-2">
                                        {/*{('' === stream || null === stream || 'Not Settled' === settleStatus) ?*/}
                                        {/*{true ?*/}
                                        {/*    <span className='btn btn-secondary w-75 disabled'>Reset</span>*/}
                                        {/*    :*/}
                                        {/*    //(null === matchResult) && ('Settled' === settleStatus) ?*/}
                                        {/*    (0) ?*/}
                                        {/*        <span className='btn btn-secondary w-75 disabled'>Reset</span>*/}
                                        {/*        :*/}
                                        {/*        <><a href='#' onClick={() => handleOpenConfirmation('reset-result', id, '')} ><span className='btn btn-secondary w-75'>Reset</span></a></>*/}
                                        {/*}*/}
                                        <><a href='#' onClick={() => handleOpenConfirmation('reset-result', id, '')} ><span className='btn btn-secondary w-75'>Reset</span></a></>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={{ span: 12 }} className="mt-2">
                                        <hr />
                                        {('' === stream || null === stream) ?
                                            <span className='btn btn-primary w-75 mt-3 disabled'>Settle Match</span>
                                            :
                                            // (((null == settleStatus) && (null == matchResult)) || ((null == settleStatus) && ('' == matchResult))) ?
                                            ((null === matchResult) || ("" === matchResult)) ?
                                                <span className='btn btn-primary w-75 mt-3 disabled'>Settle Match</span>
                                                :
                                                (('Not Settled' === settleStatus)) ?
                                                    <a href='#' onClick={() => handleOpenConfirmation('settle-match', id, matchResult)}><span className='btn btn-primary w-75 mt-3'>Settle Match</span></a>
                                                    :
                                                    <span className='btn btn-primary w-75 mt-3 disabled'>Match Settled</span>

                                        }
                                    </Col>
                                </Row>
                                {/* SHOW THIS ON MOBILE */}
                                <Row>
                                    <Col md={{ span: 12 }}
                                        className='d-md-none d-lg-none d-xl-none d-xxl-none'
                                    >
                                        <Row>
                                            <Col
                                                md={{ span: 12 }}
                                                className="text-center pb-2 bg-dark mb-4 mt-4">
                                                <hr />
                                                <Form onSubmit={handleSubmit}>
                                                    <Row className='mb-2'>
                                                        <Col className='pt-2'><h5 className='text-center w-100'>SETTINGS</h5></Col>
                                                    </Row>
                                                    <Row className='pb-2'>
                                                        <Col md={{ span: 6 }} className='text-start'>
                                                            <FormGroup>
                                                                <Form.Label className='d-inline-flex me-2'><h6>Name</h6></Form.Label>
                                                                <Form.Control type='text' name='matchName' className='w-75 d-inline-flex' placeholder="Match Name" key={matchName} defaultValue={matchName} required />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={{ span: 6 }} className='text-start'>
                                                            <FormGroup>
                                                                <Form.Label className='d-inline-flex me-2'><h6>Date</h6></Form.Label>
                                                                <Form.Control type='text' name='matchDate' className='w-75 d-inline-flex bg-secondary' placeholder="Match Name" key={matchDate} defaultValue={matchDate} disabled />
                                                                {/* <Form.Control type='date' name='matchDate' className='w-75 d-inline-flex' placeholder="" value={matchDate} required /> */}
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={{ span: 12 }} className="pb-2">
                                                            <h6 className='text-start'>Stream iFrame</h6>
                                                            <textarea rows={4} className='w-100' name='stream' key={stream} defaultValue={stream} required>
                                                            </textarea>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <Col md={{ span: 6 }} className='text-start'>
                                                            <Form.Label><h6 className='text-primary'>Team 1 Name</h6></Form.Label>
                                                            <Form.Control type='text' name='teamName1' placeholder="Team 1"
                                                                defaultValue={team1 ? team1 : 'Wala'}
                                                                key={team1} required />
                                                        </Col>
                                                        <Col md={{ span: 6 }} className='text-start mb-2'>
                                                            <Form.Label><h6 className='text-danger'>Team 2 Name</h6></Form.Label>
                                                            <Form.Control type='text' name='teamName2' placeholder="Team 2"
                                                                defaultValue={team2 ? team2 : 'Meron'}
                                                                key={team2}
                                                                required />
                                                        </Col>

                                                    </Row>
                                                    <Row className='mt-3'>
                                                        <Col md={{ span: 6 }} className='text-start'>
                                                            <Form.Label><h6 className='text-primary'>Team 1 Logo</h6></Form.Label>
                                                            <Form.Control type="file" placeholder="Choose File" />
                                                        </Col>
                                                        <Col md={{ span: 6 }} className='text-start mb-2'>
                                                            <Form.Label><h6 className='text-danger'>Team 2 Logo</h6></Form.Label>
                                                            <Form.Control type="file" placeholder="Choose File" />
                                                        </Col>
                                                        <Form.Group>
                                                            <Form.Label className='d-flex justify-content-center mt-4'><span className='text-danger'>&nbsp;{error}</span></Form.Label>
                                                        </Form.Group>
                                                        {('0' == settleStatus) ?
                                                            <p className='text-middle mt-2'><Button className="w-50" type="submit">Update</Button></p>
                                                            :
                                                            <p className='text-middle mt-2'><Button className="w-50" type="button" disabled>Update</Button></p>
                                                        }
                                                    </Row>
                                                </Form>
                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={{ span: 12 }} className="mt-4">
                                        <a href={ROUTES.OPERATOR_MATCHES_ROUTE + '/' + categoryId + '/' + gameID + '/1'}> &lt;&lt; Back to Matches</a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container >
        </>
    );
}

export default MatchView;