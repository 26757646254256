import { Col, Row, Container, Pagination } from 'react-bootstrap';
import { API } from '../resources/api-constants';

const CustomPagination = (props: any) => {
    const runCallback = (cb: any) => {
        return cb();
    };

    const post = props.post;
    const total = props.total; //total posts
    const itemsPerPage = API.NUM_PER_PAGE; //posts per page
    const currentPage = props.current;
    const totalPages = Math.ceil((total / itemsPerPage));

    let pagesCount = 1;
    let newPagesCount = 1;

    let start = currentPage;
    let end = currentPage;

    const maxPages = API.MAX_PAGE_DISPLAY;

    while (pagesCount < maxPages) {
        if (end <= totalPages) {
            end++;
            newPagesCount++;
        }
        if (start - 1 > 0) {
            start--;
            newPagesCount++;
        }

        if (newPagesCount == pagesCount)
            break;
        else
            pagesCount = newPagesCount;
    }

    if (end == 2) {
        return (
            <></>
        );
    } else {
        return (
            <>
                <Container>
                    <Row>
                        <Col
                            xs={{ span: 4 }}
                            className='d-flex justify-content-start'
                        >
                            {post.data.length} of {total} results
                        </Col>
                        <Col
                            xs={{ span: 8 }}
                            className='d-flex justify-content-end'
                        >
                            <Pagination size='sm'>
                                {
                                    runCallback(() => {
                                        const row = [];
                                        const prevLink = '' + (currentPage - 1);
                                        const nextLink = '' + (parseInt(currentPage) + 1);
                                        const totalPag = '' + (totalPages);

                                        for (var i = start; i < end; i++) {
                                            //check if start
                                            if ((currentPage > 2) && (i == start)) {
                                                row.push(
                                                    <li className="page-item" key={Math.random()}><a className="page-link" role="button" tabIndex={0} href='1'><span aria-hidden="true">«</span><span className="visually-hidden">First</span></a></li>
                                                );
                                            }
                                            if ((currentPage > 1) && (i == start)) {
                                                row.push(
                                                    <li className="page-item" key={Math.random()}><a className="page-link" role="button" tabIndex={0} href={prevLink}><span aria-hidden="true">‹</span><span className="visually-hidden">Previous</span></a></li>
                                                );
                                            }

                                            if (currentPage == i) {
                                                row.push(
                                                    <li className="page-item active" key={Math.random()}>
                                                        <span className="page-link">{i}
                                                            <span className="visually-hidden">(current)</span>
                                                        </span>
                                                    </li>
                                                );
                                            } else {
                                                row.push(
                                                    <li className="page-item" key={Math.random()}><a className="page-link" role="button" tabIndex={0} href={i}>{i}</a></li>
                                                );
                                            }

                                            //check if end
                                            if ((currentPage < totalPages) && (i == (end - 1))) {
                                                row.push(
                                                    <li className="page-item" key={Math.random()}><a className="page-link" role="button" tabIndex={0} href={nextLink}><span aria-hidden="true">›</span><span className="visually-hidden">Next</span></a></li>
                                                );
                                            }
                                            if ((currentPage < totalPages) && ((end - 1) == i)) {
                                                row.push(
                                                    <li className="page-item" key={Math.random()}><a className="page-link" role="button" tabIndex={0} href={totalPag}><span aria-hidden="true">»</span><span className="visually-hidden">Last</span></a></li>
                                                );
                                            }
                                        }
                                        return row;
                                    })
                                }
                                {/* <Pagination.First />
            <Pagination.Prev />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Ellipsis />

            <Pagination.Item>{10}</Pagination.Item>
            <Pagination.Item>{11}</Pagination.Item>
            <Pagination.Item active>{12}</Pagination.Item>
            <Pagination.Item>{13}</Pagination.Item>
            <Pagination.Item disabled>{14}</Pagination.Item>

            <Pagination.Ellipsis />
            <Pagination.Item>{20}</Pagination.Item>
            <Pagination.Next />
            <Pagination.Last /> */}
                            </Pagination>
                        </Col>
                    </Row>
                </Container>

            </>
        )
    }
}

export default CustomPagination;