import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';

import { clientAxiosLogin } from 'utility/api/agent/axiosAgent';

import { API } from '../../resources/api-constants';

const useAgentLoginLogic = () => {
    const initialFormData = Object.freeze({
        username: "",
        password: "",
        remember: "",
    });

    const [formData, updateFormData] = React.useState(initialFormData);
    const [loadingStatus, setLoadingStatus] = useState(true);

    const handleChange = (e: any) => {
        updateFormData({
            ...formData,

            // Trimming any whitespace
            [e.target.name]: e.target.value.trim()
        });
    };

    const homeRedirect = useNavigate();

    const [errorMessage, updateErrorMessage] = React.useState('');

    const handleSubmit = (e: any) => {
        e.preventDefault();

        loginUser(e);
    };

    function loginUser(e: any) {
        // try {
        updateErrorMessage("Logging In...");

        //clear everything first
        localStorage.clear();

        const rememberMe = e.target.remember.checked;

        clientAxiosLogin.post(
            API.AGENT_LOGIN_LINK,
            {
                username: formData.username,
                password: formData.password,
                //deviceID: browserFingerPrint,
            }
        ).then((response) => {
            if (response.data.data.role.title === 'Master Agent' || response.data.data.role.title === 'Sub Agent') {

                updateErrorMessage('Login Successful');
                //successful

                localStorage.setItem('validToken', 'true');

                localStorage.setItem('access-token', response.data.data.accessToken);

                localStorage.setItem('login-date', String(Date.now()));
                localStorage.setItem('user-id', response.data.data.id);
                localStorage.setItem('email-address', response.data.data.email);
                localStorage.setItem('name', response.data.data.name);
                localStorage.setItem('user-role', response.data.data.role.title);

                if (rememberMe) {
                    localStorage.setItem('agent-remember', '1');
                } else {
                    localStorage.setItem('agent-remember', '0');
                }

                localStorage.setItem('total-credits', response.data.data.wallet.balance);

                //temporarily hide withdraw otp
                //localStorage.setItem('withdraw-credit', '0');
                localStorage.setItem('withdraw-credit', '1');

                localStorage.setItem('user-session', API.AGENT_CODE);

                window.location.replace(ROUTES.AGENT_HOME_ROUTE);
            } else {
                updateErrorMessage("Login Failed.");
                localStorage.clear();
            }
        }).catch((error) => {
            updateErrorMessage("Login Failed.");
            localStorage.clear();
        }).finally(() => {
            // homeRedirect(ROUTES.HOME_ROUTE);
        });
    }

    const [eyeicon, seteye] = useState(true);
    const [password, setpassword] = useState("password");

    function Eye() {
        if (password == "password") {
            setpassword("text");
            seteye(false);
        }
        else {
            setpassword("password");
            seteye(true);
        }
    }

    //fetch on initial load
    React.useEffect(() => {
        if (localStorage.getItem('agent-remember') === '1' && localStorage.getItem('access-token')) {
            window.location.replace(ROUTES.AGENT_HOME_ROUTE);
        } else {
            setLoadingStatus(false);
        }
    }, []);

    return {
        handleSubmit, handleChange,
        password, eyeicon,
        Eye,
        errorMessage,
        loadingStatus
    };
}

export default useAgentLoginLogic;