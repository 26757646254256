import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import CustomPagination from '../../components/CustomPagination';
import { useParams } from 'react-router-dom';
import { API } from 'resources/api-constants';
import { clientAxios } from 'utility/api/agent/axiosAgent';
import Loader from 'components/Loader';

// import useWithdrawalRequestListLogic from 'components/admin/WithdrawalRequestListLogic';

const AgentStats: React.FC = () => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    //GET STATS
    const [post, setPost] = useState({ data: Array(2) });
    const [error, setError] = useState(null);
    const [total, setTotal] = useState(0);

    const [loadingStatus, setLoadingStatus] = useState(true);

    // function to fetch all table data
    const [currentPage, setCurrentPage] = useState(useParams());
    const [pageNumber, setPageNumber] = useState(currentPage.id);

    function getPostData(pageNumber: any) {
        clientAxios.get(
            API.AGENT_COMMISSION_STATISTICS_LINK, {
                params: {
                    offset: API.NUM_PER_PAGE * (pageNumber - 1),
                    limit: API.NUM_PER_PAGE,
                }
        }
        ).then((response: any) => {
            setPost(response.data);
            setTotal(response.data.count);
            setError(null);

            setLoadingStatus(false);
        }).catch(setError)
    }

    //fetch on initial load
    React.useEffect(() => {
        getPostData(pageNumber);
    }, []);

    //END GET STATS

    if (!(post) || (0 === total)) {
        return (
            <>
                {loadingStatus ?
                    <Loader />
                    :

                    <Container >
                        <Row>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                                className="text-center mb-5">
                                <h4>No Items Found</h4>
                            </Col>
                        </Row>
                    </Container>
                }
            </>
        );

    } else {
        let MTD = 0;
        return (
            <>
                <Container>
                    <Row>
                        <Col
                            md={{ span: 10, offset: 1 }}
                            sm={{ span: 12 }}
                            className="text-center mb-5">
                            <h4>Agent <span className='highlightText px-2'>Stats</span></h4>
                        </Col>
                    </Row>

                    {loadingStatus ?
                        <Loader />
                        :
                        <>
                            <Row>
                                <Col
                                    md={{ span: 10, offset: 1 }}
                                    sm={{ span: 12 }}
                                    className="text-center">
                                    <Table striped bordered hover variant="dark">
                                        <thead className='bg-dark'>
                                            <tr>
                                                <th>DATE</th>
                                                <th>MATCHES</th>
                                                <th>COMMISSION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {post && post.data.map((stat: any, count: any) => {

                                                // if (count > 0) {
                                                //     MTD = MTD + stat.totalDayCommissions;
                                                // } else {
                                                //     MTD = stat.totalDayCommissions;
                                                // }
                                                return (
                                                    <tr className='bg-dark p-5 text-white' key={stat.id}>
                                                        <td>{stat.day}</td>
                                                        <td><span >{stat.noOfMatch}</span></td>
                                                        <td><span className='text-warning'>{stat.totalDayCommissions}</span></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    md={{ span: 3, offset: 1 }}
                                    xs={{ span: 12 }}
                                >
                                    {/* <div className='text-info'>* The Month-to-date commission at 6AM</div> */}
                                </Col>
                                <Col
                                    md={{ span: 10, offset: 1 }}
                                    sm={{ span: 12 }}
                                    className="d-flex justify-content-end mb-5">
                                    <CustomPagination post={post} total={total} current={pageNumber} />
                                </Col>
                            </Row>
                        </>
                    }
                </Container >
            </>
        );
    }
}

export default AgentStats;