import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import TutorialCarousel from '../../components/members/TutorialCarousel';

const Tutorial: React.FC = () => {
    return (
        <div>
            <Container>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>Website <span className='highlightText px-2'>Tutorial</span></h4>
                    </Col>
                </Row>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        style={{ minHeight: '325px' }}
                        className='boxContainer p-5'>

                        <TutorialCarousel />

                    </Col>
                </Row>
            </Container >
        </div >
    );
}

export default Tutorial;