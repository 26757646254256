import React, { useState } from 'react';

import { clientAxios } from '../../utility/api/admin/axiosCMS';
import { API } from '../../resources/api-constants';
import { useParams } from 'react-router-dom';

const useGameListLogic = () => {
    //get game list
    const [post, setPost] = React.useState({ data: Array(2) });
    const [error, setError] = useState(null);
    const [total, setTotal] = React.useState(0);
    const [gameCategory, setGameCategory] = React.useState('');

    const [loadingStatus, setLoadingStatus] = React.useState(true); //check loading status


    // function to fetch all table data
    const [pageParams, setPageParams] = useState(useParams());
    const [categoryId, setCategoryId] = useState(pageParams.id);
    const [pageNumber, setPageNumber] = useState(pageParams.page);
    async function getPostData(categoryId: any, pageNumber: any) {
        await clientAxios.get(
            API.ADMIN_GAMES_LINK + '/' + categoryId, {
            params: {
                offset: API.NUM_PER_PAGE * (pageNumber - 1),
                limit: API.NUM_PER_PAGE
            }
        }
        ).then((response) => {
            setPost(response.data);
            setTotal(response.data.count);
            setGameCategory(response.data.data.name);

            setLoadingStatus(false);
            setError(null);
        }).catch((setError) => {
            setLoadingStatus(false);
        });
    }

    //fetch on initial load
    React.useEffect(() => {
        getPostData(categoryId, pageNumber);
    }, []);


    return {
        gameCategory,
        post, total, pageNumber,
        loadingStatus
    };
}

export default useGameListLogic;