import React, { useState } from 'react';
import { API } from 'resources/api-constants';
import { clientAxios } from 'utility/api/admin/axiosCMS';

const useAccountLogic = () => {

    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    const transaction = () => 'verify';
    const handleClose = () => setShow(false);
    const handleSubmit = function () {
        setShow(false);
        setShowToast(true);
    }

    //CHANGE PASSWORD
    const handleChangePassword = async (e: any) => {
        e.preventDefault();
        // handleShow();

        const password = e.target.password.value;
        const newpassword = e.target.newpassword.value;
        const verifypassword = e.target.verifypassword.value;

        if (newpassword == verifypassword) {
            //call API

            try {
                // updateErrorMessage("Logging In...");

                const response = await clientAxios.put(
                    API.ADMIN_RESET_PASSWORD_LINK,
                    {
                        currentPassword: password,
                        newPassword: newpassword,
                        verifyPassword: verifypassword
                    },
                    {
                        headers: { "Content-Type": "application/json" },
                    }
                );
                setErrorMessage(response.data.message);


            } catch (error: any) {
                setErrorMessage("An error has occured. Please try again.");
            }
        } else {
            setErrorMessage("Passwords don't match.");
        }

    }
    //END CHANGE PASSWORD

    const handleShow = () => setShow(true);
    const handleCloseToast = () => setShowToast(false);

    const [eyeicon, seteye] = useState(true);
    const [password, setpassword] = useState("password");

    const [eyeicon2, seteye2] = useState(true);
    const [password2, setpassword2] = useState("password");

    const [eyeicon3, seteye3] = useState(true);
    const [password3, setpassword3] = useState("password");

    const Eye = (passType: any) => {
        if (1 == passType) {
            if (password == "password") {
                setpassword("text");
                seteye(false);
            }
            else {
                setpassword("password");
                seteye(true);
            }
        } else if (2 == passType) {
            if (password2 == "password") {
                setpassword2("text");
                seteye2(false);
            }
            else {
                setpassword2("password");
                seteye2(true);
            }
        } else {
            if (password3 == "password") {
                setpassword3("text");
                seteye3(false);
            }
            else {
                setpassword3("password");
                seteye3(true);
            }
        }
    }

    return {
        Eye,
        showToast,
        handleCloseToast,
        show,
        handleClose,
        handleSubmit,
        transaction,
        handleChangePassword,
        password, eyeicon,
        password2, eyeicon2,
        password3, eyeicon3,
        errorMessage
    };
}

export default useAccountLogic;