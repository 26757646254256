import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../resources/routes-constants';

import { clientAxiosLoginPlayer, clientAxios } from 'utility/api/members/axiosPlayer';

import { API } from '../resources/api-constants';

const useForgotPasswordLogic = () => {
    const initialFormData = Object.freeze({
        email: ""
    });

    const [formData, updateFormData] = React.useState(initialFormData);
    const [errorMessage, updateErrorMessage] = React.useState('');

    const handleChange = (e: any) => {
        updateFormData({
            ...formData,

            // Trimming any whitespace
            [e.target.name]: e.target.value.trim()
        });
    };

    const homeRedirect = useNavigate();
    const handleSubmit = async (e: any) => {
        e.preventDefault()

        updateErrorMessage("Sending email...");

        try {

            const response = await clientAxiosLoginPlayer.post(
                API.PLAYER_FORGOT_PASSWORD_LINK,
                {
                    email: formData.email
                }
            );

            //redirect
            homeRedirect(ROUTES.FORGOTPASSWORD_SUCCESS_ROUTE);

        } catch (error: any) {
            if (error.response.data.resource.result === 400) {
                updateErrorMessage(error.response.data.resource.message);
            } else if (error.response.data.resource.result) {
                updateErrorMessage(error.response.data.resource.message);
            } else {
                updateErrorMessage("Submit Failed. Try again.");
            }
        }
    };

    return {
        handleSubmit, handleChange,
        errorMessage
    };
}

export default useForgotPasswordLogic;