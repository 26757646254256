import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { clientAxios } from '../../utility/api/admin/axiosCMS';
import { API } from '../../resources/api-constants';
import { formatDate } from 'utility/functions';

const useAccountGcashLogic = () => {

    //get details
    const [id, setId] = useState(useParams());
    const [post, setPost] = React.useState({ data: Array(2) });
    const [error, setError] = useState('');
    const [loadingStatus, setLoadingStatus] = useState(true);

    const [number, setNumber] = useState('');
    const [status, setStatus] = useState('');
    const [gcashName, setGcashName] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [updatedAt, setUpdatedAt] = useState('');

    const getPostData = async (id: any) => {
        try {
            const response = await clientAxios.get(
                API.ADMIN_GCASH_LINK + '/' + id, {
            })

            setError('');

            setGcashName(response.data.data.name);
            setNumber(response.data.data.number);
            setStatus(response.data.data.status);

            setCreatedBy(response.data.data.createdBy);
            setCreatedAt(response.data.data.createdAt);
            setUpdatedAt(response.data.data.updatedAt);

            setLoadingStatus(false);
        } catch (error) {
            setError('An error has occured. Please try again.');
        }

    };

    React.useEffect(() => {
        getPostData(id.id);
        // end get details
    }, []);

    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const transaction = () => 'update-gcash';
    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);
    const handleCloseToast = () => setShowToast(false);

    const [showToastType, setShowToastType] = useState('success');
    const result = true;

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const gName = e.target.name.value;
        const gNumber = e.target.number.value;
        const gStatus = e.target.status.checked;
        let gStatusF = false;

        if (gStatus)
            gStatusF = true;

        //api call
        try {
            const response = await clientAxios.put(
                API.ADMIN_GCASH_UPDATE_LINK + '/' + id.id, {
                name: gName,
                    mobileNo: gNumber,
                status: gStatusF
            })

            setError('');
        } catch (error) {
            setError('An error has occured. Please try again.');
        }


        if (result) {
            setShowToastType('success');
        } else {
            setShowToastType('fail');
        }

        setShow(false);
        setShowToast(true);
    };

    return {
        showToast, handleCloseToast, showToastType,
        show, handleClose,
        handleSubmit,
        transaction, post,
        number, status, gcashName,
        createdBy, createdAt, updatedAt,
        loadingStatus
    };
}

export default useAccountGcashLogic;