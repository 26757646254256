import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';
import { ROUTES } from '../../resources/routes-constants';

import CustomPagination from '../../components/CustomPagination';

import useWithdrawalListAgentLogic from 'components/admin/WithdrawalListAgentLogic';
import { formatDateWithTime, formatNumberToCurrency } from 'utility/functions';
import Loader from 'components/Loader';

const WithdrawalsAgentList: React.FC = () => {
    const {
        post, total, pageNumber,
        handleSearch, searchParam, handleSearchInput, showCloseButton, resetSearch,
        handleDropdown, dropdownText,
        loadingStatus
    } = useWithdrawalListAgentLogic();

    if (!(post) || (0 === total)) {
        return (
            <>
                {loadingStatus ?
                    <Loader />
                    :
                    <Container >
                        <Row>
                            <Col
                                md={{ span: 10, offset: 1 }}
                                sm={{ span: 12 }}
                                className="text-center mb-5">
                                <h4>No Items Found</h4>
                            </Col>
                        </Row>
                    </Container>
                }
            </>
        );

    } else {

        return (
            <>
                <Container>
                    <Row>
                        <Col
                            md={{ span: 8, offset: 2 }}
                            sm={{ span: 12 }}
                            className="text-center mb-5">
                            <h4>Agent Withdrawals History</h4>
                        </Col>
                    </Row>
                    {loadingStatus ?
                        <Loader />
                        :
                        <>
                            <Row className='mb-3'>
                                <Col
                                    md={{ span: 8, offset: 2 }}
                                    sm={{ span: 12 }}
                                >
                                    <Row>
                                        <Col
                                            md={{ span: 3, offset: 5 }}
                                            sm={{ span: 12 }}
                                            className="text-end me-0 pe-0">
                                            <Dropdown className="justify-content-end me-0 pe-0 w-100">
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='w-100'>
                                                    {dropdownText}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className='w-100'>
                                                    <Dropdown.Item href="#" onClick={handleDropdown}>All</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={handleDropdown}>Approved</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={handleDropdown}>Disapproved</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>
                                        <Col
                                            md={{ span: 4 }}
                                            sm={{ span: 12 }}
                                            className="text-end ms-0">
                                            <Form id='searchForm' className='w-100' onSubmit={handleSearch}>
                                                <Form.Group className="d-inline-flex justify-content-end ms-0 w-100">
                                                    <Form.Control id="searchField" type='text' name='searchParam' placeholder="Search Name" className='w-100 ms-0 pe-4' value={searchParam} onChange={handleSearchInput} />
                                                    <FontAwesomeIcon icon={faClose} className={'text-dark my-auto d-flex justify-content-end search-icon search-close-button ' + showCloseButton} onClick={resetSearch} />
                                                    <FontAwesomeIcon icon={faSearch} className='text-dark my-auto d-flex justify-content-end ps-2' />
                                                </Form.Group>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={{ span: 8, offset: 2 }} className="text-center table-responsive">
                                    <Table striped bordered hover variant="dark">
                                        <thead className='bg-dark'>
                                            <tr>
                                                <th>DATE</th>
                                                <th>AGENT NAME</th>
                                                <th>GCASH NAME</th>
                                                <th>GCASH NUMBER</th>
                                                <th>AMOUNT</th>
                                                <th>STATUS</th>
                                                <th>ACTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {post && post.data.map((withdrawal: any, count: any) => (
                                                <tr className='bg-dark p-5 text-white' key={withdrawal.id}>
                                                    {/* <td>{formatDateWithTime(withdrawal.createdAt)}</td> */}
                                                    <td>{formatDateWithTime(withdrawal.createdAt)}</td>
                                                    <td>{withdrawal.user.name}</td>
                                                    <td>{withdrawal.gcashName}</td>
                                                    <td>{withdrawal.gcashNumber}</td>
                                                    <td><span className='text-warning'>{formatNumberToCurrency(withdrawal.amount)}</span></td>
                                                    <td>
                                                        {withdrawal.status === 'Approved'
                                                            ?
                                                            <span className='text-success'>Approved</span>
                                                            :
                                                            <span className='text-danger'>Disapproved</span>}
                                                    </td>
                                                    <td>
                                                        <a href={ROUTES.ADMIN_WITHDRAWAL_VIEW_AGENT_ROUTE + '/' + withdrawal.id} className="me-1">View</a>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ span: 8, offset: 2 }} className="d-flex justify-content-end mb-5">
                                    <CustomPagination post={post} total={total} current={pageNumber} />
                                </Col>
                            </Row>
                        </>
                    }
                </Container >
            </>
        );
    }
}

export default WithdrawalsAgentList;