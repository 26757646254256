import React, { useState } from 'react';
import { Button, InputGroup, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import { clientAxios } from 'utility/api/operator/axiosOperator';
import { API } from 'resources/api-constants';
import { useParams } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';

const OperatorConfirmationModal = (props: any) => {
    const setError = props.setError;
    const setShowToast = props.setShowToast;
    const setShowToastType = props.setShowToastType;
    const handleClose = props.close;
    const handleSubmit = props.submit;
    const confirmationType = props.confirmationType;
    const fullName = props.fullName;
    const matchId = props.matchId;
    const betStatusUpdate = props.betStatusUpdate;
    const gameID = props.gameID;
    const team1 = props.team1;
    const team2 = props.team2;


    if ('deactivate-game' == confirmationType) {
        return (
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deactivate</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <h6>Deactivate game <span className='text-danger'>{fullName}</span>?</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(API.OPERATOR_GAMES_DEACTIVATE_LINK)}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else if ('activate-game' == confirmationType) {
        return (
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Activate</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <h6>Deactivate game <span className='text-danger'>{fullName}</span>?</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(API.OPERATOR_GAMES_ACTIVATE_LINK)}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else if ('delete-match' == confirmationType) {
        return (

            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Update</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <h6>Deactivate user <span className='text-danger'>{fullName}</span>?</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(API.ADMIN_OPERATORS_DEACTIVATE_LINK)}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else if ('bet-status' === confirmationType) {
        return (
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Result</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <h6>Change bet status to <span className='text-danger'>{fullName}</span>?</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(betStatusUpdate, 'bet-status')}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else if ('match-result' === confirmationType) {
        return (
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Result</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    {('red' === betStatusUpdate) ?
                        // <h6>Set result to <span className='text-danger'>{fullName}</span>?</h6>
                        <h6>Set result to <span className='text-danger'>{fullName}</span>?</h6>
                        :
                        ('blue' === betStatusUpdate) ?
                            // <h6>Set result to <span className='text-primary'>{fullName}</span>?</h6>
                            <h6>Set result to <span className='text-primary'>{fullName}</span>?</h6>
                            :
                            ('draw' === betStatusUpdate) ?
                                <h6>Set result to <span className='text-warning'>{fullName}</span>?</h6>
                                :
                                <h6>Set result to <span className='text-secondary'>{fullName}</span>?</h6>
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(betStatusUpdate, 'match-result')}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else if ('reset-result' === confirmationType) {
        return (
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Action</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <h6>Reset Match Result?</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(betStatusUpdate, 'reset-result')}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else if ('new-match' == confirmationType) {
        return (
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>No Match Found</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <h6>Create new match?</h6><br />
                    <p className='text-secondary'>You will be redirected to the Match List page</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    {/* <Button variant="primary" onClick={handleSubmit(betStatusUpdate, 'reset-result')}>
                        Yes
                    </Button> */}
                    <a href={ROUTES.OPERATOR_MATCHES_ROUTE + '/' + gameID + '/1'} className='btn btn-primary'>Yes</a>
                </Modal.Footer>
            </Modal>
        );
    } else if ('settle-match' === confirmationType) {
        return (
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Settle Match</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <h6>Are you sure you want to settle match?
                        <br /><br />
                        Result is
                        {
                            (betStatusUpdate === 'blue') ?
                                <span className='ms-1 text-primary text-capitalize d-inline-block'>{team1}</span>
                                :
                                (betStatusUpdate === 'red') ?
                                    <span className='ms-1 text-danger text-capitalize d-inline-block'>{team2}</span>
                                    :
                                    (betStatusUpdate === 'draw') ?
                                        <span className='ms-1 text-warning text-capitalize d-inline-block'>{betStatusUpdate}</span>
                                        :
                                        <span className='ms-1 text-secondary text-capitalize d-inline-block'>{betStatusUpdate}</span>
                        }
                    </h6>
                    <br />
                    <p className='text-secondary'>This action cannot be undone</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(betStatusUpdate, 'settle-match')}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    } else {
        return (

            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deactivate</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <h6>Deactivate game <span className='text-danger'>{fullName}</span>?</h6>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default OperatorConfirmationModal;