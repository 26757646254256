import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import { clientAxios } from 'utility/api/operator/axiosOperator';
import { API } from '../../resources/api-constants';
import { convertImageToBase64, fileCheck, formatDate } from 'utility/functions';

const useOperatorGameLogic = () => {

    //get details
    const [id, setId] = useState(useParams());
    const [post, setPost] = useState({ data: Array(2) });
    const [error, setError] = useState('');
    const [loadingStatus, setLoadingStatus] = useState(true); //check loading status

    const [gameName, setGameName] = useState('');
    const [gameId, setGameId] = useState(id.id);
    const [categoryId, setCategoryId] = useState(0);
    const [image, setImage] = useState('');
    const [status, setStatus] = useState('');

    const [createdBy, setCreatedBy] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [updatedAt, setUpdatedAt] = useState('');

    const getPostData = async (id: any) => {
        let queryType = "sabong";

        if (categoryId === 1) {
            queryType = 'esports';
        } else if (categoryId === 3) {
            queryType = 'sports';
        }

        try {
            const response = await clientAxios.get(
                //API.OPERATOR_GAMES_LINK + '/' + '3' + '/' + queryType + '/' + id, {
                API.OPERATOR_GAMES_LINK + '/' + id, {
            })

            setError('');

            setGameName(response.data.data.name);
            setImage(response.data.data.image);
            //setCategoryId(response.data.gameCategoryID);

            setStatus(response.data.data.status);

            setCreatedBy(response.data.data.createdBy);
            setCreatedAt(response.data.data.createdAt);
            setUpdatedAt(response.data.data.updatedAt);

            setLoadingStatus(false);
        } catch (error) {
            setError('An error has occured. Please try again.');

            setLoadingStatus(false);
        }


    };

    React.useEffect(() => {
        getPostData(id.id);

    }, []);

    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);

    // const transaction = () => 'add-category';
    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);
    const handleCloseToast = () => setShowToast(false);

    const [showToastType, setShowToastType] = useState('success');
    const result = true;

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const gameName = e.target.name.value;

        const cStatus = e.target.status.checked;
        let cStatusF = 0;

        if (cStatus)
            cStatusF = 1;

        let newImage = e.target.image.files[0];

        let imageValid = '';
        let result = true;

        if (!newImage) {
            newImage = image;
        } else {
            imageValid = fileCheck(newImage);
        }

        if (imageValid === '') {

            //api call
            try {
                const response = await clientAxios.patch(
                    API.OPERATOR_GAMES_UPDATE_LINK + '/' + id.id, {
                    name: gameName,
                    image: newImage,
                    status: cStatusF
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                setError('');
            } catch (error) {
                result = false;
                setError('An error has occured. Please try again.');
            }

            getPostData(id.id);
        } else {
            setError(imageValid);
            result = false;
        }

        if (result) {
            setShowToastType('success');
        } else {
            setShowToastType('fail');
        }

        setShow(false);
        setShowToast(true);
    };

    return {
        showToast, handleCloseToast, showToastType,
        show, handleClose,
        handleSubmit, error,
        gameName, image, status, gameId,
        createdBy, createdAt, updatedAt,
        loadingStatus
    };
}

export default useOperatorGameLogic;