import React from 'react';

import { faEye, faEyeLowVision } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Row } from 'react-bootstrap';

import useLoginLogic from '../../components/admin/LoginLogic';

const Login: React.FC = () => {
    const {
        handleSubmit, handleChange,
        password, eyeicon,
        Eye,
        errorMessage,
        loadingStatus
    } = useLoginLogic();

    return (
        <>
            {
                (loadingStatus) ? <></>
                    :

                    <Container>
                        <Row className='m-auto vh-100'>
                            <Col
                                lg={{ span: 8, offset: 2 }}
                                sm={{ span: 12 }}
                                className='m-auto'
                            >
                                <div id="login" className="wrapper">
                                    <section className="content">
                                        <div className="container-fluid">
                                            <div className="login-box">
                                                <div className="card card-outline card-primary mx-auto">
                                                    <h2 className="card-header text-center">
                                                        Admin Log In
                                                    </h2>
                                                    <div className="card-body bg-dark">
                                                        <form action="/" method="get" onSubmit={handleSubmit}>
                                                            <div className="input-group mb-3">
                                                                <label className="form-label" ><h6>Email</h6></label>
                                                                <input name='email' type="email" className="form-control w-100" placeholder="Email" onChange={handleChange} required />
                                                            </div>
                                                            <div className="input-group mb-3">
                                                                <label className="form-label" ><h6>Password</h6></label>
                                                                <input type={password} name='password' className="form-control w-100" placeholder="Password" onChange={handleChange} required />
                                                                <FontAwesomeIcon icon={eyeicon ? faEye : faEyeLowVision} className='password-eye' onClick={Eye} />
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-8">
                                                                    <div className="icheck-primary">
                                                                        <input type="checkbox" id="remember" name='remember' onChange={handleChange} />
                                                                        <label htmlFor='remember' className='ms-2'>
                                                                            Remember Me
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-4">
                                                                    <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                                                </div>
                                                            </div>
                                                        </form>

                                                        {/* <p className="mb-1">
                                                    <a href={ROUTES.ADMIN_FORGOTPASSWORD_ROUTE}>I forgot my password</a>
                                                </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="content">
                                        <div className="container-fluid text-center mt-3">
                                            <span className='text-danger h6'>&nbsp;{errorMessage}</span>
                                        </div>
                                    </section>
                                </div>
                            </Col>
                        </Row>
                    </Container>
            }
        </>
    )
}

export default Login
