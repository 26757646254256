import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import { Button, Dropdown, Form } from 'react-bootstrap';
import { ROUTES } from '../../resources/routes-constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faSearch } from '@fortawesome/free-solid-svg-icons';

import CustomPagination from '../../components/CustomPagination';

import OperatorCustomModal from '../../components/operator/modals/OperatorCustomModal';
import OperatorConfirmationModal from '../../components/operator/modals/OperatorConfirmationModal';
import OperatorCustomToast from '../../components/operator/OperatorCustomToast';

import useOperatorGameListLogic from '../../components/operator/OperatorGameListLogic';

const OperatorGameList: React.FC = () => {
    const {
        showToast, handleCloseToast, showToastType,
        show, handleClose, handleSubmit, transaction,
        showConfirmation, handleConfirmationSubmit, handleCloseConfirmation, confirmationType, fullName,
        handleShow, handleConfirmation,
        categoryId, gameCategory,
        post, total, pageNumber,
        loadingStatus,
        handleDropdown, dropdownText,
        handleSearch, resetSearch, handleSearchInput, searchParam, showCloseButton
    } = useOperatorGameListLogic();

    if (loadingStatus)
        return null;

    return (
        <>
            <OperatorCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
            <OperatorCustomModal show={show} close={handleClose} submit={handleSubmit} transaction={transaction} gameCategory={gameCategory} categoryId={categoryId} />
            <OperatorConfirmationModal show={showConfirmation} submit={handleConfirmationSubmit} close={handleCloseConfirmation} confirmationType={confirmationType} fullName={fullName} />

            <Container>
                <Row>
                    <Col
                        md={{ span: 8, offset: 2 }}
                        sm={{ span: 12 }}
                        className="text-center mb-5">
                        <h4>Games under {gameCategory}</h4>
                    </Col>
                </Row>

                <Row className='mb-3'>
                    <Col
                        md={{ span: 8, offset: 2 }}
                        sm={{ span: 12 }}
                    >
                        <Row>
                            <Col
                                md={{ span: 4 }}
                                sm={{ span: 12 }}
                                className="text-start">
                                <div><Button type="button" className='w-100' onClick={() => handleShow('add')}>Add Game</Button></div>
                            </Col>
                            <Col
                                md={{ span: 3, offset: 1 }}
                                sm={{ span: 12 }}
                                className="text-end me-0 pe-0">
                                <Dropdown className="justify-content-end me-0 pe-0 w-100">
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='w-100'>
                                        {dropdownText}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className='w-100'>
                                        <Dropdown.Item href="#" onClick={handleDropdown}>All</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={handleDropdown}>Active</Dropdown.Item>
                                        <Dropdown.Item href="#" onClick={handleDropdown}>Inactive</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                            <Col
                                md={{ span: 4 }}
                                sm={{ span: 12 }}
                                className="text-end ms-0">
                                <Form id='searchForm' className='w-100' onSubmit={handleSearch}>
                                    <Form.Group className="d-inline-flex justify-content-end ms-0 w-100">
                                        <Form.Control id="searchField" type='text' name='searchParam' placeholder="Search Name" className='w-100 ms-0 pe-5' value={searchParam} onChange={handleSearchInput} />
                                        <FontAwesomeIcon icon={faClose} className={'text-dark my-auto d-flex justify-content-end search-icon search-close-button ' + showCloseButton} onClick={resetSearch} />
                                        <FontAwesomeIcon icon={faSearch} className='text-dark my-auto d-flex justify-content-end ps-2' />
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col
                        md={{ span: 8, offset: 2 }}
                        sm={{ span: 12 }}
                        className="text-center">
                        <Table striped bordered hover variant="dark">
                            <thead className='bg-dark'>
                                <tr>
                                    {/* <th>ID</th> */}
                                    <th>NAME</th>
                                    <th>STATUS</th>
                                    <th>ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {post && post.data.map((gameObj: any, count: any) => (
                                    <tr className='bg-dark p-5 text-white' key={gameObj.id}>
                                        {/* <td>{gameObj.game.gameID}</td> */}
                                        <td>{gameObj.name}</td>
                                        <td>
                                            {gameObj.status === true ? <span className='text-success'>Active</span> : <span className='text-danger'>Inactive</span>}
                                        </td>
                                        <td>
                                            <a href={ROUTES.OPERATOR_GAME_VIEW_ROUTE + '/' + gameObj.id} className="me-1">View</a>
                                            {/* <a href={ROUTES.OPERATOR_GAME_EDIT_ROUTE + '/1'} className='mx-1'>Edit</a> <br /> */}
                                            <a href={ROUTES.OPERATOR_MATCHES_ROUTE + '/' + gameObj.categories[0].id + '/' + gameObj.id + '/1'} className='ms-1'>Matches</a> <br />
                                            {/*{gameObj.status === true ?*/}
                                            {/*    <a href='#' className='ms-1' onClick={() => handleConfirmation('deactivate-game', gameObj.name, gameObj.id)}>Deactivate</a> :*/}
                                            {/*    <a href='#' className='ms-1' onClick={() => handleConfirmation('activate-game', gameObj.name, gameObj.id)}>Activate</a>*/}
                                            {/*}*/}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col
                        md={{ span: 8, offset: 2 }}
                        sm={{ span: 12 }}
                        className="d-flex justify-content-end">
                        <CustomPagination post={post} total={total} current={pageNumber} />
                    </Col>
                </Row>
                <Row>
                    <Col
                        md={{ span: 8, offset: 2 }}
                        sm={{ span: 12 }}
                        className="d-flex justify-content-end mb-5">
                        <div className='text-center mt-2 mb-4'>
                            <a href={ROUTES.OPERATOR_CATEGORIES_ROUTE}>&lt;&lt; Back to Categories</a>
                        </div>
                    </Col>
                </Row>
            </Container >
        </>
    );
}

export default OperatorGameList;