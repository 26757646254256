import React, { useState } from 'react';
import { API } from 'resources/api-constants';
import { clientAxios } from 'utility/api/agent/axiosAgent';
import { formatNumberToCurrency, getNumber } from 'utility/functions';

const useAgentCommissionConvertLogic = () => {
    const [showToast, setShowToast] = useState(false);
    const [showToastType, setShowToastType] = useState('fail');

    const [error, setError] = useState('');

    const [loadingStatus, setLoadingStatus] = useState(false);

    const [totalCommission, setTotalCommission] = useState(localStorage.getItem('total-commissions'));

    React.useEffect(() => {

    }, []);

    //END 

    const handleCloseToast = () => setShowToast(false);


    //CONVERT COMMISSION
    const [errorFlag, setErrorFlag] = useState(false);
    const handleSubmit = function (e: any) {

        e.preventDefault();

        //api call
        const amount = getNumber(e.target.amount.value)
        const notes = e.target.notes.value;

        e.target.btnSubmit.disabled = true;

        clientAxios.post(
            API.AGENT_COMMISSION_CONVERT_LINK, {
            amount: amount,
            note: notes
        }
        ).then((response) => {
            updateCredits();

            setErrorFlag(false);

        }).catch((error) => {
            setError('An error has occured. Try again later.');
            setErrorFlag(true);
        }).finally(() => {
            e.target.btnSubmit.disabled = false;
        });

    }

    function updateCredits() {
        clientAxios.get(
            API.AGENT_ACCOUNT_LINK,
            {}
        ).then((response) => {
            localStorage.setItem('total-credits', response.data.data.wallet.balance);

            //update balance text
            const creditsObj = document.getElementById('totalCredits');
            Object(creditsObj).innerHTML = formatNumberToCurrency(response.data.data.wallet.balance);

            //update commission
            setTotalCommission(response.data.data.agent.commission_wallet.amount);
        }).catch((error) => {
        });
    }
    //END CONVERT COMMISSION

    return {
        showToast, showToastType, handleCloseToast,
        totalCommission,
        error, errorFlag,
        handleSubmit,
        loadingStatus
    };
}

export default useAgentCommissionConvertLogic;