import { faEye, faEyeLowVision } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ROUTES } from '../resources/routes-constants'
import AvailableGames from './partials/AvailableGames';

import useRegisterLogic from 'components/RegisterLogic';
import ConfirmationModal from 'components/members/modals/ConfirmationModal';

const Register: React.FC = () => {
    const {
        Eye, handleSubmit, handleChange,
        password, password2, eyeicon,
        eyeicon2, errorMessage,
        show, handleShow, handleClose, confirmationType,
        registerLoading
    } = useRegisterLogic();

    return (
        <>
            <ConfirmationModal
                show={show} 
                close={handleClose}
                confirmationType={confirmationType}
            />

            <Container>
                <Row className='m-auto vh-100'>
                    <Col
                        lg={{ span: 6 }}
                        sm={{ span: 12 }}
                        className='m-auto'
                    >
                        <div id="register" className="register-box mb-2">
                            <div className="card card-outline card-primary mx-auto">
                                <div className="text-center">
                                    <h2 className="card-header text-center">
                                        Register
                                    </h2>
                                </div>
                                <div className="card-body bg-dark">
                                    {/* <p className="login-box-msg">Register as a new member</p> */}

                                    <form action="#" method="post" onSubmit={handleSubmit}>
                                        <div className="input-group mb-3">
                                            <label className="form-label" ><h6>Email</h6></label>
                                            <input type="email" name='email' className="form-control w-100" placeholder="Email" required onChange={handleChange} />
                                            <small className='text-secondary'>This must be a working email</small>
                                            <div className="input-group-append">
                                            </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <label className="form-label" ><h6>Username</h6></label>
                                            <input type="text" name='username' className="form-control w-100" placeholder="Username" required onChange={handleChange} />
                                            <div className="input-group-append">
                                            </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <label className="form-label" ><h6>Name</h6></label>
                                            <input type="text" name='name' className="form-control w-100" placeholder="Name" required onChange={handleChange} />
                                            <div className="input-group-append">
                                            </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <label className="form-label" ><h6>Password</h6></label>
                                            <input type={password} name='password' minLength={6} className="form-control w-100" placeholder="Password" required onChange={handleChange} />
                                            <FontAwesomeIcon icon={eyeicon ? faEye : faEyeLowVision} className='password-eye' onClick={() => Eye(1)} />
                                            <div className="input-group-append">
                                            </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <label className="form-label" ><h6>Confirm Password</h6></label>
                                            <input type={password2} name='verifyPassword' minLength={6} className="form-control w-100" placeholder="Confirm password" required onChange={handleChange} />
                                            <FontAwesomeIcon icon={eyeicon2 ? faEye : faEyeLowVision} className='password-eye' onClick={() => Eye(2)} />
                                            <div className="input-group-append">
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-8">
                                                <div className="icheck-primary">
                                                    <input type="checkbox" id="agreeTerms" name="terms" value="agree" required />
                                                    <label htmlFor="agreeTerms" className='ps-2'>
                                                        I agree to the <a href="#" onClick={handleShow}>terms</a>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <label className="d-flex justify-content-center form-label"><span className="text-danger">&nbsp;{errorMessage}</span></label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {(registerLoading) ?
                                                    <button type="button" className="btn btn-primary btn-block register-button w-100 mt-3" disabled>Register</button>
                                                    :
                                                    <button type="submit" className="btn btn-primary btn-block register-button w-100 mt-3">Register</button>
                                                    }
                                            </div>
                                            <div className='text-center'>
                                                <a href={ROUTES.LOGIN_ROUTE} className='btn-outline-secondary btn w-100 my-3'>Back to Login</a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col
                        lg={{ span: 6 }}
                        sm={{ span: 12 }}
                        className='m-auto'
                    >
                        <AvailableGames />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Register