import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { ROUTES } from '../../resources/routes-constants';
import { Button } from 'react-bootstrap';

import AdminCustomModal from '../../components/admin/modals/AdminCustomModal';
import AdminCustomToast from '../../components/admin/AdminCustomToast';

import useAgentLogic from '../../components/admin/AgentLogic';
import { formatDate, formatDateWithTime, formatNumberToCurrency } from 'utility/functions';
import Loader from 'components/Loader';

const UsersAgentView: React.FC = () => {
    const {
        showToast, handleCloseToast, showToastType,
        show, handleClose,
        handleSubmit,
        transaction,
        fullName, email, status, type, totalCredits,
        lastLogin, createdBy, createdAt, updatedAt,
        loadingStatus
    } = useAgentLogic();

    let agentType = '';

    if (3 == Number(type))
        agentType = 'Master Agent';
    else
        agentType = 'Sub Agent';

    return (
        <>
            <AdminCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
            <AdminCustomModal show={show} close={handleClose} submit={handleSubmit} transaction={transaction} />

            <Container>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>Agent Profile</h4>
                    </Col>
                </Row>
                {loadingStatus ?
                    <Loader />
                    :
                    <>
                        <Row>
                            <Col
                                lg={{ span: 4, offset: 4 }}
                                md={{ span: 8, offset: 2 }}
                                className='boxContainer px-5 pt-4 pb-4'>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group className="my-1 text-center">
                                        <Form.Label className='text-danger'>
                                            <h5 className=''>{agentType}</h5>
                                        </Form.Label>
                                    </Form.Group>
                                    <Form.Group className="mb-1">
                                        <Form.Label><h6>Email : {email}</h6></Form.Label>
                                    </Form.Group>
                                    <Form.Group className="mb-1">
                                        <Form.Label><h6>Name : {fullName}</h6></Form.Label>
                                    </Form.Group>
                                    <Form.Group className="mb-1">
                                        <Form.Label><h6>Credits : <span className='text-warning'>{formatNumberToCurrency(totalCredits)}</span></h6></Form.Label>
                                    </Form.Group>

                                    {/* <Form.Group className="mb-3 mt-4">
                                <Form.Label>
                                    <h6 className='mb-0'>Status</h6>
                                </Form.Label>
                                <div className="m-0 form-check">
                                    {status ?
                                        <input name="status" type="checkbox" id="custom-switch" className="form-check-input" defaultChecked /> :
                                        <input name="status" type="checkbox" id="custom-switch" className="form-check-input" />
                                    }
                                    <label title="" htmlFor="custom-switch" className="form-check-label">Activate</label>
                                </div>
                            </Form.Group> */}
                                    <Form.Group className="">
                                        <Form.Label>Last Login : </Form.Label>
                                        <Form.Text className="ms-2">{formatDateWithTime(lastLogin)}</Form.Text>
                                    </Form.Group>
                                    <Form.Group className="">
                                        <Form.Label>Created By : </Form.Label>
                                        <Form.Text className="ms-2">{createdBy}</Form.Text>
                                    </Form.Group>
                                    <Form.Group className="">
                                        <Form.Label>Created : </Form.Label>
                                        <Form.Text className="ms-2">{formatDate(createdAt)}</Form.Text>
                                    </Form.Group>
                                    <Form.Group className="">
                                        <Form.Label>Updated : </Form.Label>
                                        <Form.Text className="ms-2">{formatDate(updatedAt)}</Form.Text>
                                    </Form.Group>
                                    <div className='text-end mt-4'>
                                        <Button href={ROUTES.ADMIN_AGENTS_ROUTE + '/1'} variant="secondary" className='me-2'>Back</Button>
                                        {/* <Button variant="primary" type='submit'>Update</Button> */}
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </>
                }
            </Container >
        </>
    );
}

export default UsersAgentView;