import AppContent from './AppContent'
import TopNavBar from 'pages/partials/members/TopNavbar'

const DefaultLayout = () => {
  return (
    <div>
      <TopNavBar />
        <div className="body flex-grow-1">
          <AppContent />
        </div>
    </div>
  )
}

export default DefaultLayout
