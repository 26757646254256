import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { refreshToken } from 'utility/api/members/axiosPlayer';

const Rules: React.FC = () => {
    refreshToken();

    return (
        <div>
            <Container>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>Website <span className='highlightText px-2'>Rules</span></h4>
                    </Col>
                </Row>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        className='boxContainer p-5'>
                        <ol>
                            <li>Minimum bet is <span className="text-warning">20</span></li>
                            <li>Betting window is <span className="text-warning">3 to 5 mins</span>. There are 3 game status; <span className="text-warning">OPEN</span>, <span className="text-warning">LAST CALL</span> and <span className="text-warning">CLOSED</span>. Once betting is CLOSED, bets will not be accepted.</li>
                            <li>Payout style: <span className="text-warning">Sports betting</span>.</li>
                            <li>Payout computation (odds):<br />
                                % based on total bet per side<br />

                                Example: 100 bet at 180% wins 180</li>
                            <li>If fight is a draw, bets are returned</li>
                        </ol>

                    </Col>
                </Row>
            </Container >
        </div >
    );
}

export default Rules;