import React, { useState } from 'react';
import { API } from 'resources/api-constants';
import { clientAxios } from 'utility/api/agent/axiosAgent';

const useAgentHomeLogic = () => {
    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showToastType, setShowToastType] = useState('fail');

    const [transaction, setTransaction] = useState('showQR')
    const [agentLink, setAgentLink] = useState('');

    const [error, setError] = useState('');

    const [loadingStatus, setLoadingStatus] = useState(true);

    //get total commissions
    const [totalCommission, setTotalCommission] = useState(0);
    async function getTotalCommissions() {
        await clientAxios.get(
            API.AGENT_COMMISSION_CREDITS_TOTAL_LINK, {
        }
        ).then((response) => {
            setTotalCommission(response.data.data);
            setError('');

            setLoadingStatus(false);
        }).catch(setError)
    }
    //END GET TOTAL COMMISSIONS

    //GET DASHBOARD
    const [commissionMTD, setCommissionMTD] = useState('');
    const [mainCoor, setMainCoor] = useState(Object);
    const [topDownlines, setTopDownlines] = useState(Array);
    
    async function getDashboardStats() {
        await clientAxios.get(
            API.AGENT_DASHBOARD_LINK, {
        }
        ).then((response) => {
            setCommissionMTD(response.data.data.commissionsMTD);
            setMainCoor(response.data.data.mainCoor);
            setTopDownlines(response.data.data.topDownlines);

            setError('');

            //setLoadingStatus(false);
        }).catch(setError)
    }

    async function getMTD() {
        await clientAxios.get(
            API.AGENT_MTD_LINK, {
        }
        ).then((response) => {
            setCommissionMTD(response.data.data.commissions);
            setError('');

            //setLoadingStatus(false);
        }).catch(setError)
    }
    async function getTopDownlines() {
        await clientAxios.get(
            API.AGENT_TOP_DOWNLINES_LINK, {
        }
        ).then((response) => {
            //setCommissionMTD(response.data.data.commissionsMTD);
            //setMainCoor(response.data.data.mainCoor);
            setTopDownlines(response.data.data);

            setError('');

            //setLoadingStatus(false);
        }).catch(setError)
    }

    async function getInviteLink() {
        await clientAxios.post(
            API.AGENT_INVITE_LINK, {
        }
        ).then((response) => {
            localStorage.setItem('referral-code', response.data.data.link);

            setError('');

            setLoadingStatus(false);
        }).catch(setError)
    }

    //END GET DASHBOARD
    React.useEffect(() => {
        Promise.all([getTopDownlines(), getInviteLink(), getMTD()]).then(results => {
            setLoadingStatus(false);
        });

        //getTopDownlines();
        //getInviteLink();
    }, []);


    const handleClose = () => setShow(false);

    const handleShowQR = async function (e: any) {
        e.preventDefault();

        //get agent link
        const baseUrl = window.location.host;
        // setAgentLink(baseUrl + '/register/' + localStorage.getItem('referral-code'));
        setAgentLink('/register/' + localStorage.getItem('referral-code'));

        setShow(true);
    }
    const handleCloseToast = () => setShowToast(false);

    return {
        show, handleClose, handleShowQR, transaction,
        agentLink,
        totalCommission,
        commissionMTD, mainCoor, topDownlines,
        loadingStatus
    };
}

export default useAgentHomeLogic;