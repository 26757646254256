import React, { useState } from 'react';
import { API } from 'resources/api-constants';
import { ROUTES } from 'resources/routes-constants';
import { clientAxios } from 'utility/api/members/axiosPlayer';

const useAuthorizeLogic = () => {
    const [errorMessage, setErrorMessage] = useState('');

    const handleAuthorizeUser = async (e: any) => {
        e.preventDefault();

        try {

            //authorize device apo goes ehre
            // const response = await clientAxios.post(
            //     API.PLAYER_UPDATE_NAME_LINK,
            //     {
            //         deviceID: localStorage.getItem('deviceID')
            //     }
            // );

            window.location.replace(ROUTES.HOME_ROUTE);

        } catch (error: any) {
            setErrorMessage("An error has occured. Please try again.");
        }
    }
    return {
        handleAuthorizeUser,
        errorMessage,
    };
}

export default useAuthorizeLogic;