import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';
import { clientAxios } from '../../utility/api/admin/axiosCMS';
import { API } from '../../resources/api-constants';

function useOperatorLogoutLogic() {
    const homeRedirect = useNavigate();

    //clientAxios.post(
    //    API.OPERATOR_LOGOUT_ROUTE
    //).then((response) => {
    //    localStorage.clear();
    //}).catch()

    ////redirect
    //useEffect(() => {
    //    homeRedirect(ROUTES.OPERATOR_LOGIN_ROUTE);
    //}, []);
    localStorage.clear();
    homeRedirect(ROUTES.OPERATOR_LOGIN_ROUTE);

};

export default useOperatorLogoutLogic;