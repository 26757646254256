import { API } from "resources/api-constants";

/**
 * Returns a greeting message for the user
 * @param userName The user's first name
 * @returns A kind greeting message
 */
export const sayHello = (userName: string): string => `Welcome ${userName}!`;

/**
 * Formats a date object to yyyy-mm-dd
 * @param date Date or string representation of the date
 * @returns Formatted date or '--' if invalid
 */
export const formatDate = (date: Date | string): string => {
    if (!date || date === '0000-00-00 00:00:00') return '--';

    const dateObject = new Date(date);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

/**
 * Formats a date object to yyyy/mm/dd hh:mm am/pm
 * @param date Date or string representation of the date
 * @returns Formatted date with time or '--' if invalid
 */
export const formatDateWithTime = (date: Date | string): string => {
    if (!date || date === '0000-00-00 00:00:00') return '--';

    const dateObject = new Date(date);
    const hours24 = dateObject.getHours();
    const minutes = String(dateObject.getMinutes()).padStart(2, '0');
    const ampm = hours24 >= 12 ? 'pm' : 'am';
    const hours = hours24 % 12 || 12;

    const formattedDate = `${dateObject.getFullYear()}/${dateObject.getMonth() + 1}/${dateObject.getDate()}`;
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    return `${formattedDate} ${formattedTime}`;
};

/**
 * Generates a random string of a given length
 * @param length Length of the random string
 * @returns Randomly generated string
 */
export const generateId = (length: number): string => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_+[]';
    return Array.from({ length }, () => chars.charAt(Math.floor(Math.random() * chars.length))).join('');
};

/**
 * Converts an image file to a base64 string
 * @param file Image file
 * @returns Promise resolving to base64 string
 */
export const convertImageToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result as string);
        fileReader.onerror = reject;
        fileReader.readAsDataURL(file);
    });
};

/**
 * Checks if an image file is valid
 * @param file Image file
 * @returns Error message or empty string
 */
export const fileCheck = (file: File): string => {
    if (!file.type.startsWith('image/')) return 'File is not an image.';
    if (file.size > API.MAX_FILE_SIZE) return 'File is too large.';
    return '';
};

/**
 * Optimizes an image file to fit within maxWidth and maxHeight
 * @param file Image file
 * @param maxWidth Maximum width
 * @param maxHeight Maximum height
 * @returns Promise resolving to optimized image base64 string
 */
export const optimizeImage = (file: File, maxWidth: number, maxHeight: number): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const img = new Image();
            img.src = reader.result as string;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d')!;
                let { width, height } = img;

                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);
                resolve(canvas.toDataURL('image/jpeg', 0.8));
            };
            img.onerror = () => reject(new Error('Failed to load image'));
        };
        reader.onerror = () => reject(new Error('Failed to read file'));
        reader.readAsDataURL(file);
    });
};

/**
 * Extracts base64 data from a data URL
 * @param dataUrl Data URL
 * @returns Base64 string
 */
export const toBase64 = (dataUrl: string): string => dataUrl.split(',')[1];

/**
 * Focuses the next input field when current input is filled
 * @param field Id of the next input field
 * @returns Focus handler
 */
export const focusNextInput = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.value.trim() !== '') {
        const fieldDOM = document.getElementById(field) as HTMLInputElement | null;
        fieldDOM?.focus();
    }
};

/**
 * Clears the value of an input field
 * @param e Event
 */
export const clearInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.target.value = '';
};

/**
 * Copies text to clipboard
 * @param text Text to copy
 */
export const copyContent = async (text: string) => {
    try {
        await navigator.clipboard.writeText(text);
    } catch (err) {
        console.error('Failed to copy:', err);
    }
};

/**
 * Formats a number to currency format
 * @param amount Number to format
 * @returns Formatted currency string
 */
export const formatNumberToCurrency = (amount: any): string => {
    return Number(amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || '0';
};

/**
 * Rounds number to two decimal places
 * @param amount Number to format
 * @returns Formatted currency string
 */
export const roundNumberTwoDecimals = (amount: any): string => {
    return amount.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
};

/**
 * Formats a number to currency format with rounding up
 * @param amount Number to format
 * @returns Formatted currency string
 */
export const formatNumberToCurrencyRoundUp = (amount: any): string => {
    return formatNumberToCurrency(amount);
};

/**
 * Sets a delay for a given amount of time
 * @param delay Time in milliseconds
 * @returns Promise resolving after the delay
 */
export const setDelay = (delay: number): Promise<void> => new Promise(res => setTimeout(res, delay));

/**
 * Updates input value with comma separated format
 * @param obj Event object containing target value
 */
export const updateTextView = (obj: React.ChangeEvent<HTMLInputElement>) => {
    const num = getNumber(obj.target.value);
    obj.target.value = num === 0 ? '' : num.toLocaleString();
};

/**
 * Extracts number from a string
 * @param str String to extract number from
 * @returns Extracted number
 */
export const getNumber = (str: string): number => {
    const numStr = str.replace(/[^\d]/g, '');
    return Number(numStr);
};

/**
 * Disables a button element
 * @param btnRef Button reference
 */
export const disableButton = (btnRef: React.RefObject<HTMLButtonElement>) => {
    if (btnRef.current) btnRef.current.disabled = true;
};
