import React, { useState, useRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';

//font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { ROUTES } from '../../resources/routes-constants';

function TutorialCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);

    let nextButton;
    let backButton;

    if (0 == index) {
      nextButton = <button className='mt-3 btn btn-primary ps-5 pe-5'>Next</button>;
      backButton = <button className='mt-3 btn btn-secondary ps-5 pe-5 d-none'>Back</button>;
    } else {
      nextButton = <button className='mt-3 btn btn-primary ps-5 pe-5'>Next</button>;
      backButton = <button className='mt-3 btn btn-secondary ps-5 pe-5'>Back</button>;
    }
  };

  const handleClick = (event: any) => {
    // event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  return (
    <Carousel interval={null} indicators={false}
      nextIcon={(5 == index)
        ? <button className='mt-3 btn ps-5 pe-5 btn-outline-secondary'>Again</button>
        : <button className='mt-3 btn btn-primary ps-5 pe-5'>Next</button>
      }
      prevIcon={(0 == index)
        ? <button className='mt-3 btn btn-secondary ps-5 pe-5 d-none'>Back</button>
        : (5 == index) ? <a href={ROUTES.HOME_ROUTE} className="btn mt-3 btn btn-warning ps-5 pe-5" role="button" onClick={handleClick}>Play</a>
          : <button className='mt-3 btn btn-secondary ps-5 pe-5'>Back</button>
      }

      // {<button className='mt-3 btn btn-secondary ps-5 pe-5'>Back</button>}
      activeIndex={index} onSelect={handleSelect}
    >
      <Carousel.Item className='text-center'>
        <div style={{ minHeight: '120px' }}>
          <h4 className='text-warning mb-4'>ADD CREDITS</h4>
          <p>Load using <b>Gcash</b> and receive your credits in <b>less than 5 minutes</b></p>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div style={{ minHeight: '120px' }}>
          <h4 className='text-warning mb-4 text-center'>HOW TO ADD CREDITS</h4>
          <div>
            <ol>
              <li>Click <FontAwesomeIcon icon={faBars} /> Home to open sidebar</li>
              <li>Click <b>Buy Credits</b> in the sidebar</li>
            </ol>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item className='text-center'>
        <div style={{ minHeight: '120px' }}>
          <h4 className='text-warning mb-4'>BETTING</h4>
          <p>Select your lucky side and pick the amount. Easy as that!</p>
        </div>
      </Carousel.Item>
      <Carousel.Item className='text-center'>
        <div style={{ minHeight: '120px' }}>
          <h4 className='text-warning mb-4'>SWITCH GAMES</h4>
          <p>To view all available games, click &quot;Home&quot; icon or from the navigation menu.</p>
        </div>
      </Carousel.Item>
      <Carousel.Item className='text-center'>
        <div style={{ minHeight: '120px' }}>
          <h4 className='text-warning mb-4'>WITHDRAW CREDITS</h4>
          <p>Convert your credits into GCash quickly. </p>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div style={{ minHeight: '120px' }}>
          <h4 className='text-warning mb-4 text-center'>HOW TO WITHDRAW</h4>
          <div>
            <ol>
              <li>Click <FontAwesomeIcon icon={faBars} /> to open sidebar</li>
              <li>Click <b>Withdraw</b> in the sidebar</li>
            </ol>
          </div>
        </div>
      </Carousel.Item>


    </Carousel>
  );
}

export default TutorialCarousel;