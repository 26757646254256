import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AdminConfirmationModal from '../../components/admin/modals/AdminConfirmationModal';

import { Button } from 'react-bootstrap';
import AdminCustomToast from '../../components/admin/AdminCustomToast';
import { ROUTES } from 'resources/routes-constants';

import useWithdrawalRequestViewLogic from 'components/admin/WithdrawalRequestViewLogic';
import { formatDate, formatDateWithTime, formatNumberToCurrency } from 'utility/functions';
import Loader from 'components/Loader';

const WithdrawalRequestView: React.FC = () => {
    const {
        showConfirmation, handleConfirmationSubmit, handleCloseConfirmation,
        confirmationType, handleApprove, handleDisapprove,
        showToast, handleCloseToast, showToastType,
        setShowToast, setShowToastType,
        id, fullName, status, amount, gcashNumber, gcashName, remarks,
        approvedBy, createdAt, updatedAt,
        loadingStatus
    } = useWithdrawalRequestViewLogic();

    return (
        <>
            {/* <AdminCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
            <AdminConfirmationModal show={showConfirmation} submit={handleConfirmationSubmit} close={handleCloseConfirmation} confirmationType={confirmationType} /> */}
            <AdminCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />
            <AdminConfirmationModal
                show={showConfirmation}
                submit={handleConfirmationSubmit} close={handleCloseConfirmation}
                confirmationType={confirmationType} requestId={id.id}
                setShowToast={setShowToast} setShowToastType={setShowToastType}
            />

            <Container>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 10, offset: 1 }}
                        className="text-center mb-5">
                        <h4>View Withdrawal Request</h4>
                    </Col>
                </Row>
                {loadingStatus ?
                    <Loader />
                    :
                    <>
                        <Row>
                            <Col
                                lg={{ span: 6, offset: 3 }}
                                md={{ span: 10, offset: 1 }}
                                className='boxContainer px-5 pt-4 pb-4'>
                                <Row>
                                    <Col
                                        md={{ span: 6, offset: 3 }}
                                    >
                                        <div className='mb-2'><span className='h5 me-2'>Name:</span><span className='h6'>{fullName}</span></div>
                                        <div className='mb-2'><span className='h5 me-2'>Amount:</span><span className='text-warning h6'>{formatNumberToCurrency(amount)}</span></div>
                                        <div className='mb-2'><span className='h5 me-2'>Gcash Name:</span><span className='h6'>{gcashName}</span></div>
                                        <div className='mb-2'><span className='h5 me-2'>Gcash Number:</span><span className='h6'>{gcashNumber}</span></div>
                                        {/* <div className=''><span className='h5 me-2'>Requested Date:</span><span className='h6'>{formatDate(createdAt)}</span></div> */}
                                        <div className=''><span className='h5 me-2'>Requested Date:</span><span className='h6'>{formatDateWithTime(createdAt)}</span></div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                lg={{ span: 6, offset: 3 }}
                                md={{ span: 10, offset: 1 }}
                                className='boxContainer'
                            >
                                <div className='text-end mt-2 mb-4'>
                                    <Button variant='secondary' className='me-2' onClick={handleDisapprove}>Disapprove</Button>
                                    <Button variant='primary' className='me-2' onClick={handleApprove}>Approve</Button>
                                </div>
                                <div className='text-center mt-2 mb-4'>
                                    <a href={ROUTES.ADMIN_WITHDRAWAL_REQUESTS_ROUTE + '/1'}>Back to Request List</a>
                                </div>
                            </Col>
                        </Row>
                    </>
                }
            </Container >
        </>
    );
}

export default WithdrawalRequestView;