import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../resources/routes-constants'

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate()

    /**
     * Call this function to redirect the user to the homepage.
     */
    const redirectToHomePage = () => {
        navigate(ROUTES.HOMEPAGE_ROUTE)
    }

    return (
        <>
            <Container>
                <Row className='vh-100 d-flex align-items-center text-center'>
                    <Col className=''>
                        <p className='display-1'>Oops!</p>
                        <p className='text-warning display-6'>404 - Page Not Found</p>
                        <div className='mt-5'>
                            <span style={{ cursor: 'pointer' }} onClick={() => redirectToHomePage()} className='text-center w-25 p-3 btn-primary '>
                                Go to Home Page
                            </span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default NotFoundPage
