import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { Button } from 'react-bootstrap';
import AdminCustomModal from '../../components/admin/modals/AdminCustomModal';
import AdminCustomToast from '../../components/admin/AdminCustomToast';
import Form from 'react-bootstrap/Form';
import { ROUTES } from '../../resources/routes-constants';

const UsersOperatorEdit: React.FC = () => {
    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const transaction = () => 'add-operator';
    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);
    const handleCloseToast = () => setShowToast(false);

    const initialFormData = Object.freeze({
        status: "",
        name: "",
    });

    const [formData, updateFormData] = React.useState(initialFormData);

    const handleChange = (e: any) => {
        updateFormData({
            ...formData,

            // Trimming any whitespace
            [e.target.name]: e.target.value.trim()
        });
    };

    const [showToastType, setShowToastType] = useState('success');
    const result = true;

    const handleSubmit = (e: any) => {
        e.preventDefault();

        //api call
        if (result) {
            setShowToastType('success');
        } else {
            setShowToastType('fail');
        }

        setShow(false);
        setShowToast(true);
    };


    return (
        <>
            <AdminCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType}/>
            <AdminCustomModal show={show} close={handleClose} submit={handleSubmit} transaction={transaction} />

            <Container>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>Operator Profile</h4>
                    </Col>
                </Row>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 8, offset: 2 }}
                        className='boxContainer px-5 pt-4 pb-4'>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="exampl e Form.ControlInput1">
                                <Form.Label><h6>Email : email@email.com</h6></Form.Label>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="example Form.ControlInput1">
                                <Form.Label><h6>Full Name</h6></Form.Label>
                                <Form.Control type="text" placeholder="full name" name='name' onChange={handleChange} required/>
                            </Form.Group>
                            <Form.Group className="mb-3 mt" controlId="example Form.ControlInput1">
                                <Form.Label>
                                    <h6 className='mb-0'>Status</h6>
                                </Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="Activate"
                                    className='m-0'
                                    name='status' 
                                    onChange={handleChange}/>
                            </Form.Group>
                            <div className='text-end mt-4'>
                                {/* <Button variant="danger" className='me-2'>Deactivate</Button> */}
                                <Button href={ROUTES.ADMIN_OPERATORS_ROUTE} variant="secondary" className='me-2'>Back</Button>
                                <Button variant="primary" type='submit'>Update</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container >
        </>
    );
}

export default UsersOperatorEdit;