import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { ROUTES } from '../../resources/routes-constants';
import { Button } from 'react-bootstrap';

import OperatorCustomToast from '../../components/operator/OperatorCustomToast';

import useOperatorGameLogic from '../../components/operator/OperatorGameLogic';

const OperatorGameEdit: React.FC = () => {
    const {
        showToast, handleCloseToast, showToastType,
        show, handleClose,
        handleSubmit

    } = useOperatorGameLogic();


    return (
        <>
            <OperatorCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />

            <Container>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>Edit Game</h4>
                    </Col>
                </Row>
                <Row>
                    <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 8, offset: 2 }}
                        className='boxContainer px-5 pt-4 pb-4'>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3 position-relative">
                                <Form.Label><h6>Name</h6></Form.Label>
                                <Form.Control type='text' name='name' placeholder="name" required />
                            </Form.Group>
                            <Form.Group className="mb-3 position-relative">
                                <Form.Label><h6>Category</h6></Form.Label>
                                <Form.Select aria-label="Select Category" required>
                                    <option value='' >Select Category</option>
                                    <option value="1">Category 1</option>
                                    <option value="2">Cat Two</option>
                                    <option value="3">Cat 333</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3 mt-4">
                                <Form.Label>
                                    <h6 className='mb-0'>Status</h6>
                                </Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="Activate"
                                    className='m-0'
                                    name='status'
                                    />
                            </Form.Group>
                            <div className='text-end mt-4'>
                                <Button href={ROUTES.OPERATOR_GAMES_ROUTE + '/1'} variant="secondary" className='me-2'>Back</Button>
                                <Button variant="primary" type='submit'>Update</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container >
        </>
    );
}

export default OperatorGameEdit;