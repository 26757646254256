import axios from "axios";
import { API } from "resources/api-constants";
import { ROUTES } from "resources/routes-constants";

const CMS = API.CMS_URL;

export const clientAxios = axios.create({
   baseURL: CMS,
   headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access-token') }
});

// Function that will be called to refresh authorization
export const refreshAuthLogic = (failedRequest) =>
   clientAxios.post(API.ADMIN_REFRESH_TOKEN).then((response) => {
      
      localStorage.setItem('access-token', response.data.access.token);
      localStorage.setItem('login-date', String(Date.now()));
      localStorage.setItem('expires', response.data.access.expires_in);
      localStorage.setItem('user-id', response.data.data.user_id);
      localStorage.setItem('email-address', response.data.data.email);
      localStorage.setItem('name', response.data.data.name);
      
      return Promise.resolve();
   });

export const refreshToken = () => {
   let result = false;

   clientAxios.post(
      API.ADMIN_REFRESH_TOKEN, {},
      {
         headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access-token') }
      }
   ).then((response) => {

      localStorage.setItem('access-token', response.data.access.token);
      localStorage.setItem('login-date', String(Date.now()));
      localStorage.setItem('expires', response.data.access.expires_in);

      result = true;
   }).catch((error) => {
      result = false;
   });

   return result;
}

export const validateToken = async() => {
   let result = false;
   await clientAxios.post(
      API.ADMIN_VALID_TOKEN, {},
      {
         headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access-token') }
      }
   ).then((response) => {
      result = response.data.valid;
      localStorage.setItem('validateResult', result);
   }).catch((error) => {
      result = false;
      localStorage.setItem('validateResult', result);
   });
}

export const validToken = () => {
   var result = false;
   clientAxios.post(
      API.PLAYER_VALID_TOKEN, {},
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access-token') } }
   ).then((response) => {
      result = response.data.valid;

      if (!result) { // expired token
         // homeRedirect(ROUTES.PLAYER_LOGOUT_ROUTE);
         localStorage.clear();
         window.location.href = ROUTES.ADMIN_LOGIN_ROUTE;
      }
   }).catch((error) => {
      result = false;
      window.location.href = ROUTES.ADMIN_LOGIN_ROUTE;
   });
}