import React, { useState, useCallback } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { ROUTES } from '../../resources/routes-constants';
import { Button } from 'react-bootstrap';

import ImageViewer from 'react-simple-image-viewer';

import OperatorCustomToast from '../../components/operator/OperatorCustomToast';
import useOperatorGameLogic from '../../components/operator/OperatorGameLogic';

const OperatorGameView: React.FC = () => {
    const {
        showToast, handleCloseToast, showToastType,
        show, handleClose,
        handleSubmit, error,
        gameName, image, status, gameId,
        createdBy, createdAt, updatedAt,
        loadingStatus
    } = useOperatorGameLogic();

    //IMAGE VIEWER
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const images = [
        image
    ];

    const openImageViewer = useCallback((index: any) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    //END IMAGE VIEWER

    if (loadingStatus)
        return null;

    return (
        <>
            <OperatorCustomToast show={showToast} close={handleCloseToast} showToastType={showToastType} />

            <Container>
                <Row>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        className="text-center mb-5">
                        <h4>View Game</h4>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 8, offset: 2 }}
                        className='boxContainer px-5 pt-4 pb-4'>
                        <Form onSubmit={handleSubmit} className='viewGameForm'>
                            <Form.Group className="mb-3 position-relative">
                                <Form.Label><h6>Name</h6></Form.Label>
                                <Form.Control type='text' name='name' placeholder="name" defaultValue={gameName} required />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label><h6>Image</h6></Form.Label><br />
                                <Form.Label className='d-flex justify-content-center mb-2'>
                                    <img
                                        src={
                                            image ?
                                                image :
                                                '/images/placeholder.png'
                                        }
                                        className='text-center w-50'
                                        onClick={() => openImageViewer(0)}
                                        alt=''
                                    />
                                </Form.Label>
                                <Form.Control type="file" placeholder="Choose File" name='image' />
                                <Form.Text className="text-muted">Maximum size (2Mb)</Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3 mt-4">
                                <Form.Label>
                                    <h6 className='mb-0'>Status</h6>
                                </Form.Label>
                                <div className="m-0 form-check">
                                    {status ?
                                        <input name="status" type="checkbox" id="custom-switch" className="form-check-input" defaultChecked /> :
                                        <input name="status" type="checkbox" id="custom-switch" className="form-check-input" />
                                    }
                                    <label title="" htmlFor="custom-switch" className="form-check-label">Activate</label>
                                </div>
                            </Form.Group>
                            <Form.Group className="">
                                <Form.Label>Created By : </Form.Label>
                                <Form.Text className="ms-2">{createdBy}</Form.Text>
                            </Form.Group>
                            <Form.Group className="">
                                <Form.Label>Created : </Form.Label>
                                <Form.Text className="ms-2">{createdAt}</Form.Text>
                            </Form.Group>
                            <Form.Group className="">
                                <Form.Label>Updated : </Form.Label>
                                <Form.Text className="ms-2">{updatedAt}</Form.Text>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className='d-flex justify-content-center'><span className='text-danger'>&nbsp;{error}</span></Form.Label><br />
                            </Form.Group>
                            <div className='text-end'>
                                {/*<Button href={ROUTES.OPERATOR_GAMES_ROUTE + '/' + gameId + '/1'} onClick={() => window.history.go(-1)} variant="secondary" className='me-2'>Back</Button>*/}
                                <Button onClick={() => window.history.go(-1)} variant="secondary" className='me-2'>Back</Button>
                                <Button variant="primary" type='submit'>Update</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container >

            {isViewerOpen && (
                <ImageViewer
                    src={images}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
        </>
    );
}

export default OperatorGameView;